import { useCallback } from 'react';
import { useDispatch } from 'react-redux';

import { useSelector } from '@hh.ru/front-static-app';

import { ErrorsMap, useEditorError } from 'src/models/applicant/resume/editor/form/errors';
import { makeErrorMessage } from 'src/models/applicant/resume/editor/form/errors/lib';
import {
    selectResumeEditorDeleting,
    selectResumeEditorSaving,
} from 'src/models/applicant/resume/editor/store/loading/selectors';
import {
    selectResumeConditionByKey,
    selectResumeEditorErrors,
} from 'src/models/applicant/resume/editor/store/selectors';
import {
    setResumeEditorFieldValue,
    clearResumeEditorErrorByKey,
    clearResumeEditorErrorByNamePath,
} from 'src/models/applicant/resume/editor/store/slice';
import { ApplicantResumeEditorFields } from 'src/models/applicant/resume/editor/types';
import { Condition } from 'src/models/applicant/resume/types/conditions';

interface FieldData<Value, OnChangeValue = Value> {
    value: Value;
    invalid: boolean;
    onChange: (value: OnChangeValue, resetError?: boolean) => void;
    disabled: boolean;
    condition?: Condition;
    errorMessage: string | null | undefined;
}

export const useResumeEditorField = <Key extends keyof ApplicantResumeEditorFields>(
    key: Key,
    errorsMap: ErrorsMap = {},
    _: string[] = []
): FieldData<Required<ApplicantResumeEditorFields>[Key]> => {
    const dispatch = useDispatch();
    const saving = useSelector(selectResumeEditorSaving);
    const deleting = useSelector(selectResumeEditorDeleting);
    const condition = useSelector(selectResumeConditionByKey(key));

    const value = useSelector(
        ({ resumeEditor }) => resumeEditor.fields[key] as Required<ApplicantResumeEditorFields>[Key]
    );

    const { invalid, errorMessage } = useEditorError(key, errorsMap, value, condition);

    const onChange = useCallback(
        (value: ApplicantResumeEditorFields[Key], resetError = true) => {
            dispatch(setResumeEditorFieldValue({ [key]: value }));
            if (resetError) {
                dispatch(clearResumeEditorErrorByKey(key));
            }
        },
        [dispatch, key]
    );

    return {
        value,
        invalid,
        onChange,
        disabled: saving || deleting,
        condition,
        errorMessage,
    };
};

/**
 * Хук для полей, которые массивы
 * рекомендации, опыт и т.д.
 * Отличие в том что ошибки приходять по ключам
 */
export const useResumeEditorListField = <Value extends string | number | number[] | null = string>(
    key:
        | 'recommendation'
        | 'additionalEducation'
        | 'attestationEducation'
        | 'elementaryEducation'
        | 'primaryEducation'
        | 'experience'
        | 'certificate',
    index: number,
    subname: string,
    errorsMap: ErrorsMap = {}
): FieldData<Value> => {
    const dispatch = useDispatch();
    const saving = useSelector(selectResumeEditorSaving);
    const deleting = useSelector(selectResumeEditorDeleting);
    const condition = useSelector(selectResumeConditionByKey(key));
    const formErrors = useSelector(selectResumeEditorErrors);
    const namePath = `/${key}/${index}/${subname}`;

    const field = useSelector(({ resumeEditor }) => resumeEditor.fields[key]);

    const fieldFormErrors = formErrors?.[key] || [];
    const errors = makeErrorMessage(fieldFormErrors, errorsMap, condition, field);
    const error = errors.find(({ fieldNamePath }) => fieldNamePath === namePath);

    const onChange = (value: Value, resetError = true) => {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        dispatch(setResumeEditorFieldValue({ [key]: { ...field, [subname]: value } }));
        if (resetError) {
            dispatch(clearResumeEditorErrorByNamePath({ key, namePath }));
        }
    };
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const value = field?.[subname] as Value;

    return {
        value,
        onChange,
        disabled: saving || deleting,
        invalid: !!error,
        errorMessage: error?.message,
    };
};
