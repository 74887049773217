import { FC, PropsWithChildren } from 'react';

import { FormLabel, VSpacingContainer } from '@hh.ru/magritte-ui';

interface Props {
    label: string;
    htmlFor: string;
}

const EditorField: FC<PropsWithChildren<Props>> = ({ children, label, htmlFor }) => (
    <VSpacingContainer default={12}>
        <FormLabel htmlFor={htmlFor}>{label}</FormLabel>
        {children}
    </VSpacingContainer>
);

export default EditorField;
