import { useMemo } from 'react';
import { useDispatch } from 'react-redux';

import { translation, useSelector } from '@hh.ru/front-static-app';
import { FieldsGroupHelper, NumberInput, Text, useBreakpoint, VSpacingContainer } from '@hh.ru/magritte-ui';

import {
    selectResumeEditorPrimaryEducationIndex,
    selectResumeEditorOpenedPrimaryEducation,
} from 'src/models/applicant/resume/blocks/primaryEducation/editor/selectors';
import { selectApplicantResumePrimaryEducation } from 'src/models/applicant/resume/blocks/primaryEducation/selectors';
import { useResumeEditorListField } from 'src/models/applicant/resume/editor/form/change';
import { useTranslatedErrorMap } from 'src/models/applicant/resume/editor/form/errors';
import { isNewArrayField } from 'src/models/applicant/resume/editor/lib';
import { deleteFieldResumeEditor } from 'src/models/applicant/resume/editor/store/actions/delete';
import { submitResumeEditorData } from 'src/models/applicant/resume/editor/store/actions/submitEditor';
import { closeResumeEditorModal } from 'src/models/applicant/resume/editor/store/actions/toggleModal';
import EditorModal from 'src/pages/ResumeView/redesign/common/EditorModal';

import { FacultySuggest } from 'src/pages/ResumeView/redesign/blocks/education/Editor/PrimaryEducation/FacultySuggest';
import { SpecialitySuggest } from 'src/pages/ResumeView/redesign/blocks/education/Editor/PrimaryEducation/SpecialitySuggest';
import { UniversitySuggest } from 'src/pages/ResumeView/redesign/blocks/education/Editor/PrimaryEducation/UniversitySuggest';

import styles from './styles.less';

const TrlKeys = {
    title: 'resume.editor.primaryEducation.title',
    delete: 'resume.editor.primaryEducation.delete.title',
    labels: {
        name: 'resume.editor.primaryEducation.labels.name',
        result: 'resume.editor.primaryEducation.labels.position',
        organization: 'resume.editor.primaryEducation.labels.organization',
        year: 'resume.editor.primaryEducation.labels.year',
    },
    description: 'resume.editor.primaryEducation.year.description',
    commonErrors: {
        UNKNOWN: 'resume.editor.field.error.unknown',
        REQUIRED: 'resume.editor.field.error.required',
        NOT_MATCH_REGEXP: 'resume.editor.field.error.regexp',
        LENGTH_GREATER_THAN_MAXIMUM: {
            one: 'resume.editor.field.error.max-length.one',
            some: 'resume.editor.field.error.max-length.some',
            many: 'resume.editor.field.error.max-length.many',
        },
        EARLY_THAN_MINIMUM: 'resume.editor.field.year.error.min-date',
        LATER_THAN_MAXIMUM: 'resume.editor.field.year.error.max-date',
        VALUE_LESS_THAN_MINIMUM: 'resume.editor.field.year.error.min-date',
        VALUE_GREATER_THAN_MAXIMUM: 'resume.editor.field.year.error.max-date',
    },
};
const FIELD_NAME = 'primaryEducation';

export const ResumePrimaryEducationEditor = translation(({ trls }) => {
    const { isMobile } = useBreakpoint();
    const dispatch = useDispatch();

    const visible = useSelector(selectResumeEditorOpenedPrimaryEducation);
    const primaryEducations = useSelector(selectApplicantResumePrimaryEducation);

    const index = useSelector(selectResumeEditorPrimaryEducationIndex);
    const errorsMap = useTranslatedErrorMap(TrlKeys.commonErrors, trls);

    const id = useResumeEditorListField<number>(FIELD_NAME, index, 'id');
    const year = useResumeEditorListField<number>(FIELD_NAME, index, 'year', errorsMap);

    const handleSave = () => dispatch(submitResumeEditorData());
    const handleClose = () => dispatch(closeResumeEditorModal());
    const handleDelete = () => dispatch(deleteFieldResumeEditor(trls[TrlKeys.delete]));

    const educationCount = primaryEducations.length;
    const isNew = useMemo(() => isNewArrayField({ id: id.value }), [id.value]);
    const canDelete = educationCount > 1 && !isNew;

    return (
        <EditorModal
            title={trls[TrlKeys.title]}
            visible={visible}
            onSave={handleSave}
            onClose={handleClose}
            onDelete={canDelete ? handleDelete : undefined}
        >
            <VSpacingContainer default={12}>
                <UniversitySuggest />
                <FacultySuggest />
                <SpecialitySuggest />
                <FieldsGroupHelper invalid={year.invalid} errorMessage={isMobile ? undefined : year.errorMessage}>
                    <div className={styles.root}>
                        <div className={styles.year}>
                            <NumberInput
                                size="medium"
                                value={year.value === 0 ? '' : `${year.value}`}
                                maxLength={4}
                                groupSize={0}
                                allowNegative={false}
                                invalid={year.invalid}
                                onChange={(newValue) => year.onChange(newValue ? Number(newValue) : 0)}
                                errorMessage={isMobile ? year.errorMessage : undefined}
                                placeholder={trls[TrlKeys.labels.year]}
                            />
                        </div>
                        <div className={styles.hint}>
                            <Text style="secondary" typography={'label-3-regular'}>
                                {trls[TrlKeys.description]}
                            </Text>
                        </div>
                    </div>
                </FieldsGroupHelper>
            </VSpacingContainer>
        </EditorModal>
    );
});
