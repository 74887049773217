import { ChangeEvent } from 'react';

import { translation, useSelector } from '@hh.ru/front-static-app';
import { CheckableChip, NumberInput, HSpacingContainer, ScrollableContainer, ScrollableItem } from '@hh.ru/magritte-ui';

import { getCurrencyLabel } from 'src/components/Currency';
import { selectCurrencies } from 'src/models/applicant/resume/blocks/currencies/selectors';
import { useResumeEditorField } from 'src/models/applicant/resume/editor/form/change';
import { ErrorsMap } from 'src/models/applicant/resume/editor/form/errors';
import { CurrencyType } from 'src/models/currencies.types';
import EditorField from 'src/pages/ResumeView/redesign/common/Form/EditorField';

const FIELD_NAME = 'salary';

const TrlKeys = {
    hint: 'resume.editor.salary.hint.v1',
    label: 'resume.editor.salary.label.v1',
};

const diffCountErrorKeys = ['LENGTH_GREATER_THAN_MAXIMUM'];

const SalaryField = translation<{ commonErrors: ErrorsMap }>(({ trls, commonErrors }) => {
    const { value, onChange, invalid, errorMessage } = useResumeEditorField(
        FIELD_NAME,
        commonErrors,
        diffCountErrorKeys
    );

    const currencies = useSelector(selectCurrencies);
    const currencyPostfix = getCurrencyLabel({ trls, value: value.currency });

    const handleAmountChanged = (amount: string) => {
        onChange({ ...value, amount: parseInt(amount || '0', 10) });
    };
    const handleCurrencyChanged = (event: ChangeEvent<HTMLInputElement>) => {
        onChange({ ...value, currency: event.target.value as CurrencyType });
    };

    return (
        <EditorField htmlFor="salary" label={trls[TrlKeys.label]}>
            <NumberInput
                size="medium"
                placeholder={trls[TrlKeys.hint]}
                value={value.amount !== null ? value.amount.toString() : ''}
                invalid={invalid}
                postfix={currencyPostfix}
                maxLength={10}
                allowNegative={false}
                onChange={handleAmountChanged}
                errorMessage={errorMessage}
            />
            <ScrollableContainer>
                <HSpacingContainer default={8}>
                    {currencies.map(({ code }) => (
                        <ScrollableItem key={code}>
                            <CheckableChip
                                type="radio"
                                name="currency"
                                value={code}
                                checked={value.currency === code}
                                onChange={handleCurrencyChanged}
                            >
                                {getCurrencyLabel({ trls, value: code, fullLabel: true, capitalizeFirstLetter: true })}
                            </CheckableChip>
                        </ScrollableItem>
                    ))}
                </HSpacingContainer>
            </ScrollableContainer>
        </EditorField>
    );
});

export default SalaryField;
