import { Link } from '@hh.ru/magritte-ui';
import { GlobeOutlinedSize16 } from '@hh.ru/magritte-ui/icon';
import {
    FacebookSolidSize16,
    FlSolidSize16,
    LinkedinSolidSize16,
    LivejournalSolidSize16,
    MediumSolidSize16,
} from '@hh.ru/magritte-ui/service';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import DenseText from 'src/components/DenseText';
import translation from 'src/components/translation';
import { PersonalSite } from 'src/models/resume/resumeCommon.types';
import { NON_BREAKING_SPACE } from 'src/utils/constants/symbols';

import styles from 'src/pages/ResumeView/employerRedesign/components/ResumeContacts/styles.less';

const TrlKeys = {
    facebook: 'rb.personalsites.facebook',
    moi_krug: 'rb.personalsites.moi_krug',
    freelance: 'rb.personalsites.freelance',
    livejournal: 'rb.personalsites.livejournal',
    linkedin: 'rb.personalsites.linkedin',
    personal: 'rb.personalsites.personal',
};

const ICONS = {
    personal: GlobeOutlinedSize16,
    facebook: FacebookSolidSize16,
    freelance: FlSolidSize16,
    livejournal: LivejournalSolidSize16,
    moi_krug: MediumSolidSize16,
    linkedin: LinkedinSolidSize16,
};

const emptyFn = () => {};

const SocialSite: TranslatedComponent<PersonalSite> = ({ type, text, url, trls }) => {
    const contentText = text || trls[TrlKeys[type]];
    const Component = ICONS[type] || emptyFn;
    const icon = <Component padding={4} initialColor="secondary" />;
    return url ? (
        <div className={styles.icon}>
            {icon}
            <Link
                typography="label-3-regular"
                style="neutral"
                key={type}
                href={url}
                rel="noopener noreferrer"
                target="_blank"
            >
                <DenseText typography="label-3-regular">{contentText}</DenseText>
            </Link>
        </div>
    ) : (
        <div className={styles.icon} key={type}>
            {icon}
            <DenseText typography="label-3-regular">{`${trls[TrlKeys[type]]}:${NON_BREAKING_SPACE}${contentText}`}</DenseText>
        </div>
    );
};

export default translation(SocialSite);
