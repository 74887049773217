import { useMemo } from 'react';
import { useDispatch } from 'react-redux';

import { translation, useSelector } from '@hh.ru/front-static-app';
import {
    FieldsGroupHelper,
    Input,
    NumberInput,
    Text,
    TextArea,
    useBreakpoint,
    VSpacingContainer,
} from '@hh.ru/magritte-ui';

import {
    selectResumeEditorElementaryEducationIndex,
    selectResumeEditorOpenedElementaryEducation,
} from 'src/models/applicant/resume/blocks/elementaryEducation/editor/selectors';
import { useResumeEditorListField } from 'src/models/applicant/resume/editor/form/change';
import { useTranslatedErrorMap } from 'src/models/applicant/resume/editor/form/errors';
import { isNewArrayField } from 'src/models/applicant/resume/editor/lib';
import { deleteFieldResumeEditor } from 'src/models/applicant/resume/editor/store/actions/delete';
import { submitResumeEditorData } from 'src/models/applicant/resume/editor/store/actions/submitEditor';
import { closeResumeEditorModal } from 'src/models/applicant/resume/editor/store/actions/toggleModal';
import EditorModal from 'src/pages/ResumeView/redesign/common/EditorModal';

import styles from './styles.less';

const TrlKeys = {
    title: 'resume.editor.elementaryEducation.title',
    delete: 'resume.editor.elementaryEducation.delete.title',
    labels: {
        name: 'resume.editor.elementaryEducation.labels.name',
        year: 'resume.editor.elementaryEducation.labels.year',
    },
    description: 'resume.editor.elementaryEducation.year.description',
    commonErrors: {
        UNKNOWN: 'resume.editor.field.error.unknown',
        REQUIRED: 'resume.editor.field.error.required',
        NOT_MATCH_REGEXP: 'resume.editor.field.error.regexp',
        LENGTH_GREATER_THAN_MAXIMUM: {
            one: 'resume.editor.field.error.max-length.one',
            some: 'resume.editor.field.error.max-length.some',
            many: 'resume.editor.field.error.max-length.many',
        },
        EARLY_THAN_MINIMUM: 'resume.editor.field.year.error.min-date',
        LATER_THAN_MAXIMUM: 'resume.editor.field.year.error.max-date',
        VALUE_LESS_THAN_MINIMUM: 'resume.editor.field.year.error.min-date',
        VALUE_GREATER_THAN_MAXIMUM: 'resume.editor.field.year.error.max-date',
    },
};
const FIELD_NAME = 'elementaryEducation';

export const ResumeElementaryEducationEditor = translation(({ trls }) => {
    const { isMobile } = useBreakpoint();
    const dispatch = useDispatch();

    const visible = useSelector(selectResumeEditorOpenedElementaryEducation);

    const index = useSelector(selectResumeEditorElementaryEducationIndex);
    const errorsMap = useTranslatedErrorMap(TrlKeys.commonErrors, trls);

    const id = useResumeEditorListField<number>(FIELD_NAME, index, 'id');
    const name = useResumeEditorListField(FIELD_NAME, index, 'name', errorsMap);
    const year = useResumeEditorListField<number>(FIELD_NAME, index, 'year', errorsMap);

    const handleSave = () => dispatch(submitResumeEditorData());
    const handleClose = () => dispatch(closeResumeEditorModal());
    const handleDelete = () => dispatch(deleteFieldResumeEditor(trls[TrlKeys.delete]));

    const isNew = useMemo(() => isNewArrayField({ id: id.value }), [id.value]);

    return (
        <EditorModal
            title={trls[TrlKeys.title]}
            visible={visible}
            onSave={handleSave}
            onClose={handleClose}
            onDelete={!isNew ? handleDelete : undefined}
        >
            <VSpacingContainer default={12}>
                {isMobile ? (
                    <TextArea
                        layout={'hug'}
                        value={name.value}
                        invalid={name.invalid}
                        onChange={(e) => name.onChange(e.target.value)}
                        errorMessage={name.errorMessage}
                        placeholder={trls[TrlKeys.labels.name]}
                    />
                ) : (
                    <Input
                        size="medium"
                        value={name.value}
                        invalid={name.invalid}
                        onChange={name.onChange}
                        errorMessage={name.errorMessage}
                        placeholder={trls[TrlKeys.labels.name]}
                    />
                )}

                <FieldsGroupHelper invalid={year.invalid} errorMessage={isMobile ? undefined : year.errorMessage}>
                    <div className={styles.root}>
                        <div className={styles.year}>
                            <NumberInput
                                size="medium"
                                value={year.value === 0 ? '' : `${year.value}`}
                                maxLength={4}
                                groupSize={0}
                                allowNegative={false}
                                invalid={year.invalid}
                                onChange={(newValue) => year.onChange(newValue ? Number(newValue) : 0)}
                                placeholder={trls[TrlKeys.labels.year]}
                                errorMessage={isMobile ? year.errorMessage : undefined}
                            />
                        </div>
                        <div className={styles.hint}>
                            <Text style="secondary" typography={'label-3-regular'}>
                                {trls[TrlKeys.description]}
                            </Text>
                        </div>
                    </div>
                </FieldsGroupHelper>
            </VSpacingContainer>
        </EditorModal>
    );
});
