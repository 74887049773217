import { FC, PropsWithChildren } from 'react';

import { Banner } from '@hh.ru/magritte-ui';

import InfoBlockSubstrate from 'src/components/InfoBlockSubstrate';
import BuyContacts from 'src/components/ResumeContacts/BuyContacts';
import { useMagritte } from 'src/hooks/useMagritte';
import { useSelectorNonNullable, useSelector } from 'src/hooks/useSelector';

const ResumeWithoutContactInfo: FC<PropsWithChildren> = () => {
    const isShowSnippetResume = useSelector(({ snippetAccessResume }) => snippetAccessResume?.isShowSnippetResume);
    const additionalCheck = useSelector(({ additionalCheck }) => additionalCheck);
    const { hasPfpTopics, canOpenContacts } = useSelectorNonNullable((state) => state.resume);
    const isMagritte = useMagritte();

    if (!isShowSnippetResume || additionalCheck || hasPfpTopics || !canOpenContacts) {
        return null;
    }

    return isMagritte ? (
        <Banner style="accent-secondary" stretched showClose={false} content={<BuyContacts />} />
    ) : (
        <InfoBlockSubstrate data-qa="resume-demo-block">
            <BuyContacts />
        </InfoBlockSubstrate>
    );
};

export default ResumeWithoutContactInfo;
