import { ComponentProps, useMemo } from 'react';
import { useDispatch } from 'react-redux';

import { translation, useSelector } from '@hh.ru/front-static-app';
import { DateTimeInput, HSpacingContainer, Input, useBreakpoint, VSpacingContainer } from '@hh.ru/magritte-ui';

import {
    selectResumeEditorCertificateIndex,
    selectResumeEditorOpenedCertificate,
} from 'src/models/applicant/resume/blocks/certificates/editor/selectors';
import { useResumeEditorListField } from 'src/models/applicant/resume/editor/form/change';
import { useTranslatedErrorMap } from 'src/models/applicant/resume/editor/form/errors';
import { isNewArrayField } from 'src/models/applicant/resume/editor/lib';
import { deleteFieldResumeEditor } from 'src/models/applicant/resume/editor/store/actions/delete';
import { submitResumeEditorData } from 'src/models/applicant/resume/editor/store/actions/submitEditor';
import { closeResumeEditorModal } from 'src/models/applicant/resume/editor/store/actions/toggleModal';
import EditorModal from 'src/pages/ResumeView/redesign/common/EditorModal';

import styles from './styles.less';

const TrlKeys = {
    title: 'resume.editor.certificates.title',
    description: 'resume.editor.certificates.description',
    delete: 'resume.editor.certificates.delete.title',
    labels: {
        title: 'resume.editor.certificates.labels.title',
        year: 'resume.editor.certificates.labels.year',
        url: 'resume.editor.certificates.labels.url',
    },
    commonErrors: {
        UNKNOWN: 'resume.editor.field.error.unknown',
        REQUIRED: 'resume.editor.field.error.required',
        NOT_MATCH_REGEXP: 'resume.editor.field.error.regexp',
        EARLY_THAN_MINIMUM: 'resume.editor.field.year.error.min-date',
        LATER_THAN_MAXIMUM: 'resume.editor.field.year.error.max-date',
        LENGTH_GREATER_THAN_MAXIMUM: {
            one: 'resume.editor.field.error.max-length.one',
            some: 'resume.editor.field.error.max-length.some',
            many: 'resume.editor.field.error.max-length.many',
        },
    },
};
const FIELD_NAME = 'certificate';

export const ResumeCertificatesEditor = translation(({ trls }) => {
    const { isMobile } = useBreakpoint();
    const dispatch = useDispatch();

    const visible = useSelector(selectResumeEditorOpenedCertificate);

    const index = useSelector(selectResumeEditorCertificateIndex);
    const errorsMap = useTranslatedErrorMap(TrlKeys.commonErrors, trls);

    const id = useResumeEditorListField<number>(FIELD_NAME, index, 'id');

    const title = useResumeEditorListField(FIELD_NAME, index, 'title', errorsMap);

    const achievementDate = useResumeEditorListField(FIELD_NAME, index, 'achievementDate', errorsMap);
    const year = String(new Date(achievementDate.value).getFullYear() || '');
    const onChangeYear = (newYear: string) => achievementDate.onChange(`${newYear.padStart(4, '0')}-01-01`);

    const url = useResumeEditorListField<string | null>(FIELD_NAME, index, 'url', errorsMap);

    const handleSave = () => dispatch(submitResumeEditorData());
    const handleClose = () => dispatch(closeResumeEditorModal());
    const handleDelete = () => dispatch(deleteFieldResumeEditor(trls[TrlKeys.delete]));

    const isNew = useMemo(() => isNewArrayField({ id: id.value }), [id.value]);

    const dateInputProps: ComponentProps<typeof DateTimeInput> = {
        // @ts-expect-error
        dateMask: 'yyyy',
        size: 'medium',
        value: year,
        invalid: achievementDate.invalid,
        onChange: onChangeYear,
        errorMessage: achievementDate.errorMessage,
        placeholder: trls[TrlKeys.labels.year],
    };

    const titleInput = (
        <Input
            size="medium"
            value={title.value}
            invalid={title.invalid}
            onChange={title.onChange}
            errorMessage={title.errorMessage}
            placeholder={trls[TrlKeys.labels.title]}
        />
    );

    const dateInput = <DateTimeInput {...dateInputProps} />;

    return (
        <EditorModal
            title={trls[TrlKeys.title]}
            description={trls[TrlKeys.description]}
            visible={visible}
            onSave={handleSave}
            onClose={handleClose}
            onDelete={!isNew ? handleDelete : undefined}
        >
            <VSpacingContainer default={12}>
                {isMobile ? (
                    <>
                        {titleInput}
                        {dateInput}
                    </>
                ) : (
                    <HSpacingContainer default={12}>
                        {titleInput}
                        <div className={styles.year}> {dateInput}</div>
                    </HSpacingContainer>
                )}
                <Input
                    size="medium"
                    value={url.value ?? ''}
                    invalid={url.invalid}
                    onChange={url.onChange}
                    errorMessage={url.errorMessage}
                    placeholder={trls[TrlKeys.labels.url]}
                />
            </VSpacingContainer>
        </EditorModal>
    );
});
