import { FC } from 'react';

import ContactFeedbackBanner from 'src/components/EmployerSearch/ContactFeedbackBanner';
import { useBannerProps } from 'src/components/EmployerSearch/ContactFeedbackBanner/hooks/useBannerProps';
import { useSelector } from 'src/hooks/useSelector';

const ResumeFeedbackBanner: FC = () => {
    const hash = useSelector((state) => state.resume?.hash);
    const showContactSuccessBanner = useSelector((state) => state.resume?.showContactSuccessBanner);
    const [isBannerVisible] = useBannerProps({
        resumeHash: hash || '',
        shouldShowBanner: showContactSuccessBanner,
        isAllowedPage: true,
    });

    const showBanner = isBannerVisible && hash;

    return showBanner ? <ContactFeedbackBanner paddingSize="none" resumeHash={hash} /> : null;
};

export default ResumeFeedbackBanner;
