import { useDispatch } from 'react-redux';

import { ElementShownAnchor } from '@hh.ru/analytics-js';
import { translation, useSelector } from '@hh.ru/front-static-app';
import { usePush } from '@hh.ru/redux-spa-middleware';

import ResumeCompleteness from 'src/components/ResumeCompleteness';
import { useActionLinkAnalytics } from 'src/models/applicant/resume/analytics/provider';
import {
    selectResumeFieldPercent,
    selectResumeFieldStatuses,
} from 'src/models/applicant/resume/completeness/selectors';
import {
    EDITOR_QUERY_KEY,
    getBlockTypeByField,
    getProfileBlockTypeByField,
} from 'src/models/applicant/resume/completeness/utils';
import { openResumeEditorModal } from 'src/models/applicant/resume/editor/store/actions/toggleModal';

const TrlKeys = {
    description: 'resume.completeness.description',
    redDescription: 'resume.completeness.description.red',
};

const ResumeCompletenessCard = translation(({ trls }) => {
    const push = usePush();
    const dispatch = useDispatch();
    const analytics = useActionLinkAnalytics();

    const percent = useSelector(selectResumeFieldPercent);
    const { redFields, greenFields } = useSelector(selectResumeFieldStatuses);

    const fields = redFields.length ? redFields : greenFields;

    const handleClick = (field: string) => {
        analytics.resumeCompletenessTagClick(field);

        const profileType = getProfileBlockTypeByField(field);
        if (profileType) {
            push(`/profile/me?${EDITOR_QUERY_KEY}=${profileType}`);
            return;
        }

        const type = getBlockTypeByField(field);
        if (type) {
            void dispatch(openResumeEditorModal(type));
        }
    };

    if (fields.length === 0) {
        return null;
    }

    return (
        <ElementShownAnchor Element="span" fn={analytics.resumeCompletenessElementShown} fields={fields}>
            <ResumeCompleteness
                max={100}
                fields={greenFields}
                onClick={handleClick}
                redFields={redFields}
                progress={percent}
                description={trls[TrlKeys.description]}
                redDescription={trls[TrlKeys.redDescription]}
            />
        </ElementShownAnchor>
    );
});

export default ResumeCompletenessCard;
