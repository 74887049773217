import { translation } from '@hh.ru/front-static-app';
import { Cell, CellText } from '@hh.ru/magritte-ui';

const TrlKeys = {
    title: 'applicant.resume.proftest.title',
    description: 'applicant.resume.proftest.empty.description',
};

const MobileCellEmpty = translation(({ trls }) => {
    return (
        <Cell>
            <CellText>{trls[TrlKeys.title]}</CellText>
            <CellText type="subtitle">{trls[TrlKeys.description]}</CellText>
        </Cell>
    );
});

export default MobileCellEmpty;
