import { useMemo } from 'react';

import { translation, useSelector } from '@hh.ru/front-static-app';
import { VSpacingContainer } from '@hh.ru/magritte-ui';

import { selectResumeEditorPersonalSite } from 'src/models/applicant/resume/blocks/contacts/editor/selectors';
import { ErrorsMap } from 'src/models/applicant/resume/editor/form/errors';
import PersonalSiteField from 'src/pages/ResumeView/redesign/blocks/contacts/Editor/PersonalSite/Field';

const TrlKeys = {
    INVALID: 'resume.editor.field.personalSite.error.invalid',
    NOT_MATCH_REGEXP: 'resume.editor.field.personalSite.error.invalid',
};

const PersonalSiteFieldList = translation<{ errorsMap: ErrorsMap }>(({ errorsMap, trls }) => {
    const errors = useMemo(
        () => ({ ...errorsMap, NOT_MATCH_REGEXP: trls[TrlKeys.INVALID], INVALID: trls[TrlKeys.INVALID] }),
        [errorsMap, trls]
    );
    const personalSite = useSelector(selectResumeEditorPersonalSite);

    if (personalSite.length === 0) {
        return null;
    }

    return (
        <VSpacingContainer default={16}>
            {personalSite.map(({ type }, index) => (
                <PersonalSiteField key={type} type={type} fieldIndex={index} errorsMap={errors} />
            ))}
        </VSpacingContainer>
    );
});

export default PersonalSiteFieldList;
