import { useDispatch } from 'react-redux';

import { useSelector } from '@hh.ru/front-static-app';

import {
    updateKeySkillsLevels,
    cancelSkillsLevelsEditing,
} from 'src/models/applicant/resume/blocks/keySkills/skillsLevels/actions';
import { selectSkillsLevelsAlertVisibility } from 'src/models/applicant/resume/blocks/keySkills/skillsLevels/selector';
import { toggleSkillsLevelsAlert } from 'src/models/applicant/resume/blocks/keySkills/skillsLevels/slice';
import ChangedAlert from 'src/pages/ResumeView/redesign/common/Confirm/ChangedAlert';

const SkillChangedAlert = () => {
    const dispatch = useDispatch();

    const visible = useSelector(selectSkillsLevelsAlertVisibility);

    const handleClose = () => {
        dispatch(toggleSkillsLevelsAlert(false));
    };
    const handleCancel = () => {
        dispatch(cancelSkillsLevelsEditing());
    };
    const handleApprove = () => {
        dispatch(toggleSkillsLevelsAlert(false));
        void dispatch(updateKeySkillsLevels());
    };

    return <ChangedAlert visible={visible} onClose={handleClose} onApprove={handleApprove} onCancel={handleCancel} />;
};

export default SkillChangedAlert;
