import { DefaultRootState } from 'react-redux';
import { ActionCreator, Dispatch } from 'redux';

import { selectApplicantResume } from 'src/models/applicant/resume/selectors';
import { setApplicantResumeFields } from 'src/models/applicant/resume/slice';

import { selectResumeCreds } from 'src/models/applicant/resume/blocks/creds/selectors';

export const updateQuestionToAnswerMap = (questionToAnswerMap: Record<string, string[]>): ActionCreator<unknown> => {
    return (dispatch: Dispatch, getState: () => DefaultRootState) => {
        const resume = selectApplicantResume(getState());
        const currentCreds = selectResumeCreds(getState());

        dispatch(
            setApplicantResumeFields({
                ...resume,
                creds: {
                    ...currentCreds,
                    questionToAnswerMap,
                },
            })
        );
    };
};
