import { SVGProps, FC } from 'react';

const Female: FC<SVGProps<SVGSVGElement>> = (props) => (
    <svg data-qa="svg-female" viewBox="0 0 208 208" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
        <rect width="208" height="208" fill="#EEF1F7" />
        <path
            d="M127.173 79.8894C127.189 79.1933 128.026 79.1201 128.436 79.4316C128.754 79.6091 128.895 80.1021 128.809 80.5518C129.241 83.1982 129.581 85.7106 130.013 88.357C136.835 79.7558 149.235 76.9989 158.979 83.174C168.086 88.9941 171.001 111.745 135.501 120.502C131.5 123.502 117 125.002 111.753 103.279C111.682 93.2427 118.526 84.529 127.173 79.8894Z"
            fill="#DCE3EB"
        />
        <path
            d="M28.0441 167.788C21.826 195.052 18.7311 223.361 14.1881 250.885C9.42226 279.982 4.68042 308.967 -0.0853729 338.064L-1.00047 342.848C14.5039 346.419 30.8401 350.407 46.3671 353.868C90.9428 363.918 134.837 376.598 179.293 387.201C200.345 337.142 219.778 286.027 235.82 234.041C243.806 208.213 244.758 181.282 225.443 160.043C216.099 149.841 204.347 143.858 191.629 140.212C188.924 139.377 186.108 138.517 183.355 137.905C179.635 136.959 175.781 136.1 171.989 135.487C167.45 134.592 162.753 133.893 158.277 133.243L127.871 128.671L116.251 126.895C115.457 126.833 114.686 126.662 113.915 126.49C102.87 124.724 91.7381 122.824 80.6188 123.013C69.1682 123.13 56.9012 125.964 47.8363 133.224C36.9821 141.825 31.1109 154.087 28.0441 167.788Z"
            fill="#DCE3EB"
        />
        <path
            d="M103.74 123.732L95.6984 197.532C95.6984 197.532 137.13 157.315 158.742 133.268C155.051 132.316 151.227 131.449 147.465 130.831C142.962 129.929 138.302 129.224 133.861 128.569L103.74 123.732Z"
            fill="#ffffff"
        />
        <path
            d="M97.8143 115.298C99.0227 116.625 108.229 179.332 108.229 179.332L148.012 142.157L129 100L97.8143 115.298Z"
            fill="#ffffff"
        />
        <path
            d="M101.71 148.534C100.068 137.868 98.402 127.706 98.0001 127.274L112.673 122C113.831 131.449 108.467 141.173 101.71 148.534Z"
            fill=" #F1F4F9"
        />
        <path
            d="M121.545 40.7383C111.161 30.599 85.3871 35.4814 71.93 46.2862C66.0912 51.0172 60.5415 47.9025 61.6427 54.0842C64.3767 69.3597 74.0239 120.283 82.7631 126.794C100.374 139.728 121.191 125.699 127.329 107.658C133.554 89.7517 136.293 55.2372 121.545 40.7383Z"
            fill="#ffffff"
        />
        <path
            d="M56.0176 41.9837C61.9834 36.7831 74.9427 37.4243 81.3074 39.639C95.1733 33.9235 112.672 32.6801 120.968 40.1438C131.22 49.4771 133.446 67.5345 132.042 83.8281C125.848 83.0025 122.779 78.6471 116.718 75.0286C97.7466 73.2611 84.9353 61.0313 82.042 54.2301C78.3492 58.2067 63.3398 59.3993 58.12 55.1646C51.1278 48.7596 54.1283 43.6248 56.0176 41.9837Z"
            fill="#DCE3EB"
        />
        <path
            d="M142.838 74.8316C138.561 67.841 123.674 66.785 116.5 75.5006C118.078 84.3219 121.503 92.5524 122.726 101.41C127.77 102.657 137.388 100.869 141.908 95.386C147.168 89.1414 147.462 82.3637 142.838 74.8316Z"
            fill="#ffffff"
        />
    </svg>
);

export { Female };
