import { Link, Text, VSpacingContainer } from '@hh.ru/magritte-ui';
import { ArrowRightOutlinedSize16 } from '@hh.ru/magritte-ui/icon';
import { SPALink } from '@hh.ru/redux-spa-middleware';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import formatDate from 'Modules/formatDate';
import translation from 'src/components/translation';
import { EmployerStateExtName } from 'src/models/negotiationTopic.types';
import { NegotiationsHistory } from 'src/models/negotiationsHistory';

import styles from './styles.less';

const TrlKeys = {
    author: 'resume.employer.history.author',
    title: 'resume.employer.history.title',
    expand: 'resume.employer.history.expand',
    collapse: 'resume.employer.history.collapse',
    loading: 'resume.employer.history.error.loading',
    [EmployerStateExtName.Response]: 'resume.employer.history.response',
    [EmployerStateExtName.Interview]: 'resume.employer.history.interview',
    [EmployerStateExtName.PhoneInterview]: 'resume.employer.history.phone_interview',
    [EmployerStateExtName.Assessment]: 'resume.employer.history.assessment',
    [EmployerStateExtName.Consider]: 'resume.employer.history.consider',
    [EmployerStateExtName.Hired]: 'resume.employer.history.hired',
    [EmployerStateExtName.Offer]: 'resume.employer.history.offer',
    [EmployerStateExtName.DiscardByEmployer]: 'resume.employer.history.discard_by_employer.softForm',
    [EmployerStateExtName.DiscardByApplicant]: 'resume.employer.history.discard_by_applicant',
    [EmployerStateExtName.DiscardNoInteraction]: 'resume.employer.history.discard_no_interaction',
    [EmployerStateExtName.DiscardVacancyClosed]: 'resume.employer.history.discard_vacancy_closed',
    [EmployerStateExtName.DiscardToOtherVacancy]: 'resume.employer.history.discard_to_other_vacancy',
};

interface ItemProp {
    vacancy: NegotiationsHistory;
}

const Item: TranslatedComponent<ItemProp> = ({ vacancy, trls }) => (
    <VSpacingContainer default={4} data-qa="resume-history-item">
        <Link
            Element={SPALink}
            data-qa="resume-history-item-text"
            to={`/vacancy/${vacancy['@vacancyId']}`}
            style="neutral"
            typography="label-3-regular"
        >
            {vacancy['@name']}
        </Link>

        {vacancy.items.map((item, index) => (
            <div className={styles.vacancyItem} key={index}>
                <ArrowRightOutlinedSize16 initialColor="secondary" />
                <Text Element="span" typography="label-3-regular" style="secondary" data-qa="resume-history-item-info">
                    {trls[TrlKeys[item['@employerStateExtName']]]}
                    {', '}
                    <span suppressHydrationWarning>{formatDate(item['@changeTime'], 'dd.MM.yy')}</span>
                    {item['@senderEmployerManagerId'] && (
                        <>
                            {' '}
                            {trls[TrlKeys.author]}
                            {': '}
                            <Link
                                inline
                                Element={SPALink}
                                to={`/employer/settings?employerManagerId=${item['@senderEmployerManagerId']}`}
                                typography="label-3-regular"
                            >
                                {item['@senderFullName']}
                            </Link>
                        </>
                    )}
                </Text>
            </div>
        ))}
    </VSpacingContainer>
);

export default translation(Item);
