import { ReactNode, FC, ComponentPropsWithoutRef } from 'react';
import classnames from 'classnames';

import Column, { ColumnsRow } from 'bloko/blocks/column';
import { H2, SubHeader } from 'bloko/blocks/header';
import VSpacing from 'bloko/blocks/vSpacing';

import { useGetMColumnSize } from 'src/pages/ResumeView/hooks/useGetMColumnSize';

interface ResumeBlockProps {
    title: string | ReactNode;
    subTitle?: string;
    noPrint?: boolean;
    EditLink: ReactNode;
    children: ReactNode;
    profileTitle?: boolean;
}

const ResumeBlock: FC<ComponentPropsWithoutRef<'div'> & ResumeBlockProps> = ({
    title,
    subTitle,
    EditLink,
    children,
    noPrint = false,
    profileTitle = false,
    ...otherProps
}) => {
    const { mMain } = useGetMColumnSize();
    return (
        <div
            {...otherProps}
            className={classnames('resume-block', {
                'resume-block_disable-spacing': profileTitle,
                'resume-block_no-print': noPrint,
            })}
        >
            {typeof title === 'string' ? (
                <ColumnsRow>
                    <Column xs="4" s="8" m={mMain} l="12">
                        <div className="resume-block-container">
                            <div className="resume-block__title-text-wrapper">
                                <H2 lite>
                                    <span className="resume-block__title-text resume-block__title-text_sub">
                                        {title}
                                    </span>
                                    {subTitle && (
                                        <SubHeader>
                                            <div className="resume-block__title-text">{subTitle}</div>
                                        </SubHeader>
                                    )}
                                </H2>
                                {EditLink}
                            </div>
                            <VSpacing base={4} />
                        </div>
                    </Column>
                </ColumnsRow>
            ) : (
                title
            )}
            <div className="resume-block-item-gap">
                {profileTitle && children}
                {!profileTitle && <ColumnsRow>{children}</ColumnsRow>}
            </div>
        </div>
    );
};

export default ResumeBlock;
