import { FC } from 'react';

import { useSelector } from '@hh.ru/front-static-app';

import ContainerForMicroFrontend from 'src/components/CodeInjector/ContainerForMicroFrontend';
import { useIsHeadHunterPlatform } from 'src/hooks/usePlatform';

const ApplicantResumeSkillsVerificationCard: FC = () => {
    const isHeadHunterPlatform = useIsHeadHunterPlatform();
    const hasSkillsVerificationCard = useSelector((state) => !!state.microFrontends.skillsVerificationCard);

    if (!isHeadHunterPlatform || !hasSkillsVerificationCard) {
        return null;
    }

    return <ContainerForMicroFrontend place={'skillsVerificationCard'} />;
};

export default ApplicantResumeSkillsVerificationCard;
