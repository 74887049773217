import { useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { translation } from '@hh.ru/front-static-app';
import { useBreakpoint, VSpacingContainer, Button } from '@hh.ru/magritte-ui';

import { useActionLinkAnalytics } from 'src/models/applicant/resume/analytics/provider';
import { selectApplicantResumeAttestationEducation } from 'src/models/applicant/resume/blocks/attestationEducation/selectors';
import { selectApplicantResumeProftest } from 'src/models/applicant/resume/blocks/proftest/selector';
import { openResumeEditorModal } from 'src/models/applicant/resume/editor/store/actions/toggleModal';
import { isEmpty } from 'src/models/applicant/resume/lib/common/empty';
import ResumeAttestationEducationEditor from 'src/pages/ResumeView/redesign/blocks/attestationEducation/Editor';
import ProftestCard from 'src/pages/ResumeView/redesign/blocks/proftest/Card';
import MobileCell from 'src/pages/ResumeView/redesign/blocks/proftest/Card/MobileCell';
import MobileCellEmpty from 'src/pages/ResumeView/redesign/blocks/proftest/Card/MobileCellEmpty';
import DesktopCard from 'src/pages/ResumeView/redesign/common/ListCard/DesktopCard';
import MobileCard from 'src/pages/ResumeView/redesign/common/ListCard/MobileCard';

const TrlKeys = {
    label: 'applicant.resume.attestationEducation.label',
    listTitle: 'applicant.resume.attestationEducation.mobile.list.title',
    takeProftest: 'applicant.resume.proftest.mobile.empty.take',
    addProftest: 'applicant.resume.proftest.mobile.empty.add',
};
const BLOCK_NAME = 'attestationEducation';
const MORE_LINK = '/article/proforientation_promo';

const ApplicantResumeAttestationEducation = translation(({ trls }) => {
    const dispatch = useDispatch();
    const { isMobile } = useBreakpoint();
    const analytics = useActionLinkAnalytics();

    const proftest = useSelector(selectApplicantResumeProftest);
    const attestationEducation = useSelector(selectApplicantResumeAttestationEducation);

    const handleAdd = () => {
        analytics.resumeEditButtonClick(BLOCK_NAME);
        void dispatch(openResumeEditorModal(BLOCK_NAME));
    };
    const handleEdit = (index: number) => {
        analytics.resumeEditButtonClick(BLOCK_NAME);
        void dispatch(openResumeEditorModal(BLOCK_NAME, { index }));
    };

    const handleOpenMorePage = () => {
        window.open(MORE_LINK, '_blank');
    };

    const items = useMemo(
        () =>
            attestationEducation.map(({ id, name, organization, year }) => ({
                id,
                title: name,
                subtitle: organization,
                description: year ? `${year}` : '',
            })),
        [attestationEducation]
    );

    const isAttestationOrProftest = attestationEducation.length || proftest?.resultsReceived;

    return (
        <>
            {!isEmpty(items) && (
                <>
                    {isMobile ? (
                        <MobileCard
                            onAdd={handleAdd}
                            onEdit={handleEdit}
                            items={items}
                            label={trls[TrlKeys.label]}
                            listTitle={trls[TrlKeys.listTitle]}
                            banner={!isAttestationOrProftest ? <MobileCellEmpty /> : <MobileCell proftest={proftest} />}
                            additionalCard={<ProftestCard proftest={proftest} onOpenMorePage={handleOpenMorePage} />}
                            buttons={
                                !isAttestationOrProftest ? (
                                    <VSpacingContainer default={12}>
                                        <Button size="small" mode="secondary" onClick={handleOpenMorePage}>
                                            {trls[TrlKeys.takeProftest]}
                                        </Button>
                                        <Button size="small" mode="secondary" onClick={handleAdd}>
                                            {trls[TrlKeys.addProftest]}
                                        </Button>
                                    </VSpacingContainer>
                                ) : undefined
                            }
                            blockName={BLOCK_NAME}
                        />
                    ) : (
                        <DesktopCard
                            onAdd={handleAdd}
                            onEdit={handleEdit}
                            items={items}
                            label={trls[TrlKeys.label]}
                            banner={<ProftestCard proftest={proftest} onOpenMorePage={handleOpenMorePage} />}
                            blockName={BLOCK_NAME}
                        />
                    )}
                </>
            )}
            <ResumeAttestationEducationEditor />
        </>
    );
});

export default ApplicantResumeAttestationEducation;
