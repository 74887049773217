import { Fragment, useEffect } from 'react';

import Analytics from '@hh.ru/analytics-js';
import Button, { ButtonAppearance } from 'bloko/blocks/button';
import Gap from 'bloko/blocks/gap';
import Link from 'bloko/blocks/link';
import NotificationHeading from 'bloko/blocks/notificationManager/NotificationHeading';

import translation from 'src/components/translation';

const TalantixResumeImportedNotification = ({ link, trls }) => {
    useEffect(() => {
        Analytics.sendEvent('talantix', 'import_success', 'show_saved_notification');
    }, []);

    return (
        <Fragment>
            <NotificationHeading>{trls[TalantixResumeImportedNotification.trls.title]}</NotificationHeading>
            <div>{trls[TalantixResumeImportedNotification.trls.text]}</div>
            <Gap top>
                <Button
                    data-qa="resume-talantix-import-button__success-link"
                    appearance={ButtonAppearance.Outlined}
                    target="_blank"
                    Element={Link}
                    href={link}
                    onClick={() => {
                        Analytics.sendEvent('talantix', 'import_success', 'click_go_to_talantix');
                    }}
                >
                    {trls[TalantixResumeImportedNotification.trls.button]}
                </Button>
            </Gap>
        </Fragment>
    );
};

TalantixResumeImportedNotification.trls = {
    title: 'talantix.import.notification.title',
    text: 'talantix.import.notification.text',
    button: 'talantix.import.notification.button',
};

export default translation(TalantixResumeImportedNotification);
