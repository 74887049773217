import { useState } from 'react';

import { VSpacing, VSpacingContainer } from '@hh.ru/magritte-ui';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import { useBannerProps } from 'src/components/EmployerSearch/ContactFeedbackBanner/hooks/useBannerProps';
import ResumeChatPinnedMessages from 'src/components/ResumeView/ResumeChatPinnedMessages';
import WebcallButton from 'src/components/WebcallButton';
import translation from 'src/components/translation';
import { useSelector } from 'src/hooks/useSelector';
import ResumeContactsChatButton from 'src/pages/ResumeView/components/ResumeHeader/Contacts/ResumeContactsChat';
import ResumeBlock from 'src/pages/ResumeView/employerRedesign/components/ResumeBlock';
import ResumeMap from 'src/pages/ResumeView/employerRedesign/components/ResumeMap';
import { PHONES } from 'src/utils/constants/resumeHiddenFields';
import { VIEW_WITHOUT_CONTACTS } from 'src/utils/constants/resumePermission';

import ResumeContactsEmail from 'src/pages/ResumeView/employerRedesign/components/ResumeContacts/ResumeContactsEmail';
import ResumeContactsPersonalSites from 'src/pages/ResumeView/employerRedesign/components/ResumeContacts/ResumeContactsPersonalSites';
import ResumeContactsPhones from 'src/pages/ResumeView/employerRedesign/components/ResumeContacts/ResumeContactsPhones';
import ShowContacts from 'src/pages/ResumeView/employerRedesign/components/ResumeContacts/ShowContacts';

const TrlKeys = {
    title: 'resume.field.contacts',
};

const ResumeContacts: TranslatedComponent = ({ trls }) => {
    const isAllowed = useSelector((state) => state.resume?.permission !== VIEW_WITHOUT_CONTACTS);
    const isPhoneHidden = useSelector((state) => state.resume?.hiddenFields.includes(PHONES));
    const openedContacts = useSelector((state) => state.resume?.openedContacts);
    const phone = useSelector((state) => state.resume?.phone.value?.[0]);
    const hash = useSelector((state) => state.resume?.hash);
    const showContactSuccessBanner = useSelector((state) => state.resume?.showContactSuccessBanner);

    const [contactsVisibility, setContactsVisibility] = useState(isPhoneHidden || !phone || openedContacts);
    const pinnedChatMessages = useSelector((state) => state.pinnedChatMessages?.items);

    const [_, handleContactClick] = useBannerProps({
        resumeHash: hash || '',
        shouldShowBanner: showContactSuccessBanner,
        isAllowedPage: true,
    });

    if (!isAllowed) {
        return null;
    }

    return (
        <>
            <ResumeBlock
                title={trls[TrlKeys.title]}
                data-qa="resume-block-contacts"
                right={<ResumeMap />}
                buttons={
                    <>
                        <WebcallButton data-qa="resume__call-button" isResumeViewMagritte />
                        <ResumeContactsChatButton isResumeViewMagritte onContactsClick={handleContactClick} />
                    </>
                }
            >
                {contactsVisibility ? (
                    <VSpacingContainer default={4}>
                        <ResumeContactsPhones />
                        <ResumeContactsEmail />
                        <ResumeContactsPersonalSites />
                    </VSpacingContainer>
                ) : (
                    phone && <ShowContacts phone={phone} setContactsVisibility={setContactsVisibility} />
                )}
                {!!pinnedChatMessages.length && (
                    <>
                        <VSpacing default={4} />
                        <ResumeChatPinnedMessages pinnedChatMessages={pinnedChatMessages} />
                    </>
                )}
            </ResumeBlock>
        </>
    );
};

export default translation(ResumeContacts);
