import { AxiosResponse } from 'axios';

import { fetcher } from 'src/utils/fetcher';

import { ResumeVisibilityEmployerListDTO, ResumeVisibilitySearchListDTO } from 'src/models/applicant/resume/api/dto';

export type ResumeEmployersListType = 'whitelist' | 'blacklist';

declare global {
    interface FetcherPostApi {
        '/shards/resume/edit/visibility': {
            body: { hash: string; accessType: string };
            response: void;
            queryParams: { from: string; hhtmFromLabel: string };
        };
    }
}

declare global {
    interface FetcherGetApi {
        [key: `/applicant/resume/${string}/${ResumeEmployersListType}`]: {
            response: ResumeVisibilityEmployerListDTO;
            queryParams: {
                perPage?: number;
                page?: number;
            };
        };
        [key: `/applicant/resume/${string}/${ResumeEmployersListType}/search`]: {
            response: ResumeVisibilitySearchListDTO;
            queryParams: {
                text?: string;
            };
        };
    }
}

declare global {
    interface FetcherDeleteApi {
        [key: `/applicant/resume/${string}/${ResumeEmployersListType}/employer`]: {
            response: void;
            body: void;
            queryParams: { employerId: number[] };
        };
    }
}

declare global {
    interface FetcherPutApi {
        [key: `/applicant/resume/${string}/${ResumeEmployersListType}`]: {
            response: void;
            body: void;
            queryParams: void;
        };
        [key: `/applicant/resume/${string}/${ResumeEmployersListType}/employer`]: {
            response: void;
            body: void;
            queryParams?: { employerId?: number[] };
        };
    }
}

export const ResumeVisibilityApi = {
    hide(hash: string): Promise<AxiosResponse<void>> {
        return fetcher.post(
            '/shards/resume/edit/visibility',
            { hash, accessType: 'no_one' },
            { params: { from: 'resume-delete', hhtmFromLabel: 'resume-delete' } }
        );
    },
    getVisibilityEmployerList(
        hash: string,
        type: ResumeEmployersListType,
        page: number
    ): Promise<ResumeVisibilityEmployerListDTO> {
        return fetcher.get(`/applicant/resume/${hash}/${type}`, { params: { page, perPage: 10 } });
    },
    searchVisibilityEmployerList(
        hash: string,
        type: ResumeEmployersListType,
        text: string
    ): Promise<ResumeVisibilitySearchListDTO> {
        return fetcher.get(`/applicant/resume/${hash}/${type}/search`, { params: { text } });
    },
    deleteVisibilityEmployer(
        hash: string,
        type: ResumeEmployersListType,
        employerId: number[]
    ): Promise<AxiosResponse<void>> {
        const params = { employerId };
        return fetcher.delete(`/applicant/resume/${hash}/${type}/employer`, { params });
    },
    clearVisibilityEmployers(hash: string, type: ResumeEmployersListType): Promise<AxiosResponse<void>> {
        return fetcher.put(`/applicant/resume/${hash}/${type}`);
    },
    addVisibilityEmployers(
        hash: string,
        type: ResumeEmployersListType,
        employerId: number[]
    ): Promise<AxiosResponse<void>> {
        const params = { employerId };
        return fetcher.put(`/applicant/resume/${hash}/${type}/employer`, undefined, { params });
    },
};
