import { DefaultRootState } from 'react-redux';
import { Dispatch } from 'redux';

import { sendResumeEditSaveFormSubmitAnalytics } from 'src/models/applicant/resume/analytics/actions';
import { ResumeApi } from 'src/models/applicant/resume/api';
import { isConditionsErrors, isSuccess } from 'src/models/applicant/resume/api/lib';
import { editorConfigMap } from 'src/models/applicant/resume/editor/configMap';
import { fromApiToLocalErrors } from 'src/models/applicant/resume/editor/form/errors/lib';
import { selectResumeEditorType } from 'src/models/applicant/resume/editor/store/selectors';
import {
    showNotification,
    setResumeEditorFormErrors,
    toggleResumeEditorLoading,
} from 'src/models/applicant/resume/editor/store/slice';
import { UpdateBodyType } from 'src/models/applicant/resume/editor/types';
import { Thunk } from 'src/models/applicant/resume/editor/types/actions';
import { EditorFormErrors } from 'src/models/applicant/resume/editor/types/error';
import { selectHhtmSource, selectResumeHash } from 'src/models/applicant/resume/selectors';
import { setApplicantResumeFields } from 'src/models/applicant/resume/slice';

import { closeResumeEditor } from 'src/models/applicant/resume/editor/store/actions/toggleModal';

export function updateResume(body: UpdateBodyType, loadingType: 'saving' | 'deleting', fromAlert = false): Thunk {
    return async (dispatch: Dispatch, getState: () => DefaultRootState): Promise<void> => {
        const state = getState();

        dispatch(toggleResumeEditorLoading({ type: loadingType, loading: true }));

        const type = selectResumeEditorType(getState());
        const config = editorConfigMap[type];

        let errors: EditorFormErrors | undefined;
        try {
            const resumeHash = selectResumeHash(getState());
            const hhtmSource = selectHhtmSource(getState());

            const response = await ResumeApi.update(resumeHash, hhtmSource, body);
            if (isSuccess(response)) {
                dispatch(setApplicantResumeFields(response.resume));
                dispatch(closeResumeEditor());
                dispatch(showNotification('success-save'));
                return;
            }

            if (isConditionsErrors(response)) {
                errors = fromApiToLocalErrors(response.errors);
                dispatch(setResumeEditorFormErrors(errors));
                return;
            }

            dispatch(showNotification('error-api'));
            // mb sentry log
        } catch (_) {
            dispatch(showNotification('error-api'));
            // mb sentry log
        } finally {
            if (config.afterSubmit) {
                config.afterSubmit(getState(), state, dispatch);
            }

            dispatch(sendResumeEditSaveFormSubmitAnalytics(fromAlert, errors));
            dispatch(toggleResumeEditorLoading({ type: loadingType, loading: false }));
        }
    };
}
