import { FC, PropsWithChildren } from 'react';

import { OnlineStatus, useOnlineStatus } from 'Utils/LastActivityTime';
import ApplicantActivityTime from 'src/components/ApplicantActivityTime';
import TagWithContrastText from 'src/components/TagWithContrastText';
import { useSelector } from 'src/hooks/useSelector';
import { Gender } from 'src/models/resume/resumeCommon.types';

const ResumeLastActivityTime: FC<PropsWithChildren> = () => {
    const gender = useSelector((state) => state.resume?.gender.value || Gender.Male);
    const lastActivityTime = useSelector((state) => state.resume?.lastActivityTime || '');
    const status = useOnlineStatus(lastActivityTime);

    if (status !== OnlineStatus.Offline) {
        return null;
    }

    return (
        <TagWithContrastText>
            <ApplicantActivityTime gender={gender} lastActivityTime={lastActivityTime} />
        </TagWithContrastText>
    );
};

export default ResumeLastActivityTime;
