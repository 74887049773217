import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { RouterLocation } from 'connected-react-router';

import { useSelector } from '@hh.ru/front-static-app';

import { EDITOR_QUERY_KEY } from 'src/models/applicant/resume/completeness/utils';
import { selectLocation } from 'src/models/applicant/resume/selectors';

import { openResumeEditorModal } from 'src/models/applicant/resume/editor/store/actions/toggleModal';
import { EditorFormType } from 'src/models/applicant/resume/editor/types';

export const useOpenEditorByUrl = () => {
    const dispatch = useDispatch();

    const { query } = useSelector(selectLocation);

    useEffect(() => {
        if (EDITOR_QUERY_KEY in query && query[EDITOR_QUERY_KEY] !== '') {
            void dispatch(openResumeEditorModal(query[EDITOR_QUERY_KEY] as EditorFormType));
        }
    }, [query, dispatch]);
};

export const removeQueryFromUrl = (location: RouterLocation<unknown>) => {
    const { search, pathname } = location;
    const queryParams = new URLSearchParams(search);

    if (queryParams.has(EDITOR_QUERY_KEY)) {
        queryParams.delete(EDITOR_QUERY_KEY);

        const query = queryParams.toString();
        const path = `${pathname}${query === '' ? '' : `?${query}`}`;

        window.history.replaceState(window.history.state, document.title, path);
    }
};
