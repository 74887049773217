import { useRef } from 'react';

import { format, translation } from '@hh.ru/front-static-app';
import { Tag, TooltipHover } from '@hh.ru/magritte-ui';
import { VerifiedBadgeFilledSize16 } from '@hh.ru/magritte-ui/icon';

import { ResumeKeySkill } from 'src/models/applicant/resume/blocks/keySkills/types';

interface Props {
    skill: ResumeKeySkill;
}

const TrlKeys = {
    verified: {
        none: 'applicant.resume.block.skills.tooltip.verified.none',
        full: 'applicant.resume.block.skills.tooltip.verified.full',
        theory: 'applicant.resume.block.skills.tooltip.verified.theory',
        practice: 'applicant.resume.block.skills.tooltip.verified.practice',
    },
    months: {
        0: 'calendar.prepositional.month.0',
        1: 'calendar.prepositional.month.1',
        2: 'calendar.prepositional.month.2',
        3: 'calendar.prepositional.month.3',
        4: 'calendar.prepositional.month.4',
        5: 'calendar.prepositional.month.5',
        6: 'calendar.prepositional.month.6',
        7: 'calendar.prepositional.month.7',
        8: 'calendar.prepositional.month.8',
        9: 'calendar.prepositional.month.9',
        10: 'calendar.prepositional.month.10',
        11: 'calendar.prepositional.month.11',
    },
};

const SkillTag = translation<Props>(({ skill, trls }) => {
    const activatorRef = useRef(null);

    const verifiedInfo = skill.verifiedInfo;

    if (verifiedInfo) {
        return (
            <>
                <Tag
                    icon={verifiedInfo.fullVerified ? VerifiedBadgeFilledSize16 : undefined}
                    style={verifiedInfo.verified ? 'positive' : 'neutral'}
                    key={skill.id}
                >
                    <span ref={activatorRef}>{skill.name}</span>
                </Tag>
                {verifiedInfo.verified && (
                    <TooltipHover activatorRef={activatorRef} placement="right-center">
                        {format(trls[TrlKeys.verified[verifiedInfo.type]], {
                            '{0}': `${trls[TrlKeys.months[verifiedInfo.date.month]]} ${verifiedInfo.date.year}`,
                        })}
                    </TooltipHover>
                )}
            </>
        );
    }

    return (
        <Tag key={skill.id}>
            <span ref={activatorRef}>{skill.name}</span>
        </Tag>
    );
});

export default SkillTag;
