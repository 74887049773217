import { connect } from 'react-redux';

import Column from 'bloko/blocks/column';

import ResumeCertificates from 'src/components/ResumeCertificates';
import translation from 'src/components/translation';
import { useGetMColumnSize } from 'src/pages/ResumeView/hooks/useGetMColumnSize';

import ResumeBlock from 'src/pages/ResumeView/components/View/ResumeBlock';
import ResumeEditLink from 'src/pages/ResumeView/components/View/ResumeEditLink';

const ResumeViewCertificates = ({ certificates, trls }) => {
    const { mMain } = useGetMColumnSize();
    if (!certificates) {
        return null;
    }
    return (
        <ResumeBlock
            title={trls[ResumeViewCertificates.trls.title]}
            EditLink={
                <ResumeEditLink
                    block="education"
                    field="attestationEducation"
                    data-qa="resume-block-certificate-edit"
                />
            }
            data-qa="resume-block-certificate"
        >
            <Column xs="4" s="8" m={mMain} l="12">
                <div className="resume-block-container">
                    <ResumeCertificates certificates={certificates} />
                </div>
            </Column>
        </ResumeBlock>
    );
};

ResumeViewCertificates.trls = {
    title: 'rb.title.certificates',
};

export default connect((state) => ({
    certificates: state.resume?.certificates.value,
}))(translation(ResumeViewCertificates));
