import { ComponentType, useCallback, useMemo, useState } from 'react';

import { format, translation } from '@hh.ru/front-static-app';
import {
    FormLabel,
    ChipsInput,
    CheckableChip,
    ChipsContainer,
    ChipsTriggerProps,
    ChipsTriggerState,
    VSpacingContainer,
    FormHelper,
    DataProvider,
    StaticDataFetcherItem,
    CustomChip,
    RenderSuggestOptionType,
} from '@hh.ru/magritte-ui';

import { useKeyskillsSuggestDataProvider } from 'src/models/applicant/resume/blocks/keySkills/editor/provider';
import { useRecomendedSkills } from 'src/models/applicant/resume/blocks/keySkills/editor/recomended';
import { areSimpleArraysDifferent } from 'src/models/applicant/resume/editor/lib';
import EditorField from 'src/pages/ResumeView/redesign/common/Form/EditorField';

interface Props {
    skills: string[];
    disabled?: boolean;
    invalid?: boolean;
    resumeHash: string;
    errorMessage?: string | null;
    maxInputTextLength?: number;
    onChange(skillNames: string[]): void;
}

const TrlKeys = {
    label: 'resume.editor.key.skills.input.label',
    popular: 'resume.editor.key.skills.popular.lebel',
    recommend: 'resume.editor.key.skills.recommend.lebel',
    notFound: 'resume.editor.key.skills.recommend.not.found',
    placeholder: {
        simple: 'resume.editor.key.skills.placeholder.simple',
        example: 'resume.editor.key.skills.placeholder.example',
    },
};

const KeySkillsSuggest: ComponentType<Props> = translation(
    ({ trls, resumeHash, skills, disabled, maxInputTextLength, invalid, onChange, errorMessage }) => {
        const [inputText, setInputText] = useState('');

        const provider = useKeyskillsSuggestDataProvider(trls);
        const recomendedSkills = useRecomendedSkills(skills, resumeHash);

        const chipsInputState: ChipsTriggerProps['state'] = {
            value: inputText,
            chips: skills.map((name) => ({
                value: name,
            })),
        };
        const placeholder = useMemo(() => {
            const example = recomendedSkills[0];
            if (example) {
                return format(trls[TrlKeys.placeholder.example], { '{0}': example });
            }
            return trls[TrlKeys.placeholder.simple];
        }, [recomendedSkills, trls]);

        const handleAddRecomended = (skill: string) => onChange([...skills, skill]);
        const handleRemoveRecomended = (skill: string) => onChange(skills.filter((v) => v !== skill));

        const handleChange = useCallback(
            (newState: ChipsTriggerState) => {
                if (!maxInputTextLength || newState.value.length <= maxInputTextLength) {
                    setInputText(newState.value);
                }
                const newChips = newState.chips.map((chip) => chip.value);

                if (areSimpleArraysDifferent(newChips, skills)) {
                    onChange(newChips);
                }
            },
            [maxInputTextLength, onChange, skills]
        );

        const renderItem = useCallback<RenderSuggestOptionType<StaticDataFetcherItem>>(
            ({ item: skill, onValueSelect }) => {
                return (
                    <CustomChip
                        onMouseDown={(event) => event.preventDefault()}
                        onClick={() => onValueSelect(skill.value, skill.data)}
                        data-qa="suggest-item-chips"
                        checked={skills.findIndex((x) => x === skill.data?.text) >= 0}
                    >
                        {skill.value}
                    </CustomChip>
                );
            },
            [skills]
        );

        return (
            <VSpacingContainer default={24}>
                <EditorField htmlFor="keySkills" label={trls[TrlKeys.label]}>
                    <ChipsInput
                        type="suggest"
                        state={chipsInputState}
                        onChange={handleChange}
                        triggerProps={{
                            size: 'large',
                            invalid,
                            disabled,
                            placeholder,
                            allowArbitraryValues: true,
                        }}
                        suggestProps={{
                            dataProvider: provider as unknown as DataProvider<StaticDataFetcherItem>,
                            trls: {
                                resetButton: trls[TrlKeys.popular],
                            },
                            errorPlaceholder: null,
                            renderItem,
                        }}
                    />
                    <FormHelper invalid={invalid} errorMessage={errorMessage} />
                </EditorField>

                {recomendedSkills.length > 0 && (
                    <VSpacingContainer default={12}>
                        <FormLabel>{trls[TrlKeys.recommend]}</FormLabel>
                        <ChipsContainer>
                            {recomendedSkills.map((skill) => {
                                const selected = skills.includes(skill);
                                return (
                                    <CheckableChip
                                        key={skill}
                                        type="checkbox"
                                        checked={selected}
                                        onChange={() => {
                                            if (selected) {
                                                handleRemoveRecomended(skill);
                                            } else {
                                                handleAddRecomended(skill);
                                            }
                                        }}
                                    >
                                        {skill}
                                    </CheckableChip>
                                );
                            })}
                        </ChipsContainer>
                    </VSpacingContainer>
                )}
            </VSpacingContainer>
        );
    }
);

export default KeySkillsSuggest;
