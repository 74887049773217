import { useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { ElementShownAnchor } from '@hh.ru/analytics-js';
import { translation } from '@hh.ru/front-static-app';
import { Button, Card, Text, VSpacing, VSpacingContainer, useBreakpoint } from '@hh.ru/magritte-ui';

import { useActionLinkAnalytics } from 'src/models/applicant/resume/analytics/provider';
import { hasTitles, makeSkillsByLevel, makeSkillsLevels } from 'src/models/applicant/resume/blocks/keySkills/lib';
import {
    selectApplicantSkills,
    selectApplicantResumeAdvancedKeySkills,
} from 'src/models/applicant/resume/blocks/keySkills/selectors';
import { openSkillsLevelsEditor } from 'src/models/applicant/resume/blocks/keySkills/skillsLevels/actions';
import { openResumeEditorModal } from 'src/models/applicant/resume/editor/store/actions/toggleModal';
import { SkillsLevelsKeys } from 'src/models/skills';
import ResumeKeySkillsEditor from 'src/pages/ResumeView/redesign/blocks/skills/KeySkillsEditor';
import KeySkillsLevelEditor from 'src/pages/ResumeView/redesign/blocks/skills/KeySkillsLevelEditor';
import BlockLabel from 'src/pages/ResumeView/redesign/common/BlockLabel';

import SkillTag from 'src/pages/ResumeView/redesign/blocks/skills/KeySkillsCard/SkillTag';

import styles from './styles.less';

const TrlKeys = {
    add: 'applicant.resume.block.skills.add',
    level: 'applicant.resume.block.skills.level',
    title: 'applicant.resume.block.skills.title',
    levels: {
        [SkillsLevelsKeys.NONE]: 'skillLevels.level.none',
        [SkillsLevelsKeys.BASIC]: 'skillLevels.level.basic',
        [SkillsLevelsKeys.MEDIUM]: 'skillLevels.level.medium',
        [SkillsLevelsKeys.ADVANCED]: 'skillLevels.level.advanced',
    },
};
const BLOCK_NAME = 'keySkills';

const KeySkillsCard = translation(({ trls }) => {
    const dispatch = useDispatch();
    const { isMobile } = useBreakpoint();
    const analytics = useActionLinkAnalytics();

    const applicantSkills = useSelector(selectApplicantSkills);
    const advancedKeySkills = useSelector(selectApplicantResumeAdvancedKeySkills);

    const skillsByLevel = useMemo(
        () => makeSkillsByLevel(applicantSkills, advancedKeySkills, isMobile),
        [applicantSkills, advancedKeySkills, isMobile]
    );
    const levels = useMemo(() => makeSkillsLevels(skillsByLevel), [skillsByLevel]);
    const hideLevelTitle = useMemo(() => !hasTitles(levels), [levels]);

    const handleClickAdd = () => {
        analytics.resumeKeySkillButtonClick('skill_add');
        void dispatch(openResumeEditorModal(BLOCK_NAME));
    };
    const handleOpenLevelEditor = () => {
        analytics.resumeKeySkillButtonClick('skill_level');
        dispatch(openSkillsLevelsEditor());
    };

    return (
        <>
            {advancedKeySkills.length !== 0 && (
                <ElementShownAnchor fn={analytics.resumeKeySkillElementShown}>
                    <VSpacingContainer default={12}>
                        <BlockLabel>{trls[TrlKeys.title]}</BlockLabel>
                        <Card stretched padding={12} borderRadius={24} borderWidth="default">
                            <div className={styles.skills}>
                                <VSpacingContainer default={24}>
                                    {levels.map((level) => {
                                        return (
                                            <div key={level}>
                                                {!hideLevelTitle && (
                                                    <>
                                                        <Text
                                                            style="secondary"
                                                            typography="label-3-regular"
                                                            data-qa={`skill-level-title-${level}`}
                                                        >
                                                            {trls[TrlKeys.levels[level]]}
                                                        </Text>
                                                        <VSpacing default={8} />
                                                    </>
                                                )}
                                                <div className={styles.tags}>
                                                    {skillsByLevel[level]!.map((skill) => (
                                                        <SkillTag skill={skill} key={skill.id} />
                                                    ))}
                                                </div>
                                            </div>
                                        );
                                    })}
                                </VSpacingContainer>
                            </div>

                            <VSpacing default={12} />

                            <div className={styles.footer}>
                                <Button mode="secondary" style="neutral" stretched onClick={handleOpenLevelEditor}>
                                    {trls[TrlKeys.level]}
                                </Button>
                                <Button mode="secondary" style="neutral" stretched onClick={handleClickAdd}>
                                    {trls[TrlKeys.add]}
                                </Button>
                            </div>
                        </Card>
                    </VSpacingContainer>
                </ElementShownAnchor>
            )}
            <KeySkillsLevelEditor />
            <ResumeKeySkillsEditor />
        </>
    );
});

export default KeySkillsCard;
