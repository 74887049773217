import { useDispatch } from 'react-redux';

import { translation, useSelector } from '@hh.ru/front-static-app';
import { Action, Button, Title, Text, Card, ActionBar, VSpacingContainer } from '@hh.ru/magritte-ui';
import { CrossOutlinedSize24 } from '@hh.ru/magritte-ui/icon';

import { selectSkillsLevelsInfoModalVisibility } from 'src/models/applicant/resume/blocks/keySkills/skillsLevels/selector';
import { toggleSkillsLevelsInfoModal } from 'src/models/applicant/resume/blocks/keySkills/skillsLevels/slice';
import SmartModal from 'src/pages/ResumeView/redesign/common/EditorModal/SmartModal';

import styles from './styles.less';

const TrlKeys = {
    title: 'profile.skillLevels.modal.title.magritte',
    levels: {
        '0': {
            rank: 1,
            title: 'profile.skillLevels.modal.basic.magritte',
            items: { 0: 'profile.skillLevels.modal.basic.0.magritte', 1: 'profile.skillLevels.modal.basic.1.magritte' },
        },
        '1': {
            rank: 2,
            title: 'profile.skillLevels.modal.medium.magritte',
            items: {
                0: 'profile.skillLevels.modal.medium.0.magritte',
                1: 'profile.skillLevels.modal.medium.1.magritte',
            },
        },
        '2': {
            rank: 3,
            title: 'profile.skillLevels.modal.advanced.magritte',
            items: {
                0: 'profile.skillLevels.modal.advanced.0.magritte',
                1: 'profile.skillLevels.modal.advanced.1.magritte',
                2: 'profile.skillLevels.modal.advanced.2.magritte',
            },
        },
    },
    close: 'profile.skillLevels.modal.close.magritte',
};

export const SkillLevelModal = translation(({ trls }) => {
    const dispatch = useDispatch();

    const levels = Object.keys(TrlKeys.levels);
    const visible = useSelector(selectSkillsLevelsInfoModalVisibility);

    const handleClose = () => {
        dispatch(toggleSkillsLevelsInfoModal(false));
    };

    return (
        <SmartModal
            fullScreen
            title={trls[TrlKeys.title]}
            visible={visible}
            onClose={handleClose}
            bottomSheetProps={{
                size: 'large',
                right: <Action icon={CrossOutlinedSize24} onClick={handleClose} />,
                showDivider: 'with-scroll',
            }}
            modalProps={{
                footer: (
                    <ActionBar
                        type="modal"
                        primaryActions={
                            <Button mode="secondary" onClick={handleClose}>
                                {trls[TrlKeys.close]}
                            </Button>
                        }
                    />
                ),
            }}
        >
            <VSpacingContainer default={16}>
                {levels.map((levelKey) => {
                    const level = TrlKeys.levels[levelKey];
                    const items = Object.values(level.items);
                    return (
                        <Card borderWidth="default" padding={24} borderRadius={24} key={level.rank}>
                            <Title Element="h5" style="primary" size="small">
                                {trls[level.title]}
                            </Title>
                            <ul className={styles.modalList}>
                                {items.map((item) => (
                                    <li key={item}>
                                        <Text style="primary" Element="span">
                                            {trls[item]}
                                        </Text>
                                    </li>
                                ))}
                            </ul>
                        </Card>
                    );
                })}
            </VSpacingContainer>
        </SmartModal>
    );
});
