// Не закончено
export const NOT_FINISHED = 'not_finished';
// Опубликовано, но не проверено
export const NEW = 'new';
// Изменено после проверки
export const MODIFIED = 'modified';
// Проверено модератором
export const APPROVED = 'approved';
// Заблокировано модератором
export const BLOCKED = 'blocked';
// Изменено после блокировки модератором
export const CORRECTED = 'corrected';
// Проверено автомодератором
export const AUTO_APPROVED = 'auto_approved';

export enum ResumeTags {
    ExpertIsWorking = 'EXPERT_IS_WORKING',
    MadeByExpert = 'MADE_BY_EXPERT',
}
