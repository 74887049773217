import { translation, useSelector } from '@hh.ru/front-static-app';
import { Select } from '@hh.ru/magritte-ui';

import { useAreaDataProvider } from 'src/models/applicant/resume/api/area';
import { useEditorExperienceChanged } from 'src/models/applicant/resume/blocks/experience/editor/hooks';
import { selectPopularAreas } from 'src/models/applicant/resume/blocks/experience/editor/selectors';
import { useResumeEditorListField } from 'src/models/applicant/resume/editor/form/change';
import { ExperienceFieldProps } from 'src/pages/ResumeView/redesign/blocks/experience/Editor/types';

const TrlKeys = {
    delimeterTitle: 'resume.editor.experience.area.search.delimeter',
    label: 'resume.editor.experience.area.label',
};

const AreaField = translation<ExperienceFieldProps>(({ trls, errorsMap, index, fieldName }) => {
    const { value: companyAreaId } = useResumeEditorListField<number>(fieldName, index, 'companyAreaId', errorsMap);
    const { value: companyAreaTitle } = useResumeEditorListField(fieldName, index, 'companyAreaTitle');

    const siteLanguage = useSelector((state) => state.langs[0]);
    const popularAreas = useSelector(selectPopularAreas);

    const searchProvider = useAreaDataProvider(siteLanguage, trls[TrlKeys.delimeterTitle], popularAreas?.area ?? []);

    const onChange = useEditorExperienceChanged();

    const handleChange = (companyAreaId: string, companyAreaTitle: string) => {
        onChange({ companyAreaId: Number(companyAreaId), companyAreaTitle });
    };

    const getSelectValue = (companyAreaId: number, companyAreaTitle: string) =>
        companyAreaId ? { value: String(companyAreaId), text: companyAreaTitle } : undefined;

    return (
        <Select
            type="radio"
            dataProvider={searchProvider}
            triggerProps={{
                size: 'medium',
                label: trls[TrlKeys.label],
                stretched: true,
            }}
            value={getSelectValue(companyAreaId, companyAreaTitle)}
            searchable
            onChange={(newItem) => handleChange(newItem.value, newItem.text ?? '')}
        />
    );
});

export default AreaField;
