import { translation } from '@hh.ru/front-static-app';
import { VSpacing, Text, BottomSheetFooter, Button, ActionBar } from '@hh.ru/magritte-ui';

import CdnImg from 'src/components/CdnImg';
import { PhotoUrl } from 'src/models/resume/resumeCommon.types';
import SmartModal from 'src/pages/ResumeView/redesign/common/EditorModal/SmartModal';

import styles from './styles.less';

const TrlKeys = {
    close: 'applicant.resume.portfolio.close',
    empty: 'applicant.resume.portfolio.empty',
};

interface Props {
    portfolio: PhotoUrl | null;
    onClose: () => void;
}

const ViewPortfolioModal = translation<Props>(({ trls, portfolio, onClose }) => {
    const visible = portfolio !== null;
    const big = portfolio ? portfolio.big : '';
    const title = portfolio ? portfolio.title : null;
    const isEmptyTitle = title === null;

    return (
        <SmartModal
            visible={visible}
            onClose={onClose}
            bottomSheetProps={{
                hideNav: true,
                footer: (
                    <BottomSheetFooter>
                        <Button mode="secondary" style="neutral" size="medium" onClick={onClose} stretched>
                            {trls[TrlKeys.close]}
                        </Button>
                    </BottomSheetFooter>
                ),
            }}
            modalProps={{
                size: 'large',
                footer: (
                    <ActionBar
                        primaryActions={
                            <Button mode="secondary" style="neutral" size="medium" onClick={onClose}>
                                {trls[TrlKeys.close]}
                            </Button>
                        }
                    />
                ),
            }}
        >
            <div className={styles.portfolio}>
                <CdnImg className={styles.artifact} path={big || ''} />
                <VSpacing default={24} />
                <Text style={isEmptyTitle ? 'secondary' : 'primary'} typography="label-1-regular">
                    {isEmptyTitle ? trls[TrlKeys.empty] : title}
                </Text>
            </div>
        </SmartModal>
    );
});

export default ViewPortfolioModal;
