import { ComponentType, useMemo } from 'react';

import { useSelector } from '@hh.ru/front-static-app';
import { Text, Link, VSpacingContainer } from '@hh.ru/magritte-ui';

import translation from 'src/components/translation';
import { getPreferredContactType, sortPhoneByType } from 'src/models/applicant/resume/blocks/contacts/lib';
import { selectResumePhone, selectResumePreferredContact } from 'src/models/applicant/resume/blocks/contacts/selectors';
import { EM_DASH, NON_BREAKING_SPACE } from 'src/utils/constants/symbols';

const TrlKeys = {
    phone: {
        cell: 'applicant.resume.contacts.field.phone.cell',
        home: 'applicant.resume.contacts.field.phone.home',
        work: 'applicant.resume.contacts.field.phone.work',
    },
    preferredContact: 'applicant.resume.contacts.preferredContact.hint',
    nonVerified: 'applicant.resume.contacts.non.verified',
};

const ContactsPhones: ComponentType = translation(({ trls }) => {
    const phones = useSelector(selectResumePhone);
    const sortedPhones = useMemo(() => sortPhoneByType(phones), [phones]);
    const preferredContact = useSelector(selectResumePreferredContact);

    const getPhoneVerificationHint = (needVerification: boolean, isPreferred: boolean) => {
        if (needVerification) {
            return (
                <>
                    {<>{`${NON_BREAKING_SPACE}${NON_BREAKING_SPACE}`}</>}
                    <Text Element="span" style="warning" typography="label-3-regular">
                        {trls[TrlKeys.nonVerified]}
                    </Text>
                </>
            );
        }
        if (isPreferred) {
            return (
                <>
                    {<>{`${NON_BREAKING_SPACE}${EM_DASH}${NON_BREAKING_SPACE}`}</>}
                    {trls[TrlKeys.preferredContact]}
                </>
            );
        }
        return null;
    };

    return (
        <>
            {sortedPhones.map(({ type, formatted, raw, needVerification }) => {
                const isPreferred = getPreferredContactType(preferredContact) === type;

                return (
                    <div key={type} data-qa="resume-serp_resume-item-content">
                        <div data-qa="resume-contacts-phone">
                            <VSpacingContainer default={4}>
                                <Text style="secondary" typography="label-3-regular">
                                    {trls[TrlKeys.phone[type]]}
                                    {getPhoneVerificationHint(!!needVerification, isPreferred)}
                                </Text>
                                <Link
                                    style="neutral"
                                    typography="label-2-regular"
                                    href={`tel:+${raw}`}
                                    data-qa={isPreferred ? 'resume-contact-preferred' : undefined}
                                >
                                    {formatted}
                                </Link>
                            </VSpacingContainer>
                        </div>
                    </div>
                );
            })}
        </>
    );
});

export default ContactsPhones;
