import { useLayoutEffect, useRef, useState } from 'react';

import { Button } from '@hh.ru/magritte-ui';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import DenseText from 'src/components/DenseText';
import Markup from 'src/components/Markup';
import ResumeComplaint from 'src/components/ResumeComplaint';
import { useResumeComplaint } from 'src/components/ResumeComplaint/useResumeComplaint';
import ResumeBlockButtonWrapper from 'src/components/ResumeView/ResumeBlockButtonWrapper';
import translation from 'src/components/translation';
import { useSelector } from 'src/hooks/useSelector';
import ResumeBlock from 'src/pages/ResumeView/employerRedesign/components/ResumeBlock';
import { DEFAULT_RESUME_BUTTON_PROPS } from 'src/pages/ResumeView/employerRedesign/const';

const TrlKeys = {
    title: 'employer.resume.responseLetter',
    complaintButtonTitle: 'employer.vacancyResponses.responseComplaint',
    showAll: 'resume.block.responseLetter.showAll',
    hide: 'resume.block.responseLetter.hide',
};

const MAX_HEIGHT_TO_RENDER = 130;
const MAX_LINES = 3;

const ResponseLetter: TranslatedComponent = ({ trls }) => {
    const resumeHash = useSelector((state) => state.resume?.hash)!;
    const letter = useSelector((state) => state.employerNegotiations.responseLetters?.[resumeHash]);
    const { onOpen, ...resumeComplaintPros } = useResumeComplaint({ resumeHash });

    const [showAllByDefault, setShowAllByDefault] = useState(true);
    const [showAll, setShowAll] = useState(true);
    const wrapperRef = useRef<HTMLDivElement>(null);
    useLayoutEffect(() => {
        if (wrapperRef.current) {
            const isHeightLessThenMax = wrapperRef.current.clientHeight <= MAX_HEIGHT_TO_RENDER;
            setShowAllByDefault(isHeightLessThenMax);
            setShowAll(isHeightLessThenMax);
        }
    }, []);

    if (!letter) {
        return null;
    }

    return (
        <ResumeBlock
            title={trls[TrlKeys.title]}
            buttons={
                <>
                    {!showAllByDefault && (
                        <ResumeBlockButtonWrapper>
                            <Button {...DEFAULT_RESUME_BUTTON_PROPS} onClick={() => setShowAll((showAll) => !showAll)}>
                                {trls[TrlKeys[showAll ? 'hide' : 'showAll']]}
                            </Button>
                        </ResumeBlockButtonWrapper>
                    )}
                    <ResumeBlockButtonWrapper>
                        <Button {...DEFAULT_RESUME_BUTTON_PROPS} onClick={onOpen} data-qa="resume-complaint-button">
                            {trls[TrlKeys.complaintButtonTitle]}
                        </Button>
                    </ResumeBlockButtonWrapper>
                </>
            }
        >
            <div ref={wrapperRef}>
                <DenseText typography="paragraph-3-regular" maxLines={showAll ? undefined : MAX_LINES}>
                    <Markup>{letter}</Markup>
                </DenseText>
            </div>
            <ResumeComplaint {...resumeComplaintPros} />
        </ResumeBlock>
    );
};

export default translation(ResponseLetter);
