import { translation } from '@hh.ru/front-static-app';
import { CheckCircleOutlinedSize16 } from '@hh.ru/magritte-ui/icon';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import TagWithContrastText from 'src/components/TagWithContrastText';
import { useSelector } from 'src/hooks/useSelector';

const TrlKeys = {
    phoneVerified: 'resume.phoneVerified',
};

const ResumePhoneVerified: TranslatedComponent = ({ trls }) => {
    const phonePreview = useSelector((state) => state.resume?.phonePreview);
    const hasVerifiedPhone = phonePreview?.some((phone) => phone.verified);

    const canOpenContacts = useSelector((state) => state.resume?.canOpenContacts);
    const canBuyContacts = useSelector((state) => state.resume?.resumeContactsOpening?.canOpenContactsThroughCIV);
    const isOpenContactsVisible = canBuyContacts && canOpenContacts;

    if (!hasVerifiedPhone || !isOpenContactsVisible) {
        return null;
    }

    return (
        <TagWithContrastText style="positive" icon={CheckCircleOutlinedSize16} data-qa="phone-verified-tag">
            {trls[TrlKeys.phoneVerified]}
        </TagWithContrastText>
    );
};

export default translation(ResumePhoneVerified);
