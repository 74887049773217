import { translation, useSelector } from '@hh.ru/front-static-app';
import { Button, VSpacingContainer } from '@hh.ru/magritte-ui';

import { selectResumeEditorPhotoUrl } from 'src/models/applicant/resume/blocks/position/edit/photo/selectors';
import { getAvatarLetters } from 'src/models/applicant/resume/blocks/position/lib';
import {
    selectApplicantResumeGender,
    selectApplicantResumeLastName,
    selectApplicantResumeFirstName,
} from 'src/models/applicant/resume/blocks/position/selectors';
import { useTranslatedErrorMap } from 'src/models/applicant/resume/editor/form/errors';
import Avatar from 'src/pages/ResumeView/redesign/blocks/position/Card/Avatar';
import ProfessionalRoleField from 'src/pages/ResumeView/redesign/blocks/position/Editor/fields/ProfessionalRole';
import SalaryField from 'src/pages/ResumeView/redesign/blocks/position/Editor/fields/Salary';
import TitleField from 'src/pages/ResumeView/redesign/blocks/position/Editor/fields/Title';

import styles from './styles.less';

const TrlKeys = {
    photoBtn: {
        add: 'resume.editor.photo.add',
        changed: 'resume.editor.photo.changed',
    },
    commonErrors: {
        UNKNOWN: 'resume.editor.field.error.unknown',
        REQUIRED: 'resume.editor.field.error.required',
        DUPLICATE: 'resume.editor.field.error.title.not-in',
        NOT_MATCH_REGEXP: 'resume.editor.field.error.regexp',
        SIZE_LESS_THAN_MINIMUM: 'resume.editor.field.error.required',
        LENGTH_GREATER_THAN_MAXIMUM: {
            one: 'resume.editor.field.error.max-length.one',
            some: 'resume.editor.field.error.max-length.some',
            many: 'resume.editor.field.error.max-length.many',
        },
    },
};

interface Props {
    onPhotoEdit: () => void;
}

const MainStep = translation<Props>(({ trls, onPhotoEdit }) => {
    const gender = useSelector(selectApplicantResumeGender);
    const photoUrl = useSelector(selectResumeEditorPhotoUrl);
    const lastName = useSelector(selectApplicantResumeLastName);
    const firstName = useSelector(selectApplicantResumeFirstName);

    const letters = getAvatarLetters(lastName, firstName);

    const errorsMap = useTranslatedErrorMap(TrlKeys.commonErrors, trls);

    return (
        <VSpacingContainer default={24}>
            <div className={styles.avatar}>
                <Avatar path={photoUrl} letters={letters} gender={gender} />
                <Button size="small" style="accent" mode="secondary" onClick={onPhotoEdit}>
                    {photoUrl ? trls[TrlKeys.photoBtn.changed] : trls[TrlKeys.photoBtn.add]}
                </Button>
            </div>
            <TitleField commonErrors={errorsMap} />
            <SalaryField commonErrors={errorsMap} />
            <ProfessionalRoleField commonErrors={errorsMap} />
        </VSpacingContainer>
    );
});

export default MainStep;
