import { ComponentType, PropsWithChildren } from 'react';

import { translation } from '@hh.ru/front-static-app';
import { Text } from '@hh.ru/magritte-ui';

import styles from './styles.less';

const TrlKeys = {
    add: 'resume.editor.contacts.add',
};

const AddContactWwapper: ComponentType<PropsWithChildren> = translation(({ trls, children }) => {
    return (
        <div className={styles.wrapper}>
            <div className={styles.textWrapper}>
                <Text typography="label-3-regular">{trls[TrlKeys.add]}</Text>
            </div>
            {children}
        </div>
    );
});

export default AddContactWwapper;
