import { useCallback, useState } from 'react';
import { connect } from 'react-redux';
import classnames from 'classnames';

import Column from 'bloko/blocks/column';
import { H3 } from 'bloko/blocks/header';
import VSpacing from 'bloko/blocks/vSpacing';

import EmployerZpOnboardingTitleDropInfo, {
    EMPLOYER_ZP_ONBOARDING_RESUME_VIEW_COMMENTS_TIP,
    TipTypes,
} from 'src/components/EmployerZpOnboarding/EmployerZpOnboardingTitleDropInfo';
import { useHiringManagerCommentButton } from 'src/components/HiringManager/hooks/useHiringManagerCommentButton';
import ResumeComments from 'src/components/ResumeComments';
import translation from 'src/components/translation';
import { useSelector } from 'src/hooks/useSelector';
import { useGetMColumnSize } from 'src/pages/ResumeView/hooks/useGetMColumnSize';

import HiringManagerComment from 'src/pages/ResumeView/components/View/ResumeSidebar/Comments/HiringManagerComment';

const ResumeCommentsSidebar = ({
    trls,
    shouldBeRendered,
    isPrintVersion,
    user,
    isFirstSideBlock,
    showResumeViewCommentsTip,
}) => {
    const { vacancyId, t: topicId } = useSelector((state) => state.router.location.query);
    const resumeHash = useSelector((state) => state.resume.hash);
    const hiringManagerCommentButton = useHiringManagerCommentButton();
    const { mSidebar } = useGetMColumnSize();

    const [showComments, setShowComments] = useState(shouldBeRendered);
    const hideComment = useCallback(() => setShowComments(false), []);

    if (!shouldBeRendered || (isPrintVersion && !showComments)) {
        return null;
    }

    const CommentsHeader = () => <H3>{trls[ResumeCommentsSidebar.trls.resumeComments]}</H3>;

    return (
        <Column xs="0" s="0" m={mSidebar} l="4">
            <div
                className={classnames('resume-sidebar-section', {
                    'resume-sidebar-section_first': isFirstSideBlock,
                    'resume-sidebar-section_noprint': !showComments,
                })}
                data-qa="resume-comments"
            >
                {showResumeViewCommentsTip ? (
                    <EmployerZpOnboardingTitleDropInfo tipType={TipTypes.ResumeViewComments}>
                        <CommentsHeader />
                    </EmployerZpOnboardingTitleDropInfo>
                ) : (
                    <CommentsHeader />
                )}
                <VSpacing base={4} />
                <ResumeComments
                    isResumePage
                    noCommentsCallback={hideComment}
                    user={user}
                    {...(hiringManagerCommentButton ? { render: { add: HiringManagerComment } } : {})}
                    topicId={topicId}
                    resumeHash={resumeHash}
                    vacancyId={vacancyId}
                />
            </div>
        </Column>
    );
};

ResumeCommentsSidebar.trls = {
    resumeComments: 'resume.comments',
};

export default connect((state) => ({
    user: state.resume?.user,
    isPrintVersion: state.printVersion.isPrintVersion,
    shouldBeRendered: state.printVersion.comments !== false,
    showResumeViewCommentsTip: state.infoTip?.name === EMPLOYER_ZP_ONBOARDING_RESUME_VIEW_COMMENTS_TIP,
}))(translation(ResumeCommentsSidebar));
