import { useRef } from 'react';

import { Action, Cell, Text, Tooltip, VSpacingContainer } from '@hh.ru/magritte-ui';
import { QuestionCircleOutlinedSize16 } from '@hh.ru/magritte-ui/icon';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import AsyncHighlighter from 'src/components/Markup/AsyncHighlighter';
import translation from 'src/components/translation';
import { useOnOffState } from 'src/hooks/useOnOffState';
import { useSelector } from 'src/hooks/useSelector';
import ResumeBlock from 'src/pages/ResumeView/employerRedesign/components/ResumeBlock';
import { RESUME_BLOCK_VSPACING } from 'src/pages/ResumeView/employerRedesign/const';

const TrlKeys = {
    title: 'rb.title.recommendation',
    tooltip: 'resume.applicant.recommendations.contactsHint',
};

const ResumeRecommendations: TranslatedComponent = ({ trls }) => {
    const recommendations = useSelector((state) => state.resume?.recommendation.value);

    const [tooltipVisibility, showTooltip, hideTooltip] = useOnOffState(false);
    const activatorRef = useRef<HTMLDivElement>(null);

    if (!recommendations?.length) {
        return null;
    }

    return (
        <>
            <ResumeBlock
                title={trls[TrlKeys.title]}
                actions={
                    <div ref={activatorRef}>
                        <Action
                            mode="secondary"
                            icon={QuestionCircleOutlinedSize16}
                            disablePadding
                            onClick={showTooltip}
                        />
                    </div>
                }
            >
                <VSpacingContainer default={RESUME_BLOCK_VSPACING}>
                    {recommendations.map((recommendation) => (
                        <Cell key={recommendation.id}>
                            <VSpacingContainer default={4}>
                                <Text typography="label-3-regular">
                                    <AsyncHighlighter>{recommendation.name}</AsyncHighlighter>
                                </Text>
                                {recommendation.position && (
                                    <Text typography="label-3-regular" style="secondary">
                                        <AsyncHighlighter>{recommendation.position}</AsyncHighlighter>
                                    </Text>
                                )}
                                <Text typography="label-3-regular" style="secondary">
                                    <AsyncHighlighter>{recommendation.organization}</AsyncHighlighter>
                                </Text>
                            </VSpacingContainer>
                        </Cell>
                    ))}
                </VSpacingContainer>
            </ResumeBlock>

            <Tooltip
                aria-label-close="close"
                placement="right-center"
                activatorRef={activatorRef}
                visible={tooltipVisibility}
                showClose
                onClose={hideTooltip}
            >
                {trls[TrlKeys.tooltip]}
            </Tooltip>
        </>
    );
};

export default translation(ResumeRecommendations);
