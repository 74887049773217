import { connect } from 'react-redux';

import Column from 'bloko/blocks/column';
import { H3 } from 'bloko/blocks/header';
import VSpacing from 'bloko/blocks/vSpacing';

import translation from 'src/components/translation';

import VacancyTest from 'src/pages/ResumeView/components/View/ResumeSidebar/Tests/Vacancy';

function VacancyTestSidebar({ trls, topicList }) {
    const current = topicList && topicList.find((topic) => topic.currentVacancy);
    if (!current || !current.testSolution) {
        return null;
    }

    return (
        <div className="resume-sidebar-section-wrapper">
            <Column xs="0" s="4" m="3" l="4">
                <div className="resume-sidebar-section">
                    <H3>{trls[VacancyTestSidebar.trls.title]}</H3>
                    <VSpacing base={4} />
                    <VacancyTest />
                </div>
            </Column>
        </div>
    );
}

VacancyTestSidebar.trls = {
    title: 'employer.resume.testSolution.title',
};

export default connect((state) => ({
    topicList: state.employerNegotiations.topicList,
}))(translation(VacancyTestSidebar));
