import { FC } from 'react';

import { Link } from '@hh.ru/magritte-ui';
import { GlobeOutlinedSize16 } from '@hh.ru/magritte-ui/icon';

import DenseText from 'src/components/DenseText';
import { PersonalSite } from 'src/models/resume/resumeCommon.types';

import styles from './styles.less';

const Site: FC<PersonalSite> = ({ url, type, text }) => {
    if (!text) {
        return null;
    }

    return (
        <div className={styles.wrapper}>
            <GlobeOutlinedSize16 padding={4} initialColor="secondary" />
            <Link
                typography="label-3-regular"
                style="neutral"
                href={url}
                rel="noopener noreferrer"
                target="_blank"
                data-qa={`resume-personalsite-${type}`}
            >
                <DenseText typography="label-3-regular">{text.replace(/https?:\/\//, '')}</DenseText>
            </Link>
        </div>
    );
};

export default Site;
