import { useState } from 'react';
import { connect } from 'react-redux';

import { VSpacing } from '@hh.ru/magritte-ui';
import Text, { TextImportance } from 'bloko/blocks/text';

import ResumeChatPinnedMessages from 'src/components/ResumeView/ResumeChatPinnedMessages';
import WebcallButton from 'src/components/WebcallButton';
import translation from 'src/components/translation';
import { useSelector } from 'src/hooks/useSelector';
import { UserType } from 'src/models/userType';
import ResumeEditLink from 'src/pages/ResumeView/components/View/ResumeEditLink';
import ResumeEmptyBlock from 'src/pages/ResumeView/components/View/ResumeEmptyBlock';
import { PHONES } from 'src/utils/constants/resumeHiddenFields';
import { VIEW_WITHOUT_CONTACTS } from 'src/utils/constants/resumePermission';

import ResumeContactsChatButton from 'src/pages/ResumeView/components/ResumeHeader/Contacts/ResumeContactsChat';
import ResumeContactsEmail from 'src/pages/ResumeView/components/ResumeHeader/Contacts/ResumeContactsEmail';
import ResumeContactsPersonalSites from 'src/pages/ResumeView/components/ResumeHeader/Contacts/ResumeContactsPersonalSites';
import ResumeContactsPhones from 'src/pages/ResumeView/components/ResumeHeader/Contacts/ResumeContactsPhones';
import ShowContacts from 'src/pages/ResumeView/components/ResumeHeader/Contacts/ShowContacts';

const ResumeContacts = ({
    isAllowed,
    isEmpty,
    isPhoneHidden,
    openedContacts,
    phone,
    userType,
    onContactsClick,
    trls,
}) => {
    const [contactsVisibility, setContactsVisibility] = useState(
        userType !== UserType.Employer || isPhoneHidden || !phone || openedContacts
    );
    const pinnedChatMessages = useSelector((state) => state.pinnedChatMessages?.items);

    if (isEmpty) {
        return (
            <div className="resume-header-field">
                <ResumeEmptyBlock block="contacts" />
            </div>
        );
    }

    if (isAllowed) {
        return (
            <div className="resume-header-field">
                <div data-qa="resume-block-contacts">
                    <Text importance={TextImportance.Tertiary}>{trls[ResumeContacts.trls.contacts]}</Text>
                    <VSpacing default={4} />
                    {contactsVisibility ? (
                        <>
                            <ResumeContactsPhones />
                            <ResumeContactsEmail />
                            <ResumeContactsPersonalSites />
                        </>
                    ) : (
                        <ShowContacts phone={phone} setContactsVisibility={setContactsVisibility} />
                    )}
                    <ResumeEditLink block="contacts" data-qa="resume-block-contacts-edit" WrapperComponent="p" />
                    <VSpacing default={16} />
                    <div className="resume-communication-buttons noprint">
                        <WebcallButton data-qa="resume__call-button" />
                        <ResumeContactsChatButton onContactsClick={onContactsClick} />
                    </div>
                    {!!pinnedChatMessages.length && (
                        <>
                            <VSpacing default={4} />
                            <ResumeChatPinnedMessages pinnedChatMessages={pinnedChatMessages} />
                        </>
                    )}
                </div>
            </div>
        );
    }

    return null;
};

ResumeContacts.trls = {
    contacts: 'resume.field.contacts',
};

export default connect((state) => ({
    isAllowed: state.resume?.permission !== VIEW_WITHOUT_CONTACTS,
    isEmpty: state.resume?.emptyBlocks.includes('contacts'),
    isPhoneHidden: state.resume?.hiddenFields.includes(PHONES),
    openedContacts: state.resume?.openedContacts,
    phone: state.resume?.phone.value?.[0],
    userType: state.userType,
}))(translation(ResumeContacts));
