import { useCallback, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';

import resumeHistoryButtonClick from '@hh.ru/analytics-js-events/build/xhh/employer/resume/resume_history_button_click';
import { Button, VSpacingContainer } from '@hh.ru/magritte-ui';
import { makeSetStoreField } from '@hh.ru/redux-create-reducer/lib/createReducer';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import { useNotification } from 'src/components/Notifications/Provider';
import resumeSidebarLoadError from 'src/components/Notifications/ResumeSidebar';
import ResumeBlockButtonWrapper from 'src/components/ResumeView/ResumeBlockButtonWrapper';
import translation from 'src/components/translation';
import { useSelector } from 'src/hooks/useSelector';
import ResumeBlock from 'src/pages/ResumeView/employerRedesign/components/ResumeBlock';
import {
    DEFAULT_RESUME_BUTTON_PROPS,
    MAX_NUMBER_OF_ITEMS_TO_RENDER,
} from 'src/pages/ResumeView/employerRedesign/const';
import { fetcher } from 'src/utils/fetcher';

import Item from 'src/pages/ResumeView/employerRedesign/components/ResumeHistory/HistoryItem';

const TrlKeys = {
    title: 'resume.employer.history.title',
    expand: 'resume.employer.history.expand',
};

interface ResumeHistoryProps {
    inSidebar?: boolean;
}

const RESUME_HISTORY_URL = '/shards/resume/history';
const load = makeSetStoreField('negotiationsHistory');

const ResumeHistory: TranslatedComponent<ResumeHistoryProps> = ({ trls, inSidebar = false }) => {
    const dispatch = useDispatch();
    const negotiationsHistory = useSelector(({ negotiationsHistory }) => negotiationsHistory);
    const resumeId = useSelector((state) => state.resume?.id);
    const resumeHash = useSelector((state) => state.resume?.hash);
    const { vacancyId } = useSelector((state) => state.router.location.query);
    const { addNotification } = useNotification();

    const [showAll, setShowAll] = useState(false);

    const negotiationsHistoryList = useMemo(
        () => (showAll ? negotiationsHistory : negotiationsHistory?.slice(0, MAX_NUMBER_OF_ITEMS_TO_RENDER)),
        [negotiationsHistory, showAll]
    );

    const fetchHistoryAndHideButton = useCallback(() => {
        resumeHistoryButtonClick({ vacancyId, resumeId });
        fetcher
            .get(RESUME_HISTORY_URL, { params: { resumeHash } })
            .then((result) => {
                dispatch(load(result.vacancies));
                setShowAll(true);
            })
            .catch(() => {
                addNotification(resumeSidebarLoadError);
            });
    }, [vacancyId, resumeId, resumeHash, dispatch, setShowAll, addNotification]);

    if (!negotiationsHistoryList?.length) {
        return null;
    }

    return (
        <ResumeBlock
            isTitleInside={inSidebar}
            title={trls[TrlKeys.title]}
            data-qa="resume-history-sidebar-container"
            buttons={
                !showAll && (
                    <ResumeBlockButtonWrapper>
                        <Button
                            {...DEFAULT_RESUME_BUTTON_PROPS}
                            data-qa="resume-history-toggle"
                            onClick={fetchHistoryAndHideButton}
                        >
                            {trls[TrlKeys.expand]}
                        </Button>
                    </ResumeBlockButtonWrapper>
                )
            }
        >
            <VSpacingContainer default={24}>
                {negotiationsHistoryList.map((vacancy) => (
                    <Item vacancy={vacancy} key={vacancy['@vacancyId']} />
                ))}
            </VSpacingContainer>
        </ResumeBlock>
    );
};

export default translation(ResumeHistory);
