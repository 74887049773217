import { FC } from 'react';

import ResumeWithoutContactInfo from 'src/pages/ResumeView/components/ResumeWithoutContactInfo';
import ResumeHasHiddenFieldsWarning from 'src/pages/ResumeView/employerRedesign/components/ResumeHasHiddenFieldsWarning';

import styles from './styles.less';

const ResumeBanners: FC = () => {
    return (
        <div className={styles.wrapper}>
            <ResumeWithoutContactInfo />
            <ResumeHasHiddenFieldsWarning />
        </div>
    );
};

export default ResumeBanners;
