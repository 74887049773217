import { DefaultRootState } from 'react-redux';

export const selectAnalyticsParamsHhtmFrom = ({ analyticsParams }: DefaultRootState): string =>
    analyticsParams?.hhtmFrom || '';

export const selectAnalyticsParamsHhtmSource = ({ analyticsParams }: DefaultRootState): string =>
    analyticsParams?.hhtmSource || '';

export const selectAnalyticsParamsHhtmFromLabel = ({ analyticsParams }: DefaultRootState): string =>
    (analyticsParams?.hhtmFromLabel as string) || '';

export const selectAnalyticsParamsHhtmSourceLabel = ({ analyticsParams }: DefaultRootState): string =>
    (analyticsParams?.hhtmSourceLabel as string) || '';
