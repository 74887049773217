import { useEffect, useMemo, useRef, useState } from 'react';

import { translation, format } from '@hh.ru/front-static-app';
import {
    useBreakpoint,
    TreeSelector,
    ListControls,
    TreeCollection,
    AdditionalDefault,
    BottomSheet,
    Modal,
    NavigationBar,
    Action,
    BottomSheetFooter,
    Button,
    ActionBar,
} from '@hh.ru/magritte-ui';
import { ChevronLeftOutlinedSize24, CrossOutlinedSize24 } from '@hh.ru/magritte-ui/icon';

interface Props {
    visible: boolean;
    value: number[];
    maxSelected?: number;
    collection: TreeCollection<AdditionalDefault>;
    onChange: (selectedIds: string[]) => void;
    onChangeVisible: (isVisible: boolean) => void;
}

const TrlKeys = {
    hint: 'resume.editor.experience.industry.modal.selected.hint',
    title: 'resume.editor.experience.industry.modal.title',
    titleMobile: 'resume.editor.experience.industry.modal.title.mobile',
    save: 'resume.editor.experience.industry.modal.btn.save',
    reset: 'resume.editor.experience.industry.modal.btn.reset',
};

const IndustryTreeSelector = translation<Props>(
    ({ trls, visible, value, collection, onChange, onChangeVisible, maxSelected = 5 }) => {
        const { isMobile } = useBreakpoint();
        const controlsRef = useRef<ListControls>(null);

        const currentIds = useMemo(() => value.map((id) => id.toString()), [value]);
        const [selected, setSelected] = useState<string[]>(currentIds);

        const handleBack = () => {
            controlsRef.current?.back();
        };

        const handleChange = (allSelected: string[]) => {
            setSelected(allSelected);
        };

        const handleClose = () => {
            onChange(currentIds);
            onChangeVisible(false);
        };

        const handleReset = () => {
            onChange(currentIds);
        };

        const handleSave = () => {
            onChange(selected);
            onChangeVisible(false);
        };

        useEffect(() => {
            setSelected(currentIds);
        }, [currentIds]);

        const description = useMemo(() => {
            if (!maxSelected) {
                return undefined;
            }

            return format(trls[TrlKeys.hint], {
                '{0}': selected.length,
                '{1}': maxSelected,
            });
        }, [selected, maxSelected, trls]);

        return (
            <TreeSelector
                leavesOnly
                collapseToParentId
                ref={controlsRef}
                value={selected}
                onChange={handleChange}
                maxSelected={maxSelected}
                collection={collection}
                getSelectAllParentTrl={() => undefined}
            >
                {({ renderTreeSelector, renderInput }) => {
                    return isMobile ? (
                        <BottomSheet
                            visible={visible}
                            height="full-screen"
                            header={
                                <NavigationBar
                                    title={trls[TrlKeys.titleMobile]}
                                    subtitle={description}
                                    options={renderInput()}
                                    right={<Action icon={CrossOutlinedSize24} onClick={handleClose} />}
                                    left={<Action icon={ChevronLeftOutlinedSize24} onClick={handleBack} />}
                                />
                            }
                            footer={
                                <BottomSheetFooter>
                                    <Button mode="secondary" style="accent" stretched onClick={handleReset}>
                                        {trls[TrlKeys.reset]}
                                    </Button>
                                    <Button mode="primary" style="accent" stretched onClick={handleSave}>
                                        {trls[TrlKeys.save]}
                                    </Button>
                                </BottomSheetFooter>
                            }
                            onClose={handleClose}
                        >
                            {renderTreeSelector()}
                        </BottomSheet>
                    ) : (
                        <Modal
                            title={trls[TrlKeys.title]}
                            visible={visible}
                            options={renderInput()}
                            onClose={handleClose}
                            titleSize="large"
                            titleDescription={description}
                            titleDescriptionStyle="secondary"
                            actions={
                                <Action
                                    style="neutral"
                                    mode="secondary"
                                    icon={CrossOutlinedSize24}
                                    onClick={handleClose}
                                />
                            }
                            footer={
                                <ActionBar
                                    primaryActions={
                                        <Button mode="primary" style="accent" onClick={handleSave}>
                                            {trls[TrlKeys.save]}
                                        </Button>
                                    }
                                    secondaryActions={
                                        <Button mode="secondary" style="accent" onClick={handleReset}>
                                            {trls[TrlKeys.reset]}
                                        </Button>
                                    }
                                />
                            }
                        >
                            {renderTreeSelector()}
                        </Modal>
                    );
                }}
            </TreeSelector>
        );
    }
);

export default IndustryTreeSelector;
