import resumeDeleteAlertPrimaryButtonClick from '@hh.ru/analytics-js-events/build/xhh/applicant/resume/resume_delete_alert_primary_button_click';
import resumeDeleteAlertSecondaryButtonClick from '@hh.ru/analytics-js-events/build/xhh/applicant/resume/resume_delete_alert_secondary_button_click';
import { Alert, Button } from '@hh.ru/magritte-ui';
import { ExclamationCircleFilledSize24 } from '@hh.ru/magritte-ui/icon';

import translation from 'src/components/translation';

const TrlKeys = {
    modalHeaderBlocked: 'resumeList.delete.modal.blocked.header',
    modalDescriptionBlocked: 'resumeList.delete.modal.blocked.description',
    deleteButtonBlocked: 'resumeList.delete.modal.blocked.button.delete',
    cancelButtonBlocked: 'resumeList.delete.modal.blocked.button.cancel',
};

interface Props {
    visible: boolean;
    resumeId: string;
    onClose: () => void;
    onDelete: () => void;
}

export const DeleteAlert = translation<Props>(({ trls, visible, onClose, onDelete, resumeId }) => {
    return (
        <Alert
            icon={<ExclamationCircleFilledSize24 />}
            title={trls[TrlKeys.modalHeaderBlocked]}
            layout="vertical"
            visible={visible}
            onClose={onClose}
            iconStyle="negative"
            description={trls[TrlKeys.modalDescriptionBlocked]}
            buttons={
                <>
                    <Button
                        stretched
                        mode="primary"
                        style="negative"
                        onClick={() => {
                            resumeDeleteAlertPrimaryButtonClick({
                                hhtmSource: 'blocked_resume_delete_alert',
                                buttonText: trls[TrlKeys.deleteButtonBlocked],
                                resumeId,
                            });
                            onDelete();
                        }}
                        data-qa="resume-delete-confirm"
                    >
                        {trls[TrlKeys.deleteButtonBlocked]}
                    </Button>

                    <Button
                        stretched
                        mode="secondary"
                        style="negative"
                        data-qa="resume-delete-cancel"
                        onClick={() => {
                            resumeDeleteAlertSecondaryButtonClick({
                                hhtmSource: 'blocked_resume_delete_alert',
                                buttonText: trls[TrlKeys.cancelButtonBlocked],
                                resumeId,
                            });
                            onClose();
                        }}
                    >
                        {trls[TrlKeys.cancelButtonBlocked]}
                    </Button>
                </>
            }
        />
    );
});
