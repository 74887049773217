import { ComponentProps } from 'react';

import Column from 'bloko/blocks/column';

import { AppStore } from 'src/app/store';
import { UserType } from 'src/models/userType';

type MSizes = ComponentProps<typeof Column>['m'];

const DEFAULT_EMPLOYER_HIRING_MANAGER_COLUMNS_SIZE: { mMain: MSizes; mSidebar: MSizes } = { mMain: '8', mSidebar: '4' };
const DEFAULT_COLUMN_SIZE: { mMain: MSizes; mSidebar: MSizes } = { mMain: '9', mSidebar: '3' };

export const selectorMColumnSize = (state: AppStore): { mMain: MSizes; mSidebar: MSizes } => {
    return state.userType === UserType.Employer && state.isHiringManager
        ? DEFAULT_EMPLOYER_HIRING_MANAGER_COLUMNS_SIZE
        : DEFAULT_COLUMN_SIZE;
};
