import { ComponentType } from 'react';

import { translation } from '@hh.ru/front-static-app';
import { BottomSheet, NavigationBar, Action, VSpacingContainer } from '@hh.ru/magritte-ui';
import { CrossOutlinedSize24 } from '@hh.ru/magritte-ui/icon';

import BusinessTripReadiness from 'src/pages/ResumeView/redesign/blocks/additional/BottomSheet/BusinessTripReadiness';
import Employment from 'src/pages/ResumeView/redesign/blocks/additional/BottomSheet/Employment';
import TravelTime from 'src/pages/ResumeView/redesign/blocks/additional/BottomSheet/TravelTime';
import WorkSchedule from 'src/pages/ResumeView/redesign/blocks/additional/BottomSheet/WorkSchedule';

interface Props {
    visible: boolean;
    onClose: VoidFunction;
}

const TrlKeys = {
    title: 'resume.editor.additional.title',
};

const AdditionalModal: ComponentType<Props> = translation(({ trls, visible, onClose }) => {
    return (
        <BottomSheet
            height="full-screen"
            visible={visible}
            onClose={onClose}
            header={
                <NavigationBar
                    title={trls[TrlKeys.title]}
                    right={<Action icon={CrossOutlinedSize24} onClick={onClose} />}
                    size="large"
                />
            }
        >
            <VSpacingContainer default={12}>
                <WorkSchedule />
                <Employment />
                <TravelTime />
                <BusinessTripReadiness />
            </VSpacingContainer>
        </BottomSheet>
    );
});

export default AdditionalModal;
