import getConversionType from 'bloko/common/getConversionType';

import { ApplicantResumeEditorFields } from 'src/models/applicant/resume/editor/types';
import { EditorFieldError, EditorFormErrors } from 'src/models/applicant/resume/editor/types/error';
import { dateFromDateTypeToFormFormat } from 'src/models/applicant/resume/lib/common/date';
import { isArray, isEmpty, isNumber, isObject, isString } from 'src/models/applicant/resume/lib/common/empty';
import { hasOwn } from 'src/models/applicant/resume/lib/common/object';
import { Condition } from 'src/models/applicant/resume/types/conditions';

import { ErrorsMap } from 'src/models/applicant/resume/editor/form/errors';

function getConditionText(code: string, key: string, condition?: Condition): string {
    if (code === 'SIZE_LESS_THAN_MINIMUM') {
        return condition?.mincount ? `${condition.mincount}` : '';
    } else if (code === 'SIZE_GREATER_THAN_MAXIMUM') {
        return condition?.maxcount ? `${condition.maxcount}` : '';
    } else if (code === 'VALUE_LESS_THAN_MINIMUM') {
        return condition?.mincount ? `${condition.mincount}` : '';
    } else if (code === 'VALUE_GREATER_THAN_MAXIMUM') {
        return condition?.maxcount ? `${condition.maxcount}` : '';
    } else if (code === 'LENGTH_GREATER_THAN_MAXIMUM') {
        return String(condition?.parts.find((part) => key in part)?.[key].maxlength);
    } else if (code === 'LENGTH_LESS_THAN_MINIMUM') {
        return String(condition?.parts.find((part) => key in part)?.[key].minlength);
    } else if (code === 'EARLY_THAN_MINIMUM') {
        const value = condition?.parts.find((part) => key in part)?.[key].minvalue;
        return value ? dateFromDateTypeToFormFormat(value) : '';
    } else if (code === 'LATER_THAN_MAXIMUM') {
        const value = condition?.parts.find((part) => key in part)?.[key].maxvalue;
        return value ? dateFromDateTypeToFormFormat(value) : '';
    }
    return '';
}

/**
 * Бэк отдает массив ошибок.
 * Преобразовываем в мапу формата Record<FieldName, Error[]>
 */
export function fromApiToLocalErrors(errors: EditorFieldError[]): EditorFormErrors {
    return errors.reduce((acc, error) => {
        const field = error.field;
        if (hasOwn(acc, field)) {
            acc[field].push(error);
            return acc;
        }
        return { ...acc, [error.field]: [error] };
    }, {} as EditorFormErrors);
}

export function makeErrorMessage(
    formErrors: EditorFieldError[],
    errorsMap: ErrorsMap,
    condition?: Condition,
    _?: Required<ApplicantResumeEditorFields>[keyof ApplicantResumeEditorFields]
): EditorFieldError[] {
    if (isEmpty(errorsMap)) {
        return [];
    }
    return formErrors.map((error) => {
        const code = error.code;
        const subname = getFieldSubname(error.fieldNamePath);

        const errorValue = errorsMap[code];
        const conditionText = getConditionText(code, subname, condition);

        if (!errorValue || isEmpty(errorValue)) {
            const unknownError = errorsMap.UNKNOWN;
            if (unknownError) {
                return {
                    ...error,
                    message: unknownError as string,
                };
            }
            return {
                ...error,
                message: code,
            };
        }

        if (isObject(errorValue)) {
            // значит ошибка имеет плюрал формат
            const value = error.value;
            const valueLength = getValueLength(value);
            const conditionParameter = valueLength - Number(conditionText);
            const baseText = getConversionType({ ...errorValue, value: valueLength });

            return {
                ...error,
                message: baseText?.replace('{0}', `${conditionParameter}`) || '',
            };
        }

        return {
            ...error,
            message: errorValue?.replace('{0}', conditionText) || '',
        };
    });
}

function getFieldSubname(fieldNamePath: string) {
    return fieldNamePath.split('/')?.[3] || '';
}

function getValueLength(value?: EditorFieldError['value']): number {
    if (!value) {
        return 0;
    }
    if (isString(value)) {
        return value.length;
    }
    if (isArray(value)) {
        return value.length;
    }
    if (isNumber(value)) {
        return `${value}`.length;
    }
    return 1;
}
