import { FC, ReactElement } from 'react';

import { Text, Card, Cell } from '@hh.ru/magritte-ui';

interface CallTypeCardProps {
    icon: ReactElement;
    title: string;
    description: string;
    onClick: () => void;
    ctaText: string;
}

const CallTypeCard: FC<CallTypeCardProps> = ({ icon, title, description, onClick, ctaText }) => (
    <Card padding={16} borderWidth="default" borderRadius={12} stretched onClick={onClick}>
        <Cell left={icon} right={<Text style="accent">{ctaText}</Text>} align="top">
            <Text typography="label-2-regular">{title}</Text>
            <Text typography="label-3-regular" style="secondary">
                {description}
            </Text>
        </Cell>
    </Card>
);

export default CallTypeCard;
