import { useRef } from 'react';

import resumeLetterButtonClick from '@hh.ru/analytics-js-events/build/xhh/employer/resume/resume_letter_button_click';
import { TooltipHover, Button } from '@hh.ru/magritte-ui';
import { EnvelopeOutlinedSize16 } from '@hh.ru/magritte-ui/icon';
import { SPALink } from '@hh.ru/redux-spa-middleware';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import translation from 'src/components/translation';
import { useSelector } from 'src/hooks/useSelector';
import { TransitionType } from 'src/models/employerNegotiations/negotiationLinks';
import { VIEW_WITHOUT_CONTACTS } from 'src/utils/constants/resumePermission';

const TrlKeys = {
    title: 'resume.button.writeLetter',
};

const ResumeSendMessage: TranslatedComponent = ({ trls }) => {
    const activatorRef = useRef(null);
    const resumeId = useSelector((state) => state.resume?.id);
    const vacancyId = useSelector((state) => state.router.location.query.vacancyId);

    const permissionWithoutContacts = useSelector((state) => state.resume?.permission === VIEW_WITHOUT_CONTACTS);
    const isEmailEmpty = useSelector((state) => !state.resume?.email?.value);
    const linkToMail = useSelector(
        (state) =>
            state.resume?.hash && state.employerNegotiationLinks[state.resume?.hash]?.[TransitionType.Mail]?.defaultLink
    );

    if (!linkToMail || permissionWithoutContacts || isEmailEmpty) {
        return null;
    }

    return (
        <>
            <Button
                size="small"
                mode="secondary"
                icon={<EnvelopeOutlinedSize16 />}
                to={linkToMail}
                Element={SPALink}
                aria-label="resume-mailto"
                data-qa="resume__mailto-button"
                ref={activatorRef}
                onClick={() => resumeLetterButtonClick({ vacancyId, resumeId })}
            />
            <TooltipHover activatorRef={activatorRef} placement="top-center">
                {trls[TrlKeys.title]}
            </TooltipHover>
        </>
    );
};

export default translation(ResumeSendMessage);
