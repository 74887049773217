import { createContext, FC, PropsWithChildren, useContext, useMemo } from 'react';
import { useSelector } from 'react-redux';

import resumeActionLinkButtonClick, {
    ButtonName as ActionLinkName,
} from '@hh.ru/analytics-js-events/build/xhh/applicant/resume/resume_action_link_button_click';
import resumeCompletionButtonClick from '@hh.ru/analytics-js-events/build/xhh/applicant/resume/resume_completion_button_click';
import resumeCompletionElementShown, {
    Suggests_list as SuggestsList,
} from '@hh.ru/analytics-js-events/build/xhh/applicant/resume/resume_completion_element_shown';
import resumeEditButtonClick, {
    ButtonTitle as BlockName,
} from '@hh.ru/analytics-js-events/build/xhh/applicant/resume/resume_edit_button_click';
import resumeEducationAddButtonClick from '@hh.ru/analytics-js-events/build/xhh/applicant/resume/resume_education_add_button_click';
import resumeRenewalElementShown from '@hh.ru/analytics-js-events/build/xhh/applicant/resume/resume_renewal_element_shown';
import resumeVewAllButtonClick from '@hh.ru/analytics-js-events/build/xhh/applicant/resume/resume_view_all_button_click';
import resumeWorkExperienceAddButtonClick from '@hh.ru/analytics-js-events/build/xhh/applicant/resume/resume_work_experience_add_button_click';
import resumeKeySkillButtonClick, {
    ButtonTitle as KeySkillType,
} from '@hh.ru/analytics-js-events/build/xhh/applicant/resume_key_skill/resume_key_skill_button_click';
import resumeKeySkillElementShown from '@hh.ru/analytics-js-events/build/xhh/applicant/resume_key_skill/resume_key_skill_element_shown';

import { EditorFormType } from 'src/models/applicant/resume/editor/types';
import { toSnakeCase } from 'src/models/applicant/resume/lib/common/string';
import { selectResumeHash } from 'src/models/applicant/resume/selectors';

import { INIT_VALUE } from 'src/models/applicant/resume/analytics/constants';
import {
    selectAnalyticsParamsHhtmFrom,
    selectAnalyticsParamsHhtmSource,
    selectAnalyticsParamsHhtmFromLabel,
    selectAnalyticsParamsHhtmSourceLabel,
} from 'src/models/applicant/resume/analytics/selectors';
import { convertFieldNameToAnalyticsButtonTitle } from 'src/models/applicant/resume/analytics/utils';

const ApplicantResumeAnalyticsContext = createContext(INIT_VALUE);

export const ApplicantResumeAnalyticsProvider: FC<PropsWithChildren> = ({ children }) => {
    const resumeHash = useSelector(selectResumeHash);
    const hhtmFrom = useSelector(selectAnalyticsParamsHhtmFrom);
    const hhtmSource = useSelector(selectAnalyticsParamsHhtmSource);
    const hhtmFromLabel = useSelector(selectAnalyticsParamsHhtmFromLabel);
    const hhtmSourceLabel = useSelector(selectAnalyticsParamsHhtmSourceLabel);

    const analytics = useMemo(() => {
        const resumeActionLinkButtonClickFn = (buttonName: ActionLinkName) => () =>
            resumeActionLinkButtonClick({
                resumeId: resumeHash,
                buttonName,
                hhtmFrom,
                hhtmSource,
                hhtmFromLabel,
                hhtmSourceLabel,
            });

        return {
            resumeCompletenessTagClick: (fieldName: string) =>
                resumeCompletionButtonClick({
                    resumeId: resumeHash,
                    buttonTitle: convertFieldNameToAnalyticsButtonTitle(fieldName),
                    hhtmFrom,
                    hhtmSource,
                    hhtmFromLabel,
                    hhtmSourceLabel,
                }),
            resumeCompletenessElementShown: (element: HTMLElement, { fields }: { fields: string[] }) =>
                resumeCompletionElementShown(element, {
                    resumeId: resumeHash,
                    suggests_list: fields
                        .map((fieldName) => convertFieldNameToAnalyticsButtonTitle(fieldName))
                        .join(',') as SuggestsList,
                    hhtmFrom,
                    hhtmSource,
                    hhtmFromLabel,
                    hhtmSourceLabel,
                }),
            resumeEducationAddButtonClick: () =>
                resumeEducationAddButtonClick({
                    resumeId: resumeHash,
                    hhtmFrom,
                    hhtmSource,
                    hhtmFromLabel,
                    hhtmSourceLabel,
                }),
            resumeWorkExperienceAddButtonClick: () =>
                resumeWorkExperienceAddButtonClick({
                    resumeId: resumeHash,
                    hhtmFrom,
                    hhtmSource,
                    hhtmFromLabel,
                    hhtmSourceLabel,
                }),
            resumeEditButtonClick: (type: EditorFormType) =>
                resumeEditButtonClick({
                    resumeId: resumeHash,
                    buttonTitle: toSnakeCase(type) as BlockName,
                    hhtmFrom,
                    hhtmSource,
                    hhtmFromLabel,
                    hhtmSourceLabel,
                }),
            resumeKeySkillButtonClick: (type: KeySkillType) =>
                resumeKeySkillButtonClick({
                    buttonTitle: type,
                    resumeId: resumeHash,
                }),
            resumeKeySkillElementShown: (element: HTMLElement) =>
                resumeKeySkillElementShown(element, {
                    resumeId: resumeHash,
                }),
            resumeRenewalElementShown: (element: HTMLElement, { isActive }: { isActive: boolean }) =>
                resumeRenewalElementShown(element, {
                    resumeId: resumeHash,
                    isActive: isActive ? '1' : '0',
                    hhtmFrom,
                    hhtmSource,
                    hhtmFromLabel,
                    hhtmSourceLabel,
                }),
            resumeVewAllButtonClick: (label: string) =>
                resumeVewAllButtonClick({
                    resumeId: resumeHash,
                    hhtmSourceLabel: toSnakeCase(label),
                    hhtmFrom,
                    hhtmSource,
                    hhtmFromLabel,
                }),
            resumePrintClick: resumeActionLinkButtonClickFn('resume_print'),
            resumeDeleteClick: resumeActionLinkButtonClickFn('resume_delete'),
            resumeUpdateClick: resumeActionLinkButtonClickFn('resume_update'),
            resumeSettingsClick: resumeActionLinkButtonClickFn('resume_settings'),
            changeVisibilityClick: resumeActionLinkButtonClickFn('resume_change_visibility'),
            vacancySearchListClick: resumeActionLinkButtonClickFn('resume_vacancy_search_list'),
            resumeTranslateEnglishClick: resumeActionLinkButtonClickFn('resume_translate_english'),
        };
    }, [resumeHash, hhtmFrom, hhtmSource, hhtmSourceLabel, hhtmFromLabel]);

    return (
        <ApplicantResumeAnalyticsContext.Provider value={analytics}>
            {children}
        </ApplicantResumeAnalyticsContext.Provider>
    );
};

export const useActionLinkAnalytics = () => {
    const context = useContext(ApplicantResumeAnalyticsContext);

    return context;
};
