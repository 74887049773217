import { ComponentType } from 'react';

import { useSelector } from '@hh.ru/front-static-app';
import { VSpacingContainer } from '@hh.ru/magritte-ui';

import { selectResumeEditorPhone } from 'src/models/applicant/resume/blocks/contacts/editor/selectors';
import { ErrorsMap } from 'src/models/applicant/resume/editor/form/errors';
import PhoneField from 'src/pages/ResumeView/redesign/blocks/contacts/Editor/Phone/Field';

const PhoneFieldList: ComponentType<{ errorsMap: ErrorsMap }> = ({ errorsMap }) => {
    const phones = useSelector(selectResumeEditorPhone);

    return (
        <VSpacingContainer default={16}>
            {phones.map(({ type, comment, formatted }, index) => (
                <PhoneField
                    key={type}
                    type={type}
                    value={formatted}
                    comment={comment}
                    errorsMap={errorsMap}
                    fieldIndex={index}
                />
            ))}
        </VSpacingContainer>
    );
};

export default PhoneFieldList;
