import { useCallback, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';

import { translation, useSelector } from '@hh.ru/front-static-app';
import { Action, ActionBar, BottomSheetFooter, Button, Text } from '@hh.ru/magritte-ui';
import { ChevronLeftOutlinedSize24 } from '@hh.ru/magritte-ui/icon';

import { removeApplicantPhotoArtifact } from 'src/models/applicant/resume/artifacts/actions';
import {
    selectApplicantResumeArtivacts,
    selectArtivactsRemoving,
    selectArtivactsFilesLoading,
} from 'src/models/applicant/resume/artifacts/selectors';
import { WITHOUT_PHOTO_ID } from 'src/models/applicant/resume/blocks/position/edit/photo/constants';
import { selectResumeEditorPhoto } from 'src/models/applicant/resume/blocks/position/edit/photo/selectors';
import { selectResumeEditorOpenedPosition } from 'src/models/applicant/resume/blocks/position/edit/selectors';
import { PositionModalStep } from 'src/models/applicant/resume/blocks/position/edit/types';
import { submitResumeEditorData } from 'src/models/applicant/resume/editor/store/actions/submitEditor';
import { closeResumeEditorModal } from 'src/models/applicant/resume/editor/store/actions/toggleModal';
import { setResumeEditorFieldValue } from 'src/models/applicant/resume/editor/store/slice';
import EditorModal, { EditorModalProps } from 'src/pages/ResumeView/redesign/common/EditorModal';

import MainStep from 'src/pages/ResumeView/redesign/blocks/position/Editor/steps/Main';
import PhotoStep from 'src/pages/ResumeView/redesign/blocks/position/Editor/steps/Photo';

import styles from './styles.less';

const TrlKeys = {
    close: 'resume.editor.photo.edit.close',
    select: 'resume.editor.photo.edit.select',
    remove: 'resume.editor.photo.edit.remove',
    corrupted: 'resume.editor.photo.upload.error.corrupted',
    mainTitle: 'resume.editor.postion.title',
    photoEditTitle: 'resume.editor.photo.edit.title',
    photoEditDescription: 'resume.editor.photo.edit.description',
};

const ResumePositionEditor = translation(({ trls }) => {
    const dispatch = useDispatch();

    const visible = useSelector(selectResumeEditorOpenedPosition);
    const removing = useSelector(selectArtivactsRemoving);
    const filesLoading = useSelector(selectArtivactsFilesLoading);
    const artivacts = useSelector(selectApplicantResumeArtivacts);
    const resumeEditorPhotoId = useSelector(selectResumeEditorPhoto);

    const [selectedPhotoId, setSelectedPhotoId] = useState(resumeEditorPhotoId);
    const [step, setStep] = useState<PositionModalStep>('main');

    const selectedArtivact = artivacts.find(({ id }) => id === selectedPhotoId);

    const handleSave = () => dispatch(submitResumeEditorData());
    const handlerAfterExit = () => setStep('main');

    const handleCloseModal = () => {
        dispatch(closeResumeEditorModal());
    };

    const onPhotoEdit = useCallback(() => {
        setSelectedPhotoId(resumeEditorPhotoId);
        setStep('editPhoto');
    }, [setSelectedPhotoId, setStep, resumeEditorPhotoId]);

    const goToMain = useCallback(() => {
        setSelectedPhotoId(resumeEditorPhotoId);
        setStep('main');
    }, [setSelectedPhotoId, setStep, resumeEditorPhotoId]);

    const onSelectArtifact = useCallback(() => {
        dispatch(
            setResumeEditorFieldValue({
                photo: selectedPhotoId,
            })
        );
        setStep('main');
    }, [dispatch, selectedPhotoId]);

    const onRemoveArtifact = useCallback(() => {
        void dispatch(removeApplicantPhotoArtifact(selectedPhotoId)).then(() => setSelectedPhotoId(WITHOUT_PHOTO_ID));
    }, [dispatch, selectedPhotoId, setSelectedPhotoId]);

    const props = useMemo<Partial<EditorModalProps>>(() => {
        const corrupted = selectedArtivact?.state === 'corrupted';

        if (step === 'main') {
            return {
                title: trls[TrlKeys.mainTitle],
                bottomSheetProps: {
                    size: 'standard',
                },
                children: <MainStep onPhotoEdit={onPhotoEdit} />,
            };
        }
        return {
            title: trls[TrlKeys.photoEditTitle],
            description: trls[TrlKeys.photoEditDescription],
            modalProps: {
                footer: (
                    <ActionBar
                        primaryActions={
                            <>
                                {corrupted && (
                                    <div className={styles.corruptedText}>
                                        <Text style="primary" typography="label-2-regular">
                                            {trls[TrlKeys.corrupted]}
                                        </Text>
                                    </div>
                                )}
                                {WITHOUT_PHOTO_ID !== selectedPhotoId && (
                                    <Button
                                        size="medium"
                                        mode="secondary"
                                        style="negative"
                                        stretched={false}
                                        disabled={filesLoading}
                                        loading={removing}
                                        onClick={onRemoveArtifact}
                                    >
                                        {trls[TrlKeys.remove]}
                                    </Button>
                                )}
                                <Button
                                    size="medium"
                                    mode="primary"
                                    style="accent"
                                    disabled={corrupted || removing || filesLoading}
                                    stretched={false}
                                    onClick={onSelectArtifact}
                                >
                                    {trls[TrlKeys.select]}
                                </Button>
                            </>
                        }
                        secondaryActions={
                            <Button
                                size="medium"
                                mode="tertiary"
                                style="accent"
                                onClick={goToMain}
                                stretched={false}
                                disabled={removing || filesLoading}
                            >
                                {trls[TrlKeys.close]}
                            </Button>
                        }
                    />
                ),
            },
            bottomSheetProps: {
                size: 'standard',
                left: (
                    <Action icon={ChevronLeftOutlinedSize24} onClick={goToMain} disabled={removing || filesLoading} />
                ),
                interceptClickHandlers: false,
                footer: (
                    <BottomSheetFooter>
                        {corrupted ? (
                            <div className={styles.corruptedText}>
                                <Text style="primary" typography="label-2-regular">
                                    {trls[TrlKeys.corrupted]}
                                </Text>
                            </div>
                        ) : (
                            <Button
                                mode="primary"
                                style="accent"
                                size="medium"
                                disabled={removing || filesLoading}
                                stretched
                                onClick={onSelectArtifact}
                            >
                                {trls[TrlKeys.select]}
                            </Button>
                        )}
                        {WITHOUT_PHOTO_ID !== selectedPhotoId && (
                            <Button
                                size="medium"
                                mode="secondary"
                                style="negative"
                                loading={removing}
                                disabled={filesLoading}
                                onClick={onRemoveArtifact}
                            >
                                {trls[TrlKeys.remove]}
                            </Button>
                        )}
                    </BottomSheetFooter>
                ),
            },
            children: <PhotoStep selectedPhotoId={selectedPhotoId} onChange={setSelectedPhotoId} />,
        };
    }, [
        step,
        trls,
        removing,
        goToMain,
        onPhotoEdit,
        selectedPhotoId,
        onSelectArtifact,
        onRemoveArtifact,
        selectedArtivact,
        filesLoading,
    ]);

    return (
        <EditorModal
            fullScreen
            onSave={handleSave}
            visible={visible}
            onClose={handleCloseModal}
            onAfterExit={handlerAfterExit}
            {...props}
        />
    );
});

export default ResumePositionEditor;
