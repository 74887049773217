import { ComponentType } from 'react';

import { VSpacingContainer } from '@hh.ru/magritte-ui';

import { ErrorsMap } from 'src/models/applicant/resume/editor/form/errors';

import PhoneAdd from 'src/pages/ResumeView/redesign/blocks/contacts/Editor/Phone/Add';
import PhoneFieldList from 'src/pages/ResumeView/redesign/blocks/contacts/Editor/Phone/FieldList';

const Phone: ComponentType<{ errorsMap: ErrorsMap }> = ({ errorsMap }) => {
    return (
        <VSpacingContainer default={24}>
            <PhoneFieldList errorsMap={errorsMap} />
            <PhoneAdd />
        </VSpacingContainer>
    );
};

export default Phone;
