import { ComponentType } from 'react';

import { translation } from '@hh.ru/front-static-app';
import { Action, Avatar, Card, Cell, CellText, Checkbox, Link, useBreakpoint, VSpacing } from '@hh.ru/magritte-ui';
import { ArrowSquareOutlinedSize16 } from '@hh.ru/magritte-ui/icon';

import styles from './styles.less';

const TrlKeys = {
    link: 'applicant.resume.visibility.editor.employerList.search.link',
};

export const SearchListItem: ComponentType<{
    logo?: string;
    name: string;
    id: number;
    area?: string;
    checked: boolean;
    onChange: (checked: boolean) => void;
}> = translation(({ name, logo, onChange, area, checked, id, trls }) => {
    const { isMobile } = useBreakpoint();
    const link = `/employer/${id}`;
    const checkbox = <Checkbox checked={checked} onChange={(e) => onChange(e.target.checked)} />;
    const linkActivator = isMobile ? (
        <>
            <VSpacing default={16} />
            <Link
                typography="label-3-regular"
                href={link}
                onClick={(e) => {
                    e.preventDefault();
                    window.open(link, '_blank');
                }}
                iconRight={<ArrowSquareOutlinedSize16 />}
            >
                {trls[TrlKeys.link]}
            </Link>
        </>
    ) : (
        <Action
            mode="primary"
            style="accent"
            icon={ArrowSquareOutlinedSize16}
            onClick={() => window.open(link, '_blank')}
        />
    );
    const avatar = (
        <Avatar
            mode="image"
            image={logo}
            placeholder="city"
            fallbackMode="placeholder"
            size={40}
            shapeCircle
            aria-label={name}
        />
    );
    return (
        <Card borderRadius={12} padding={12} borderWidth="default">
            <Cell left={isMobile ? avatar : checkbox} right={isMobile ? checkbox : linkActivator}>
                <div className={styles.searchListContent}>
                    {!isMobile && avatar}

                    <div className={styles.searchListText}>
                        <CellText type="title">{name}</CellText>
                        {!!area && <CellText type="subtitle">{area}</CellText>}
                    </div>
                </div>
            </Cell>
            {isMobile && linkActivator}
        </Card>
    );
});
