import { SVGProps, FC } from 'react';

const Male: FC<SVGProps<SVGSVGElement>> = (props) => (
    <svg data-qa="svg-male" viewBox="0 0 208 208" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
        <rect width="208" height="208" fill="#EEF1F7" />
        <path
            d="M23.9833 174.59L113.41 157.735L225.224 191.671C225.224 191.671 250.259 479.463 243.525 478.31C236.654 477.167 -7.79446 482.039 -8.17889 476.943C-8.5632 471.849 -34.4447 341.701 -34.4447 341.701L23.9833 174.59Z"
            fill="#DCE3EB"
        />
        <path
            d="M116.781 158.313L114.884 158.871L61.4973 174.391L38.0514 181.145C38.0514 181.145 34.6757 215.325 11.0479 316.945C9.47085 323.572 7.88331 330.061 6.27513 336.275C-22.5943 315.191 -52.2172 295.131 -82.0947 275.368C-75.9633 261.197 -67.1118 240.867 -54.436 212.493C-30.4016 158.477 -2.57847 156.516 55.7929 157.512C72.5673 157.77 91.7238 158.403 114.133 158.096C114.98 158.309 115.807 158.247 116.781 158.313Z"
            fill="#DCE3EB"
        />
        <path
            d="M292.122 302.108C260.506 305.187 228.942 308.953 198.219 316.533L185.178 211.585L140.855 178.374L114.874 158.871L114.144 158.372L113.266 157.746C127.138 158.084 141.774 157.534 156.283 157.131C206.726 155.818 255.78 156.271 266.252 199.654C277.643 246.012 285.665 271.578 292.122 302.108Z"
            fill="#DCE3EB"
        />
        <path
            d="M108.001 172.269C119.623 168.485 130.939 164.308 142.394 160.12L112.224 101.608L68.844 124.959C69.7221 125.585 79.0943 154.372 86.5469 177.904C93.7405 175.977 100.965 174.463 108.001 172.269Z"
            fill="#ffffff"
        />
        <path
            d="M99.3654 112.96C99.0933 111.18 98.8212 109.401 98.2721 107.642L68.9863 125.083C69.5681 125.455 73.8491 138.148 78.7405 153.426C89.4855 141.676 97.5756 127.773 99.3654 112.96Z"
            fill="#DCE3EB"
        />
        <path
            d="M144.524 69.1209C143.075 53.5829 118.601 39.3668 99.9855 38.5556C91.9261 38.1943 89.5106 31.8684 86.0604 37.5291C77.5781 51.7392 49.6836 94.9694 52.2653 110.837C56.0861 133.951 84.569 137.063 102.228 127.008C120.022 116.942 146.6 91.1195 144.524 69.1209Z"
            fill="#ffffff"
        />
        <path
            d="M145.753 72.4915C147.93 68.3116 146.317 63.4483 142.804 60.944C142.656 60.8166 142.656 60.8166 142.507 60.6894C143.028 52.8957 136.915 47.1257 129.979 46.9567C129.007 45.0914 127.379 43.691 125.24 42.883C124.903 42.0778 124.575 41.4101 123.982 40.9009C122.651 39.7551 121.095 39.3187 119.441 39.4435C118.081 36.0843 115.925 33.2005 112.495 31.7977C110.348 30.8519 108.027 31.304 106.311 32.4027C102.006 28.5734 96.789 25.5051 91.8112 25.6038C89.736 25.6218 85.678 26.8973 84.2724 32.1268C82.1968 32.1449 80.3522 33.3917 79.5546 35.6676C78.427 39.0759 80.339 42.3935 83.1109 44.2613C84.719 45.3863 86.9841 46.0462 89.0391 45.7526C95.9026 52.2969 114.028 63.1149 118.148 62.6657C115.532 70.202 113.218 87.2698 122.007 88.1299C124.21 95.3028 116.99 107.894 121.368 112.688C129.639 104.863 137.246 95.5655 141.359 85.8391C141.496 85.8288 141.772 85.808 142.047 85.7872C148.14 83.9429 149.527 76.6377 145.753 72.4915Z"
            fill="#DCE3EB"
        />
        <path
            d="M140.151 104.76C141.594 96.3432 132.67 86.3542 121.049 88.3387C116.251 96.3166 111.305 104.167 106.21 111.89C109.317 116.364 118.141 121.376 125.535 120.264C133.872 118.804 138.613 113.738 140.151 104.76Z"
            fill="#ffffff"
        />
        <path
            d="M156.755 156.883C153.217 165.043 147.805 172.237 141.188 178.136C133.925 184.639 125.011 189.465 114.809 191.896C95.2214 196.558 72.2825 189.842 61.6836 174.164C58.5559 169.414 56.4778 163.894 56 157.561C72.7744 157.818 91.9311 158.451 114.34 158.144L113.462 157.519C127.463 157.709 142.098 157.159 156.755 156.883Z"
            fill="#ffffff"
        />
    </svg>
);

export { Male };
