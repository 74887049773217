import { translation } from '@hh.ru/front-static-app';
import { Input, Placeholder, Suggest, Cell, CellText, Avatar } from '@hh.ru/magritte-ui';

import { CompanyDTO, useCompanyDataProvider } from 'src/models/applicant/resume/api/company';

const TrlKeys = {
    errorTitle: 'resume.editor.suggest.errors.placeholder',
    resetButton: 'resume.editor.suggest.errors.button',
    errorDescription: 'resume.editor.suggest.errors.description',
};

interface Props {
    value: string;
    invalid: boolean;
    onChange: (value: string) => void;
    placeholder?: string;
    errorMessage?: string | null;
}

const CompanyField = translation<Props>(({ trls, value, invalid, onChange, placeholder, errorMessage }) => {
    const handleChange = (value: string) => {
        onChange(value);
    };

    const handleSelect = (item?: CompanyDTO) => {
        if (item) {
            onChange(item.text);
        }
    };

    const dataProvider = useCompanyDataProvider();

    return (
        <Suggest
            onChange={handleChange}
            onSelectValidator={(_value, data) => {
                if (!data) {
                    return true;
                }

                handleSelect(data);

                return true;
            }}
            dataProvider={dataProvider}
            navigationBarProps={{
                title: placeholder,
            }}
            input={{
                component: Input,
                props: {
                    name: 'company',
                    size: 'medium',
                    clearable: true,
                    placeholder,
                    value,
                    invalid,
                    errorMessage,
                },
            }}
            trls={{
                resetButton: trls[TrlKeys.resetButton],
            }}
            errorPlaceholder={
                <Placeholder title={trls[TrlKeys.errorTitle]} description={trls[TrlKeys.errorDescription]} />
            }
            renderItem={({ data: item }) => {
                if (!item) {
                    return null;
                }
                return (
                    <Cell
                        avatar={
                            <Avatar
                                image={item.logo_url}
                                mode="image"
                                size={40}
                                aria-label="company-logo"
                                shapeCircle
                                placeholder="city"
                                fallbackMode="placeholder"
                            />
                        }
                    >
                        <CellText>{item.text}</CellText>
                        <CellText type="subtitle">{item.area}</CellText>
                    </Cell>
                );
            }}
        />
    );
});

export default CompanyField;
