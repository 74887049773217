import { DefaultRootState } from 'react-redux';
import { AxiosError } from 'axios';
import { Dispatch } from 'redux';

import { LoadedFile } from '@hh.ru/magritte-ui-upload';

import { ArtifactsApi } from 'src/models/applicant/resume/api/artifacts';
import { ArtifactsUploadErrorDTO } from 'src/models/applicant/resume/api/artifacts/dto';
import { WITHOUT_PHOTO_ID } from 'src/models/applicant/resume/blocks/position/edit/photo/constants';
import { selectResumeEditorPhoto } from 'src/models/applicant/resume/blocks/position/edit/photo/selectors';
import { selectApplicantResumePhoto } from 'src/models/applicant/resume/blocks/position/selectors';
import { setResumeEditorFieldValue, showNotification } from 'src/models/applicant/resume/editor/store/slice';
import { setApplicantResumeFields } from 'src/models/applicant/resume/slice';

import { selectArtivactsFilesLoading } from 'src/models/applicant/resume/artifacts/selectors';
import {
    setApplicantResumeArtifacts,
    setApplicantResumeArtifactsRemoving,
    setApplicantResumeArtifactsUploadData,
} from 'src/models/applicant/resume/artifacts/slice';

export const removeApplicantPhotoArtifact =
    (id: number) =>
    async (dispatch: Dispatch, getState: () => DefaultRootState): Promise<void> => {
        const currentResumePhoto = selectApplicantResumePhoto(getState());
        const currentResumeEditorPhoto = selectResumeEditorPhoto(getState());

        dispatch(setApplicantResumeArtifactsRemoving(true));

        try {
            await ArtifactsApi.remove(id);

            const artifacts = await ArtifactsApi.getArtifacts('RESUME_PHOTO');
            dispatch(setApplicantResumeArtifacts(artifacts));

            if (currentResumePhoto === id) {
                dispatch(setApplicantResumeFields({ photo: [] }));
            }
            if (currentResumeEditorPhoto === id) {
                dispatch(setResumeEditorFieldValue({ photo: WITHOUT_PHOTO_ID }));
            }
        } catch (_) {
            dispatch(showNotification('error-api'));
        } finally {
            dispatch(setApplicantResumeArtifactsRemoving(false));
        }
    };

export const refreshResumePhotoArtifacts =
    () =>
    async (dispatch: Dispatch): Promise<void> => {
        try {
            const artifacts = await ArtifactsApi.getArtifacts('RESUME_PHOTO');
            dispatch(setApplicantResumeArtifacts(artifacts));

            const hasNew = artifacts.some(({ state }) => state === 'new');
            if (hasNew) {
                setTimeout(() => {
                    void dispatch(refreshResumePhotoArtifacts());
                }, 500);
            }
        } catch (_) {
            dispatch(showNotification('error-api'));
        }
    };

export const uploadResumePhotoArtifact =
    (file: LoadedFile) =>
    async (dispatch: Dispatch, getState: () => DefaultRootState): Promise<void> => {
        const loading = selectArtivactsFilesLoading(getState());
        if (loading) {
            return;
        }

        dispatch(
            setApplicantResumeArtifactsUploadData({
                files: [file],
                loading: true,
            })
        );
        try {
            await ArtifactsApi.upload('RESUME_PHOTO', file.data);

            void dispatch(refreshResumePhotoArtifacts());

            dispatch(
                setApplicantResumeArtifactsUploadData({
                    files: [],
                    loading: false,
                })
            );
        } catch (e) {
            const error = e as AxiosError<ArtifactsUploadErrorDTO>;
            const errorMessageCode = error.response ? error.response.data.message : 'network';

            // Upload отображает картинку при ошибке и перекрывает ошибку.
            // Хак, что бы пользователь увидел ошибку
            const data = new File(['error'], file.data.name);
            dispatch(
                setApplicantResumeArtifactsUploadData({
                    files: [
                        {
                            data,
                            error: errorMessageCode,
                            loading: false,
                        },
                    ],
                    loading: false,
                })
            );
        }
    };
