import { useMemo } from 'react';
import { useDispatch } from 'react-redux';

import { translation, useSelector } from '@hh.ru/front-static-app';
import { Action, Button } from '@hh.ru/magritte-ui';
import { ChevronLeftOutlinedSize24 } from '@hh.ru/magritte-ui/icon';

import { selectResumeEditorOpenedVisibility } from 'src/models/applicant/resume/blocks/visibility/editor/selectors';
import { submitResumeEditorData } from 'src/models/applicant/resume/editor/store/actions/submitEditor';
import { closeResumeEditorModal } from 'src/models/applicant/resume/editor/store/actions/toggleModal';
import {
    batchEmployersListForResumeUpdate,
    clearEmployersFromListForResume,
} from 'src/models/applicant/resume/employersLists/actions';
import {
    selectResumeEmployerListStep,
    selectResumeEmployerListSubStep,
    selectResumeEmployerListSaving,
    getSelectResumeEmployersListSelector,
} from 'src/models/applicant/resume/employersLists/selectors';
import {
    changeResumeEmployersListStep,
    resetResumeEmployersStepper,
} from 'src/models/applicant/resume/employersLists/slice';
import EditorModal, { EditorModalProps } from 'src/pages/ResumeView/redesign/common/EditorModal';

import { AccessTypesStep } from 'src/pages/ResumeView/redesign/visibility/Editor/steps/AccessTypesStep';
import { EmployersListContent } from 'src/pages/ResumeView/redesign/visibility/Editor/steps/EmployersListStep/EmployersListContent';
import { EmployersListSearchInput } from 'src/pages/ResumeView/redesign/visibility/Editor/steps/EmployersListStep/EmployersListSearchInput';

const TrlKeys = {
    title: 'applicant.resume.visibility.editor.title',
    whiteListTitle: 'applicant.resume.visibility.editor.employerList.whitelist.subtitle',
    blackListTitle: 'applicant.resume.visibility.editor.employerList.blacklist.subtitle',
    backButton: 'applicant.resume.visibility.editor.employerList.list.back',
    clearButton: 'applicant.resume.visibility.editor.employerList.list.clear',
    confirmButton: 'applicant.resume.visibility.editor.employerList.list.confirm',
    searchDone: 'applicant.resume.visibility.editor.employerList.search.done',
};

export const VisibilityEditor = translation(({ trls }) => {
    const dispatch = useDispatch();

    const step = useSelector(selectResumeEmployerListStep);
    const subStep = useSelector(selectResumeEmployerListSubStep);

    const saving = useSelector(selectResumeEmployerListSaving);

    const visible = useSelector(selectResumeEditorOpenedVisibility);

    const whitelist = useSelector(getSelectResumeEmployersListSelector('whitelist'));
    const blacklist = useSelector(getSelectResumeEmployersListSelector('blacklist'));

    const modalProps: EditorModalProps = useMemo(() => {
        const toAccessType = () => dispatch(changeResumeEmployersListStep({ step: 'accessType', subStep: null }));
        const handleClose = () => {
            dispatch(closeResumeEditorModal());
            dispatch(resetResumeEmployersStepper());
        };
        const handleSave = () => dispatch(submitResumeEditorData());
        const isSearchSubStep = subStep === 'search';
        if (step === 'whitelist') {
            const stepProps: EditorModalProps = {
                title: trls[TrlKeys.whiteListTitle],
                visible,
                savingButtons: saving,
                fullScreen: true,
                onSave: toAccessType,
                onClose: handleClose,
                saveText: trls[TrlKeys.confirmButton],
                extraSecondaryAction: (
                    <Button mode="tertiary" style="accent" onClick={toAccessType}>
                        {trls[TrlKeys.backButton]}
                    </Button>
                ),
                extraPrimaryAction: whitelist?.items?.length ? (
                    <Button
                        mode="secondary"
                        style="accent"
                        onClick={() => dispatch(clearEmployersFromListForResume('whitelist'))}
                    >
                        {trls[TrlKeys.clearButton]}
                    </Button>
                ) : undefined,
                bottomSheetProps: {
                    options: <EmployersListSearchInput type={'whitelist'} />,
                    left: <Action onClick={toAccessType} icon={ChevronLeftOutlinedSize24} />,
                },
                modalProps: {
                    options: <EmployersListSearchInput type={'whitelist'} />,
                },
                children: <EmployersListContent type={'whitelist'} />,
            };
            if (isSearchSubStep) {
                stepProps.onSave = () => dispatch(batchEmployersListForResumeUpdate('whitelist'));
                stepProps.saveText = trls[TrlKeys.searchDone];
                stepProps.extraPrimaryAction = undefined;
                stepProps.extraSecondaryAction = undefined;
            }
            return stepProps;
        }

        if (step === 'blacklist') {
            const stepProps: EditorModalProps = {
                title: trls[TrlKeys.blackListTitle],
                visible,
                savingButtons: saving,
                fullScreen: true,
                onSave: toAccessType,
                onClose: handleClose,
                saveText: trls[TrlKeys.confirmButton],
                extraSecondaryAction: (
                    <Button mode="tertiary" style="accent" onClick={toAccessType}>
                        {trls[TrlKeys.backButton]}
                    </Button>
                ),
                extraPrimaryAction: blacklist?.items?.length ? (
                    <Button
                        mode="secondary"
                        style="accent"
                        onClick={() => dispatch(clearEmployersFromListForResume('blacklist'))}
                    >
                        {trls[TrlKeys.clearButton]}
                    </Button>
                ) : undefined,
                bottomSheetProps: {
                    options: <EmployersListSearchInput type={'blacklist'} />,
                    left: <Action onClick={toAccessType} icon={ChevronLeftOutlinedSize24} />,
                },
                modalProps: {
                    options: <EmployersListSearchInput type={'blacklist'} />,
                },
                children: <EmployersListContent type={'blacklist'} />,
            };

            if (isSearchSubStep) {
                stepProps.onSave = () => dispatch(batchEmployersListForResumeUpdate('blacklist'));
                stepProps.saveText = trls[TrlKeys.searchDone];
                stepProps.extraPrimaryAction = undefined;
                stepProps.extraSecondaryAction = undefined;
            }
            return stepProps;
        }

        return {
            title: trls[TrlKeys.title],
            visible,
            fullScreen: true,
            onSave: handleSave,
            onClose: handleClose,
            children: (
                <AccessTypesStep
                    onEditList={(type) => dispatch(changeResumeEmployersListStep({ step: type, subStep: 'list' }))}
                />
            ),
        };
    }, [blacklist?.items?.length, dispatch, saving, step, subStep, trls, visible, whitelist?.items?.length]);

    return <EditorModal {...modalProps} />;
});
