import { ApplicantResumeEditorFields } from 'src/models/applicant/resume/editor/types';
import { EditorConfig } from 'src/models/applicant/resume/editor/types/config';

import {
    selectApplicantResumeLang,
    selectResumeEditorLang,
} from 'src/models/applicant/resume/blocks/languages/selectors';

type LangFields = 'lang';

type LangBlockConfig = EditorConfig<Pick<ApplicantResumeEditorFields, LangFields>>;

export const langConfig: LangBlockConfig = {
    init: (store) => {
        return {
            lang: selectApplicantResumeLang(store),
        };
    },
    changed: (store) => {
        const editorLang = selectResumeEditorLang(store);
        const resumeLang = selectApplicantResumeLang(store);

        return editorLang !== resumeLang;
    },
    prepare: ({ lang }) => ({
        lang: lang ? [{ string: String(lang) }] : [],
    }),
    validate: () => ({}),
    afterSubmit: () => {
        window.location.reload();
    },
};
