import { ButtonProps } from '@hh.ru/magritte-ui';

export const MAX_NUMBER_OF_ITEMS_TO_RENDER = 3;

export const RESUME_BLOCK_VSPACING = 12;

export const DEFAULT_RESUME_BUTTON_PROPS: Pick<ButtonProps, 'mode' | 'style' | 'size'> = {
    mode: 'secondary',
    style: 'neutral',
    size: 'small',
};
