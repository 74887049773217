import { useEffect, useState } from 'react';

import { fetchRecommendedSkills } from 'src/models/applicant/resume/api/skills';

export const useRecomendedSkills = (skillNames: string[], resumeHash: string) => {
    const [recomendedSkills, setSkills] = useState<string[]>([]);

    useEffect(() => {
        const abortController = new AbortController();

        const chosenSkills = skillNames.map((name) => ({ name }));

        fetchRecommendedSkills(resumeHash, chosenSkills, abortController.signal)
            .then((skills) => {
                setSkills((oldSkills) => {
                    const newSuggestedSkills = skills
                        .filter(({ name }) => !oldSkills.includes(name))
                        .map(({ name }) => name);

                    return [...oldSkills, ...newSuggestedSkills];
                });
            })
            .catch(() => {});

        return () => {
            abortController.abort();
        };
    }, [skillNames, resumeHash]);

    return recomendedSkills;
};
