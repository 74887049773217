import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import DenseText from 'src/components/DenseText';
import translation from 'src/components/translation';
import { useSelector } from 'src/hooks/useSelector';
import { Platform } from 'src/models/locale';
import { Gender } from 'src/models/resume/resumeCommon.types';
import { NON_BREAKING_SPACE } from 'src/utils/constants/symbols';

const TrlKeys = {
    metro: 'rb.edit.metro.m',
    district: 'rb.edit.district.m',
    'relocation.no_relocation.male': 'rb.no_relocation.male',
    'relocation.relocation_desirable.male': 'rb.relocation_desirable.male',
    'relocation.relocation_possible.male': 'rb.relocation_possible.male',
    'relocation.no_relocation.female': 'rb.no_relocation.female',
    'relocation.relocation_desirable.female': 'rb.relocation_desirable.female',
    'relocation.relocation_possible.female': 'rb.relocation_possible.female',
    'relocation.no_relocation.unknown': 'rb.no_relocation.unknown',
    'relocation.relocation_desirable.unknown': 'rb.relocation_desirable.unknown',
    'relocation.relocation_possible.unknown': 'rb.relocation_possible.unknown',

    'businessTrip.never.male': 'rb.businessTripReadiness.never.male',
    'businessTrip.ready.male': 'rb.businessTripReadiness.ready.male',
    'businessTrip.sometimes.male': 'rb.businessTripReadiness.sometimes.male',
    'businessTrip.never.female': 'rb.businessTripReadiness.never.female',
    'businessTrip.ready.female': 'rb.businessTripReadiness.ready.female',
    'businessTrip.sometimes.female': 'rb.businessTripReadiness.sometimes.female',
    'businessTrip.never.unknown': 'rb.businessTripReadiness.never.unknown',
    'businessTrip.ready.unknown': 'rb.businessTripReadiness.ready.unknown',
    'businessTrip.sometimes.unknown': 'rb.businessTripReadiness.sometimes.unknown',
};

const ResumePersonalLocation: TranslatedComponent = ({ trls }) => {
    const gender = useSelector((state) => state.resume?.gender.value || Gender.Male);
    const area = useSelector((state) => state.resume?.area.value);
    const metro = useSelector((state) => state.resume?.metro.value);
    const relocation = useSelector((state) => state.resume?.relocation.value);
    const relocationArea = useSelector((state) => state.resume?.relocationArea.value);
    const residenceDistrict = useSelector((state) => state.resume?.residenceDistrict.value);
    const businessTripReadiness = useSelector((state) => state.resume?.businessTripReadiness.value);
    const isZp = useSelector((state) => state.locale.platform === Platform.Zarplata);

    const renderArea = () => {
        if (!area?.id) {
            return null;
        }
        return (
            <>
                <span data-qa="resume-personal-address">{area.title}</span>
                {', '}
            </>
        );
    };

    const renderDistrict = () => {
        if (!residenceDistrict) {
            return null;
        }
        return (
            <>
                <span data-qa="resume-personal-district">
                    {`${trls[TrlKeys.district]}${NON_BREAKING_SPACE}${residenceDistrict.title}, `}
                </span>
            </>
        );
    };

    const renderMetro = () => {
        if (!metro) {
            return null;
        }
        return (
            <>
                <span data-qa="resume-personal-metro" style={{ color: metro.color }}>
                    {trls[TrlKeys.metro]}
                    &nbsp;
                    {metro.title}
                </span>
                {', '}
            </>
        );
    };

    const renderRelocation = () => {
        return (
            <>
                {trls[TrlKeys[`relocation.${relocation}.${gender}` as keyof typeof TrlKeys]]}
                {relocationArea &&
                    relocationArea.length > 0 &&
                    !isZp &&
                    ` (${relocationArea.map((area) => area.title).join(', ')})`}
            </>
        );
    };

    const renderBusinessTrip = () => {
        return (
            <>
                {', '}
                {trls[TrlKeys[`businessTrip.${businessTripReadiness}.${gender}` as keyof typeof TrlKeys]]}
            </>
        );
    };

    return (
        <DenseText typography="paragraph-3-regular">
            {renderArea()}
            {renderDistrict()}
            {renderMetro()}
            {renderRelocation()}
            {renderBusinessTrip()}
        </DenseText>
    );
};

export default translation(ResumePersonalLocation);
