import { useEffect, useState } from 'react';

import { Button, VSpacingContainer } from '@hh.ru/magritte-ui';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';
import urlParser from 'bloko/common/urlParser';

import paths from 'src/app/routePaths';
import ResumeBlockButtonWrapper from 'src/components/ResumeView/ResumeBlockButtonWrapper';
import translation from 'src/components/translation';
import { useSelector } from 'src/hooks/useSelector';
import ResumeBlock from 'src/pages/ResumeView/employerRedesign/components/ResumeBlock';
import {
    DEFAULT_RESUME_BUTTON_PROPS,
    RESUME_BLOCK_VSPACING,
    MAX_NUMBER_OF_ITEMS_TO_RENDER,
} from 'src/pages/ResumeView/employerRedesign/const';

import ResumeRelatedItem from 'src/pages/ResumeView/employerRedesign/components/ResumeRelated/ResumeRelatedItem';
import fetchRelatedResumes, {
    RelatedResumes,
} from 'src/pages/ResumeView/employerRedesign/components/ResumeRelated/fetchRelatedResumes';

const TrlKeys = {
    title: 'resume.related',
    showMoreButton: 'resume.showMoreButton',
};

const ResumeRelated: TranslatedComponent = ({ trls }) => {
    const resumeHash = useSelector((state) => state.resume?.hash);
    const [relatedResumes, setRelatedResumes] = useState<RelatedResumes | null>(null);

    useEffect(() => {
        if (resumeHash) {
            fetchRelatedResumes({ resumeHash })
                .then((result) => {
                    setRelatedResumes(result);
                })
                .catch(console.error);
        }
    }, [resumeHash]);

    if (!relatedResumes?.resumes?.length) {
        return null;
    }

    const isShowMoreButtonVisible = relatedResumes.totalUngroupedResults > MAX_NUMBER_OF_ITEMS_TO_RENDER;
    const url = urlParser(paths.resumeSearch);

    if (isShowMoreButtonVisible) {
        url.params = {
            resume: [relatedResumes?.searchCriteria?.resume],
            search_period: [relatedResumes?.searchCriteria?.search_period],
            job_search_status: relatedResumes?.searchCriteria?.job_search_status,
        };
    }

    return (
        <ResumeBlock
            title={trls[TrlKeys.title]}
            buttons={
                isShowMoreButtonVisible && (
                    <ResumeBlockButtonWrapper>
                        <Button
                            {...DEFAULT_RESUME_BUTTON_PROPS}
                            Element="a"
                            href={url.href}
                            postfix={relatedResumes.totalUngroupedResults}
                            data-qa="similar-resumes-showmore-link"
                        >
                            {trls[TrlKeys.showMoreButton]}
                        </Button>
                    </ResumeBlockButtonWrapper>
                )
            }
        >
            <VSpacingContainer default={RESUME_BLOCK_VSPACING}>
                {relatedResumes.resumes.map((resume) => (
                    <ResumeRelatedItem resume={resume} key={resume._attributes.hash} />
                ))}
            </VSpacingContainer>
        </ResumeBlock>
    );
};

export default translation(ResumeRelated);
