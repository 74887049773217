import { useDispatch } from 'react-redux';

import { useSelector } from '@hh.ru/front-static-app';
import { Snackbar } from '@hh.ru/magritte-ui';
import { CheckCircleFilledSize24, MinusCircleFilledSize24 } from '@hh.ru/magritte-ui/icon';

import translation from 'src/components/translation';
import { selectNotifications } from 'src/models/applicant/resume/editor/store/notifications/selectors';
import { hideNotification } from 'src/models/applicant/resume/editor/store/slice';

const TrlKeys = {
    'error-api': 'applicant.resume.editor.notification.error.api',
    'success-save': 'applicant.resume.editor.notification.success.save',
    'success-touch': 'applicant.resume.editor.notification.success.touch',
    'copy-link': 'applicant.resume.editor.notification.copy-link',
};

const ADDON = {
    'error-api': <MinusCircleFilledSize24 initialColor="negative" />,
    'success-save': <CheckCircleFilledSize24 initialColor="positive" />,
    'success-touch': <CheckCircleFilledSize24 initialColor="positive" />,
    'copy-link': <CheckCircleFilledSize24 initialColor="positive" />,
};

const Notifications = translation(({ trls }) => {
    const dispatch = useDispatch();
    const { show, type } = useSelector(selectNotifications);

    const handleClosed = () => dispatch(hideNotification());

    if (!show) {
        return null;
    }
    return (
        <Snackbar showClose align="bottom" addon={ADDON[type]} onClose={handleClosed} autohideTime={3000}>
            {trls[TrlKeys[type]]}
        </Snackbar>
    );
});

export default Notifications;
