import { ComponentType, useState } from 'react';

import { translation } from '@hh.ru/front-static-app';
import {
    useBreakpoint,
    FieldsGroupHelper,
    HSpacingContainer,
    NumberInput,
    Card,
    Cell,
    Checkbox,
    VSpacingContainer,
    CellText,
} from '@hh.ru/magritte-ui';

import { useResumeEditorListField } from 'src/models/applicant/resume/editor/form/change';
import { ExperienceFieldProps } from 'src/pages/ResumeView/redesign/blocks/experience/Editor/types';
import EditorField from 'src/pages/ResumeView/redesign/common/Form/EditorField';
import MonthInput from 'src/pages/ResumeView/redesign/common/MonthInput';

import styles from './styles.less';

const TrlKeys = {
    year: 'resume.editor.experience.year.placeholder',
    labels: {
        startDate: 'resume.editor.experience.startDate.label',
        endDate: 'resume.editor.experience.endDate.label',
        present: 'resume.editor.experience.present.label',
    },
};

const PeriodField: ComponentType<ExperienceFieldProps> = translation(({ trls, index, errorsMap, fieldName }) => {
    const startDate = useResumeEditorListField(fieldName, index, 'startDate', errorsMap);
    const endDate = useResumeEditorListField(fieldName, index, 'endDate', errorsMap);

    const [toThePresent, setToThePresent] = useState(!endDate.value);

    const [startYear = '', startMonth = ''] = startDate.value?.split('-') ?? [];
    const [endYear = '', endMonth = ''] = endDate.value?.split('-') ?? [];

    const handleChangedToThePresent = () => {
        setToThePresent(!toThePresent);
        if (!toThePresent) {
            endDate.onChange('');
        }
    };

    const { isMobile } = useBreakpoint();

    return (
        <div className={styles.container}>
            <div className={styles.item}>
                <EditorField htmlFor="startDate" label={trls[TrlKeys.labels.startDate]}>
                    <FieldsGroupHelper
                        invalid={startDate.invalid}
                        errorMessage={isMobile ? undefined : startDate.errorMessage}
                    >
                        <HSpacingContainer default={12}>
                            <div className={styles.input}>
                                <MonthInput
                                    size="medium"
                                    value={startMonth}
                                    onChange={(value: string) => startDate.onChange(`${startYear}-${value}-01`)}
                                    invalid={startDate.invalid}
                                    widthEqualToActivator={false}
                                />
                            </div>
                            <div className={styles.input}>
                                <NumberInput
                                    size="medium"
                                    value={startYear}
                                    groupSize={0}
                                    maxLength={4}
                                    allowNegative={false}
                                    invalid={startDate.invalid}
                                    onChange={(value: string) => startDate.onChange(`${value}-${startMonth}-01`)}
                                    placeholder={trls[TrlKeys.year]}
                                    errorMessage={isMobile ? startDate.errorMessage : undefined}
                                />
                            </div>
                        </HSpacingContainer>
                    </FieldsGroupHelper>
                </EditorField>
            </div>
            <div className={styles.item}>
                <EditorField htmlFor="endDate" label={trls[TrlKeys.labels.endDate]}>
                    <VSpacingContainer default={24}>
                        {!toThePresent && (
                            <FieldsGroupHelper
                                invalid={endDate.invalid}
                                errorMessage={isMobile ? undefined : endDate.errorMessage}
                            >
                                <HSpacingContainer default={12}>
                                    <div className={styles.input}>
                                        <MonthInput
                                            size="medium"
                                            value={endMonth}
                                            onChange={(value: string) => endDate.onChange(`${endYear}-${value}-01`)}
                                            invalid={endDate.invalid}
                                            widthEqualToActivator={false}
                                        />
                                    </div>
                                    <div className={styles.input}>
                                        <NumberInput
                                            size="medium"
                                            value={endYear}
                                            maxLength={4}
                                            groupSize={0}
                                            allowNegative={false}
                                            invalid={endDate.invalid}
                                            onChange={(value: string) => endDate.onChange(`${value}-${endMonth}-01`)}
                                            placeholder={trls[TrlKeys.year]}
                                            errorMessage={isMobile ? endDate.errorMessage : undefined}
                                        />
                                    </div>
                                </HSpacingContainer>
                            </FieldsGroupHelper>
                        )}
                        <Card padding={16} borderRadius={12} borderWidth="default" onClick={handleChangedToThePresent}>
                            <Cell
                                left={
                                    !isMobile ? (
                                        <Checkbox checked={toThePresent} onChange={handleChangedToThePresent} />
                                    ) : undefined
                                }
                                right={
                                    isMobile ? (
                                        <Checkbox checked={toThePresent} onChange={handleChangedToThePresent} />
                                    ) : undefined
                                }
                            >
                                <CellText>{trls[TrlKeys.labels.present]}</CellText>
                            </Cell>
                        </Card>
                    </VSpacingContainer>
                </EditorField>
            </div>
        </div>
    );
});

export default PeriodField;
