import { DefaultRootState } from 'react-redux';

import { selectResumeEditorOpenedType } from 'src/models/applicant/resume/editor/store/selectors';
import { LangsList } from 'src/models/resume/resumeCommon.types';

export const selectResumeEditorOpenedLang = selectResumeEditorOpenedType('lang');

export const selectApplicantResumeLang = ({ applicantResume }: DefaultRootState): LangsList =>
    applicantResume._attributes.lang;

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore не сходятся типые, пока не хочу менять типы в сторе, так как сломается
export const selectResumeLanguages = ({ resumeLanguages }: DefaultRootState): LangsList[] => resumeLanguages;
export const selectResumeEditorLang = ({ resumeEditor }: DefaultRootState): LangsList =>
    resumeEditor.fields.lang || LangsList.RU;
