import { Fragment, useState } from 'react';
import { connect } from 'react-redux';

import { VendorAnalytics } from '@hh.ru/analytics-js';
import resumeNoAnswerButtonClick from '@hh.ru/analytics-js-events/build/xhh/employer/resume/resume_no_answer_button_click';
import resumeNotArrivedButtonClick from '@hh.ru/analytics-js-events/build/xhh/employer/resume/resume_not_arrived_button_click';
import resumeTopicActionButtonClick from '@hh.ru/analytics-js-events/build/xhh/employer/resume/resume_topic_action_button_click';
import { Button, Card, HSpacing, Text, useBreakpoint, VSpacing } from '@hh.ru/magritte-ui';
import { SPALink } from '@hh.ru/redux-spa-middleware';
import BlokoButton, { ButtonKind } from 'bloko/blocks/button';
import MenuItem from 'bloko/blocks/drop/Menu/Item';
import urlParser from 'bloko/common/urlParser';

import { HHTM_SOURCE_VACANCY_RESPONSES } from 'Modules/constants/analytics';
import AdaptiveButtons from 'src/components/AdaptiveButtons';
import BlokoChangeTopicButton from 'src/components/ChangeTopicButton';
import ChangeTopicButton from 'src/components/ChangeTopicButton/Magritte';
import ChangeTopicMenuItem from 'src/components/ChangeTopicMenuItem';
import NoCallAnswer from 'src/components/Employer/NoCallAnswer';
import NonAppearanceTrigger from 'src/components/Employer/NonAppearance';
import ShareResume from 'src/components/Employer/ShareResume';
import PfpBillingButton from 'src/components/PfpBillingButton';
import ResumeComplaint from 'src/components/ResumeComplaint';
import { useResumeComplaint } from 'src/components/ResumeComplaint/useResumeComplaint';
import translation from 'src/components/translation';
import { useFeatureEnabled } from 'src/hooks/useFeatureEnabled';
import { useMagritte } from 'src/hooks/useMagritte';
import { useSelector } from 'src/hooks/useSelector';
import { TransitionType } from 'src/models/employerNegotiations/negotiationLinks';
import { Platform } from 'src/models/locale';
import { Permission } from 'src/models/resume/resumeCommon.types';
import { UserType } from 'src/models/userType';

import ComplaintButton from 'src/pages/ResumeView/components/ResumeHeader/ComplaintButton';
import DivisionSelector from 'src/pages/ResumeView/components/ResumeHeader/Contacts/DivisionSelector';
import OpenContacts from 'src/pages/ResumeView/components/ResumeHeader/Contacts/OpenContacts';
import ResumeVideocallInviteButton from 'src/pages/ResumeView/components/ResumeVideocallIniveButton';

import styles from './resumeTransitions.less';

export const sendAnalytics = () => {
    VendorAnalytics.eventToStorage('contact_gift', 'invite', 'recommended');
};

const LINKS_PROPS = {
    [TransitionType.ChangeTopic]: {
        [`data-qa`]: 'resume__change-topic-button',
        kind: ButtonKind.Primary,
    },
    [TransitionType.Invite]: {
        [`data-qa`]: 'resume__invite-button',
        kind: ButtonKind.Success,
    },
    [TransitionType.Discard]: {
        [`data-qa`]: 'resume__discard-button',
    },
    [TransitionType.Consider]: {
        [`data-qa`]: 'resume__postpone-button',
    },
    [TransitionType.InviteAnother]: {
        [`data-qa`]: 'resume__another-vacancy-button',
    },
};

const LINKS_ORDER = [
    TransitionType.ChangeTopic,
    TransitionType.Invite,
    TransitionType.Discard,
    TransitionType.Consider,
    TransitionType.InviteAnother,
];

export const renderActionButtons = (
    { negotiationLinks, negotiationsCount, vacancyId, resumeId },
    renderItem,
    shareButton,
    noCallAnswerButton,
    nonAppearanceButton,
    videoCall,
    complaintButton
) => {
    let negotiationButtons = LINKS_ORDER.reduce((buttons, transitionType) => {
        const linkProps = LINKS_PROPS[transitionType];
        const isDiscard = transitionType === TransitionType.Discard;
        const location = isDiscard
            ? negotiationLinks[transitionType]?.stateLinks
            : negotiationLinks[transitionType]?.defaultLink;
        const isInviteAnother = transitionType === TransitionType.InviteAnother;
        const isInvite = transitionType === TransitionType.Invite;

        const getAnalyticsHandler = () => {
            if (isInviteAnother || (negotiationsCount === 0 && isInvite)) {
                return sendAnalytics;
            }

            const commonParams = { vacancyId, resumeId, topicAction: transitionType };

            if (isDiscard) {
                return (toState) => resumeTopicActionButtonClick({ ...commonParams, toState });
            }

            return () => resumeTopicActionButtonClick(commonParams);
        };

        if (location) {
            buttons.push(
                renderItem({
                    location,
                    transitionType,
                    isDiscard,
                    onClick: getAnalyticsHandler(),
                    props: { ...linkProps },
                })
            );
        }

        return buttons;
    }, []);

    if (shareButton) {
        negotiationButtons = negotiationButtons.concat(shareButton);
    }

    if (videoCall && negotiationButtons.length) {
        negotiationButtons.splice(1, 0, videoCall);
    }
    if (noCallAnswerButton) {
        negotiationButtons = negotiationButtons.concat(noCallAnswerButton);
    }
    if (nonAppearanceButton) {
        negotiationButtons = negotiationButtons.concat(nonAppearanceButton);
    }
    if (complaintButton) {
        negotiationButtons = negotiationButtons.concat(complaintButton);
    }
    return negotiationButtons;
};

const ResumeTransitions = ({
    userType,
    vacancyId,
    topicId,
    resumeId,
    resumeHash,
    firstName,
    fromResponsesPage,
    lastName,
    negotiationLinks,
    negotiationsCount,
    permission,
    nonAppearanceEnabled,
    nonCallAnswerEnabled,
    isHH,
    isCrossposted,
    query,
    trls,
    hasPfpTopics,
    isHiringManagerExperiment,
    canOpenContacts,
}) => {
    const { isXS, isMobile } = useBreakpoint();
    const { onOpen, ...complaintProps } = useResumeComplaint({ resumeHash });
    const canBuyContacts = useSelector((state) => state.resume.resumeContactsOpening?.canOpenContactsThroughCIV);
    const isOpenContactsVisible = canBuyContacts && canOpenContacts;
    const additionalCheck = useSelector(({ additionalCheck }) => additionalCheck);
    const isVideoCallsDisabled = useFeatureEnabled('video_calls_disabled');

    const isContactsOpened = permission !== Permission.ViewWithoutContacts;
    const showPfpButtons = hasPfpTopics && !isContactsOpened;
    const isMagritte = useMagritte();

    const complaintModal = <ResumeComplaint {...complaintProps} />;

    const currentDivisionId = useSelector((state) => state.employerCurrentDivision?.divisionId);
    const [divisionId, setDivisionId] = useState(currentDivisionId);

    if (
        userType !== UserType.Employer ||
        !negotiationLinks ||
        (isContactsOpened && Object.keys(negotiationLinks).length === 0) ||
        (isCrossposted && !(fromResponsesPage && query.t !== undefined))
    ) {
        return isHiringManagerExperiment ? (
            <>
                <ShareResume type="button" />
                <VSpacing default={28} />
            </>
        ) : null;
    }

    if (!isContactsOpened) {
        const billingButtonProps = {
            topicId,
            resumeId,
            resumeHash,
            onContactsBought: () => {
                window.location.reload();
            },
        };

        const handleSendAnalytics = (toState) => {
            resumeTopicActionButtonClick({
                vacancyId,
                resumeId,
                topicAction: TransitionType.Discard,
                toState,
            });
        };

        if (!canOpenContacts || (showPfpButtons && additionalCheck)) {
            return null;
        }

        const openContacts = (
            <AdaptiveButtons
                redesign={isMagritte}
                size="small"
                menuButtonStyle="accent"
                buttons={[
                    isOpenContactsVisible ? (
                        <Fragment key="openContacts">
                            <OpenContacts type="button" currentDivisionId={divisionId} setDivisionId={setDivisionId} />
                        </Fragment>
                    ) : null,
                    isMagritte && isOpenContactsVisible && currentDivisionId ? (
                        <div key="division" className={styles.divisionSelectorWrapper}>
                            <HSpacing default={12} />
                            <DivisionSelector currentDivisionId={divisionId} setDivisionId={setDivisionId} isMagritte />
                        </div>
                    ) : null,
                    isHiringManagerExperiment ? (
                        <Fragment key="share">
                            <HSpacing default={12} />
                            <ShareResume type="button" />
                        </Fragment>
                    ) : null,
                    !complaintProps.isHidden ? (
                        <Fragment key="complaint">
                            <HSpacing default={12} />
                            <ComplaintButton onOpen={onOpen} />
                        </Fragment>
                    ) : null,
                ]}
                menuItems={() => [
                    isOpenContactsVisible ? (
                        <OpenContacts
                            key="openContacts"
                            type="menuItem"
                            currentDivisionId={divisionId}
                            setDivisionId={setDivisionId}
                        />
                    ) : null,
                    isMagritte && isOpenContactsVisible && currentDivisionId ? (
                        <DivisionSelector
                            key="division"
                            currentDivisionId={divisionId}
                            setDivisionId={setDivisionId}
                            isMagritte
                            isInMenu
                        />
                    ) : null,
                    isHiringManagerExperiment ? <ShareResume key="share" type="menuItem" /> : null,
                    !complaintProps.isHidden ? (
                        <ComplaintButton key="complaint" onOpen={onOpen} type="menuItem" />
                    ) : null,
                ]}
            />
        );

        return (
            <>
                {showPfpButtons ? (
                    <div className="resume-actions">
                        <AdaptiveButtons
                            size="small"
                            redesign={isMagritte}
                            buttons={[
                                <PfpBillingButton
                                    type="resumeViewButton"
                                    key="pfpBillingButton"
                                    {...billingButtonProps}
                                />,
                                <HSpacing key="hspacing" default={12} />,
                                negotiationLinks[TransitionType.Discard] && (
                                    <ChangeTopicButton
                                        links={negotiationLinks[TransitionType.Discard].stateLinks}
                                        sendAnalytics={handleSendAnalytics}
                                        mode="secondary"
                                        size="small"
                                        style="accent"
                                    />
                                ),
                                isHiringManagerExperiment ? (
                                    <Fragment key="share">
                                        <HSpacing default={12} />
                                        <ShareResume type="button" />
                                    </Fragment>
                                ) : null,
                                !complaintProps.isHidden ? (
                                    <Fragment key="complaint">
                                        <HSpacing default={12} />
                                        <ComplaintButton onOpen={onOpen} />
                                    </Fragment>
                                ) : null,
                            ]}
                            menuItems={() => [
                                <PfpBillingButton key="pfpBillingButton" type="link" {...billingButtonProps} />,
                                <HSpacing key="hspacing" default={12} />,
                                negotiationLinks[TransitionType.Discard]?.stateLinks.map(({ state, name, url }) => (
                                    <ChangeTopicMenuItem
                                        key={name}
                                        state={state}
                                        name={name}
                                        url={url}
                                        sendAnalytics={handleSendAnalytics}
                                        isMagritte={isMagritte}
                                    />
                                )),
                                isHiringManagerExperiment ? (
                                    <Fragment key="share">
                                        <ShareResume type="menuItem" />
                                    </Fragment>
                                ) : null,
                                !complaintProps.isHidden ? (
                                    <ComplaintButton key="complaint" onOpen={onOpen} type="menuItem" />
                                ) : null,
                            ]}
                        />
                    </div>
                ) : (
                    openContacts
                )}
                {(showPfpButtons || canBuyContacts || isHiringManagerExperiment || !complaintProps.isHidden) && (
                    <VSpacing default={24} />
                )}
                {complaintModal}
            </>
        );
    }

    if (additionalCheck) {
        return null;
    }

    return isCrossposted && !(fromResponsesPage && query.t !== undefined) ? null : (
        <div className="resume-actions">
            <AdaptiveButtons
                redesign={isMagritte}
                size="small"
                menuButtonStyle="accent"
                buttons={renderActionButtons(
                    { negotiationLinks, negotiationsCount, vacancyId, resumeId },
                    ({ location, transitionType, isDiscard, onClick, props }) => (
                        <Fragment key={transitionType}>
                            {isDiscard &&
                                (isMagritte ? (
                                    <ChangeTopicButton
                                        dataQa={props[`data-qa`]}
                                        links={location}
                                        mode="secondary"
                                        size="small"
                                        style="accent"
                                        sendAnalytics={onClick}
                                    />
                                ) : (
                                    <BlokoChangeTopicButton
                                        links={location}
                                        showIcon={!isXS}
                                        sendAnalytics={onClick}
                                        dataQa={props[`data-qa`]}
                                    />
                                ))}
                            {!isDiscard &&
                                (isMagritte ? (
                                    <Button
                                        to={location}
                                        Element={SPALink}
                                        onClick={onClick}
                                        size="small"
                                        mode={
                                            [TransitionType.Invite, TransitionType.ChangeTopic].includes(transitionType)
                                                ? 'primary'
                                                : 'secondary'
                                        }
                                        style="accent"
                                        {...props}
                                    >
                                        {trls[ResumeTransitions.trls[transitionType]]}
                                    </Button>
                                ) : (
                                    <BlokoButton to={location} Element={SPALink} onClick={onClick} {...props}>
                                        {trls[ResumeTransitions.trls[transitionType]]}
                                    </BlokoButton>
                                ))}
                            <HSpacing default={12} />
                        </Fragment>
                    ),
                    isHiringManagerExperiment ? (
                        <Fragment key="share">
                            <ShareResume type="button" />
                            <HSpacing default={12} />
                        </Fragment>
                    ) : null,
                    nonCallAnswerEnabled ? (
                        <Fragment key="nocallanswer">
                            <NoCallAnswer
                                viewType="button"
                                topicId={topicId}
                                onClick={() => resumeNoAnswerButtonClick({ resumeId, vacancyId })}
                            />
                            <HSpacing default={12} />
                        </Fragment>
                    ) : null,
                    nonAppearanceEnabled ? (
                        <NonAppearanceTrigger
                            key="nonappearance"
                            vacancyId={vacancyId}
                            resumeHash={resumeHash}
                            data-qa="non-appearance__trigger"
                            onSubmit={() => resumeNotArrivedButtonClick({ resumeId, topicId })}
                        />
                    ) : null,
                    isHH && !isVideoCallsDisabled ? (
                        <ResumeVideocallInviteButton
                            key="videoCallInvite"
                            applicant={{
                                resumeHash,
                                name: firstName,
                                lastName,
                            }}
                        />
                    ) : null,
                    !complaintProps.isHidden ? <ComplaintButton key="complaint" onOpen={onOpen} /> : null
                )}
                menuItems={({ onClose }) =>
                    renderActionButtons(
                        {
                            negotiationLinks,
                            negotiationsCount,
                            vacancyId,
                            resumeId,
                        },
                        ({ location, transitionType, isDiscard, onClick, props }) => {
                            if (isDiscard) {
                                return location.map(({ name, url }) => (
                                    <ChangeTopicMenuItem
                                        isMagritte={isMagritte}
                                        key={name}
                                        name={name}
                                        url={url}
                                        sendAnalytics={onClick}
                                    />
                                ));
                            }

                            if (isMagritte) {
                                return (
                                    <Card
                                        key={transitionType}
                                        Element={SPALink}
                                        to={location}
                                        onClick={onClick}
                                        borderRadius={16}
                                        padding={12}
                                        paddingLeft={16}
                                        hoverStyle="secondary"
                                        stretched
                                    >
                                        <Text style="primary" typography="label-2-regular">
                                            {trls[ResumeTransitions.trls[transitionType]]}
                                        </Text>
                                    </Card>
                                );
                            }

                            return (
                                <MenuItem
                                    to={location}
                                    Element={SPALink}
                                    key={transitionType}
                                    onClick={onClick}
                                    {...props}
                                >
                                    {trls[ResumeTransitions.trls[transitionType]]}
                                </MenuItem>
                            );
                        },
                        isHiringManagerExperiment ? (
                            <Fragment key="share">
                                <ShareResume type="menuItem" />
                            </Fragment>
                        ) : null,
                        nonCallAnswerEnabled ? (
                            <NoCallAnswer
                                onClick={() => {
                                    onClose?.();
                                    resumeNoAnswerButtonClick({ resumeId, vacancyId });
                                }}
                                key="nocallanswer"
                                viewType="menu"
                                topicId={topicId}
                            />
                        ) : null,
                        nonAppearanceEnabled ? (
                            <NonAppearanceTrigger
                                key="nonappearance"
                                vacancyId={vacancyId}
                                resumeHash={resumeHash}
                                viewType="menu"
                                data-qa="non-appearance__trigger"
                                onSubmit={() => resumeNotArrivedButtonClick({ resumeId, topicId })}
                                closeMenu={isMagritte ? onClose : undefined}
                            />
                        ) : null,
                        isHH && !isVideoCallsDisabled ? (
                            <ResumeVideocallInviteButton
                                key="videoCallInvite"
                                applicant={{
                                    resumeHash,
                                    name: firstName,
                                    lastName,
                                }}
                                isMenuItem
                            />
                        ) : null,
                        !complaintProps.isHidden ? (
                            <ComplaintButton key="complaint" type="menuItem" onOpen={onOpen} />
                        ) : null
                    )
                }
            />
            {!isMobile && <VSpacing default={24} />}
            {complaintModal}
        </div>
    );
};

ResumeTransitions.trls = {
    [TransitionType.InviteAnother]: 'resume.button.inviteToAnotherVacancy',
    [TransitionType.Invite]: 'vacancyResponse.invite',
    [TransitionType.ChangeTopic]: 'resume.button.changeTopic',
    [TransitionType.Discard]: 'vacancyResponse.discard',
    [TransitionType.Consider]: 'resume.button.consider',
};

ResumeTransitions.features = {
    videoCallsDisabled: 'video_calls_disabled',
};

export default connect((state) => {
    const isHiringManagerExperiment = state.isHiringManagerExperiment;
    const { params } = urlParser(state.request.url);
    const { hash, firstName, lastName, hasPfpTopics, canOpenContacts } = state.resume;
    const topicId = params.t?.[0];
    const vacancyId = params.vacancyId?.[0];
    return {
        vacancyId,
        userType: state.userType,
        permission: state.resume?.permission,
        resumeId: state.resume?.id,
        resumeHash: hash,
        negotiationLinks: hash && state.employerNegotiationLinks?.[hash],
        negotiationsCount: state.employerNegotiations.total,
        nonAppearanceEnabled: !!vacancyId,
        topicId,
        isHH: state.locale.platform === Platform.HeadHunter,
        firstName: firstName?.value,
        lastName: lastName?.value,
        fromResponsesPage: state.analyticsParams.hhtmFrom === HHTM_SOURCE_VACANCY_RESPONSES,
        query: state.router.location.query,
        isCrossposted:
            state.resume?.sitePlatform === Platform.Zarplata && state.locale.platform === Platform.HeadHunter,
        nonCallAnswerEnabled: !!topicId,
        hasPfpTopics,
        isHiringManagerExperiment,
        canOpenContacts,
    };
})(translation(ResumeTransitions));
