import { ComponentType, ChangeEvent } from 'react';

import { translation } from '@hh.ru/front-static-app';
import { Card, Radio, Text, Cell, useBreakpoint } from '@hh.ru/magritte-ui';

import { PreferredContact as PreferredContactType } from 'src/models/applicant/resume/blocks/contacts/types';
import { useResumeEditorField } from 'src/models/applicant/resume/editor/form/change';

const FIELD_NAME = 'preferredContact';

const TrlKeys = {
    text: 'resume.editor.contacts.preferredContact',
};

interface Props {
    value: string;
    disabled?: boolean;
}

const PreferredContact: ComponentType<Props> = translation(({ trls, disabled, value }) => {
    const { value: preferredContact, onChange } = useResumeEditorField(FIELD_NAME);

    const handleRadioChanged = (event: ChangeEvent<HTMLInputElement>) => {
        onChange(event.target.value as PreferredContactType);
    };

    const { isMobile } = useBreakpoint();

    return (
        <Card borderRadius={12} padding={12} stretched borderWidth="default">
            <Cell
                left={
                    !isMobile ? (
                        <Radio
                            value={value}
                            checked={preferredContact === value}
                            onChange={handleRadioChanged}
                            disabled={disabled}
                        />
                    ) : undefined
                }
                right={
                    isMobile ? (
                        <Radio
                            value={value}
                            checked={preferredContact === value}
                            onChange={handleRadioChanged}
                            disabled={disabled}
                        />
                    ) : undefined
                }
            >
                <Text typography="label-2-regular">{trls[TrlKeys.text]}</Text>
            </Cell>
        </Card>
    );
});

export default PreferredContact;
