import { Text } from '@hh.ru/magritte-ui';
import { DotFilledSize16 } from '@hh.ru/magritte-ui/icon';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import translation from 'src/components/translation';
import { PhoneType } from 'src/models/applicant/resume/blocks/contacts/types';
import { NON_BREAKING_SPACE } from 'src/utils/constants/symbols';

interface PhoneTypeProps {
    isPreferred: boolean;
    type?: PhoneType;
    isShort?: boolean;
}

const TrlKeys = {
    isPreferred: 'rb.view.preferredcontact',
    isPreferredShort: 'rb.view.preferredcontact.short',
    [PhoneType.Cell]: 'resumeView.phoneType.mobile',
    [PhoneType.Work]: 'resumeView.phoneType.work',
    [PhoneType.Home]: 'resumeView.phoneType.home',
};

const PhoneTypeAndPreference: TranslatedComponent<PhoneTypeProps> = ({ trls, type, isPreferred, isShort }) => {
    if (isShort) {
        return isPreferred ? (
            <>
                <DotFilledSize16 initialColor="tertiary" />
                <Text style="secondary" typography="label-3-regular">
                    {trls[TrlKeys.isPreferredShort]}
                </Text>
            </>
        ) : null;
    }

    return (
        <Text style="secondary" typography="label-3-regular">
            {type && trls[TrlKeys[type]]}
            {isPreferred && <>{`${NON_BREAKING_SPACE}— ${trls[TrlKeys.isPreferred]}`}</>}
        </Text>
    );
};

export default translation(PhoneTypeAndPreference);
