import { translation } from '@hh.ru/front-static-app';
import { Input, Placeholder, Suggest } from '@hh.ru/magritte-ui';

import { PositionDTO, usePositionDataProvider } from 'src/models/applicant/resume/api/position';

const TrlKeys = {
    errorTitle: 'resume.editor.suggest.errors.placeholder',
    resetButton: 'resume.editor.suggest.errors.button',
    errorDescription: 'resume.editor.suggest.errors.description',
};

interface Props {
    value: string;
    invalid: boolean;
    onChange: (value: string) => void;
    placeholder?: string;
    errorMessage?: string | null;
}

const PositionField = translation<Props>(({ trls, value, invalid, onChange, placeholder, errorMessage }) => {
    const dataProvider = usePositionDataProvider();

    const handleChange = (value: string, isValueFromList: boolean) => {
        if (!isValueFromList) {
            onChange(value);
        }
    };

    const handleSelect = (_value: string, item?: PositionDTO) => {
        if (!item) {
            return true;
        }

        onChange(item.text);

        return true;
    };

    return (
        <Suggest
            onChange={handleChange}
            dataProvider={dataProvider}
            onSelectValidator={handleSelect}
            navigationBarProps={{
                title: placeholder,
            }}
            input={{
                component: Input,
                props: {
                    value,
                    size: 'medium',
                    invalid,
                    clearable: true,
                    placeholder,
                    errorMessage,
                },
            }}
            trls={{
                resetButton: trls[TrlKeys.resetButton],
            }}
            errorPlaceholder={
                <Placeholder title={trls[TrlKeys.errorTitle]} description={trls[TrlKeys.errorDescription]} />
            }
        />
    );
});

export default PositionField;
