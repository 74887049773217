import classnames from 'classnames';

import { SPALink } from '@hh.ru/redux-spa-middleware';
import { H3 } from 'bloko/blocks/header';
import VSpacing from 'bloko/blocks/vSpacing';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import translation from 'src/components/translation';
import { useSelector } from 'src/hooks/useSelector';

const TrlKeys = {
    title: 'resume.sidebar.contactsVisibilityHistory.title',
    link: 'resume.sidebar.contactsVisibilityHistory.link',
};
const ResumeContactsVisibilityHistoryLink: TranslatedComponent<{ modifier?: 'left' | 'right' }> = ({
    modifier,
    trls,
}) => {
    const contactsVisibilityHistoryLink = useSelector(
        (state) => state.resumeViewBackoffice.contactsVisibilityHistoryLink
    );
    if (!contactsVisibilityHistoryLink) {
        return null;
    }
    return (
        <div
            className={classnames('resume-sidebar-section', {
                'resume-sidebar-section_left': modifier === 'left',
                'resume-sidebar-section_right': modifier === 'right',
            })}
        >
            <H3>{trls[TrlKeys.title]}</H3>
            <VSpacing base={4} />
            <SPALink to={contactsVisibilityHistoryLink}>{trls[TrlKeys.link]}</SPALink>
        </div>
    );
};

export default translation(ResumeContactsVisibilityHistoryLink);
