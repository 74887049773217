import { DefaultRootState } from 'react-redux';
import { Dispatch } from 'redux';

import { ResumeEmployersListType } from 'src/models/applicant/resume/api';
import { ResumeVisibilityApi } from 'src/models/applicant/resume/api/visibility';
import { showNotification } from 'src/models/applicant/resume/editor/store/slice';
import { Thunk } from 'src/models/applicant/resume/editor/types/actions';
import { selectResumeHash } from 'src/models/applicant/resume/selectors';

import {
    getSelectResumeEmployersListSelector,
    selectResumeEmployerEditState,
} from 'src/models/applicant/resume/employersLists/selectors';
import {
    changeResumeEmployerListFetching,
    changeResumeEmployerListSaving,
    changeResumeEmployerListSearching,
    changeResumeEmployersListStep,
    loadResumeEmployersList,
    loadResumeEmployersSearchList,
} from 'src/models/applicant/resume/employersLists/slice';

const BASE_DELAY = 300;

export const fetchEmployersListForResume =
    (type: ResumeEmployersListType, page: number, withLoadingDelay = false): Thunk =>
    async (dispatch: Dispatch, getState: () => DefaultRootState): Promise<void> => {
        const resumeHash = selectResumeHash(getState());

        const fetchingTimeout = setTimeout(
            () => {
                dispatch(changeResumeEmployerListFetching(true));
            },
            withLoadingDelay ? BASE_DELAY : 0
        );

        try {
            const response = await ResumeVisibilityApi.getVisibilityEmployerList(resumeHash, type, page);

            dispatch(loadResumeEmployersList({ type, response }));
        } catch (_) {
            dispatch(showNotification('error-api'));
            // mb sentry log
        } finally {
            clearTimeout(fetchingTimeout);
            dispatch(changeResumeEmployerListFetching(false));
        }
    };

const getTargetPage = (page: number, totalItems: number): number => {
    if (page === 0) {
        return page;
    } else if (totalItems === 1) {
        return page - 1;
    }
    return page;
};

export const deleteEmployerFromListForResume =
    (type: ResumeEmployersListType, employerId: number[]): Thunk =>
    async (dispatch: Dispatch, getState: () => DefaultRootState): Promise<void> => {
        const resumeHash = selectResumeHash(getState());
        const page = getState().resumeEmployersList[type]?.page ?? 0;
        const currentList = getSelectResumeEmployersListSelector(type)(getState());
        const totalItems = currentList?.items?.length ?? 0;

        const targetPage = getTargetPage(page, totalItems);

        const fetchingTimeout = setTimeout(() => {
            dispatch(changeResumeEmployerListFetching(true));
        }, BASE_DELAY);

        try {
            await ResumeVisibilityApi.deleteVisibilityEmployer(resumeHash, type, employerId);
            const response = await ResumeVisibilityApi.getVisibilityEmployerList(resumeHash, type, targetPage);

            dispatch(loadResumeEmployersList({ type, response }));
        } catch (_) {
            dispatch(showNotification('error-api'));
            // mb sentry log
        } finally {
            clearTimeout(fetchingTimeout);
            dispatch(changeResumeEmployerListFetching(false));
        }
    };

export const clearEmployersFromListForResume =
    (type: ResumeEmployersListType): Thunk =>
    async (dispatch: Dispatch, getState: () => DefaultRootState): Promise<void> => {
        const resumeHash = selectResumeHash(getState());
        // записываем сразу дефолтные данные, чтобы не ждать реальной очистки
        dispatch(
            loadResumeEmployersList({
                type,
                response: { total: 0, limit: 2000, canAdd: 2000, page: 0, perPage: 10, items: [] },
            })
        );
        try {
            await ResumeVisibilityApi.clearVisibilityEmployers(resumeHash, type);
            const response = await ResumeVisibilityApi.getVisibilityEmployerList(resumeHash, type, 0);

            dispatch(loadResumeEmployersList({ type, response }));
        } catch (_) {
            dispatch(showNotification('error-api'));
            // mb sentry log
        } finally {
            dispatch(changeResumeEmployerListSaving(false));
        }
    };

export const batchEmployersListForResumeUpdate =
    (type: ResumeEmployersListType): Thunk =>
    async (dispatch: Dispatch, getState: () => DefaultRootState): Promise<void> => {
        const resumeHash = selectResumeHash(getState());
        const page = getState().resumeEmployersList[type]?.page ?? 0;
        const actions = selectResumeEmployerEditState(getState());

        const fetchingTimeout = setTimeout(() => {
            dispatch(changeResumeEmployerListSaving(true));
        }, BASE_DELAY);

        const deleteIds = Object.keys(actions).filter((key) => actions[key] === 'delete');
        const addIds = Object.keys(actions).filter((key) => actions[key] === 'add');

        try {
            if (deleteIds.length) {
                await ResumeVisibilityApi.deleteVisibilityEmployer(resumeHash, type, deleteIds);
            }
            if (addIds.length) {
                await ResumeVisibilityApi.addVisibilityEmployers(resumeHash, type, addIds);
            }
            const response = await ResumeVisibilityApi.getVisibilityEmployerList(resumeHash, type, page);

            dispatch(loadResumeEmployersList({ type, response }));
            dispatch(changeResumeEmployersListStep({ step: type, subStep: 'list' }));
        } catch (_) {
            dispatch(showNotification('error-api'));
            // mb sentry log
        } finally {
            clearTimeout(fetchingTimeout);
            dispatch(changeResumeEmployerListSaving(false));
        }
    };

export const searchEmployersListForResume =
    (type: ResumeEmployersListType, query: string): Thunk =>
    async (dispatch: Dispatch, getState: () => DefaultRootState): Promise<void> => {
        const resumeHash = selectResumeHash(getState());
        dispatch(changeResumeEmployersListStep({ step: type, subStep: 'search' }));

        dispatch(changeResumeEmployerListSearching(true));

        try {
            const response = await ResumeVisibilityApi.searchVisibilityEmployerList(resumeHash, type, query);

            dispatch(loadResumeEmployersSearchList({ response }));
        } catch (_) {
            dispatch(showNotification('error-api'));
            // mb sentry log
        } finally {
            dispatch(changeResumeEmployerListSearching(false));
        }
    };
