import { FC, ReactElement } from 'react';

import { Cell, Text, VSpacingContainer } from '@hh.ru/magritte-ui';

import AsyncHighlighter from 'src/components/Markup/AsyncHighlighter';

interface ResumeEducationItemProps {
    name: string;
    year?: number;
    organization?: string;
    result?: string;
    link?: string;
    right?: ReactElement;
}

const ResumeEducationItem: FC<ResumeEducationItemProps> = ({ name, year, organization, result, link, right }) => (
    <Cell
        {...(link && {
            Element: 'a',
            href: link,
            target: '_blank',
        })}
        right={right}
    >
        <VSpacingContainer default={4}>
            <Text typography="label-3-regular">
                <AsyncHighlighter>{name}</AsyncHighlighter>
            </Text>
            {(organization || result) && (
                <Text typography="label-3-regular" style="secondary">
                    <AsyncHighlighter>{[organization, result].filter(Boolean).join(', ')}</AsyncHighlighter>
                </Text>
            )}
            {year && (
                <Text typography="label-3-regular" style="secondary">
                    {year}
                </Text>
            )}
        </VSpacingContainer>
    </Cell>
);

export default ResumeEducationItem;
