import { FC, Fragment, PropsWithChildren } from 'react';

import { useBreakpoint, ScrollableContainer, ScrollableItem } from '@hh.ru/magritte-ui';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import translation from 'src/components/translation';
import { useSelector } from 'src/hooks/useSelector';
import ResumeBlock from 'src/pages/ResumeView/employerRedesign/components/ResumeBlock';

import ResumePortfolioItem from 'src/pages/ResumeView/employerRedesign/components/ResumePortfolio/ResumePortfolioItem';

import styles from './styles.less';

const TrlKeys = {
    title: 'rb.title.portfolio',
};

const Wrapper: FC<{ isScrollable?: boolean } & PropsWithChildren> = ({ isScrollable, children }) => {
    if (isScrollable) {
        return (
            <ScrollableContainer correction={15}>
                <div className={styles.scrollableWrapper}>{children}</div>
            </ScrollableContainer>
        );
    }

    return <div className={styles.grid}>{children}</div>;
};

const ResumePortfolio: TranslatedComponent = ({ trls }) => {
    const portfolio = useSelector((state) => state.resume?.portfolioUrls.value);

    const { isGtXS } = useBreakpoint();
    const isScrollable = !isGtXS;

    if (!portfolio?.length) {
        return null;
    }

    return (
        <ResumeBlock title={trls[TrlKeys.title]} data-qa="resume-block-portfolio" border={false}>
            <Wrapper isScrollable={isScrollable}>
                {portfolio.map(({ id, title, preview, big }) => {
                    const item = <ResumePortfolioItem title={title} preview={preview} big={big} />;

                    if (isScrollable) {
                        return <ScrollableItem key={id}>{item}</ScrollableItem>;
                    }

                    return <Fragment key={id}>{item}</Fragment>;
                })}
            </Wrapper>
        </ResumeBlock>
    );
};

export default translation(ResumePortfolio);
