import { DefaultRootState } from 'react-redux';
import { Dispatch } from 'redux';

import { editorConfigMap } from 'src/models/applicant/resume/editor/configMap';
import { removeQueryFromUrl } from 'src/models/applicant/resume/editor/openByUrl';
import {
    selectResumeEditorDeleting,
    selectResumeEditorSaving,
} from 'src/models/applicant/resume/editor/store/loading/selectors';
import { selectResumeEditorType } from 'src/models/applicant/resume/editor/store/selectors';
import {
    showNotification,
    setResumeEditorType,
    setResumeEditorFields,
    showResumeEditorConfirm,
    resetResumeEditorFormErrors,
    toggleResumeEditorVisibility,
    toggleResumeEditorLoading,
} from 'src/models/applicant/resume/editor/store/slice';
import { EditorFormType } from 'src/models/applicant/resume/editor/types';
import { PayloadType } from 'src/models/applicant/resume/editor/types/config';
import { selectLocation } from 'src/models/applicant/resume/selectors';

export const openResumeEditorModal =
    (type: EditorFormType, payload?: PayloadType) =>
    async (dispatch: Dispatch, getState: () => DefaultRootState): Promise<void> => {
        const saving = selectResumeEditorSaving(getState());
        const deleting = selectResumeEditorDeleting(getState());

        if (saving || deleting) {
            return;
        }

        const config = editorConfigMap[type];

        if (!config) {
            return;
        }

        dispatch(setResumeEditorType({ type, payload }));
        dispatch(resetResumeEditorFormErrors());

        dispatch(toggleResumeEditorVisibility(true));
        if (config.preload) {
            dispatch(toggleResumeEditorLoading({ type: 'preload', loading: true }));
            try {
                await config.preload(getState(), dispatch);
            } catch (_) {
                dispatch(closeResumeEditor());
                dispatch(showNotification('error-api'));
                // mb sentry log
            } finally {
                dispatch(toggleResumeEditorLoading({ type: 'preload', loading: false }));
            }
        }

        dispatch(setResumeEditorFields(config.init(getState())));
    };

export const closeResumeEditorModal =
    (skipChangedCheck = false) =>
    (dispatch: Dispatch, getState: () => DefaultRootState): void => {
        const state = getState();
        const type = selectResumeEditorType(state);
        const saving = selectResumeEditorSaving(state);
        const deleting = selectResumeEditorDeleting(state);

        if (saving || deleting) {
            return;
        }
        const config = editorConfigMap[type];

        if (!skipChangedCheck && config.changed(state)) {
            void dispatch(showResumeEditorConfirm({ type: 'block-changed' }));
        } else {
            dispatch(closeResumeEditor());
        }
    };

export function closeResumeEditor() {
    return (dispatch: Dispatch, getState: () => DefaultRootState): void => {
        const location = selectLocation(getState());

        removeQueryFromUrl(location);

        dispatch(toggleResumeEditorVisibility(false));
    };
}
