import { FC } from 'react';

import { Card, VSpacing } from '@hh.ru/magritte-ui';
import useBreakpoint from '@hh.ru/magritte-ui-breakpoint/useBreakpoint';

import ResumeVideoLink from 'src/components/ResumeVideoLink';
import { useSelector } from 'src/hooks/useSelector';
import ResumeMap from 'src/pages/ResumeView/employerRedesign/components/ResumeMap';

import ResumeGenderAndAge from 'src/pages/ResumeView/employerRedesign/components/ResumeMainInfo/ResumeGenderAndAge';
import ResumeJobSearchStatus from 'src/pages/ResumeView/employerRedesign/components/ResumeMainInfo/ResumeJobSearchStatus';
import ResumeLastActivityTime from 'src/pages/ResumeView/employerRedesign/components/ResumeMainInfo/ResumeLastActivityTime';
import ResumeName from 'src/pages/ResumeView/employerRedesign/components/ResumeMainInfo/ResumeName';
import ResumePersonalLocation from 'src/pages/ResumeView/employerRedesign/components/ResumeMainInfo/ResumePersonalLocation';
import ResumePhoneVerified from 'src/pages/ResumeView/employerRedesign/components/ResumeMainInfo/ResumePhoneVerified';
import ResumePhoto from 'src/pages/ResumeView/employerRedesign/components/ResumeMainInfo/ResumePhoto';
import ResumeUpdateDate from 'src/pages/ResumeView/employerRedesign/components/ResumeMainInfo/ResumeUpdateDate';

import styles from './index.less';

const ResumeMainInfo: FC = () => {
    const { isMobile } = useBreakpoint();
    const canBuyContacts = useSelector((state) => state.resume?.resumeContactsOpening?.canOpenContactsThroughCIV);
    const canOpenContacts = useSelector((state) => state.resume?.canOpenContacts);

    return (
        <div>
            <Card stretched borderWidth={!isMobile ? 'default' : 'none'} borderRadius={24} padding={isMobile ? 0 : 12}>
                <div className={styles.contentWrapper}>
                    <ResumePhoto />
                    <div className={styles.text}>
                        <div className={styles.header}>
                            <ResumeName isMobile={isMobile} />
                            {!isMobile && (
                                <div className={styles.videoLinkWrapper}>
                                    <ResumeVideoLink isMagritte />
                                </div>
                            )}
                        </div>
                        <VSpacing default={8} />
                        <ResumeGenderAndAge />
                        <ResumePersonalLocation />
                        {isMobile && (
                            <div className={styles.videoLinkWrapper}>
                                <ResumeVideoLink isMagritte />
                            </div>
                        )}
                        <VSpacing default={20} xs={12} s={12} />
                        <div className={styles.tags}>
                            <ResumeUpdateDate />
                            <ResumeLastActivityTime />
                            <ResumeJobSearchStatus />
                            <ResumePhoneVerified />
                        </div>
                    </div>
                </div>
            </Card>
            {canOpenContacts && canBuyContacts && <ResumeMap />}
        </div>
    );
};

export default ResumeMainInfo;
