import { Ref, useEffect, useMemo } from 'react';
import classnames from 'classnames';

import { ElementShownAnchor } from '@hh.ru/analytics-js';
import employerResumeViewReportsElementShown from '@hh.ru/analytics-js-events/build/xhh/employer/experiments/PORTFOLIO-32152/employer_resume_view_reports_element_shown';
import employerResumeViewReportsInfotipElementShown from '@hh.ru/analytics-js-events/build/xhh/employer/experiments/PORTFOLIO-32152/employer_resume_view_reports_infotip_element_shown';
import { Button, VSpacing, VSpacingContainer } from '@hh.ru/magritte-ui';
import Column from 'bloko/blocks/column';
import Link, { LinkAppearance } from 'bloko/blocks/link';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import MagritteInfotip from 'src/components/Infotip/MagritteInfotip';
import ResumeBlockButtonWrapper from 'src/components/ResumeView/ResumeBlockButtonWrapper';
import translation from 'src/components/translation';
import { useSelectorNonNullable, useSelector } from 'src/hooks/useSelector';
import { useToggleState } from 'src/hooks/useToggleState';
import ResumeBlock from 'src/pages/ResumeView/components/View/ResumeBlock';
import MagritteResumeBlock from 'src/pages/ResumeView/employerRedesign/components/ResumeBlock';
import {
    DEFAULT_RESUME_BUTTON_PROPS,
    RESUME_BLOCK_VSPACING,
    MAX_NUMBER_OF_ITEMS_TO_RENDER,
} from 'src/pages/ResumeView/employerRedesign/const';

import ReportCard from 'src/pages/ResumeView/components/View/ResumeVerificationReports/ReportCard';

import styles from './styles.less';

const TrlKeys = {
    title: 'skill.verification.title',
    titleInfotip: 'skill.verification.titleInfotip',
    reportDate: 'skill.verification.date',
    reportTooltip: 'skill.verification.tooltip',
    showAll: 'skill.verification.expand',
    collapse: 'skill.verification.collapse',
};

const VERIFICATION_REPORTS_TITLE_INFOTIP = 'verification_reports_title_infotip';

const ResumeVerificationReports: TranslatedComponent<{ isMagritte?: boolean }> = ({ isMagritte, trls }) => {
    const employerId = useSelectorNonNullable((state) => state.employerId);
    const resume = useSelectorNonNullable((state) => state.resume);
    const { hash: resumeHash, id } = resume;

    const infoTip = useSelector((state) => state.infoTip);
    const reports = useSelector((state) => state.verificationReports);
    const [showAll, toggleAll, setShowAll] = useToggleState(false);

    const showTip = infoTip.name === VERIFICATION_REPORTS_TITLE_INFOTIP;
    const sortedReports = useMemo(
        () => reports.sort((a, b) => Number(b.hasVerificationReport) - Number(a.hasVerificationReport)),
        [reports]
    );
    const slicedReports = sortedReports.slice(0, isMagritte ? MAX_NUMBER_OF_ITEMS_TO_RENDER : 4);
    const availableReports = useMemo(() => reports.filter((report) => report.hasVerificationReport), [reports]);

    useEffect(() => {
        const url = new URL(document.location.href);
        if (url.searchParams.has('showReports')) {
            document.getElementById('verification_reports')?.scrollIntoView({ behavior: 'smooth', block: 'center' });
        }
    }, []);

    if (!reports.length) {
        return null;
    }

    const renderCards = () => {
        return (
            <>
                {(showAll ? sortedReports : slicedReports).map((report, index) => {
                    const levelId = report.level?.id.toString() || '';
                    const levelName = report.level?.name || '';
                    return (
                        <ReportCard
                            key={report.id}
                            resumeId={id}
                            resumeHash={resumeHash}
                            skillLevel={levelId}
                            skillLevelName={levelName}
                            skillName={report.name}
                            skillDate={report.attemptedAt}
                            skillId={report.id}
                            index={index}
                            hasVerificationReport={report.hasVerificationReport}
                            isMagritte={isMagritte}
                        />
                    );
                })}
            </>
        );
    };

    return (
        <MagritteInfotip
            showClose
            visible={showTip}
            name={VERIFICATION_REPORTS_TITLE_INFOTIP}
            placement={'top-left'}
            render={({ activatorRef }) => {
                return (
                    <div id={'verification_reports'} ref={activatorRef as Ref<HTMLDivElement>}>
                        <ElementShownAnchor
                            fn={employerResumeViewReportsElementShown}
                            resumeId={id}
                            employerId={employerId}
                            skillsCount={sortedReports.length}
                            reportsCount={availableReports.length}
                        >
                            {isMagritte ? (
                                <MagritteResumeBlock
                                    title={trls[TrlKeys.title]}
                                    data-qa="verification-reports"
                                    buttons={
                                        sortedReports.length > slicedReports.length &&
                                        !showAll && (
                                            <ResumeBlockButtonWrapper>
                                                <Button
                                                    {...DEFAULT_RESUME_BUTTON_PROPS}
                                                    onClick={() => setShowAll(true)}
                                                >
                                                    {trls[TrlKeys.showAll]}
                                                </Button>
                                            </ResumeBlockButtonWrapper>
                                        )
                                    }
                                >
                                    <VSpacingContainer default={RESUME_BLOCK_VSPACING}>
                                        {renderCards()}
                                    </VSpacingContainer>
                                </MagritteResumeBlock>
                            ) : (
                                <ResumeBlock title={trls[TrlKeys.title]} data-qa="verification-reports" EditLink={null}>
                                    <Column xs="4" s="8" m="9" l="12">
                                        <div className={styles.cardsContainer}>
                                            <div className={classnames('resume-block-container', styles.cardsWrapper)}>
                                                {renderCards()}
                                            </div>
                                            {sortedReports.length > slicedReports.length && (
                                                <>
                                                    <VSpacing default={12} />
                                                    <Link appearance={LinkAppearance.Pseudo} onClick={toggleAll}>
                                                        {showAll ? trls[TrlKeys.collapse] : trls[TrlKeys.showAll]}
                                                    </Link>
                                                </>
                                            )}
                                        </div>
                                    </Column>
                                    <VSpacing default={48} />
                                </ResumeBlock>
                            )}
                        </ElementShownAnchor>
                    </div>
                );
            }}
        >
            <ElementShownAnchor fn={employerResumeViewReportsInfotipElementShown} resumeId={id} employerId={employerId}>
                {trls[TrlKeys.titleInfotip]}
            </ElementShownAnchor>
        </MagritteInfotip>
    );
};

export default translation(ResumeVerificationReports);
