import { useMemo } from 'react';
import { useDispatch } from 'react-redux';

import { translation, useSelector } from '@hh.ru/front-static-app';
import { Button, Card, Cell, CellText, Text, VSpacing } from '@hh.ru/magritte-ui';
import { ChevronRightOutlinedSize24, EyeOutlinedSize24 } from '@hh.ru/magritte-ui/icon';
import { format } from 'bloko/common/trl';

import { useTopLevelSite } from 'src/hooks/useSites';
import { useActionLinkAnalytics } from 'src/models/applicant/resume/analytics/provider';
import {
    selectApplicantResumeAccessType,
    selectApplicantResumeContactsVisibilityHistoryLink,
    selectApplicantResumeHiddenFields,
    selectApplicantResumeVisibilityHistoryLink,
} from 'src/models/applicant/resume/blocks/visibility/selectors';
import { openResumeEditorModal } from 'src/models/applicant/resume/editor/store/actions/toggleModal';
import { capitalized } from 'src/models/applicant/resume/lib/common/string';
import { useUserType } from 'src/pages/ResumeView/redesign/lib';
import { VisibilityEditor } from 'src/pages/ResumeView/redesign/visibility/Editor';

import styles from './styles.less';

const TrlKeys = {
    title: 'applicant.resume.visibility.card.title',
    anonymous: 'applicant.resume.visibility.card.anonymous',
    visibility: {
        everyone: 'applicant.resume.visibility.card.visibility.everyone',
        all: 'applicant.resume.visibility.card.visibility.all',
        whitelist: 'applicant.resume.visibility.card.visibility.whitelist',
        blacklist: 'applicant.resume.visibility.card.visibility.blacklist',
        direct: 'applicant.resume.visibility.card.visibility.direct',
        no_one: 'applicant.resume.visibility.card.visibility.noOne',
    },
    backoffice: {
        contacts: 'applicant.resume.visibility.card.contacts',
        history: 'applicant.resume.visibility.card.backoffice.history',
        employerHistory: 'applicant.resume.visibility.card.backoffice.employer.history',
        change: 'applicant.resume.visibility.card.backoffice.change',
        title: 'applicant.resume.visibility.card.backoffice.title',
        subtitle: 'applicant.resume.visibility.card.backoffice.subtitle',
    },
};

const ResumeVisibility = translation(({ trls }) => {
    const dispatch = useDispatch();
    const analytics = useActionLinkAnalytics();

    const site = useTopLevelSite();
    const { isBackOffice } = useUserType();

    const accessType = useSelector(selectApplicantResumeAccessType);
    const hiddenFieldsRaw = useSelector(selectApplicantResumeHiddenFields);
    const hiddenFields = useMemo(() => hiddenFieldsRaw.map((item) => item.string), [hiddenFieldsRaw]);
    const visibilityHistoryLink = useSelector(selectApplicantResumeVisibilityHistoryLink);
    const contactsVisibilityHistoryLink = useSelector(selectApplicantResumeContactsVisibilityHistoryLink);
    const getAccessTypeText = () => {
        if (accessType === 'everyone') {
            return trls[TrlKeys.visibility.everyone];
        }
        if (accessType === 'clients') {
            return format(trls[TrlKeys.visibility.all], { '{0}': site });
        }
        if (accessType === 'whitelist') {
            return trls[TrlKeys.visibility.whitelist];
        }
        if (accessType === 'blacklist') {
            return trls[TrlKeys.visibility.blacklist];
        }
        if (accessType === 'direct') {
            return trls[TrlKeys.visibility.direct];
        }
        if (accessType === 'no_one') {
            return trls[TrlKeys.visibility.no_one];
        }
        return '';
    };

    const getVisibleText = () => {
        if (hiddenFields.length > 0) {
            return format(trls[TrlKeys.anonymous], { '{0}': getAccessTypeText() });
        }
        return capitalized(getAccessTypeText());
    };

    const handleOpenVisibility = () => {
        analytics.changeVisibilityClick();
        void dispatch(openResumeEditorModal('visibility'));
    };

    if (isBackOffice) {
        return (
            <>
                <Card borderRadius={24} borderWidth="default">
                    <div className={styles.backofficeCardTop}>
                        <Text typography="title-5-semibold">{trls[TrlKeys.backoffice.title]}</Text>
                    </div>
                    <div className={styles.divider} />
                    <div className={styles.backofficeCardContent}>
                        <Text typography="label-2-regular">{trls[TrlKeys.backoffice.subtitle]}</Text>
                        <VSpacing default={4} />
                        <Text typography="label-3-regular" style="secondary">
                            {getVisibleText()}
                        </Text>
                        <VSpacing default={20} />
                        <div className={styles.buttons}>
                            <div className={styles.button}>
                                <Button
                                    Element="a"
                                    href={visibilityHistoryLink}
                                    mode="secondary"
                                    style="neutral"
                                    size="small"
                                    stretched
                                >
                                    {trls[TrlKeys.backoffice.history]}
                                </Button>
                            </div>

                            <div className={styles.button}>
                                <Button
                                    mode="secondary"
                                    style="neutral"
                                    size="small"
                                    stretched
                                    onClick={() => dispatch(openResumeEditorModal('visibility'))}
                                >
                                    {trls[TrlKeys.backoffice.change]}
                                </Button>
                            </div>
                        </div>
                    </div>
                    <div className={styles.divider} />
                    <div className={styles.backofficeCardContent}>
                        <Text typography="label-2-regular">{trls[TrlKeys.backoffice.contacts]}</Text>
                        <VSpacing default={20} />
                        <Button
                            Element="a"
                            href={contactsVisibilityHistoryLink}
                            mode="secondary"
                            size="small"
                            style="neutral"
                            stretched
                        >
                            {trls[TrlKeys.backoffice.employerHistory]}
                        </Button>
                    </div>
                </Card>
                <VisibilityEditor />
            </>
        );
    }

    return (
        <>
            <Card padding={24} borderRadius={24} borderWidth="default" onClick={handleOpenVisibility}>
                <Cell align="top" left={<EyeOutlinedSize24 />} right={<ChevronRightOutlinedSize24 />}>
                    <CellText type="description">{trls[TrlKeys.title]}</CellText>
                    <CellText>{getVisibleText()}</CellText>
                </Cell>
            </Card>
            <VisibilityEditor />
        </>
    );
});

export default ResumeVisibility;
