import { useMemo } from 'react';

import { Text, VSpacingContainer } from '@hh.ru/magritte-ui';
import { DriverLicenseType } from '@hh.ru/types-hh-microcore';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';
import { format } from 'bloko/common/trl';

import translation from 'src/components/translation';
import { useSelector } from 'src/hooks/useSelector';
import { capitalized } from 'src/models/applicant/resume/lib/common/string';
import { Gender, TravelTime, TripsReadiness } from 'src/models/resume/resumeCommon.types';
import ResumeBlock from 'src/pages/ResumeView/employerRedesign/components/ResumeBlock';
import { RESUME_BLOCK_VSPACING } from 'src/pages/ResumeView/employerRedesign/const';

const TrlKeys = {
    title: 'resume.block.additionalInfo.title',
    travelTimeTitle: 'rb.title.travelTime',
    travelTime: {
        [TravelTime.Any]: 'traveltime.any',
        [TravelTime.LessThanHour]: 'traveltime.less_than_hour',
        [TravelTime.FromHourToOneAndHalf]: 'traveltime.from_hour_to_one_and_half',
    },
    businessTripsReadinessTitle: 'resume.additionalInfo.businessTripsReadiness.title',
    businessTripsReadiness: {
        [Gender.Male]: {
            [TripsReadiness.Ready]: 'rb.businessTripReadiness.ready.male',
            [TripsReadiness.Sometimes]: 'rb.businessTripReadiness.sometimes.male',
            [TripsReadiness.Never]: 'rb.businessTripReadiness.never.male',
        },
        [Gender.Female]: {
            [TripsReadiness.Ready]: 'rb.businessTripReadiness.never.female',
            [TripsReadiness.Sometimes]: 'rb.businessTripReadiness.sometimes.female',
            [TripsReadiness.Never]: 'rb.businessTripReadiness.never.female',
        },
        [Gender.Unknown]: {
            [TripsReadiness.Ready]: 'rb.businessTripReadiness.ready.unknown',
            [TripsReadiness.Sometimes]: 'rb.businessTripReadiness.sometimes.unknown',
            [TripsReadiness.Never]: 'rb.businessTripReadiness.never.unknown',
        },
    },
    citizenshipTitle: 'rb.title.citizenship',
    workTicketTitle: 'rb.title.workTicket',
    driverLicenseTitle: 'rb.title.driverExperience',
    driverLicense: {
        [DriverLicenseType.A]: 'driverLicenseTypes.A',
        [DriverLicenseType.B]: 'driverLicenseTypes.B',
        [DriverLicenseType.C]: 'driverLicenseTypes.C',
        [DriverLicenseType.D]: 'driverLicenseTypes.D',
        [DriverLicenseType.E]: 'driverLicenseTypes.E',
        [DriverLicenseType.BE]: 'driverLicenseTypes.BE',
        [DriverLicenseType.CE]: 'driverLicenseTypes.CE',
        [DriverLicenseType.DE]: 'driverLicenseTypes.DE',
        [DriverLicenseType.TM]: 'driverLicenseTypes.TM',
        [DriverLicenseType.TB]: 'driverLicenseTypes.TB',
    },
    hasVehicle: {
        true: 'resume.additionalInfo.hasVehicle.true',
        false: 'resume.additionalInfo.hasVehicle.false',
    },
};

const ResumeAdditionalInfo: TranslatedComponent = ({ trls }) => {
    const travelTime = useSelector((state) => state.resume?.travelTime.value);
    const gender = useSelector((state) => state.resume?.gender.value);
    const businessTripsReadiness = useSelector((state) => state.resume?.businessTripReadiness.value);
    const citizenship = useSelector((state) => state.resume?.citizenship.value);
    const workTicket = useSelector((state) => state.resume?.workTicket.value);
    const driverLicenseTypes = useSelector((state) => state.resume?.driverLicenseTypes.value);
    const hasVehicle = useSelector((state) => state.resume?.hasVehicle.value);

    const items = useMemo(
        () =>
            [
                {
                    title: trls[TrlKeys.travelTimeTitle],
                    value: travelTime && capitalized(trls[TrlKeys.travelTime[travelTime]]),
                },
                {
                    title: trls[TrlKeys.businessTripsReadinessTitle],
                    value:
                        !!businessTripsReadiness &&
                        capitalized(
                            trls[TrlKeys.businessTripsReadiness[gender || Gender.Unknown][businessTripsReadiness]]
                        ),
                },
                {
                    title: trls[TrlKeys.citizenshipTitle],
                    value: citizenship?.length && citizenship.map(({ title }) => title).join(', '),
                },
                {
                    title: trls[TrlKeys.workTicketTitle],
                    value: workTicket?.length && workTicket.map(({ title }) => title).join(', '),
                },
                {
                    title: trls[TrlKeys.driverLicenseTitle],
                    value:
                        driverLicenseTypes?.length &&
                        format(trls[TrlKeys.hasVehicle[hasVehicle ? 'true' : 'false']], {
                            '{0}': driverLicenseTypes.map((type) => trls[TrlKeys.driverLicense[type]]).join(', '),
                        }),
                },
            ].filter(({ value }) => !!value),
        [businessTripsReadiness, citizenship, driverLicenseTypes, gender, hasVehicle, travelTime, trls, workTicket]
    );

    return (
        <ResumeBlock title={trls[TrlKeys.title]}>
            <VSpacingContainer default={RESUME_BLOCK_VSPACING}>
                {items.map(({ title, value }) => (
                    <VSpacingContainer default={4} key={title}>
                        <Text style="secondary" typography="label-3-regular">
                            {title}
                        </Text>
                        <Text typography="label-3-regular">{value}</Text>
                    </VSpacingContainer>
                ))}
            </VSpacingContainer>
        </ResumeBlock>
    );
};

export default translation(ResumeAdditionalInfo);
