import { ComponentType } from 'react';
import { useDispatch } from 'react-redux';

import { translation, useSelector } from '@hh.ru/front-static-app';
import { VSpacingContainer } from '@hh.ru/magritte-ui';

import { usePreferredContactChanged } from 'src/models/applicant/resume/blocks/contacts/editor/change';
import { selectResumeEditorOpenedContacts } from 'src/models/applicant/resume/blocks/contacts/editor/selectors';
import { useTranslatedErrorMap } from 'src/models/applicant/resume/editor/form/errors';
import { submitResumeEditorData } from 'src/models/applicant/resume/editor/store/actions/submitEditor';
import { closeResumeEditorModal } from 'src/models/applicant/resume/editor/store/actions/toggleModal';
import EditorModal from 'src/pages/ResumeView/redesign/common/EditorModal';

import EmailField from 'src/pages/ResumeView/redesign/blocks/contacts/Editor/Email';
import PersonalSite from 'src/pages/ResumeView/redesign/blocks/contacts/Editor/PersonalSite';
import Phone from 'src/pages/ResumeView/redesign/blocks/contacts/Editor/Phone';

const TrlKeys = {
    title: 'resume.editor.contacts.title',
    commonErrors: {
        UNKNOWN: 'resume.editor.field.error.unknown',
        INVALID: 'resume.editor.field.error.required',
        REQUIRED: 'resume.editor.field.error.required',
        NOT_IN_ENUM: 'resume.editor.field.error.required',
        NOT_MATCH_REGEXP: 'resume.editor.field.error.regexp',
        SIZE_LESS_THAN_MINIMUM: 'resume.editor.field.error.required',
        LENGTH_GREATER_THAN_MAXIMUM: {
            one: 'resume.editor.field.error.max-length.one',
            some: 'resume.editor.field.error.max-length.some',
            many: 'resume.editor.field.error.max-length.many',
        },
    },
};

const ResumeContactsEditor: ComponentType = translation(({ trls }) => {
    const dispatch = useDispatch();

    const visible = useSelector(selectResumeEditorOpenedContacts);

    const handleClose = () => dispatch(closeResumeEditorModal());
    const handleSave = () => dispatch(submitResumeEditorData());

    const errorsMap = useTranslatedErrorMap(TrlKeys.commonErrors, trls);
    usePreferredContactChanged();

    return (
        <EditorModal title={trls[TrlKeys.title]} visible={visible} onSave={handleSave} onClose={handleClose}>
            <VSpacingContainer default={24}>
                <VSpacingContainer default={12}>
                    <Phone errorsMap={errorsMap} />
                    <EmailField errorsMap={errorsMap} />
                    <PersonalSite errorsMap={errorsMap} />
                </VSpacingContainer>
            </VSpacingContainer>
        </EditorModal>
    );
});

export default ResumeContactsEditor;
