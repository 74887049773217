import { ComponentType, useRef } from 'react';
import { useDispatch } from 'react-redux';

import { translation, useSelector } from '@hh.ru/front-static-app';
import { Button, Card, Cell, CellText, FormLabel, Switch, VSpacing, VSpacingContainer } from '@hh.ru/magritte-ui';
import { LinkOutlinedSize24 } from '@hh.ru/magritte-ui/icon';
import { format } from 'bloko/common/trl';

import { useTopLevelSite } from 'src/hooks/useSites';
import { ResumeEmployersListType } from 'src/models/applicant/resume/api';
import { useResumeEditorField } from 'src/models/applicant/resume/editor/form/change';
import { showNotification } from 'src/models/applicant/resume/editor/store/slice';
import { capitalized } from 'src/models/applicant/resume/lib/common/string';
import { selectResumeHash } from 'src/models/applicant/resume/selectors';
import { ResumeAccessType } from 'src/models/resume/resumeAccess.types';
import { HiddenFields } from 'src/models/resume/resumeCommon.types';
import { EmployersListActivator } from 'src/pages/ResumeView/redesign/visibility/Editor/EmployersListActivator';

import { ItemCard } from 'src/pages/ResumeView/redesign/visibility/Editor/steps/AccessTypesStep/ItemCard';

const TrlKeys = {
    visibility: {
        all: 'applicant.resume.visibility.card.visibility.all',
        whitelist: 'applicant.resume.visibility.card.visibility.whitelist',
        blacklist: 'applicant.resume.visibility.card.visibility.blacklist',
        direct: 'applicant.resume.visibility.card.visibility.direct',
        no_one: 'applicant.resume.visibility.card.visibility.noOne',
        everyone: {
            title: 'applicant.resume.visibility.card.visibility.everyone',
            blocked1: 'accessType.everyone.warning.blocked',
            blocked2: 'accessType.everyone.warning.one',
            blocked3: 'accessType.everyone.warning.two',
        },
    },
    hiddenFields: {
        title: 'applicant.resume.visibility.editor.hiddenFields.title',
        hint: 'applicant.resume.visibility.editor.hiddenFields.hint',
        description: 'applicant.resume.visibility.editor.hiddenFields.description',
        formLabel: 'applicant.resume.visibility.editor.hiddenFields.formLabel',
        fullname: 'applicant.resume.visibility.editor.hiddenFields.fullname',
        phones: 'applicant.resume.visibility.editor.hiddenFields.phones',
        email: 'applicant.resume.visibility.editor.hiddenFields.email',
        otherContacts: 'applicant.resume.visibility.editor.hiddenFields.otherContacts',
        experience: 'applicant.resume.visibility.editor.hiddenFields.experience',
    },
};

export const AccessTypesStep: ComponentType<{ onEditList: (type: ResumeEmployersListType) => void }> = translation(
    ({ trls, onEditList }) => {
        const dispatch = useDispatch();

        const resumeHash = useSelector(selectResumeHash);

        const site = useTopLevelSite();

        const { value: accessType, onChange: onChangeAccessType } = useResumeEditorField('accessType', {});

        const everyOneVisible = useRef<boolean>(accessType === ResumeAccessType.Everyone);

        const { value: hiddenFields, onChange: onChangeHiddenFields } = useResumeEditorField('hiddenFields', {});

        const isFieldHidden = (field: HiddenFields) => hiddenFields?.includes(field);
        const toggleField = (field: HiddenFields) => {
            if (isFieldHidden(field)) {
                onChangeHiddenFields(hiddenFields.filter((item) => item !== field));
            } else {
                onChangeHiddenFields([...hiddenFields, field]);
            }
        };

        return (
            <>
                <VSpacingContainer default={12}>
                    {everyOneVisible.current && (
                        <ItemCard
                            checked={accessType === ResumeAccessType.Everyone}
                            disabled={true}
                            onChange={() => {
                                onChangeAccessType(ResumeAccessType.Everyone);
                            }}
                            additional={
                                <>
                                    <CellText type="subtitle" style="warning">
                                        {trls[TrlKeys.visibility.everyone.blocked1]}
                                    </CellText>
                                    <CellText type="subtitle">{trls[TrlKeys.visibility.everyone.blocked2]}</CellText>
                                    <CellText type="subtitle">{trls[TrlKeys.visibility.everyone.blocked3]}</CellText>
                                </>
                            }
                        >
                            {capitalized(trls[TrlKeys.visibility.everyone.title])}
                        </ItemCard>
                    )}

                    <ItemCard
                        checked={accessType === ResumeAccessType.Clients}
                        onChange={() => {
                            onChangeAccessType(ResumeAccessType.Clients);
                        }}
                    >
                        {capitalized(format(trls[TrlKeys.visibility.all], { '{0}': site }))}
                    </ItemCard>
                    <ItemCard
                        checked={accessType === ResumeAccessType.WhiteList}
                        onChange={() => onChangeAccessType(ResumeAccessType.WhiteList)}
                    >
                        {capitalized(trls[TrlKeys.visibility.whitelist])}
                    </ItemCard>
                    {accessType === ResumeAccessType.WhiteList && (
                        <EmployersListActivator type={'whitelist'} onClick={() => onEditList('whitelist')} />
                    )}
                    <ItemCard
                        checked={accessType === ResumeAccessType.BlackList}
                        onChange={() => onChangeAccessType(ResumeAccessType.BlackList)}
                    >
                        {capitalized(trls[TrlKeys.visibility.blacklist])}
                    </ItemCard>
                    {accessType === ResumeAccessType.BlackList && (
                        <EmployersListActivator type={'blacklist'} onClick={() => onEditList('blacklist')} />
                    )}
                    <ItemCard
                        checked={accessType === ResumeAccessType.Direct}
                        onChange={() => onChangeAccessType(ResumeAccessType.Direct)}
                    >
                        {capitalized(trls[TrlKeys.visibility.direct])}
                    </ItemCard>
                    {accessType === ResumeAccessType.Direct && navigator.clipboard?.writeText && (
                        <Button
                            mode="secondary"
                            style="accent"
                            icon={<LinkOutlinedSize24 />}
                            stretched
                            onClick={() => {
                                navigator.clipboard
                                    .writeText(`${window.location.origin}/resume/${resumeHash}`)
                                    .then(() => {
                                        dispatch(showNotification('copy-link'));
                                    })
                                    .catch((err) => {
                                        console.error('Не удалось скопировать ссылку: ', err);
                                    });
                            }}
                        >
                            Скопировать ссылку
                        </Button>
                    )}
                    <ItemCard
                        checked={accessType === ResumeAccessType.NoOne}
                        onChange={() => onChangeAccessType(ResumeAccessType.NoOne)}
                    >
                        {capitalized(trls[TrlKeys.visibility.no_one])}
                    </ItemCard>
                </VSpacingContainer>
                <VSpacing default={24} />
                <Card padding={16} borderRadius={12} borderWidth="default" stretched>
                    <Cell
                        align="top"
                        right={
                            <Switch
                                checked={!!hiddenFields?.length}
                                onClick={() => {
                                    if (hiddenFields.length === 0) {
                                        onChangeHiddenFields([HiddenFields.NamesAndPhoto, HiddenFields.Phones]);
                                    } else {
                                        onChangeHiddenFields([]);
                                    }
                                }}
                            />
                        }
                    >
                        <CellText>{trls[TrlKeys.hiddenFields.title]}</CellText>
                        <CellText type="description">{trls[TrlKeys.hiddenFields.description]}</CellText>
                    </Cell>
                </Card>
                {!!hiddenFields?.length && (
                    <>
                        <VSpacing default={16} />
                        <VSpacingContainer default={12}>
                            <FormLabel>{trls[TrlKeys.hiddenFields.hint]}</FormLabel>
                            <ItemCard
                                checked={isFieldHidden(HiddenFields.NamesAndPhoto)}
                                onChange={() => toggleField(HiddenFields.NamesAndPhoto)}
                                checkbox
                            >
                                {trls[TrlKeys.hiddenFields.fullname]}
                            </ItemCard>
                            <ItemCard
                                checked={isFieldHidden(HiddenFields.Phones)}
                                onChange={() => toggleField(HiddenFields.Phones)}
                                checkbox
                            >
                                {trls[TrlKeys.hiddenFields.phones]}
                            </ItemCard>
                            <ItemCard
                                checked={isFieldHidden(HiddenFields.Email)}
                                onChange={() => toggleField(HiddenFields.Email)}
                                checkbox
                            >
                                {trls[TrlKeys.hiddenFields.email]}
                            </ItemCard>
                            <ItemCard
                                checked={isFieldHidden(HiddenFields.OtherContacts)}
                                onChange={() => toggleField(HiddenFields.OtherContacts)}
                                checkbox
                            >
                                {trls[TrlKeys.hiddenFields.otherContacts]}
                            </ItemCard>
                            <ItemCard
                                checked={isFieldHidden(HiddenFields.Experience)}
                                onChange={() => toggleField(HiddenFields.Experience)}
                                checkbox
                            >
                                {trls[TrlKeys.hiddenFields.experience]}
                            </ItemCard>
                        </VSpacingContainer>
                    </>
                )}
            </>
        );
    }
);
