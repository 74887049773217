import { useBreakpoint } from '@hh.ru/magritte-ui';

const MAX_ITEMS_S = 2;
const MAX_ITEMS_M = 3;

export function useCalculateMaxItems(length: number) {
    const { isGtM, isGtXS } = useBreakpoint();

    if (isGtM) {
        return MAX_ITEMS_M;
    }

    if (isGtXS) {
        return MAX_ITEMS_S;
    }

    return length;
}
