import { DefaultRootState } from 'react-redux';

export const selectResumeEditorConfirmTitle = ({ resumeEditor }: DefaultRootState): string =>
    resumeEditor.confirm.title;

export const selectResumeEditorChangedConfirm = ({ resumeEditor }: DefaultRootState): boolean =>
    resumeEditor.confirm.show && resumeEditor.confirm.type === 'block-changed';

export const selectResumeEditorDeleteConfirm = ({ resumeEditor }: DefaultRootState): boolean =>
    resumeEditor.confirm.show && resumeEditor.confirm.type === 'block-item-delete';

export const selectResumeDeleteConfirm = ({ resumeEditor }: DefaultRootState): boolean =>
    resumeEditor.confirm.show && resumeEditor.confirm.type === 'resume-delete';
