import { DefaultRootState } from 'react-redux';

export const selectResumeEditorLoading = ({ resumeEditor }: DefaultRootState): boolean => resumeEditor.loading.preload;

export const selectResumeEditorSaving = ({ resumeEditor }: DefaultRootState): boolean => resumeEditor.loading.saving;

export const selectResumeEditorDeleting = ({ resumeEditor }: DefaultRootState): boolean =>
    resumeEditor.loading.deleting;

export const selectResumeEditorRenewalSaving = ({ resumeEditor }: DefaultRootState): boolean =>
    resumeEditor.loading.renewal;
