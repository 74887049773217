import { useSelector } from '@hh.ru/front-static-app';

import CareerPlatformBanner from 'src/components/CareerPlatformBanner';
import { MagritteContext } from 'src/hooks/useMagritte';
import { selectResumeUserId, selectResumeUserStatuses } from 'src/models/applicant/resume/selectors';
import { JobSearchStatus } from 'src/models/applicantUserStatuses';
import { CareerBannerPosition, CareerLinksKeys } from 'src/models/careerPlatformState';

const ACTIVE_USER_STATUSES = [JobSearchStatus.ActiveSearch, JobSearchStatus.LookingForOffers];

const ApplicantResumeCareerPlatformCard = () => {
    const hasLinks = useSelector((state) => state.careerPlatformLinks?.links != null);

    const userId = useSelector(selectResumeUserId);
    const applicantUserStatuses = useSelector(selectResumeUserStatuses);

    const userStatus = applicantUserStatuses?.[userId]?.jobSearchStatus?.name;

    const showCareerBanner = hasLinks && ACTIVE_USER_STATUSES.includes(userStatus);

    if (!showCareerBanner) {
        return null;
    }

    return (
        <MagritteContext.Provider value={true}>
            <CareerPlatformBanner position={CareerBannerPosition.Resume} destination={CareerLinksKeys.Courses} />
        </MagritteContext.Provider>
    );
};

export default ApplicantResumeCareerPlatformCard;
