import { ComponentType } from 'react';
import { useDispatch } from 'react-redux';

import { Card, VSpacingContainer, useBreakpoint } from '@hh.ru/magritte-ui';

import translation from 'src/components/translation';
import { useActionLinkAnalytics } from 'src/models/applicant/resume/analytics/provider';
import { openResumeEditorModal } from 'src/models/applicant/resume/editor/store/actions/toggleModal';
import ResumeContactsEditor from 'src/pages/ResumeView/redesign/blocks/contacts/Editor/index';
import BlockLabel from 'src/pages/ResumeView/redesign/common/BlockLabel';

import EditorButton from 'src/pages/ResumeView/redesign/blocks/contacts/Card/EditorButton';
import ContactsEmail from 'src/pages/ResumeView/redesign/blocks/contacts/Card/Email';
import ContactsPersonalSite from 'src/pages/ResumeView/redesign/blocks/contacts/Card/PersonalSite';
import ContactsPhones from 'src/pages/ResumeView/redesign/blocks/contacts/Card/Phones';

import styles from './styles.less';

const TrlKeys = {
    label: 'applicant.resume.contacts.label',
};
const BLOCK_NAME = 'contacts';

const ApplicantResumeContacts: ComponentType = translation(({ trls }) => {
    const dispatch = useDispatch();
    const analytics = useActionLinkAnalytics();

    const { isMobile } = useBreakpoint();

    const handleEdit = () => {
        analytics.resumeEditButtonClick(BLOCK_NAME);
        void dispatch(openResumeEditorModal(BLOCK_NAME));
    };

    return (
        <>
            <VSpacingContainer default={12}>
                <BlockLabel>{trls[TrlKeys.label]}</BlockLabel>
                <Card stretched padding={isMobile ? 12 : 24} borderRadius={24} borderWidth="default">
                    <div className={styles.card}>
                        <div className={styles.cardContent}>
                            <ContactsPhones />
                            <ContactsEmail />
                            <ContactsPersonalSite />
                        </div>
                        <EditorButton onClick={handleEdit} />
                    </div>
                </Card>
            </VSpacingContainer>
            <ResumeContactsEditor />
        </>
    );
});

export default ApplicantResumeContacts;
