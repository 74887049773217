import { ComponentType } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { translation } from '@hh.ru/front-static-app';
import { Loader, NumberPages, Text, VSpacing, VSpacingContainer } from '@hh.ru/magritte-ui';

import { ResumeEmployersListType } from 'src/models/applicant/resume/api';
import {
    deleteEmployerFromListForResume,
    fetchEmployersListForResume,
} from 'src/models/applicant/resume/employersLists/actions';
import {
    getSelectResumeEmployersListSelector,
    selectResumeEmployerEditState,
    selectResumeEmployerListFetching,
    selectResumeEmployerListSearching,
    selectResumeEmployerListSubStep,
    selectResumeEmployerSearchList,
} from 'src/models/applicant/resume/employersLists/selectors';
import { addResumeEmployersEditStateAction } from 'src/models/applicant/resume/employersLists/slice';

import { ListItem } from 'src/pages/ResumeView/redesign/visibility/Editor/steps/EmployersListStep/ListItem';
import { SearchListItem } from 'src/pages/ResumeView/redesign/visibility/Editor/steps/EmployersListStep/SearchListItem';

import styles from './styles.less';

const TrlKeys = {
    searchEmpty: 'applicant.resume.visibility.editor.employerList.search.empty',
    listEmpty: 'applicant.resume.visibility.editor.employerList.list.empty',
};

export const EmployersListContent: ComponentType<{
    type: ResumeEmployersListType;
}> = translation(({ type, trls }) => {
    const updatedState = useSelector(selectResumeEmployerEditState);
    const list = useSelector(getSelectResumeEmployersListSelector(type));
    const searchList = useSelector(selectResumeEmployerSearchList);
    const searching = useSelector(selectResumeEmployerListSearching);
    const fetching = useSelector(selectResumeEmployerListFetching);

    const dispatch = useDispatch();

    const subStep = useSelector(selectResumeEmployerListSubStep);

    if (searching || fetching) {
        return (
            <div className={styles.centered}>
                <Loader size={24} />
            </div>
        );
    }

    if (subStep === 'list') {
        if (!list?.items?.length) {
            return (
                <div className={styles.centered}>
                    <Text typography="title-5-semibold" style="primary">
                        {trls[TrlKeys.listEmpty]}
                    </Text>
                </div>
            );
        }
        return (
            <>
                <VSpacingContainer default={12}>
                    {list?.items?.map((item) => (
                        <ListItem
                            id={item.id}
                            name={item.name}
                            onDelete={() => dispatch(deleteEmployerFromListForResume(type, [item.id]))}
                            logo={item.logo}
                            key={item.id}
                        />
                    ))}
                </VSpacingContainer>
                {list?.pager && (
                    <>
                        <VSpacing default={12} />
                        <div className={styles.pagination}>
                            <NumberPages
                                current={list.page + 1}
                                first={1}
                                last={list.pager.items.length}
                                onChange={(page) => void dispatch(fetchEmployersListForResume(type, page - 1, true))}
                            />
                        </div>
                    </>
                )}
            </>
        );
    }

    if (subStep === 'search') {
        if (!searchList?.items?.length) {
            return (
                <div className={styles.centered}>
                    <Text typography="title-5-semibold" style="primary">
                        {trls[TrlKeys.searchEmpty]}
                    </Text>
                </div>
            );
        }
        return (
            <VSpacingContainer default={12}>
                {searchList?.items.map((item) => (
                    <SearchListItem
                        key={item.id}
                        id={item.id}
                        name={item.name}
                        logo={item.logo}
                        area={item.area}
                        onChange={(checked) =>
                            dispatch(
                                addResumeEmployersEditStateAction({
                                    employerId: item.id,
                                    action: checked ? 'add' : 'delete',
                                })
                            )
                        }
                        checked={
                            (item.checked && !(updatedState[item.id] === 'delete')) || updatedState[item.id] === 'add'
                        }
                    />
                ))}
            </VSpacingContainer>
        );
    }

    return null;
});
