import VSpacing from 'bloko/blocks/vSpacing';

import { GroupedMessages, MessageItem, UserType } from 'src/models/employer/resume/pinnedChatMessages';

import ChatMessage from 'src/components/ResumeView/ChatMessageGroup';
import { useGroupedMessages } from 'src/components/ResumeView/useGroupedMessages';

import styles from './resume-chat-pinned-messages.less';

export const isEmployerMessages = (group: GroupedMessages): boolean => group.participantType === UserType.EmployerUser;

const ResumeChatPinnedMessages: React.FC<
    {
        pinnedChatMessages: MessageItem[];
    } & React.PropsWithChildren
> = ({ pinnedChatMessages }) => {
    const groupedMessages: GroupedMessages[] = useGroupedMessages(pinnedChatMessages);
    return (
        <div className={styles.pinnedMessages}>
            {groupedMessages.map((messagesGroup, index) => (
                <div key={index}>
                    <ChatMessage messagesGroup={messagesGroup} isEmployerMessages={isEmployerMessages(messagesGroup)} />
                    {!isEmployerMessages(messagesGroup) && <VSpacing key={index} base={2} />}
                </div>
            ))}
        </div>
    );
};

export default ResumeChatPinnedMessages;
