import { ComponentType, useMemo } from 'react';
import { useDispatch } from 'react-redux';

import { useSelector } from '@hh.ru/front-static-app';
import { Card, Title, useBreakpoint } from '@hh.ru/magritte-ui';

import FormatMoney from 'src/components/FormatMoney';
import translation from 'src/components/translation';
import { useActionLinkAnalytics } from 'src/models/applicant/resume/analytics/provider';
import { getAvatarLetters } from 'src/models/applicant/resume/blocks/position/lib';
import {
    selectApplicantResumeFirstName,
    selectApplicantResumeGender,
    selectApplicantResumeLastName,
    selectApplicantResumePhotoUrl,
    selectApplicantResumeSalary,
    selectApplicantResumeTitle,
} from 'src/models/applicant/resume/blocks/position/selectors';
import { openResumeEditorModal } from 'src/models/applicant/resume/editor/store/actions/toggleModal';
import ResumePositionEditor from 'src/pages/ResumeView/redesign/blocks/position/Editor';
import EditButton from 'src/pages/ResumeView/redesign/common/EditButton';
import EditIcon from 'src/pages/ResumeView/redesign/common/EditIcon';

import Avatar from 'src/pages/ResumeView/redesign/blocks/position/Card/Avatar';

import styles from './styles.less';

const TrlKeys = {
    edit: 'applicant.resume.position.edit.button',
    salaryNotExists: 'applicant.resume.salary.notexists',
};
const BLOCK_NAME = 'position';

const ApplicantResumePosition: ComponentType = translation(({ trls }) => {
    const dispatch = useDispatch();
    const analytics = useActionLinkAnalytics();
    const { isMobile } = useBreakpoint();

    const title = useSelector(selectApplicantResumeTitle);
    const gender = useSelector(selectApplicantResumeGender);
    const salary = useSelector(selectApplicantResumeSalary);
    const photoUrl = useSelector(selectApplicantResumePhotoUrl);
    const lastName = useSelector(selectApplicantResumeLastName);
    const firstName = useSelector(selectApplicantResumeFirstName);

    const letters = getAvatarLetters(lastName, firstName);

    const handleEdit = () => {
        analytics.resumeEditButtonClick(BLOCK_NAME);
        void dispatch(openResumeEditorModal(BLOCK_NAME));
    };

    const description = useMemo(() => {
        if (!salary?.amount) {
            return <span>{trls[TrlKeys.salaryNotExists]}</span>;
        }
        return <FormatMoney currency={salary.currency}>{salary.amount}</FormatMoney>;
    }, [salary, trls]);

    return (
        <>
            <Card stretched padding={isMobile ? 0 : 24} borderRadius={24} borderWidth={!isMobile ? 'default' : 'none'}>
                <div className={styles.card}>
                    <Avatar path={photoUrl} letters={letters} gender={gender} />

                    <div className={styles.cardTitle}>
                        <Title
                            Element="h1"
                            maxLines={2}
                            size={isMobile ? 'medium' : 'large'}
                            alignment={isMobile ? 'center' : 'left'}
                            description={description}
                            descriptionMaxLines={1}
                            dataQaDescription="resume-block-salary"
                            dataQaTitle="resume-block-title-position"
                        >
                            {title}
                        </Title>
                    </div>
                    <EditIcon onClick={handleEdit} />
                    <EditButton onClick={handleEdit}>{trls[TrlKeys.edit]}</EditButton>
                </div>
            </Card>
            <ResumePositionEditor />
        </>
    );
});

export default ApplicantResumePosition;
