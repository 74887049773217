import { useEffect } from 'react';

import resumeDeleteAlertPrimaryButtonClick from '@hh.ru/analytics-js-events/build/xhh/applicant/resume/resume_delete_alert_primary_button_click';
import resumeDeleteAlertSecondaryButtonClick from '@hh.ru/analytics-js-events/build/xhh/applicant/resume/resume_delete_alert_secondary_button_click';
import alertScreenShown from '@hh.ru/analytics-js-events/build/xhh/common/design_system/alert_screen_shown';
import {
    Modal,
    Action,
    Text,
    Button,
    BottomSheet,
    useBreakpoint,
    BottomSheetFooter,
    VSpacing,
    ActionBar,
} from '@hh.ru/magritte-ui';
import { CrossOutlinedSize24 } from '@hh.ru/magritte-ui/icon';

import translation from 'src/components/translation';
import { useResumeDeleteModalContext } from 'src/models/applicant/resume/delete/resumeDeleteModal';
import { BLOCKED } from 'src/utils/constants/resumeStatuses';

import { DeleteAlert } from 'src/components/ResumeDeleteModal/DeleteAlert';

import styles from './styles.less';

const TrlKeys = {
    modalHeader: 'resumeList.delete.modal.header',
    modalDescription: 'resumeList.delete.modal.description',
    hideButton: 'resumeList.delete.modal.button.hide',
    deleteButton: 'resumeList.delete.modal.button.delete',
};

export const ResumeDeleteModal = translation(({ trls }) => {
    const { isMobile } = useBreakpoint();
    const { visible, closeModal, resume, hideResume, deleteResume } = useResumeDeleteModalContext();

    const resumeBlocked = resume?._attributes?.status === BLOCKED;
    const resumeId = resume?._attributes?.id || '';
    const showAler = visible && resumeBlocked;
    const showModal = visible && !resumeBlocked;

    const handleDelete = () => {
        resumeDeleteAlertSecondaryButtonClick({
            hhtmSource: 'resume_delete_alert',
            buttonText: trls[TrlKeys.deleteButton],
            resumeId,
        });
        deleteResume();
    };
    const handleHiden = () => {
        resumeDeleteAlertPrimaryButtonClick({
            hhtmSource: 'resume_delete_alert',
            buttonText: trls[TrlKeys.hideButton],
            resumeId,
        });
        hideResume();
    };

    useEffect(() => {
        if (visible) {
            alertScreenShown({
                hhtmSource: resumeBlocked ? 'blocked_resume_delete_alert' : 'resume_delete_alert',
            });
        }
    }, [visible, resumeBlocked]);

    if (isMobile) {
        return (
            <>
                <BottomSheet
                    visible={showModal}
                    onClose={closeModal}
                    footer={
                        <BottomSheetFooter>
                            <Button
                                stretched
                                mode="primary"
                                style="accent"
                                onClick={handleHiden}
                                data-qa="resume-hide-confirm"
                            >
                                {trls[TrlKeys.hideButton]}
                            </Button>

                            <Button
                                stretched
                                mode="secondary"
                                style="negative"
                                data-qa="resume-delete-confirm"
                                onClick={handleDelete}
                            >
                                {trls[TrlKeys.deleteButton]}
                            </Button>
                        </BottomSheetFooter>
                    }
                >
                    <div className={styles.navbar}>
                        <Action
                            data-qa="resume-delete-close"
                            style="neutral"
                            mode="secondary"
                            icon={CrossOutlinedSize24}
                            onClick={closeModal}
                        />
                    </div>
                    <Text typography="title-4-semibold" data-qa="resume-delete-title">
                        {trls[TrlKeys.modalHeader]}
                    </Text>
                    <VSpacing default={4} />
                    <Text typography="label-2-regular" style="secondary" data-qa="resume-delete-content">
                        {trls[TrlKeys.modalDescription]}
                    </Text>
                    <VSpacing default={32} />
                </BottomSheet>
                <DeleteAlert resumeId={resumeId} visible={showAler} onClose={closeModal} onDelete={deleteResume} />
            </>
        );
    }

    return (
        <>
            <Modal
                title={trls[TrlKeys.modalHeader]}
                visible={showModal}
                onClose={closeModal}
                actions={
                    <Action
                        data-qa="resume-delete-close"
                        style="neutral"
                        mode="secondary"
                        icon={CrossOutlinedSize24}
                        onClick={closeModal}
                    />
                }
                footer={
                    <ActionBar
                        primaryActions={
                            <Button mode="primary" style="accent" onClick={handleHiden} data-qa="resume-hide-confirm">
                                {trls[TrlKeys.hideButton]}
                            </Button>
                        }
                        secondaryActions={
                            <Button
                                mode="secondary"
                                style="negative"
                                data-qa="resume-delete-confirm"
                                onClick={handleDelete}
                            >
                                {trls[TrlKeys.deleteButton]}
                            </Button>
                        }
                    />
                }
            >
                <Text typography="paragraph-1-regular" data-qa="resume-delete-content">
                    {trls[TrlKeys.modalDescription]}
                </Text>
            </Modal>
            <DeleteAlert resumeId={resumeId} visible={showAler} onClose={closeModal} onDelete={deleteResume} />
        </>
    );
});
