import { useRef } from 'react';

import { TrashOutlinedSize16 } from '@hh.ru/magritte-ui/icon';
import Button from 'bloko/blocks/button';
import { HoverTip } from 'bloko/blocks/drop';

import BlokoIconReplaceContainer from 'src/components/BlokoIconReplaceContainer';
import ResumeDelete from 'src/components/ResumeDelete';
import translation from 'src/components/translation';
import { useIsClient } from 'src/hooks/useIsClient';

const ButtonDelete = ({ hash, trls }) => {
    const isClient = useIsClient();
    const activatorRef = useRef(null);

    return (
        <ResumeDelete
            hash={hash}
            from="resume"
            render={(handleClick) => (
                <HoverTip
                    host={isClient ? document.body : null}
                    render={() => trls[ButtonDelete.trls.delete]}
                    activatorRef={activatorRef}
                >
                    <Button
                        data-qa="resume-delete"
                        icon={
                            <BlokoIconReplaceContainer>
                                <TrashOutlinedSize16 initialColor="secondary" />
                            </BlokoIconReplaceContainer>
                        }
                        onClick={handleClick}
                        aria-label={trls[ButtonDelete.trls.delete]}
                        innerRef={activatorRef}
                    />
                </HoverTip>
            )}
        />
    );
};

ButtonDelete.trls = {
    delete: 'topbuttons.resume.delete',
};

export default translation(ButtonDelete);
