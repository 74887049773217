import { useCallback, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';

import { TranslatedComponent } from '@hh.ru/front-static-app';
import { Loader } from '@hh.ru/magritte-ui';

import { useHiringManagerCommentButton } from 'src/components/HiringManager/hooks/useHiringManagerCommentButton';
import ResumeComments from 'src/components/ResumeComments';
import Add from 'src/components/ResumeComments/Add';
import { useFetchComments } from 'src/components/ResumeComments/hooks/useFetchComments';
import ResumeBlockButtonWrapper from 'src/components/ResumeView/ResumeBlockButtonWrapper';
import translation from 'src/components/translation';
import { useSelector } from 'src/hooks/useSelector';
import { addCommentToUser } from 'src/models/employer/resume/commentsByUser';
import { addComment, ResumeComment, Status } from 'src/models/employer/resume/resumeComments';
import HiringManagerComment from 'src/pages/ResumeView/components/View/ResumeSidebar/Comments/HiringManagerComment';
import ResumeBlock from 'src/pages/ResumeView/employerRedesign/components/ResumeBlock';

const TrlKeys = {
    title: 'resume.comments',
};

interface ResumeCommentsWrapperProps {
    inSidebar?: boolean;
}

const ResumeCommentsWrapper: TranslatedComponent<ResumeCommentsWrapperProps> = ({ trls, inSidebar = false }) => {
    const dispatch = useDispatch();
    const commentFormRef = useRef<HTMLDivElement>(null);
    const resumeHash = useSelector((state) => state.resume?.hash);
    const hiringManagerCommentButton = useHiringManagerCommentButton();
    const userId = useSelector((state) => Number(state.resume?.user));
    const { comments } = useFetchComments(userId);
    const { vacancyId, t: topicId } = useSelector((state) => state.router.location.query);
    const hasComments = !!comments.items.length;
    const [editId, setEditId] = useState<string | number | null>(null);

    const trlTitle = trls[TrlKeys.title];

    const add = useCallback(
        (data: ResumeComment) => {
            dispatch([addComment(data), addCommentToUser({ userId, commentId: data.id })]);
        },
        [dispatch, userId]
    );

    const isFetchingComments =
        (comments.status === Status.Fetching || comments.status === Status.Initial) && !hasComments;

    const addComponentProps = {
        editId,
        setEditId,
        user: userId,
        hide: isFetchingComments && !hasComments,
        onAdd: add,
        topicId,
        vacancyId,
        resumeHash,
        inline: true,
        renderFormTo: commentFormRef.current,
    };

    const buttons = (
        <ResumeBlockButtonWrapper>
            {hiringManagerCommentButton ? (
                <HiringManagerComment />
            ) : (
                <Add {...addComponentProps} inline={false} renderFormTo={null} />
            )}
        </ResumeBlockButtonWrapper>
    );

    const loader = <Loader initial="tertiary" size={24} />;
    const content = hasComments ? (
        <ResumeComments
            hasAddButton={false}
            isResumePage
            user={userId}
            topicId={topicId}
            resumeHash={resumeHash}
            vacancyId={vacancyId}
            commentsFormRef={inSidebar ? commentFormRef : undefined}
        />
    ) : null;

    return (
        <ResumeBlock
            isTitleInside={inSidebar}
            title={trlTitle}
            right={hasComments && inSidebar && <Add {...addComponentProps} />}
            data-qa="resume-comments"
            buttons={!isFetchingComments && (!hasComments || !inSidebar) && buttons}
        >
            {isFetchingComments ? loader : content}
        </ResumeBlock>
    );
};

export default translation(ResumeCommentsWrapper);
