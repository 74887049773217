import { aboutConfig } from 'src/models/applicant/resume/blocks/about/edit/config';
import { businessTripReadinessConfig } from 'src/models/applicant/resume/blocks/additional/edit/config/businessTripReadiness';
import { employmentConfig } from 'src/models/applicant/resume/blocks/additional/edit/config/employment';
import { travelTimeConfig } from 'src/models/applicant/resume/blocks/additional/edit/config/travelTime';
import { workScheduleConfig } from 'src/models/applicant/resume/blocks/additional/edit/config/workSchedule';
import { additionalEducationConfig } from 'src/models/applicant/resume/blocks/additionalEducation/editor/config';
import { attestationEducationConfig } from 'src/models/applicant/resume/blocks/attestationEducation/editor/config';
import { certificateConfig } from 'src/models/applicant/resume/blocks/certificates/editor/config';
import { contactsConfig } from 'src/models/applicant/resume/blocks/contacts/editor/config';
import { EducationLevelConfig } from 'src/models/applicant/resume/blocks/educationLevel/editor/config';
import { elementaryEducationConfig } from 'src/models/applicant/resume/blocks/elementaryEducation/editor/config';
import { experienceConfig } from 'src/models/applicant/resume/blocks/experience/editor/config';
import { keySkillsConfig } from 'src/models/applicant/resume/blocks/keySkills/editor/config';
import { langConfig } from 'src/models/applicant/resume/blocks/languages/config';
import { portfolioConfig } from 'src/models/applicant/resume/blocks/portfolio/edit/config';
import { positionConfig } from 'src/models/applicant/resume/blocks/position/edit/config';
import { primaryEducationConfig } from 'src/models/applicant/resume/blocks/primaryEducation/editor/config';
import { recommendationConfig } from 'src/models/applicant/resume/blocks/recommendation/editor/config';
import { visibilityConfig } from 'src/models/applicant/resume/blocks/visibility/editor/config';

export const editorConfigMap = {
    lang: langConfig,
    about: aboutConfig,
    position: positionConfig,
    contacts: contactsConfig,
    portfolio: portfolioConfig,
    keySkills: keySkillsConfig,
    visibility: visibilityConfig,
    experience: experienceConfig,
    travelTime: travelTimeConfig,
    employment: employmentConfig,
    certificate: certificateConfig,
    workSchedule: workScheduleConfig,
    recommendation: recommendationConfig,
    additionalEducation: additionalEducationConfig,
    attestationEducation: attestationEducationConfig,
    businessTripReadiness: businessTripReadinessConfig,
    educationLevel: EducationLevelConfig,
    elementaryEducation: elementaryEducationConfig,
    primaryEducation: primaryEducationConfig,
};
