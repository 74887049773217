import { Link, Text, VSpacingContainer } from '@hh.ru/magritte-ui';
import ConversionNumber from 'bloko/blocks/conversion';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import translation from 'src/components/translation';
import { useSelector } from 'src/hooks/useSelector';
import ResumeBlock from 'src/pages/ResumeView/employerRedesign/components/ResumeBlock';

const TrlKeys = {
    title: 'employer.resume.testSolution.title',
    testSolution: 'employer.resume.testSolution.result',
    one: 'score.1',
    some: 'score.2',
    many: 'score.5',
    zero: 'score.5',
};

interface VacancyTestsProps {
    inSidebar?: boolean;
}

const VacancyTests: TranslatedComponent<VacancyTestsProps> = ({ trls, inSidebar = false }) => {
    const topicList = useSelector((state) => state.employerNegotiations.topicList);
    const current = topicList?.find((topic) => topic.currentVacancy);
    if (!current?.testSolution) {
        return null;
    }

    return (
        <ResumeBlock isTitleInside={inSidebar} title={trls[TrlKeys.title]}>
            <VSpacingContainer default={4}>
                <Text typography="label-3-regular" style="secondary">
                    {current.vacancyName}
                </Text>
                <Text Element="span" typography="label-3-regular">
                    {trls[TrlKeys.testSolution]}
                    <Link
                        inline
                        typography="label-3-regular"
                        target="_blank"
                        href={`/employer/vacancy_response/test?topicId=${current.id}`}
                    >
                        <ConversionNumber
                            value={parseInt(current.testSolution.score, 10)}
                            one={trls[TrlKeys.one]}
                            some={trls[TrlKeys.some]}
                            many={trls[TrlKeys.many]}
                            zero={trls[TrlKeys.zero]}
                        />
                    </Link>
                </Text>
            </VSpacingContainer>
        </ResumeBlock>
    );
};

export default translation(VacancyTests);
