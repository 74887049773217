import { useDispatch } from 'react-redux';

import { useSelector, translation } from '@hh.ru/front-static-app';

import { useActionLinkAnalytics } from 'src/models/applicant/resume/analytics/provider';
import { selectResumeTravelTime } from 'src/models/applicant/resume/blocks/additional/selectors';
import { openResumeEditorModal } from 'src/models/applicant/resume/editor/store/actions/toggleModal';
import Cell from 'src/pages/ResumeView/redesign/blocks/additional/Card/Cell';

const TrlKeys = {
    title: 'resume.editor.travelTime.title',
    type: {
        any: 'resume.editor.travelTime.any',
        less_than_hour: 'resume.editor.travelTime.less_than_hour',
        from_hour_to_one_and_half: 'resume.editor.travelTime.from_hour_to_one_and_half',
    },
};
const BLOCK_NAME = 'travelTime';

const TravelTime = translation(({ trls }) => {
    const dispatch = useDispatch();
    const analytics = useActionLinkAnalytics();

    const travelTime = useSelector(selectResumeTravelTime);

    const handleEdit = () => {
        analytics.resumeEditButtonClick(BLOCK_NAME);
        void dispatch(openResumeEditorModal(BLOCK_NAME));
    };

    return <Cell title={trls[TrlKeys.title]} text={trls[TrlKeys.type[travelTime]]} onClick={handleEdit} />;
});

export default TravelTime;
