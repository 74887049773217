import { useCallback, useMemo } from 'react';

import { translation, useSelector } from '@hh.ru/front-static-app';
import { Link, Text, CellText, useBreakpoint, VSpacingContainer, Button, Banner } from '@hh.ru/magritte-ui';
import { usePush, SPALink } from '@hh.ru/redux-spa-middleware';

import {
    selectApplicantResumeEducationLevel,
    selectEducationLevels,
} from 'src/models/applicant/resume/blocks/educationLevel/selectors';
import { selectApplicantResumeElementaryEducation } from 'src/models/applicant/resume/blocks/elementaryEducation/selectors';
import { selectApplicantResumePrimaryEducation } from 'src/models/applicant/resume/blocks/primaryEducation/selectors';
import { isEmpty } from 'src/models/applicant/resume/lib/common/empty';
import BlockLabel from 'src/pages/ResumeView/redesign/common/BlockLabel';
import DesktopCard from 'src/pages/ResumeView/redesign/common/ListCard/DesktopCard';
import MobileCard from 'src/pages/ResumeView/redesign/common/ListCard/MobileCard';
import { ListCardItems } from 'src/pages/ResumeView/redesign/common/ListCard/types';

const noopHandler = () => {};

const TrlKeys = {
    label: 'applicant.resume.education.label',
    listTitle: 'applicant.resume.education.mobile.list.title',
    level: 'profile.resume.field.education.level',
    hintPrefix: 'applicant.resume.education.profile.hint.prefix',
    hintLink: 'applicant.resume.education.profile.hint.link',
    hintBanner: 'applicant.resume.education.profile.hint.banner',
    hintBannerLink: 'applicant.resume.education.profile.hint.banner.link',
};

export const ApplicantProfileEducation = translation(({ trls }) => {
    const push = usePush();
    const { isMobile } = useBreakpoint();
    const educationLevel = useSelector(selectApplicantResumeEducationLevel);
    const educationLevelText = useSelector(
        (state) => state.educationLevels.find((item) => item.value === educationLevel)?.text
    );

    const elementaryEducation = useSelector(selectApplicantResumeElementaryEducation);
    const primaryEducation = useSelector(selectApplicantResumePrimaryEducation);
    const educationLevels = useSelector(selectEducationLevels);

    const generateItems = useCallback(
        (showResultOrOrganization = true) => {
            const _items: ListCardItems = [];

            const hasElementary = !isEmpty(elementaryEducation);
            const hasPrimary = !isEmpty(primaryEducation);

            if (!hasElementary && !hasPrimary) {
                _items.push({
                    id: 'educationLevel',
                    cellContent: (
                        <>
                            <CellText type="subtitle" style="secondary">
                                {trls[TrlKeys.level]}
                            </CellText>
                            <CellText type="title">{educationLevelText}</CellText>
                        </>
                    ),
                });
            } else {
                if (hasPrimary) {
                    primaryEducation.forEach((item) => {
                        const educationLevelId = item.educationLevel;
                        const itemEducationLevelText = educationLevelId
                            ? educationLevels.find((level) => level.value === educationLevelId)?.text ||
                              educationLevelText
                            : educationLevelText;

                        const yearAndLevel = [];
                        if (item.year) {
                            yearAndLevel.push(item.year);
                        }
                        if (itemEducationLevelText) {
                            yearAndLevel.push(itemEducationLevelText);
                        }

                        _items.push({
                            id: item.id,
                            cellContent: (
                                <>
                                    <CellText type="title">{item.name}</CellText>
                                    {showResultOrOrganization && (item.result || item.organization) && (
                                        <CellText type="subtitle" style="secondary">
                                            {item.result || item.organization}
                                        </CellText>
                                    )}
                                    {yearAndLevel.length > 0 && (
                                        <CellText type="subtitle" style="tertiary">
                                            {yearAndLevel.join(' · ')}
                                        </CellText>
                                    )}
                                </>
                            ),
                        });
                    });
                }

                if (hasElementary) {
                    elementaryEducation.forEach((item) => {
                        _items.push({
                            id: item.id,
                            cellContent: (
                                <>
                                    <CellText type="title">{item.name}</CellText>
                                    <CellText type="subtitle" style="tertiary">
                                        {'Среднее'}
                                    </CellText>
                                </>
                            ),
                        });
                    });
                }
            }

            return _items;
        },
        [educationLevelText, educationLevels, elementaryEducation, primaryEducation, trls]
    );

    const items = useMemo(() => generateItems(false), [generateItems]);

    const extendedItems = useMemo(() => generateItems(true), [generateItems]);

    const customHeader = (
        <VSpacingContainer default={8}>
            <BlockLabel>{trls[TrlKeys.label]}</BlockLabel>
            {!isMobile && (
                <Text typography="paragraph-2-regular" style="secondary">
                    {trls[TrlKeys.hintPrefix]}{' '}
                    <Link inline to="/profile/me" typography="paragraph-2-regular" Element={SPALink}>
                        {trls[TrlKeys.hintLink]}
                    </Link>
                </Text>
            )}
        </VSpacingContainer>
    );

    return (
        <>
            {isMobile ? (
                <MobileCard
                    onAdd={noopHandler}
                    onEdit={noopHandler}
                    items={items}
                    extendedItems={extendedItems}
                    label={trls[TrlKeys.label]}
                    listTitle={trls[TrlKeys.listTitle]}
                    cardAlign="center"
                    editable={false}
                    customHeader={customHeader}
                    extraBottomSheetContent={
                        <Banner
                            style="secondary"
                            content={
                                <Text typography="paragraph-2-regular" style="primary">
                                    {trls[TrlKeys.hintBanner]}
                                </Text>
                            }
                            showClose={false}
                            buttonsOrientation="vertical"
                            buttonBase={
                                <Button
                                    stretched
                                    mode="primary"
                                    size="small"
                                    style="accent"
                                    onClick={() => push('/profile/me')}
                                >
                                    {trls[TrlKeys.hintBannerLink]}
                                </Button>
                            }
                        />
                    }
                />
            ) : (
                <DesktopCard
                    onAdd={noopHandler}
                    onEdit={noopHandler}
                    items={extendedItems}
                    label={trls[TrlKeys.label]}
                    cardAlign="center"
                    editable={false}
                    customHeader={customHeader}
                />
            )}
        </>
    );
});
