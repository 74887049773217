import { translation, useSelector } from '@hh.ru/front-static-app';
import { Input, Placeholder, Suggest, TextArea, useBreakpoint, Cell, CellText } from '@hh.ru/magritte-ui';

import {
    UniversityDto,
    useUniversityDataProvider,
} from 'src/models/applicant/resume/blocks/primaryEducation/autosuggest';
import { selectResumeEditorPrimaryEducationIndex } from 'src/models/applicant/resume/blocks/primaryEducation/editor/selectors';
import { useResumeEditorField, useResumeEditorListField } from 'src/models/applicant/resume/editor/form/change';
import { useTranslatedErrorMap } from 'src/models/applicant/resume/editor/form/errors';

const TrlKeys = {
    titlePlaceholder: 'resume.editor.primaryEducation.university.title.placeholder',
    errorTitle: 'resume.editor.suggest.errors.placeholder',
    resetButton: 'resume.editor.suggest.errors.button',
    errorDescription: 'resume.editor.suggest.errors.description',
    commonErrors: {
        UNKNOWN: 'resume.editor.field.error.unknown',
        REQUIRED: 'resume.editor.field.error.required',
        FACULTY_WITHOUT_UNIVERSITY: 'resume.editor.field.error.required',
        NOT_MATCH_REGEXP: 'resume.editor.field.error.regexp',
        LENGTH_GREATER_THAN_MAXIMUM: {
            one: 'resume.editor.field.error.max-length.one',
            some: 'resume.editor.field.error.max-length.some',
            many: 'resume.editor.field.error.max-length.many',
        },
        EARLY_THAN_MINIMUM: 'resume.editor.field.year.error.min-date',
        LATER_THAN_MAXIMUM: 'resume.editor.field.year.error.max-date',
        VALUE_LESS_THAN_MINIMUM: 'resume.editor.field.year.error.min-date',
        VALUE_GREATER_THAN_MAXIMUM: 'resume.editor.field.year.error.max-date',
    },
};

export const UniversitySuggest = translation(({ trls }) => {
    const { isMobile } = useBreakpoint();
    const index = useSelector(selectResumeEditorPrimaryEducationIndex);
    const errorsMap = useTranslatedErrorMap(TrlKeys.commonErrors, trls);
    const universityProvider = useUniversityDataProvider();
    const {
        value,
        errorMessage: nameErrorMessage,
        invalid: nameInvalid,
    } = useResumeEditorListField('primaryEducation', index, 'name', errorsMap);
    const { errorMessage: universityIdErrorMessage, invalid: universityIdInvalid } = useResumeEditorListField(
        'primaryEducation',
        index,
        'universityId',
        errorsMap
    );

    const errorMessage = nameInvalid ? nameErrorMessage : universityIdErrorMessage;
    const invalid = nameInvalid || universityIdInvalid;

    const { value: wholeField, onChange } = useResumeEditorField('primaryEducation');

    const handleChange = (_value: string, isValueFromList: boolean) => {
        if (!isValueFromList) {
            onChange({ ...wholeField, name: _value, universityId: null, facultyId: null, organization: '' });
        }
    };

    const handleSelect = (_value: string, item?: UniversityDto) => {
        if (!item) {
            return true;
        }

        if (item.id === wholeField.universityId) {
            onChange({ ...wholeField, name: _value, universityId: item.id });
        } else {
            onChange({ ...wholeField, name: _value, universityId: item.id, organization: '', facultyId: null });
        }

        return true;
    };

    return (
        <Suggest
            onChange={handleChange}
            dataProvider={universityProvider}
            onSelectValidator={handleSelect}
            navigationBarProps={{
                title: trls[TrlKeys.titlePlaceholder],
            }}
            renderTrigger={
                isMobile
                    ? ({ props }) => {
                          const { value, onFocus } = props;

                          return (
                              <TextArea
                                  layout="hug"
                                  placeholder={trls[TrlKeys.titlePlaceholder]}
                                  errorMessage={errorMessage}
                                  invalid={invalid}
                                  value={value || ''}
                                  onFocus={onFocus}
                                  onChange={() => undefined}
                              />
                          );
                      }
                    : undefined
            }
            input={{
                component: Input,
                props: {
                    value,
                    size: 'medium',
                    invalid,
                    clearable: false,
                    placeholder: trls[TrlKeys.titlePlaceholder],
                    errorMessage,
                },
            }}
            trls={{
                resetButton: trls[TrlKeys.resetButton],
            }}
            errorPlaceholder={
                <Placeholder title={trls[TrlKeys.errorTitle]} description={trls[TrlKeys.errorDescription]} />
            }
            renderItem={({ data: item }) => {
                if (!item) {
                    return null;
                }
                return (
                    <Cell>
                        <CellText>{item.acronym}</CellText>
                        <CellText type="subtitle">
                            {item.synonyms}
                            {!!item.synonyms && ', '}
                            {item.text}
                        </CellText>
                    </Cell>
                );
            }}
        ></Suggest>
    );
});
