import { fetcher } from 'src/utils/fetcher';

import { AreaDTO } from 'src/models/applicant/resume/api/area/dto';

declare global {
    interface FetcherGetApi {
        '/autosuggest/multiprefix/v2': {
            queryParams: { q: string; d: string };
            response: { items: AreaDTO[] };
        };
    }
}

export const getAreaLeafs = (query: string, siteLanguage: string): Promise<AreaDTO[]> =>
    fetcher
        .get('/autosuggest/multiprefix/v2', { params: { q: query, d: `area_leafs_${siteLanguage}` } })
        .then(({ items }) => items);
