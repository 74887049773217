import { ComponentType, useCallback, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';

import { useSelector } from '@hh.ru/front-static-app';
import {
    Card,
    Link,
    Text,
    VSpacing,
    useBreakpoint,
    ScrollableItem,
    VSpacingContainer,
    ScrollableContainer,
} from '@hh.ru/magritte-ui';
import { ChevronDownOutlinedSize16, ChevronUpOutlinedSize16, PlusOutlinedSize24 } from '@hh.ru/magritte-ui/icon';

import CdnImg from 'src/components/CdnImg';
import translation from 'src/components/translation';
import { useActionLinkAnalytics } from 'src/models/applicant/resume/analytics/provider';
import { selectApplicantPortfolioUrls } from 'src/models/applicant/resume/blocks/portfolio/selectors';
import { openResumeEditorModal } from 'src/models/applicant/resume/editor/store/actions/toggleModal';
import { PhotoUrl } from 'src/models/resume/resumeCommon.types';
import { ResumePortfolioEditor } from 'src/pages/ResumeView/redesign/blocks/portfolio/Edit';
import BlockLabel from 'src/pages/ResumeView/redesign/common/BlockLabel';

import ViewPortfolioModal from 'src/pages/ResumeView/redesign/blocks/portfolio/Card/PortfolioModal';
import { useCalculateMaxItems } from 'src/pages/ResumeView/redesign/blocks/portfolio/Card/hooks';

import styles from './styles.less';

const TrlKeys = {
    add: 'applicant.resume.portfolio.add',
    label: 'applicant.resume.portfolio.label',
    empty: 'applicant.resume.portfolio.empty',
    show: 'applicant.resume.portfolio.btn.expanded.show',
    hide: 'applicant.resume.portfolio.btn.expanded.hide',
};
const BLOCK_NAME = 'portfolio';

const ApplicantResumePortfolio: ComponentType = translation(({ trls }) => {
    const dispatch = useDispatch();
    const { isMobile } = useBreakpoint();
    const analytics = useActionLinkAnalytics();

    const [expanded, setExpanded] = useState(false);
    const [openedProfile, openProfile] = useState<PhotoUrl | null>(null);

    const portfolioUrls = useSelector(selectApplicantPortfolioUrls);

    const maxItems = useCalculateMaxItems(portfolioUrls.length);

    const empty = portfolioUrls.length === 0;
    const showBtn = portfolioUrls.length > maxItems;
    const itemToShow = expanded ? portfolioUrls.length : maxItems;

    const handleAdd = useCallback(() => {
        analytics.resumeEditButtonClick(BLOCK_NAME);
        void dispatch(openResumeEditorModal(BLOCK_NAME));
    }, [dispatch, analytics]);

    const handleExpanded = () => setExpanded(!expanded);
    const handleClosePortfolioView = () => openProfile(null);

    const cards = useMemo(() => {
        return (
            <>
                <ScrollableItem>
                    <Card stretched verticalStretched onClick={handleAdd} borderRadius={24} borderWidth="default">
                        <div className={styles.addCard}>
                            <PlusOutlinedSize24 initialColor="accent" />
                            <VSpacing default={16} />
                            <Text style="accent" typography="subtitle-2-semibold">
                                {trls[TrlKeys.add]}
                            </Text>
                        </div>
                    </Card>
                </ScrollableItem>
                {portfolioUrls.slice(0, itemToShow).map((portfolio) => {
                    const { title, big, id } = portfolio;
                    const isEmptyTitle = title == null;
                    return (
                        <ScrollableItem key={id}>
                            <Card
                                padding={12}
                                stretched={!isMobile}
                                borderWidth="default"
                                borderRadius={24}
                                verticalStretched
                                onClick={() => openProfile(portfolio)}
                            >
                                <div className={styles.card}>
                                    <CdnImg className={styles.artifact} path={big || ''} />
                                    <VSpacing default={24} />
                                    <Text
                                        maxLines={2}
                                        style={isEmptyTitle ? 'secondary' : 'primary'}
                                        typography="label-4-regular"
                                    >
                                        {isEmptyTitle ? trls[TrlKeys.empty] : title}
                                    </Text>
                                </div>
                            </Card>
                        </ScrollableItem>
                    );
                })}
            </>
        );
    }, [handleAdd, trls, portfolioUrls, itemToShow, isMobile, openProfile]);

    return (
        <>
            {!empty && (
                <VSpacingContainer default={12}>
                    <BlockLabel>{trls[TrlKeys.label]}</BlockLabel>
                    {isMobile ? (
                        <ScrollableContainer>
                            <div className={styles.imageTable}>{cards}</div>
                        </ScrollableContainer>
                    ) : (
                        <>
                            <div className={styles.imageTable}>{cards}</div>
                            {showBtn && (
                                <Link
                                    onClick={handleExpanded}
                                    iconRight={expanded ? <ChevronUpOutlinedSize16 /> : <ChevronDownOutlinedSize16 />}
                                >
                                    {expanded ? trls[TrlKeys.hide] : trls[TrlKeys.show]}
                                </Link>
                            )}
                        </>
                    )}
                </VSpacingContainer>
            )}
            <ResumePortfolioEditor />
            <ViewPortfolioModal portfolio={openedProfile} onClose={handleClosePortfolioView} />
        </>
    );
});

export default ApplicantResumePortfolio;
