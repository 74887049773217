import { useMemo } from 'react';

import { LangTrls } from '@hh.ru/front-static-app';

import { buildDataProvider, getAutosuggestItems, SuggestUrl } from 'src/utils/suggest/buildDataProvider';

const TrlKeys = {
    notFound: 'resume.editor.key.skills.recommend.not.found',
    popular: 'resume.editor.key.skills.popular.lebel',
};

export const useKeyskillsSuggestDataProvider = (trls: LangTrls) => {
    return useMemo(
        () =>
            buildDataProvider(
                SuggestUrl.Auto,
                'key_skill',
                (skills) => {
                    if (!skills || skills.length === 0) {
                        return [
                            {
                                type: 'delimiter',
                                text: trls[TrlKeys.notFound],
                            },
                        ];
                    }

                    return [
                        {
                            type: 'delimiter',
                            text: trls[TrlKeys.popular],
                        },
                        {
                            type: 'chips',
                            items: skills.map((skill) => ({
                                value: skill.text,
                                data: skill,
                            })),
                        },
                    ];
                },
                getAutosuggestItems,
                {
                    debounceTimeout: 300,
                    minCharsCount: 2,
                }
            ),
        [trls]
    );
};
