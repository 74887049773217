import { Dispatch, MutableRefObject, SetStateAction, useEffect, useRef } from 'react';

import resumeKeySkillVerificationHintButtonClick from '@hh.ru/analytics-js-events/build/xhh/common/verification_key_skill/resume_key_skill_verification_hint_button_click';
import resumeKeySkillVerificationElementShown from '@hh.ru/analytics-js-events/build/xhh/common/verification_key_skill/resume_key_skill_verification_hint_element_shown';
import { Tooltip, Button } from '@hh.ru/magritte-ui';
import BlokoButton, { ButtonAppearance, ButtonKind, ButtonType } from 'bloko/blocks/button';
import { InfoPlacement } from 'bloko/blocks/drop/Info';
import VSpacing from 'bloko/blocks/vSpacing';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';
import { format } from 'bloko/common/trl';

import Notices, { MarkAsViewedEvent } from 'Modules/Notices';
import Infotip from 'src/components/Infotip';
import translation from 'src/components/translation';

interface OnboardingSkillsToolTipProps {
    children: JSX.Element;
    attemptedAtText: string;
    methodName: string;
    setIsTipVisible: Dispatch<SetStateAction<boolean>>;
    skillVerificationTip: string;
    keySkill: string;
    resumeId: string;
    practiceVerified?: boolean;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    activatorRef: MutableRefObject<any>;
    isMagritte?: boolean;
}

const TrlKeys = {
    firstShownTipText1: 'resume.skills.verification.firstShownTip.text1',
    firstShownTipText2: 'resume.skills.verification.firstShownTip.text2',
    firstShownTipSubmit: 'resume.skills.verification.firstShownTip.submit',
};

const OnboardingSkillsToolTip: TranslatedComponent<OnboardingSkillsToolTipProps> = ({
    children,
    trls,
    methodName,
    attemptedAtText,
    skillVerificationTip,
    setIsTipVisible,
    keySkill,
    resumeId,
    practiceVerified = false,
    activatorRef,
    isMagritte,
}) => {
    const onboardingToolTipRef = useRef<HTMLParagraphElement>(null);

    useEffect(() => {
        if (onboardingToolTipRef.current) {
            resumeKeySkillVerificationElementShown(onboardingToolTipRef.current, {
                keySkill,
                resumeId: Number(resumeId),
                isOnboarding: true,
                practiceVerified,
            });
        }
    }, [keySkill, practiceVerified, resumeId]);

    const closeFirstTipHandler = () => {
        if (onboardingToolTipRef.current && resumeId && keySkill) {
            resumeKeySkillVerificationHintButtonClick({
                keySkill,
                resumeId: Number(resumeId),
                practiceVerified,
            });
        }
        setTimeout(() => {
            setIsTipVisible(false);
            Notices.markAsViewed(skillVerificationTip);
        }, 0);
    };

    const content = (
        <>
            <p>
                {format(trls[TrlKeys.firstShownTipText1], {
                    '{0}': methodName,
                })}{' '}
                {attemptedAtText}
            </p>
            <VSpacing base={2} />
            <p>{trls[TrlKeys.firstShownTipText2]}</p>
            <VSpacing base={4} />
            {isMagritte ? (
                <Button mode="primary" style="contrast" size="small" onClick={closeFirstTipHandler}>
                    {trls[TrlKeys.firstShownTipSubmit]}
                </Button>
            ) : (
                <BlokoButton
                    kind={ButtonKind.Inversed}
                    type={ButtonType.Button}
                    appearance={ButtonAppearance.Outlined}
                    onClick={closeFirstTipHandler}
                >
                    {trls[TrlKeys.firstShownTipSubmit]}
                </BlokoButton>
            )}
        </>
    );

    if (isMagritte) {
        return (
            <>
                {children}
                <div ref={onboardingToolTipRef}>
                    <Tooltip
                        visible
                        showClose
                        activatorRef={activatorRef}
                        placement="bottom-center"
                        closeByClickOutside={false}
                        onClose={closeFirstTipHandler}
                    >
                        {content}
                    </Tooltip>
                </div>
            </>
        );
    }

    return (
        <div ref={onboardingToolTipRef}>
            <Infotip
                show
                name={skillVerificationTip}
                placement={InfoPlacement.BottomStart}
                closeByClickOutside={false}
                onClose={closeFirstTipHandler}
                markAsViewedEvent={MarkAsViewedEvent.OnHide}
                render={() => content}
                activatorRef={activatorRef}
            >
                {children}
            </Infotip>
        </div>
    );
};

export default translation(OnboardingSkillsToolTip);
