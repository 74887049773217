import { useDispatch } from 'react-redux';

import { translation } from '@hh.ru/front-static-app';
import { Text, Card, Cell, CellText, HSpacingContainer, Switch, VSpacing, useBreakpoint } from '@hh.ru/magritte-ui';
import { ChevronRightOutlinedSize24 } from '@hh.ru/magritte-ui/icon';

import { saveResumeProftest } from 'src/models/applicant/resume/blocks/proftest/actions';
import { isEmpty } from 'src/models/applicant/resume/lib/common/empty';
import { Proftest } from 'src/models/resume/resumeCommon.types';

const TrlKeys = {
    title: 'applicant.resume.proftest.title',
    take: 'applicant.resume.proftest.take',
    subtitle: 'applicant.resume.proftest.subtitle',
    headhunter: 'applicant.resume.proftest.headhunter',
    result: {
        title: 'applicant.resume.proftest.result.title',
        description: 'applicant.resume.proftest.result.description',
    },
};

interface Props {
    proftest: Proftest;
    onOpenMorePage: VoidFunction;
}

const ProftestCard = translation<Props>(({ trls, proftest, onOpenMorePage }) => {
    const dispatch = useDispatch();
    const { isMobile } = useBreakpoint();

    const handleOpenResult = () => {
        window.open(proftest.link, '_blank');
    };

    const handleToggleAttached = () => {
        void dispatch(saveResumeProftest(!proftest.attached));
    };

    if (isEmpty(proftest)) {
        return null;
    }

    if (proftest.resultsReceived) {
        return (
            <Card stretched padding={isMobile ? 16 : 24} borderRadius={isMobile ? 16 : 24} borderWidth="default">
                <Cell
                    align="top"
                    right={
                        <HSpacingContainer default={8}>
                            <Text style="secondary" typography="label-2-regular">
                                {trls[TrlKeys.result.title]}
                            </Text>
                            <ChevronRightOutlinedSize24 initialColor="secondary" />
                        </HSpacingContainer>
                    }
                    onClick={handleOpenResult}
                >
                    <CellText>{trls[TrlKeys.title]}</CellText>
                    <CellText type="subtitle">{trls[TrlKeys.headhunter]}</CellText>
                </Cell>
                <VSpacing default={16} />
                <Cell right={<Switch checked={proftest.attached} onClick={handleToggleAttached} />}>
                    <Text style="secondary" typography={isMobile ? 'label-3-regular' : 'label-2-regular'}>
                        {trls[TrlKeys.result.description]}
                    </Text>
                </Cell>
            </Card>
        );
    }

    return (
        <Card
            stretched
            padding={isMobile ? 16 : 24}
            onClick={onOpenMorePage}
            borderWidth="default"
            borderRadius={isMobile ? 16 : 24}
        >
            <Cell
                align="top"
                right={
                    <HSpacingContainer default={8}>
                        <Text style="secondary" typography="label-2-regular">
                            {trls[TrlKeys.take]}
                        </Text>
                        <ChevronRightOutlinedSize24 initialColor="secondary" />
                    </HSpacingContainer>
                }
            >
                <CellText>{trls[TrlKeys.title]}</CellText>
                {!isMobile && <CellText type="subtitle">{trls[TrlKeys.subtitle]}</CellText>}
            </Cell>
            {isMobile && (
                <>
                    <VSpacing default={12} />
                    <Cell>
                        <CellText type="subtitle">{trls[TrlKeys.subtitle]}</CellText>
                    </Cell>
                </>
            )}
        </Card>
    );
});

export default ProftestCard;
