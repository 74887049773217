import { PureComponent, Fragment } from 'react';
import { connect } from 'react-redux';

import { SPALink } from '@hh.ru/redux-spa-middleware';
import Column, { ColumnsRow } from 'bloko/blocks/column';
import { H2 } from 'bloko/blocks/header';
import BlokoLink, { LinkKind } from 'bloko/blocks/link';
import Text, { TextImportance } from 'bloko/blocks/text';
import VSpacing from 'bloko/blocks/vSpacing';

import ConversionDateInterval from 'src/components/Conversion/DateInterval';
import HOCExperiment from 'src/components/HOCExperiment';
import { selectorMColumnSize } from 'src/components/HiringManager/utils/selectorMColumnSize';
import Markup from 'src/components/Markup';
import AsyncHighlighter from 'src/components/Markup/AsyncHighlighter';
import translation from 'src/components/translation';
import { EXPERIENCE } from 'src/utils/constants/resumeHiddenFields';
import { EDIT } from 'src/utils/constants/resumePermission';

import ResumeBlock from 'src/pages/ResumeView/components/View/ResumeBlock';
import ResumeEditLink from 'src/pages/ResumeView/components/View/ResumeEditLink';
import ResumeExperienceIndustries from 'src/pages/ResumeView/components/View/ResumeExperienceIndustries';
import ResumeExperienceInterval from 'src/pages/ResumeView/components/View/ResumeExperienceInterval';

class ResumeExperience extends PureComponent {
    renderTitle = () => {
        const {
            trls,
            totalExperience: { years, months },
            mColumnSize: { mMain },
        } = this.props;
        return (
            <ColumnsRow>
                <Column xs="4" s="8" m={mMain} l="12">
                    <div className="resume-block__title-text-wrapper">
                        <H2 lite>
                            <span className="resume-block__title-text resume-block__title-text_sub">
                                {`${trls[ResumeExperience.trls.title]} `}
                                <ConversionDateInterval years={years} months={months} />
                            </span>
                        </H2>{' '}
                        <ResumeEditLink block="experience" data-qa="resume-block-experience-edit" />
                    </div>
                    <VSpacing base={4} />
                </Column>
            </ColumnsRow>
        );
    };

    renderCompanyInfo = ({
        companyName,
        companyState,
        employerId,
        companyAreaId,
        companyAreaTitle,
        companyUrl,
        prettyUrl,
    }) => {
        const { trls, isHidden, canEdit } = this.props;
        if (isHidden && !canEdit) {
            return (
                <div className="resume-hidden-field resume-hidden-field_experience">
                    <Text Element="span" importance={TextImportance.Tertiary}>
                        {trls[ResumeExperience.trls.isHidden]}
                    </Text>
                </div>
            );
        }
        if (!companyName) {
            return null;
        }
        return (
            <Fragment>
                <Text strong>
                    {companyState === 'APPROVED' ? (
                        <BlokoLink Element={SPALink} to={`/employer/${employerId}`} kind={LinkKind.Tertiary}>
                            <AsyncHighlighter>{companyName}</AsyncHighlighter>
                        </BlokoLink>
                    ) : (
                        <AsyncHighlighter>{companyName}</AsyncHighlighter>
                    )}
                </Text>
                {(companyAreaId || prettyUrl) && (
                    <p>
                        {companyAreaId && companyAreaTitle}
                        {companyAreaId && prettyUrl && ', '}
                        {prettyUrl && (
                            <BlokoLink className="resume__experience-url" href={companyUrl} kind={LinkKind.Tertiary}>
                                {prettyUrl}
                            </BlokoLink>
                        )}
                    </p>
                )}
            </Fragment>
        );
    };

    renderPosition = (position, description) => {
        if (!position && !description) {
            return null;
        }

        return (
            <Fragment>
                {position && (
                    <Text strong data-qa="resume-block-experience-position">
                        <AsyncHighlighter>{position}</AsyncHighlighter>
                    </Text>
                )}
                {description && (
                    <div data-qa="resume-block-experience-description">
                        <Markup>{description}</Markup>
                    </div>
                )}
            </Fragment>
        );
    };

    render() {
        const { experience, mColumnSize } = this.props;
        const { mMain } = mColumnSize;
        if (!experience.length) {
            return null;
        }
        const firstColumnMWidth = 2;

        return (
            <ResumeBlock title={this.renderTitle()} data-qa="resume-block-experience">
                {experience.map((workPlace) => {
                    const { id, startDate, endDate, interval, position, description, industries } = workPlace;

                    return (
                        <div key={id} className="resume-block-item-gap">
                            <ColumnsRow>
                                <Column xs="4" s="2" m={`${firstColumnMWidth}`} l="2">
                                    <ResumeExperienceInterval
                                        startDate={startDate}
                                        endDate={endDate}
                                        interval={interval}
                                    />
                                </Column>
                                <Column xs="4" s="6" m={`${+mMain - firstColumnMWidth}`} l="10">
                                    <div className="resume-block-container">
                                        {this.renderCompanyInfo(workPlace)}
                                        <ResumeExperienceIndustries industries={industries} />
                                        {this.renderPosition(position, description)}
                                    </div>
                                </Column>
                            </ColumnsRow>
                        </div>
                    );
                })}
            </ResumeBlock>
        );
    }
}

ResumeExperience.trls = {
    title: 'rb.title.experience',
    isHidden: 'resume.hiddenFields.experience',
};

export default connect((state) => ({
    isHidden: state.resume?.hiddenFields.includes(EXPERIENCE),
    canEdit: state.resume?.permission === EDIT,
    totalExperience: state.resume?.totalExperience,
    experience: state.resume?.experience.value,
    mColumnSize: selectorMColumnSize(state),
}))(translation(HOCExperiment(ResumeExperience)));
