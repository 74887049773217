import { ComponentType } from 'react';

import { translation } from '@hh.ru/front-static-app';
import { Input } from '@hh.ru/magritte-ui';

import { useResumeContactEditor } from 'src/models/applicant/resume/blocks/contacts/editor/change';
import { PersonalSiteTypes } from 'src/models/applicant/resume/blocks/contacts/types';
import { ErrorsMap } from 'src/models/applicant/resume/editor/form/errors';
import { FIELD_NAME } from 'src/pages/ResumeView/redesign/blocks/contacts/Editor/PersonalSite/const';
import EditorField from 'src/pages/ResumeView/redesign/common/Form/EditorField';

const PLACEHOLDERS = {
    skype: '',
    freelance: 'https://fl.ru/users/',
    personal: 'https://',
    livejournal: 'https://',
    linkedin: 'https://linkedin.com/',
    moi_krug: 'https://career.habr.ru/',
    facebook: 'https://facebook.com/',
};

const TrlKeys = {
    freelance: 'resume.editor.personalSite.field.freelance',
    personal: 'resume.editor.personalSite.field.personal',
    livejournal: 'resume.editor.personalSite.field.livejournal',
    linkedin: 'resume.editor.personalSite.field.linkedin',
    moi_krug: 'resume.editor.personalSite.field.moi_krug',
    facebook: 'resume.editor.personalSite.field.facebook',
    skype: 'resume.editor.personalSite.field.skype',
};

interface Props {
    type: PersonalSiteTypes;
    fieldIndex: number;
    errorsMap: ErrorsMap;
}

const PersonalSiteField: ComponentType<Props> = translation(({ trls, type, fieldIndex, errorsMap }) => {
    const { value, onChange, invalid, errorMessage } = useResumeContactEditor(FIELD_NAME, fieldIndex, 'url', errorsMap);

    return (
        <EditorField htmlFor={type} label={trls[TrlKeys[type]]}>
            <Input
                data-qa={`resume__personalsite__${type}-input`}
                placeholder={PLACEHOLDERS[type]}
                size="medium"
                value={value}
                onChange={onChange}
                invalid={invalid}
                errorMessage={errorMessage}
            />
        </EditorField>
    );
});

export default PersonalSiteField;
