import { DefaultRootState } from 'react-redux';

import { ResumeEmployersListType } from 'src/models/applicant/resume/api';

export const getSelectResumeEmployersListSelector =
    (type: ResumeEmployersListType) =>
    ({ resumeEmployersList }: DefaultRootState) =>
        resumeEmployersList[type];

export const selectResumeEmployerListFetching = ({ resumeEmployersList }: DefaultRootState) =>
    resumeEmployersList.fetching;

export const selectResumeEmployerListSaving = ({ resumeEmployersList }: DefaultRootState) => resumeEmployersList.saving;

export const selectResumeEmployerListSearching = ({ resumeEmployersList }: DefaultRootState) =>
    resumeEmployersList.searching;

export const selectResumeEmployerListStep = ({ resumeEmployersList }: DefaultRootState) =>
    resumeEmployersList.step || 'accessType';

export const selectResumeEmployerListSubStep = ({ resumeEmployersList }: DefaultRootState) =>
    resumeEmployersList.subStep;

export const selectResumeEmployerEditState = ({ resumeEmployersList }: DefaultRootState) =>
    resumeEmployersList.editState;

export const selectResumeEmployerSearchList = ({ resumeEmployersList }: DefaultRootState) =>
    resumeEmployersList.searchList;
