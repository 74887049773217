import { useEffect } from 'react';

import Analytics from '@hh.ru/analytics-js';
import Button, { ButtonAppearance } from 'bloko/blocks/button';
import Gap from 'bloko/blocks/gap';
import Link from 'bloko/blocks/link';
import NotificationHeading from 'bloko/blocks/notificationManager/NotificationHeading';

import translation from 'src/components/translation';

const TalantixResumeImportedNotificationPromo = ({ trls }) => {
    useEffect(() => {
        Analytics.sendEvent('talantix', 'import_promo', 'show_notification');
    }, []);

    return (
        <>
            <NotificationHeading>{trls[TalantixResumeImportedNotificationPromo.trls.title]}</NotificationHeading>
            <div>{trls[TalantixResumeImportedNotificationPromo.trls.text]}</div>
            <Gap top>
                <Button
                    data-qa="resume-talantix-import-button__success-link"
                    appearance={ButtonAppearance.Outlined}
                    target="_blank"
                    Element={Link}
                    href="https://talantix.ru/ats/extension"
                    onClick={() => {
                        Analytics.sendEvent('talantix', 'import_promo', 'click_go_to_talantix');
                    }}
                >
                    {trls[TalantixResumeImportedNotificationPromo.trls.button]}
                </Button>
            </Gap>
        </>
    );
};

TalantixResumeImportedNotificationPromo.trls = {
    title: 'talantix.import.notificationPromo.title',
    text: 'talantix.import.notificationPromo.text',
    button: 'talantix.import.notificationPromo.button',
};

export default translation(TalantixResumeImportedNotificationPromo);
