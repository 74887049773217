import { useEffect } from 'react';
import { connect } from 'react-redux';

import { ExclamationTriangleOutlinedSize16 } from '@hh.ru/magritte-ui/icon';
import Column from 'bloko/blocks/column';
import Gap from 'bloko/blocks/gap';
import { H2 } from 'bloko/blocks/header';
import { IconLink } from 'bloko/blocks/icon';
import Link, { LinkAppearance } from 'bloko/blocks/link';
import BlokoTranslateGuard from 'bloko/blocks/translateGuard';
import VSpacing from 'bloko/blocks/vSpacing';

import BlokoIconReplaceContainer from 'src/components/BlokoIconReplaceContainer';
import Markup from 'src/components/Markup';
import ResumeComplaint from 'src/components/ResumeComplaint';
import { useResumeComplaint } from 'src/components/ResumeComplaint/useResumeComplaint';
import translation from 'src/components/translation';
import { useToggleState } from 'src/hooks/useToggleState';
import { useGetMColumnSize } from 'src/pages/ResumeView/hooks/useGetMColumnSize';

const AMOUNT_TO_SLICE = 220;

const ResponseLetter = ({ letter, letterEmpty, resumeHash, trls, shouldBeRendered }) => {
    const [full, toggleFull, setFull] = useToggleState(false);
    const { onOpen, ...resumeComplaintPros } = useResumeComplaint({ resumeHash });
    const { mMain } = useGetMColumnSize();

    useEffect(() => {
        if (!letterEmpty && letter.length > AMOUNT_TO_SLICE) {
            setFull(false);
        } else {
            setFull(true);
        }
    }, [letter, setFull, letterEmpty]);

    if (letterEmpty || !shouldBeRendered) {
        return null;
    }

    const letterPart = full ? letter : letter.slice(0, AMOUNT_TO_SLICE);

    return (
        <div className="resume-block">
            <Column xs="4" s="8" m={mMain} l="12">
                <div className="resume-block-container">
                    <H2 lite>
                        <span className="resume-block__title-text resume-block__title-text_sub">
                            {trls[ResponseLetter.trls.title]}
                        </span>
                        <IconLink
                            type="button"
                            Element="button"
                            data-qa="resume-complaint-button"
                            onClick={onOpen}
                            title={trls[ResponseLetter.trls.complaintButtonTitle]}
                        >
                            <BlokoIconReplaceContainer>
                                <ExclamationTriangleOutlinedSize16 initialColor="secondary" />
                            </BlokoIconReplaceContainer>
                        </IconLink>
                        <ResumeComplaint {...resumeComplaintPros} />
                    </H2>
                    <VSpacing base={4} />
                    <div className="resume-block-letter">
                        <div className="resume-block-letter__show">
                            <Markup>{letterPart}</Markup>
                            {letterPart !== letter && (
                                <>
                                    <BlokoTranslateGuard>…</BlokoTranslateGuard>
                                    <div className="resume-block-letter__show">
                                        <Link appearance={LinkAppearance.Pseudo} onClick={toggleFull}>
                                            {trls[ResponseLetter.trls.show]}
                                        </Link>
                                    </div>
                                </>
                            )}
                            {letterPart.length > AMOUNT_TO_SLICE && (
                                <div className="resume-block-letter__hide">
                                    <Link appearance={LinkAppearance.Pseudo} onClick={toggleFull}>
                                        {trls[ResponseLetter.trls.hide]}
                                    </Link>
                                </div>
                            )}
                        </div>
                        <div className="resume-block-letter__full-text">
                            <Markup>{letter}</Markup>
                        </div>
                    </div>
                </div>
            </Column>
            <Gap bottom />
            <div className="resume-block__separator resume-block__separator_gap-top" />
        </div>
    );
};

ResponseLetter.trls = {
    title: 'employer.resume.responseLetter',
    show: 'employer.resume.responseLetter.show',
    hide: 'employer.resume.responseLetter.hide',
    complaintButtonTitle: 'employer.vacancyResponses.responseComplaint',
};

export default connect((state) => ({
    resumeHash: state.resume?.hash,
    letterEmpty: !(state.resume?.hash && state.employerNegotiations.responseLetters?.[state.resume?.hash]),
    shouldBeRendered: state.printVersion.followLetter !== false,
    letter: state.resume?.hash && state.employerNegotiations.responseLetters?.[state.resume?.hash],
}))(translation(ResponseLetter));
