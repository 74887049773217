import { ReactNode, useState } from 'react';

import {
    Action,
    BottomSheet,
    Button,
    Card,
    Cell,
    CellText,
    NavigationBar,
    VSpacing,
    VSpacingContainer,
} from '@hh.ru/magritte-ui';
import { ChevronRightOutlinedSize24, CrossOutlinedSize24, PlusOutlinedSize24 } from '@hh.ru/magritte-ui/icon';

import translation from 'src/components/translation';
import { useActionLinkAnalytics } from 'src/models/applicant/resume/analytics/provider';
import BlockLabel from 'src/pages/ResumeView/redesign/common/BlockLabel';

import { ListCardItems } from 'src/pages/ResumeView/redesign/common/ListCard/types';

import styles from './styles.less';

const TrlKeys = {
    add: 'applicant.resume.block.add',
    label: 'applicant.resume.recommendation.label',
    showAll: 'applicant.resume.block.btn.showAll',
    listTitle: 'applicant.resume.recommendation.mobile.list.title',
    listDescription: 'applicant.resume.recommendation.mobile.list.description',
};

interface Props {
    label: ReactNode;
    listTitle: string;
    listDescription?: ReactNode;
    blockName?: string;
    items: ListCardItems;
    extendedItems?: ListCardItems;
    extraBottomSheetContent?: ReactNode;
    onAdd: () => void;
    onEdit: (index: number) => void;
    banner?: ReactNode;
    additionalCard?: ReactNode;
    cardAlign?: 'center' | 'top';
    buttons?: ReactNode;
    editable?: boolean;
    customHeader?: ReactNode;
}

const MAX_ITEMS = 2;

const MobileCard = translation<Props>(
    ({
        trls,
        onAdd,
        onEdit,
        items,
        label,
        listTitle,
        listDescription,
        banner,
        additionalCard,
        cardAlign = 'top',
        buttons,
        editable = true,
        customHeader,
        extraBottomSheetContent,
        extendedItems,
        blockName,
    }) => {
        const analytics = useActionLinkAnalytics();

        const [visibility, setVisibility] = useState(false);

        const handleClose = () => setVisibility(false);
        const handleShowAll = () => {
            if (blockName) {
                analytics.resumeVewAllButtonClick(blockName);
            }
            setVisibility(true);
        };

        return (
            <div>
                <div className={styles.header}>{customHeader || <BlockLabel>{label}</BlockLabel>}</div>
                <Card stretched padding={12} borderRadius={24} borderWidth="default">
                    <div className={styles.card}>
                        {banner && banner}
                        {items.slice(0, MAX_ITEMS).map((item) => {
                            if ('cellContent' in item) {
                                return (
                                    <Cell key={item.id} avatar={item.avatar} align={cardAlign}>
                                        {item.cellContent}
                                    </Cell>
                                );
                            }
                            return (
                                <Cell key={item.id} avatar={item.avatar} align={cardAlign}>
                                    <CellText>{item.title}</CellText>
                                    <CellText type="subtitle">{item.subtitle}</CellText>
                                    <CellText type="description">{item.description}</CellText>
                                </Cell>
                            );
                        })}
                    </div>

                    <VSpacing default={12} />
                    {buttons || (
                        <Button stretched size="medium" style="neutral" mode="secondary" onClick={handleShowAll}>
                            {trls[TrlKeys.showAll]}
                        </Button>
                    )}
                </Card>
                <BottomSheet
                    header={
                        <NavigationBar
                            title={listTitle}
                            right={<Action icon={CrossOutlinedSize24} onClick={handleClose} />}
                            size="large"
                            subtitle={listDescription}
                            showDivider="always"
                        />
                    }
                    visible={visibility}
                    onClose={handleClose}
                    height="full-screen"
                    showDivider="with-scroll"
                >
                    <>
                        {additionalCard && (
                            <>
                                {additionalCard}
                                <VSpacing default={12} />
                            </>
                        )}
                        <VSpacingContainer default={16}>
                            {extraBottomSheetContent}
                            {(extendedItems ?? items).map((item, index) => (
                                <Card
                                    stretched
                                    borderWidth="default"
                                    hoverEnabled={editable}
                                    pressEnabled={editable}
                                    key={item.id}
                                    padding={16}
                                    borderRadius={16}
                                    onClick={editable ? () => onEdit(index) : undefined}
                                >
                                    <Cell
                                        avatar={item.avatar}
                                        align={cardAlign}
                                        right={editable ? <ChevronRightOutlinedSize24 /> : undefined}
                                    >
                                        {'cellContent' in item ? (
                                            item.cellContent
                                        ) : (
                                            <>
                                                <CellText>{item.title}</CellText>
                                                <CellText type="subtitle">{item.subtitle}</CellText>
                                                <CellText type="description">{item.description}</CellText>
                                            </>
                                        )}
                                    </Cell>
                                </Card>
                            ))}
                            {editable && (
                                <Button
                                    stretched
                                    size="medium"
                                    style="accent"
                                    mode="secondary"
                                    onClick={onAdd}
                                    icon={<PlusOutlinedSize24 />}
                                >
                                    {trls[TrlKeys.add]}
                                </Button>
                            )}
                            <VSpacing default={24} />
                        </VSpacingContainer>
                    </>
                </BottomSheet>
            </div>
        );
    }
);

export default MobileCard;
