import { DefaultRootState } from 'react-redux';

import { FieldStatuses } from 'src/models/resume/resume.types';

const EMPTY_FIELD_STATUSES = {
    redFields: [],
    greenFields: [],
    leftToFillFields: [],
};

export const selectResumeFieldStatuses = ({ applicantResume }: DefaultRootState): FieldStatuses =>
    applicantResume.fieldStatuses || EMPTY_FIELD_STATUSES;

export const selectResumeFieldPercent = ({ applicantResume }: DefaultRootState): number =>
    applicantResume._attributes.percent || 0;
