import { FC, PropsWithChildren, ReactNode } from 'react';

import { Tag } from '@hh.ru/magritte-ui';
import { IconWrapperComponentSize16 } from '@hh.ru/magritte-ui-icon/iconTypes';
import { TagProps, TagSize, TagStyle } from '@hh.ru/magritte-ui-tag/types';

import DenseText from 'src/components/DenseText';

// Кастом, портфель на выпиливание – https://jira.hh.ru/browse/PORTFOLIO-38390

const TextWrapper: FC<PropsWithChildren<{ style: TagStyle; size: TagSize }>> = ({ style, size, children }) => {
    if (style === 'neutral') {
        return (
            <DenseText typography={size === 'large' ? 'subtitle-1-semibold' : 'label-3-regular'}>{children}</DenseText>
        );
    }

    return <>{children}</>;
};

type TagWithContrastTextProps = Omit<TagProps<ReactNode, IconWrapperComponentSize16>, 'hideLabel'>;

const TagWithContrastText: FC<PropsWithChildren<TagWithContrastTextProps>> = ({
    children,
    style = 'neutral',
    size = 'medium',
    ...otherProps
}) => {
    return (
        <Tag style={style} size={size} {...otherProps}>
            <TextWrapper style={style} size={size}>
                {children}
            </TextWrapper>
        </Tag>
    );
};

export default TagWithContrastText;
