import { useCallback } from 'react';
import { useDispatch } from 'react-redux';

import { useSelector } from '@hh.ru/front-static-app';

import { setResumeEditorFieldValue } from 'src/models/applicant/resume/editor/store/slice';
import { Experience } from 'src/models/resume/resumeCommon.types';

import { getNewExperience } from 'src/models/applicant/resume/blocks/experience/editor/lib';
import { selectResumeEditorExperience } from 'src/models/applicant/resume/blocks/experience/editor/selectors';

export const useEditorExperienceChanged = () => {
    const dispatch = useDispatch();
    const experience = useSelector(selectResumeEditorExperience);

    return useCallback(
        (experienceFields: Partial<Experience>) => {
            dispatch(
                setResumeEditorFieldValue({
                    experience: { ...getNewExperience(), ...experience, ...experienceFields },
                })
            );
        },
        [dispatch, experience]
    );
};
