import { ComponentType, useState } from 'react';

import { translation } from '@hh.ru/front-static-app';
import { Card, VSpacingContainer } from '@hh.ru/magritte-ui';

import { useActionLinkAnalytics } from 'src/models/applicant/resume/analytics/provider';
import AdditionalModal from 'src/pages/ResumeView/redesign/blocks/additional/BottomSheet';
import BusinessTripReadinessEditor from 'src/pages/ResumeView/redesign/blocks/additional/Edit/BusinessTripReadinessEditor';
import EmploymentEditor from 'src/pages/ResumeView/redesign/blocks/additional/Edit/EmploymentEditor';
import TravelTimeEditor from 'src/pages/ResumeView/redesign/blocks/additional/Edit/TravelTimeEditor';
import WorkScheduleEditor from 'src/pages/ResumeView/redesign/blocks/additional/Edit/WorkScheduleEditor';
import BlockLabel from 'src/pages/ResumeView/redesign/common/BlockLabel';
import EditButton from 'src/pages/ResumeView/redesign/common/EditButton';

import BusinessTripReadiness from 'src/pages/ResumeView/redesign/blocks/additional/Card/BusinessTripReadiness';
import Employment from 'src/pages/ResumeView/redesign/blocks/additional/Card/Employment';
import TravelTime from 'src/pages/ResumeView/redesign/blocks/additional/Card/TravelTime';
import WorkSchedule from 'src/pages/ResumeView/redesign/blocks/additional/Card/WorkSchedule';

import styles from './styles.less';

const TrlKeys = {
    label: 'resume.editor.additional.title',
    button: 'resume.editor.additional.button',
};

const ApplicantResumeAdditional: ComponentType = translation(({ trls }) => {
    const analytics = useActionLinkAnalytics();
    const [editorMobileVisible, setEditorMobileVisible] = useState(false);

    const handleOpenEditorMobile = () => {
        analytics.resumeVewAllButtonClick('additionalInfo');
        setEditorMobileVisible(true);
    };
    const handleCloseEditorMobile = () => setEditorMobileVisible(false);

    return (
        <>
            <VSpacingContainer default={12}>
                <BlockLabel>{trls[TrlKeys.label]}</BlockLabel>
                <Card stretched padding={12} borderRadius={24} borderWidth="default">
                    <VSpacingContainer default={24}>
                        <div className={styles.cardContent}>
                            <WorkSchedule />
                            <Employment />
                            <TravelTime />
                            <BusinessTripReadiness />
                        </div>
                        <EditButton onClick={handleOpenEditorMobile}>{trls[TrlKeys.button]}</EditButton>
                    </VSpacingContainer>
                </Card>
            </VSpacingContainer>
            <AdditionalModal visible={editorMobileVisible} onClose={handleCloseEditorMobile} />
            <WorkScheduleEditor />
            <EmploymentEditor />
            <TravelTimeEditor />
            <BusinessTripReadinessEditor />
        </>
    );
});

export default ApplicantResumeAdditional;
