import { Fragment, useMemo, useState } from 'react';
import classnames from 'classnames';

import { ElementShownAnchor } from '@hh.ru/analytics-js';
import resumeKeySkillsLevelButtonClick from '@hh.ru/analytics-js-events/build/xhh/employer/experiments/PORTFOLIO-23117/resume_key_skills_level_button_click';
import resumeKeySkillsLevelElementShown from '@hh.ru/analytics-js-events/build/xhh/employer/experiments/PORTFOLIO-23117/resume_key_skills_level_element_shown';
import Button from 'bloko/blocks/button';
import BlokoLink from 'bloko/blocks/link';
import TagList from 'bloko/blocks/tagList';
import Text, { TextImportance, TextSize } from 'bloko/blocks/text';
import VSpacing from 'bloko/blocks/vSpacing';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';
import { formatToReactComponent } from 'bloko/common/trl';

import translation from 'src/components/translation';
import { useSelectorNonNullable, useSelector } from 'src/hooks/useSelector';
import type { KeySkill } from 'src/models/resume/resumeCommon.types';
import { VerifiedSkillCategory } from 'src/models/resume/resumeVerifiedSkills.types';
import { SkillsLevelsKeys } from 'src/models/skills';
import { UserType } from 'src/models/userType';
import SkillTag from 'src/pages/ResumeView/components/View/SkillTag';
import { SKILLS_LEVELS_BY_RANK, SKILLS_LEVELS_ORDER } from 'src/utils/constants/skills';

import SkillLevelModal from 'src/pages/ResumeView/components/View/ResumeSkillLevels/SkillLevelModal';

import styles from './index.less';

const TrlKeys = {
    tip: 'skillLevels.tip',
    tipLink: 'skillLevels.tip.link',
    levels: {
        [SkillsLevelsKeys.NONE]: 'skillLevels.level.none',
        [SkillsLevelsKeys.BASIC]: 'skillLevels.level.basic',
        [SkillsLevelsKeys.MEDIUM]: 'skillLevels.level.medium',
        [SkillsLevelsKeys.ADVANCED]: 'skillLevels.level.advanced',
    },
};

interface ResumeSkillLevelsProps {
    skills: KeySkill[];
}

const ResumeSkillLevels: TranslatedComponent<ResumeSkillLevelsProps> = ({ trls, skills }) => {
    const [modalVisible, setModalVisible] = useState(false);
    const resume = useSelectorNonNullable((state) => state.resume);
    const resumeApplicantSkills = resume?.resumeApplicantSkills;

    const skillsByLevel = useMemo(() => {
        return skills.reduce<Partial<Record<SkillsLevelsKeys, KeySkill[]>>>((skillsByLevel, skill) => {
            const currentSkill = resumeApplicantSkills?.find(
                (vSkill) => vSkill.id === skill.id && vSkill.category === VerifiedSkillCategory.Skill
            );
            const skillLevel = SKILLS_LEVELS_BY_RANK[currentSkill?.level?.rank || 0];
            (skillsByLevel[skillLevel] ??= []).push(skill);

            return skillsByLevel;
        }, {});
    }, [resumeApplicantSkills, skills]);
    const levels = useMemo(
        () =>
            Object.keys(skillsByLevel).sort((a, b) => SKILLS_LEVELS_ORDER.indexOf(a) - SKILLS_LEVELS_ORDER.indexOf(b)),
        [skillsByLevel]
    );
    const isEmployer = useSelector((state) => state.userType === UserType.Employer);
    const showInfo = isEmployer && (levels.length > 1 || (levels.length === 1 && levels[0] !== SkillsLevelsKeys.NONE));
    const hideLevelTitle = levels.length === 1 && levels[0] === SkillsLevelsKeys.NONE;
    const analyticsParam = {
        baseKeySkillsCount: skillsByLevel[SkillsLevelsKeys.BASIC]?.length || 0,
        middleKeySkillsCount: skillsByLevel[SkillsLevelsKeys.MEDIUM]?.length || 0,
        advancedKeySkillsCount: skillsByLevel[SkillsLevelsKeys.ADVANCED]?.length || 0,
        resumeId: resume.id,
    };

    const handleToggleModal = () => {
        if (!modalVisible) {
            resumeKeySkillsLevelButtonClick({ ...analyticsParam });
        }
        setModalVisible((value) => !value);
    };

    return (
        <ElementShownAnchor fn={resumeKeySkillsLevelElementShown} {...analyticsParam}>
            {showInfo && (
                <>
                    <div className={classnames(styles.levelTip, 'noprint')}>
                        <Text size={TextSize.Medium}>
                            {formatToReactComponent(trls[TrlKeys.tip], {
                                '{link}': (
                                    <BlokoLink Element={Button} onClick={handleToggleModal}>
                                        {trls[TrlKeys.tipLink]}
                                    </BlokoLink>
                                ),
                            })}
                        </Text>
                    </div>
                    <VSpacing base={5} />
                    <SkillLevelModal visible={modalVisible} onClose={handleToggleModal} />
                </>
            )}
            {levels.map((level) => {
                return (
                    <Fragment key={level}>
                        {!hideLevelTitle && (
                            <>
                                <Text size={TextSize.Medium} importance={TextImportance.Secondary}>
                                    <div data-qa={`skill-level-title-${level}`} className={styles.levelTitle}>
                                        {trls[TrlKeys.levels[level]]}
                                    </div>
                                </Text>
                                <VSpacing base={3} />
                            </>
                        )}
                        <TagList
                            items={skillsByLevel[level]!.map((skill) => {
                                return <SkillTag key={skill.id} skill={skill} />;
                            })}
                        />
                        <VSpacing base={5} />
                    </Fragment>
                );
            })}
        </ElementShownAnchor>
    );
};

export default translation(ResumeSkillLevels);
