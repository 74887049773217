import { Fragment, useRef } from 'react';
import { connect } from 'react-redux';

import { QuestionCircleOutlinedSize16 } from '@hh.ru/magritte-ui/icon';
import Column from 'bloko/blocks/column';
import HoverTip, { TipPlacement } from 'bloko/blocks/drop/Tip/HoverTip';
import Text from 'bloko/blocks/text';

import BlokoIconReplaceContainer from 'src/components/BlokoIconReplaceContainer';
import AsyncHighlighter from 'src/components/Markup/AsyncHighlighter';
import translation from 'src/components/translation';
import { useGetMColumnSize } from 'src/pages/ResumeView/hooks/useGetMColumnSize';

import ResumeBlock from 'src/pages/ResumeView/components/View/ResumeBlock';
import ResumeEditLink from 'src/pages/ResumeView/components/View/ResumeEditLink';

const ResumeRecommendation = ({ recommendation, trls }) => {
    const { mMain } = useGetMColumnSize();
    const activatorRef = useRef(null);
    if (!recommendation || !recommendation.length) {
        return null;
    }
    return (
        <ResumeBlock
            title={trls[ResumeRecommendation.trls.title]}
            EditLink={
                <ResumeEditLink block="experience" field="recommendation" data-qa="resume-block-recommendation-edit" />
            }
            data-qa="resume-block-recommendation"
        >
            <Column xs="4" s="8" m={mMain} l="12">
                <div className="resume-block-container">
                    {recommendation.map((recommendationItem) => (
                        <Fragment key={recommendationItem.id}>
                            <Text strong data-qa="recommendation-item-title">
                                <AsyncHighlighter>{recommendationItem.organization}</AsyncHighlighter>
                            </Text>
                            <div>
                                <AsyncHighlighter>{recommendationItem.name}</AsyncHighlighter>
                                {recommendationItem.position && (
                                    <AsyncHighlighter>&nbsp;({recommendationItem.position})</AsyncHighlighter>
                                )}
                                <span className="noprint">
                                    &nbsp;
                                    <HoverTip
                                        placement={TipPlacement.Bottom}
                                        render={() => trls[ResumeRecommendation.trls.contactsHint]}
                                        activatorRef={activatorRef}
                                    >
                                        <span ref={activatorRef}>
                                            <BlokoIconReplaceContainer>
                                                <QuestionCircleOutlinedSize16 />
                                            </BlokoIconReplaceContainer>
                                        </span>
                                    </HoverTip>
                                </span>
                            </div>
                        </Fragment>
                    ))}
                </div>
            </Column>
        </ResumeBlock>
    );
};

ResumeRecommendation.trls = {
    title: 'rb.title.recommendation',
    contactsHint: 'resume.applicant.recommendations.contactsHint',
};

export default connect((state) => ({
    recommendation: state.resume?.recommendation.value,
}))(translation(ResumeRecommendation));
