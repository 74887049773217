import { translation, useSelector } from '@hh.ru/front-static-app';
import { Input, Placeholder, Suggest, TextArea, useBreakpoint } from '@hh.ru/magritte-ui';

import { FacultyDto } from 'src/models/applicant/resume/api/dictionaries';
import { useStaticFacultiesDataProvider } from 'src/models/applicant/resume/blocks/primaryEducation/autosuggest';
import { selectResumeEditorPrimaryEducationIndex } from 'src/models/applicant/resume/blocks/primaryEducation/editor/selectors';
import { useResumeEditorField, useResumeEditorListField } from 'src/models/applicant/resume/editor/form/change';
import { useTranslatedErrorMap } from 'src/models/applicant/resume/editor/form/errors';

const TrlKeys = {
    titlePlaceholder: 'resume.editor.primaryEducation.faculty.title.placeholder',
    errorTitle: 'resume.editor.suggest.errors.placeholder',
    resetButton: 'resume.editor.suggest.errors.button',
    errorDescription: 'resume.editor.suggest.errors.description',
    commonErrors: {
        UNKNOWN: 'resume.editor.field.error.unknown',
        REQUIRED: 'resume.editor.field.error.required',
        NOT_MATCH_REGEXP: 'resume.editor.field.error.regexp',
        LENGTH_GREATER_THAN_MAXIMUM: {
            one: 'resume.editor.field.error.max-length.one',
            some: 'resume.editor.field.error.max-length.some',
            many: 'resume.editor.field.error.max-length.many',
        },
        EARLY_THAN_MINIMUM: 'resume.editor.field.year.error.min-date',
        LATER_THAN_MAXIMUM: 'resume.editor.field.year.error.max-date',
        VALUE_LESS_THAN_MINIMUM: 'resume.editor.field.year.error.min-date',
        VALUE_GREATER_THAN_MAXIMUM: 'resume.editor.field.year.error.max-date',
    },
};

export const FacultySuggest = translation(({ trls }) => {
    const { isMobile } = useBreakpoint();
    const index = useSelector(selectResumeEditorPrimaryEducationIndex);
    const errorsMap = useTranslatedErrorMap(TrlKeys.commonErrors, trls);
    const { value: universityId } = useResumeEditorListField('primaryEducation', index, 'universityId', errorsMap);
    const facultyProvider = useStaticFacultiesDataProvider(universityId ? Number(universityId) : null);
    const { value, errorMessage, invalid } = useResumeEditorListField(
        'primaryEducation',
        index,
        'organization',
        errorsMap
    );

    const { value: wholeField, onChange } = useResumeEditorField('primaryEducation');

    const handleChange = (_value: string, isValueFromList: boolean) => {
        if (!isValueFromList) {
            onChange({ ...wholeField, organization: _value, facultyId: null });
        }
    };

    const handleSelect = (_value: string, item?: FacultyDto) => {
        if (!item) {
            return true;
        }

        onChange({ ...wholeField, organization: _value, facultyId: item.id });

        return true;
    };

    return (
        <Suggest
            onChange={handleChange}
            dataProvider={facultyProvider}
            onSelectValidator={handleSelect}
            navigationBarProps={{
                title: trls[TrlKeys.titlePlaceholder],
            }}
            renderTrigger={
                isMobile
                    ? ({ props }) => {
                          const { value, onFocus } = props;

                          return (
                              <TextArea
                                  layout="hug"
                                  placeholder={trls[TrlKeys.titlePlaceholder]}
                                  errorMessage={errorMessage}
                                  invalid={invalid}
                                  value={value || ''}
                                  onFocus={onFocus}
                                  onChange={() => undefined}
                              />
                          );
                      }
                    : undefined
            }
            inputValue={value}
            input={{
                component: Input,
                props: {
                    value,
                    size: 'medium',
                    invalid,
                    clearable: false,
                    placeholder: trls[TrlKeys.titlePlaceholder],
                    errorMessage,
                },
            }}
            trls={{
                resetButton: trls[TrlKeys.resetButton],
            }}
            errorPlaceholder={
                <Placeholder title={trls[TrlKeys.errorTitle]} description={trls[TrlKeys.errorDescription]} />
            }
        ></Suggest>
    );
});
