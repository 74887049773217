import { useEffect, useMemo, useState } from 'react';

import { useSelector } from '@hh.ru/front-static-app';
import {
    createDataProvider,
    createStaticDataFetcher,
    DataProvider,
    decorateWithResultProducer,
    DataProviderResult,
} from '@hh.ru/magritte-ui';

import formatter from 'src/components/Forms/formatter';
import { FacultyDto, getFacultiesByUniversityId } from 'src/models/applicant/resume/api/dictionaries';
import { buildDataProvider, getAutosuggestItems, SuggestUrl } from 'src/utils/suggest/buildDataProvider';

export interface UniversityDto {
    id: number;
    text: string;
    area: string;
    area_id: number;
    acronym: string;
    synonyms: string;
}

export interface SpecialityDto {
    id: number;
    text: string;
    parentId: number;
    originalId: number;
    qualification: string;
    qualificationId: number;
}

export const useUniversityDataProvider = (): DataProvider<UniversityDto> => {
    const lang = useSelector((state) => state.langs[0]);
    const dynamicUrlPart = `university_${lang}`;

    return useMemo(
        () =>
            buildDataProvider<UniversityDto>(
                SuggestUrl.Auto,
                dynamicUrlPart,
                (items) => {
                    if (!items) {
                        return [];
                    }

                    return items.map((item) => ({
                        type: 'cells',
                        items: [
                            {
                                data: item,
                                value: item.text,
                            },
                        ],
                    }));
                },
                getAutosuggestItems,
                {
                    minCharsCount: 2,
                }
            ),
        [dynamicUrlPart]
    );
};

export const useSpecialityDataProvider = (): DataProvider<SpecialityDto> => {
    const lang = useSelector((state) => state.langs[0]);
    const dynamicUrlPart = `specialty_${lang}`;

    return useMemo(
        () =>
            buildDataProvider<SpecialityDto>(SuggestUrl.Auto, dynamicUrlPart, formatter, getAutosuggestItems, {
                minCharsCount: 2,
            }),
        [dynamicUrlPart]
    );
};

interface FacultyItem extends FacultyDto {
    value: string;
}

export const useFaculties = (universityId?: number) => {
    const [faculties, setFaculties] = useState<FacultyItem[]>([]);

    useEffect(() => {
        if (!universityId) {
            setFaculties([]);
            return;
        }
        void getFacultiesByUniversityId(String(universityId)).then((items) => {
            setFaculties(items.map((item) => ({ ...item, value: String(item.id) })));
        });
    }, [universityId]);

    return faculties;
};

interface FacultyAutosuggestItem {
    id: number;
    text: string;
}

const formatterFaculties = (items: FacultyItem[] | null): DataProviderResult<FacultyAutosuggestItem> => {
    if (!items) {
        return [];
    }

    return items.map((item) => ({
        type: 'cells',
        items: [
            {
                data: item,
                value: item.text,
            },
        ],
    }));
};

export const useStaticFacultiesDataProvider = (universityId: number | null) => {
    const faculties = useFaculties(universityId ?? undefined);

    return useMemo(() => {
        return createDataProvider({
            fetcher: decorateWithResultProducer(createStaticDataFetcher(faculties), formatterFaculties),
            minCharsCount: 2,
        });
    }, [faculties]);
};
