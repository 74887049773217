import { FC } from 'react';

import { Avatar, AvatarSize } from '@hh.ru/magritte-ui';

import { useCdnImageHost } from 'src/hooks/useCdnImageHost';
import { hasGender } from 'src/models/applicant/resume/blocks/position/lib';
import { Gender } from 'src/models/applicant/resume/blocks/position/types';

interface Props {
    path?: string;
    size?: AvatarSize;
    gender: Gender;
    letters: string;
}

const ResumeAvatar: FC<Props> = ({ size = 96, gender, letters, path }) => {
    const fullPath = useCdnImageHost(path || '');

    if (path) {
        return <Avatar size={size} mode="image" aria-label="profile-avatar" image={fullPath} />;
    }

    if (hasGender(gender)) {
        const placeholder = gender === Gender.Male ? 'male' : 'female';
        return <Avatar size={size} mode="placeholder" aria-label="profile-avatar" placeholder={placeholder} />;
    }

    return <Avatar size={size} style="color-2" letters={letters} aria-label="profile-avatar" mode="letters" />;
};

export default ResumeAvatar;
