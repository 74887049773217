import { useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';

import { translation, useSelector } from '@hh.ru/front-static-app';
import { useBreakpoint, VSpacingContainer } from '@hh.ru/magritte-ui';

import {
    selectResumeEditorExperienceIndex,
    selectResumeEditorOpenedExperience,
} from 'src/models/applicant/resume/blocks/experience/editor/selectors';
import { useResumeEditorListField } from 'src/models/applicant/resume/editor/form/change';
import { useTranslatedErrorMap } from 'src/models/applicant/resume/editor/form/errors';
import { isNewArrayField } from 'src/models/applicant/resume/editor/lib';
import { deleteFieldResumeEditor } from 'src/models/applicant/resume/editor/store/actions/delete';
import { submitResumeEditorData } from 'src/models/applicant/resume/editor/store/actions/submitEditor';
import { closeResumeEditorModal } from 'src/models/applicant/resume/editor/store/actions/toggleModal';
import EditorModal from 'src/pages/ResumeView/redesign/common/EditorModal';

import AreaField from 'src/pages/ResumeView/redesign/blocks/experience/Editor/fields/Area';
import CompanyField from 'src/pages/ResumeView/redesign/blocks/experience/Editor/fields/Company';
import CompanyUrlField from 'src/pages/ResumeView/redesign/blocks/experience/Editor/fields/CompanyUrl';
import DescriptionField from 'src/pages/ResumeView/redesign/blocks/experience/Editor/fields/Description';
import IndustryField from 'src/pages/ResumeView/redesign/blocks/experience/Editor/fields/Industry';
import PeriodField from 'src/pages/ResumeView/redesign/blocks/experience/Editor/fields/Period';
import PositionField from 'src/pages/ResumeView/redesign/blocks/experience/Editor/fields/Position';

const FIELD_NAME = 'experience';

const TrlKeys = {
    title: 'resume.editor.experience.title',
    delete: 'resume.editor.experience.delete.title',
    commonErrors: {
        UNKNOWN: 'resume.editor.field.error.unknown',
        REQUIRED: 'resume.editor.field.error.required',
        NOT_MATCH_REGEXP: 'resume.editor.field.error.regexp',
        LENGTH_GREATER_THAN_MAXIMUM: {
            one: 'resume.editor.field.error.max-length.one',
            some: 'resume.editor.field.error.max-length.some',
            many: 'resume.editor.field.error.max-length.many',
        },
        EARLY_THAN_MINIMUM: 'resume.editor.field.year.error.min-date',
        LATER_THAN_MAXIMUM: 'resume.editor.field.year.error.max-date',
        VALUE_LESS_THAN_MINIMUM: 'resume.editor.field.year.error.min-date',
        VALUE_GREATER_THAN_MAXIMUM: 'resume.editor.field.year.error.max-date',
    },
};

const ResumeExperienceEditor = translation(({ trls }) => {
    const dispatch = useDispatch();
    const { isMobile } = useBreakpoint();

    const visible = useSelector(selectResumeEditorOpenedExperience);

    const index = useSelector(selectResumeEditorExperienceIndex);
    const errorsMap = useTranslatedErrorMap(TrlKeys.commonErrors, trls);

    const id = useResumeEditorListField<number>(FIELD_NAME, index, 'id');
    const { value: employerId } = useResumeEditorListField<number>(FIELD_NAME, index, 'employerId');

    const isNew = useMemo(() => isNewArrayField({ id: id.value }), [id.value]);

    const [disabledCompanyFields, setDisabledCompanyFields] = useState(!!employerId && !isNew);

    const handleSave = () => dispatch(submitResumeEditorData());
    const handleClose = () => dispatch(closeResumeEditorModal());
    const handleDelete = () => dispatch(deleteFieldResumeEditor(trls[TrlKeys.delete]));

    const handleChangedDisabled = (changedFromSuggest: boolean) => {
        setDisabledCompanyFields(changedFromSuggest);
    };

    useEffect(() => {
        setDisabledCompanyFields(!!employerId && !isNew);
    }, [employerId, isNew]);

    return (
        <EditorModal
            title={trls[TrlKeys.title]}
            visible={visible}
            onSave={handleSave}
            onClose={handleClose}
            onDelete={!isNew ? handleDelete : undefined}
        >
            <VSpacingContainer default={12}>
                <CompanyField
                    index={index}
                    errorsMap={errorsMap}
                    fieldName={FIELD_NAME}
                    changeDisabled={handleChangedDisabled}
                />
                <AreaField index={index} errorsMap={errorsMap} fieldName={FIELD_NAME} />
                <CompanyUrlField
                    index={index}
                    errorsMap={errorsMap}
                    fieldName={FIELD_NAME}
                    disabled={disabledCompanyFields}
                />
                <IndustryField
                    index={index}
                    errorsMap={errorsMap}
                    fieldName={FIELD_NAME}
                    disabled={disabledCompanyFields}
                />
                <PositionField index={index} errorsMap={errorsMap} fieldName={FIELD_NAME} />
                {isMobile && <DescriptionField index={index} errorsMap={errorsMap} fieldName={FIELD_NAME} />}
                <PeriodField index={index} errorsMap={errorsMap} fieldName={FIELD_NAME} />
                {!isMobile && <DescriptionField index={index} errorsMap={errorsMap} fieldName={FIELD_NAME} />}
            </VSpacingContainer>
        </EditorModal>
    );
});

export default ResumeExperienceEditor;
