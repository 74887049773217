import {
    RpfEvent,
    ReadyRpfEvent,
    ChangedRpfEvent,
    DestroyRpfEvent,
} from 'src/models/applicant/resume/blocks/contacts/editor/proxy/types';

export function isReadyEvent(e: RpfEvent): e is ReadyRpfEvent {
    return e.type === 'ready';
}
export function isChangedEvent(e: RpfEvent): e is ChangedRpfEvent {
    return e.type === 'changed';
}
export function isDestroyEvent(e: RpfEvent): e is DestroyRpfEvent {
    return e.type === 'destroy';
}
