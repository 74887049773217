import { translation } from '@hh.ru/front-static-app';
import { VSpacingContainer, Link, Text, VSpacing } from '@hh.ru/magritte-ui';
import { SPALink } from '@hh.ru/redux-spa-middleware';

import formatDate from 'Modules/formatDate';
import { EmployerStateExtName } from 'src/models/negotiationTopic.types';
import { NegotiationsHistory } from 'src/models/negotiationsHistory';
import { ARROW_RIGHT, NON_BREAKING_SPACE } from 'src/utils/constants/symbols';

interface Props {
    vacancy: NegotiationsHistory;
}

const TrlKeys = {
    author: 'resume.employer.history.author',
    employerStateExtName: {
        [EmployerStateExtName.Response]: 'resume.employer.history.response',
        [EmployerStateExtName.Interview]: 'resume.employer.history.interview',
        [EmployerStateExtName.PhoneInterview]: 'resume.employer.history.phone_interview',
        [EmployerStateExtName.Assessment]: 'resume.employer.history.assessment',
        [EmployerStateExtName.Consider]: 'resume.employer.history.consider',
        [EmployerStateExtName.Hired]: 'resume.employer.history.hired',
        [EmployerStateExtName.Offer]: 'resume.employer.history.offer',
        [EmployerStateExtName.DiscardByEmployer]: 'resume.employer.history.discard_by_employer.softForm',
        [EmployerStateExtName.DiscardByApplicant]: 'resume.employer.history.discard_by_applicant',
        [EmployerStateExtName.DiscardNoInteraction]: 'resume.employer.history.discard_no_interaction',
        [EmployerStateExtName.DiscardVacancyClosed]: 'resume.employer.history.discard_vacancy_closed',
        [EmployerStateExtName.DiscardToOtherVacancy]: 'resume.employer.history.discard_to_other_vacancy',
    },
};

const ResumeHistoryItem = translation<Props>(({ trls, vacancy }) => {
    return (
        <VSpacingContainer default={8} data-qa="resume-history-item">
            <Link
                Element={SPALink}
                data-qa="resume-history-item-text"
                to={`/vacancy/${vacancy['@vacancyId']}`}
                style="neutral"
                typography="paragraph-2-regular"
            >
                {vacancy['@name']}
            </Link>
            {vacancy.items.map((item, idx) => {
                return (
                    <Text
                        key={idx}
                        Element="span"
                        typography="paragraph-2-regular"
                        style="secondary"
                        data-qa="resume-history-item-info"
                    >
                        <span> {ARROW_RIGHT}</span>
                        {NON_BREAKING_SPACE}
                        <span>
                            {trls[TrlKeys.employerStateExtName[item['@employerStateExtName']]]}
                            {', '}
                            <span suppressHydrationWarning>{formatDate(item['@changeTime'], 'dd.MM.yy')}</span>
                        </span>
                        {item['@senderEmployerManagerId'] && (
                            <>
                                <VSpacing default={8} />
                                <p>
                                    {trls[TrlKeys.author]}
                                    {': '}
                                    <Link
                                        inline
                                        Element={SPALink}
                                        to={`/employer/settings?employerManagerId=${item['@senderEmployerManagerId']}`}
                                        typography="paragraph-2-regular"
                                    >
                                        {item['@senderFullName']}
                                    </Link>
                                </p>
                            </>
                        )}
                    </Text>
                );
            })}
        </VSpacingContainer>
    );
});

export default ResumeHistoryItem;
