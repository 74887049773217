import { ComponentType, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { translation } from '@hh.ru/front-static-app';
import { SearchInput } from '@hh.ru/magritte-ui';

import { useDebouncedValue } from 'src/hooks/useDebouncedValue';
import { ResumeEmployersListType } from 'src/models/applicant/resume/api';
import { searchEmployersListForResume } from 'src/models/applicant/resume/employersLists/actions';
import { selectResumeEmployerListSubStep } from 'src/models/applicant/resume/employersLists/selectors';
import { changeResumeEmployersListStep } from 'src/models/applicant/resume/employersLists/slice';

const TrlKeys = {
    placeholder: 'applicant.resume.visibility.editor.employerList.search.placeholder',
};

export const EmployersListSearchInput: ComponentType<{ type: ResumeEmployersListType }> = translation(
    ({ type, trls }) => {
        const dispatch = useDispatch();
        const [searchValue, setSearchValue] = useState('');
        const subStep = useSelector(selectResumeEmployerListSubStep);
        useEffect(() => {
            if (subStep === 'list') {
                setSearchValue('');
            }
        }, [subStep]);
        const debouncedSearchValue = useDebouncedValue(searchValue, 300);
        useEffect(() => {
            if (debouncedSearchValue) {
                void dispatch(searchEmployersListForResume(type, debouncedSearchValue));
            } else {
                dispatch(changeResumeEmployersListStep({ step: type, subStep: 'list' }));
            }
        }, [debouncedSearchValue, dispatch, type]);
        return (
            <SearchInput
                placeholder={trls[TrlKeys.placeholder]}
                clearable
                value={searchValue}
                onChange={(newSearchValue) => setSearchValue(newSearchValue)}
            />
        );
    }
);
