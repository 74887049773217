import { useRef } from 'react';

import { TooltipHover } from '@hh.ru/magritte-ui';
import { CheckCircleOutlinedSize16 } from '@hh.ru/magritte-ui/icon';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import translation from 'src/components/translation';

const TrlKeys = {
    verified: 'rb.view.phone.verified',
};

const PhoneVerified: TranslatedComponent = ({ trls }) => {
    const verifiedIconRef = useRef(null);
    return (
        <>
            <CheckCircleOutlinedSize16
                padding={4}
                initialColor="positive"
                ref={verifiedIconRef}
                data-qa="mark-valid-icon"
            />
            <TooltipHover placement="bottom-center" activatorRef={verifiedIconRef}>
                {trls[TrlKeys.verified]}
            </TooltipHover>
        </>
    );
};

export default translation(PhoneVerified);
