import { useMemo } from 'react';
import { useDispatch } from 'react-redux';

import { translation, useSelector } from '@hh.ru/front-static-app';
import { Input, VSpacingContainer, NumberInput, Text, FieldsGroupHelper, useBreakpoint } from '@hh.ru/magritte-ui';

import {
    selectResumeEditorAdditionalEducationIndex,
    selectResumeEditorOpenedAdditionalEducation,
} from 'src/models/applicant/resume/blocks/additionalEducation/editor/selectors';
import { useResumeEditorListField } from 'src/models/applicant/resume/editor/form/change';
import { useTranslatedErrorMap } from 'src/models/applicant/resume/editor/form/errors';
import { isNewArrayField } from 'src/models/applicant/resume/editor/lib';
import { deleteFieldResumeEditor } from 'src/models/applicant/resume/editor/store/actions/delete';
import { submitResumeEditorData } from 'src/models/applicant/resume/editor/store/actions/submitEditor';
import { closeResumeEditorModal } from 'src/models/applicant/resume/editor/store/actions/toggleModal';
import EditorModal from 'src/pages/ResumeView/redesign/common/EditorModal';

import styles from './styles.less';

const TrlKeys = {
    title: 'resume.editor.additionalEducation.title',
    delete: 'resume.editor.additionalEducation.delete.title',
    labels: {
        name: 'resume.editor.additionalEducation.labels.name',
        year: 'resume.editor.additionalEducation.labels.year',
        result: 'resume.editor.additionalEducation.labels.position',
        organization: 'resume.editor.additionalEducation.labels.organization',
    },
    description: 'resume.editor.additionalEducation.year.description',
    commonErrors: {
        UNKNOWN: 'resume.editor.field.error.unknown',
        REQUIRED: 'resume.editor.field.error.required',
        NOT_MATCH_REGEXP: 'resume.editor.field.error.regexp',
        LENGTH_GREATER_THAN_MAXIMUM: {
            one: 'resume.editor.field.error.max-length.one',
            some: 'resume.editor.field.error.max-length.some',
            many: 'resume.editor.field.error.max-length.many',
        },
        EARLY_THAN_MINIMUM: 'resume.editor.field.year.error.min-date',
        LATER_THAN_MAXIMUM: 'resume.editor.field.year.error.max-date',
        VALUE_LESS_THAN_MINIMUM: 'resume.editor.field.year.error.min-date',
        VALUE_GREATER_THAN_MAXIMUM: 'resume.editor.field.year.error.max-date',
    },
};
const FIELD_NAME = 'additionalEducation';

const ResumeAdditionalEducationEditor = translation(({ trls }) => {
    const { isMobile } = useBreakpoint();
    const dispatch = useDispatch();

    const visible = useSelector(selectResumeEditorOpenedAdditionalEducation);

    const index = useSelector(selectResumeEditorAdditionalEducationIndex);
    const errorsMap = useTranslatedErrorMap(TrlKeys.commonErrors, trls);

    const id = useResumeEditorListField<number>(FIELD_NAME, index, 'id');
    const name = useResumeEditorListField(FIELD_NAME, index, 'name', errorsMap);
    const year = useResumeEditorListField<number>(FIELD_NAME, index, 'year', errorsMap);
    const result = useResumeEditorListField(FIELD_NAME, index, 'result', errorsMap);
    const organization = useResumeEditorListField(FIELD_NAME, index, 'organization', errorsMap);

    const handleSave = () => dispatch(submitResumeEditorData());
    const handleClose = () => dispatch(closeResumeEditorModal());
    const handleDelete = () => dispatch(deleteFieldResumeEditor(trls[TrlKeys.delete]));

    const isNew = useMemo(() => isNewArrayField({ id: id.value }), [id.value]);

    return (
        <EditorModal
            title={trls[TrlKeys.title]}
            visible={visible}
            onSave={handleSave}
            onClose={handleClose}
            onDelete={!isNew ? handleDelete : undefined}
        >
            <VSpacingContainer default={12}>
                <Input
                    size="medium"
                    value={name.value}
                    invalid={name.invalid}
                    onChange={name.onChange}
                    errorMessage={name.errorMessage}
                    placeholder={trls[TrlKeys.labels.name]}
                />
                <Input
                    size="medium"
                    value={organization.value}
                    invalid={organization.invalid}
                    onChange={organization.onChange}
                    errorMessage={organization.errorMessage}
                    placeholder={trls[TrlKeys.labels.organization]}
                />
                <Input
                    size="medium"
                    value={result.value}
                    invalid={result.invalid}
                    onChange={result.onChange}
                    errorMessage={result.errorMessage}
                    placeholder={trls[TrlKeys.labels.result]}
                />
                <FieldsGroupHelper invalid={year.invalid} errorMessage={isMobile ? undefined : year.errorMessage}>
                    <div className={styles.root}>
                        <div className={styles.year}>
                            <NumberInput
                                size="medium"
                                value={year.value === 0 ? '' : `${year.value}`}
                                maxLength={4}
                                groupSize={0}
                                allowNegative={false}
                                invalid={year.invalid}
                                onChange={(value) => year.onChange(Number(value))}
                                errorMessage={isMobile ? year.errorMessage : undefined}
                                placeholder={trls[TrlKeys.labels.year]}
                            />
                        </div>
                        <div className={styles.hint}>
                            <Text style="secondary" typography={'label-3-regular'}>
                                {trls[TrlKeys.description]}
                            </Text>
                        </div>
                    </div>
                </FieldsGroupHelper>
            </VSpacingContainer>
        </EditorModal>
    );
});

export default ResumeAdditionalEducationEditor;
