import { useMemo, useRef, useState } from 'react';

import { translation, useSelector } from '@hh.ru/front-static-app';
import { ActionList, Button, HSpacingContainer, useBreakpoint, VSpacingContainer } from '@hh.ru/magritte-ui';
import { DocumentOutlinedSize24, GridOutlinedSize24 } from '@hh.ru/magritte-ui/icon';

import { selectApplicantInfo, selectBackofficeLinks } from 'src/models/applicant/resume/backoffice/selectors';
import { selectResumeHash } from 'src/models/applicant/resume/selectors';

import styles from './styles.less';

const TrlKeys = {
    backlink: 'rb.view.backlink',
    services: 'moderatorResume.bottom.services',
    moderation: 'admin.rb.tomoderation',
    loginAsUser: 'moderatorResume.bottom.user',
};

const ModerationControls = translation(({ trls }) => {
    const { isGtM, isGtXS, isMobile } = useBreakpoint();
    const activatorRef = useRef<HTMLButtonElement>(null);
    const [showMoreList, toggleMoreList] = useState(false);

    const hash = useSelector(selectResumeHash);
    const applicantInfo = useSelector(selectApplicantInfo);
    const { moderationLink, loginAsUserLink, userServicesLink } = useSelector(selectBackofficeLinks);

    const hasBlacklistLink = useMemo(() => applicantInfo.total > 0, [applicantInfo]);
    const blackListLink = useMemo(
        () => (applicantInfo.finished > 0 ? `/applicant/resumes` : `/applicant/resumes/short?resume=${hash}`),
        [applicantInfo, hash]
    );
    const showMoreBtn = useMemo(() => {
        if (hasBlacklistLink && !isGtM) {
            return true;
        }
        if (!isGtXS) {
            return true;
        }
        return false;
    }, [hasBlacklistLink, isGtM, isGtXS]);

    const handleMoreBtn = () => toggleMoreList((value) => !value);

    return (
        <div className={styles.root}>
            <HSpacingContainer default={12}>
                <Button Element="a" href={moderationLink} style="accent" mode="secondary" size="small">
                    {trls[TrlKeys.moderation]}
                </Button>
                <Button Element="a" href={loginAsUserLink} style="accent" mode="secondary" size="small">
                    {trls[TrlKeys.loginAsUser]}
                </Button>
                {isGtXS && (
                    <Button Element="a" href={userServicesLink} style="accent" mode="secondary" size="small">
                        {trls[TrlKeys.services]}
                    </Button>
                )}
                {isGtM && hasBlacklistLink && (
                    <Button Element="a" href={blackListLink} style="accent" mode="secondary" size="small">
                        {trls[TrlKeys.backlink]}
                    </Button>
                )}
            </HSpacingContainer>
            {showMoreBtn && (
                <Button ref={activatorRef} style="accent" mode="secondary" size="small" onClick={handleMoreBtn}>
                    ...
                </Button>
            )}

            <ActionList
                visible={showMoreList}
                onClose={handleMoreBtn}
                dropProps={{
                    placement: 'bottom-right',
                    activatorRef,
                    width: 315,
                }}
            >
                <VSpacingContainer default={8}>
                    {!isGtXS && (
                        <Button
                            stretched
                            Element="a"
                            href={userServicesLink}
                            style="neutral"
                            mode="tertiary"
                            size={isMobile ? 'large' : 'small'}
                            icon={<GridOutlinedSize24 />}
                        >
                            {trls[TrlKeys.services]}
                        </Button>
                    )}
                    {!isGtM && hasBlacklistLink && (
                        <Button
                            stretched
                            Element="a"
                            href={blackListLink}
                            style="neutral"
                            mode="tertiary"
                            size={isMobile ? 'large' : 'small'}
                            icon={<DocumentOutlinedSize24 />}
                        >
                            {trls[TrlKeys.backlink]}
                        </Button>
                    )}
                </VSpacingContainer>
            </ActionList>
        </div>
    );
});

export default ModerationControls;
