import { useEffect } from 'react';

import { VSpacingContainer, Text } from '@hh.ru/magritte-ui';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import formatDate from 'Modules/formatDate';
import { useNotification } from 'src/components/Notifications/Provider';
import translation from 'src/components/translation';
import { useSelector } from 'src/hooks/useSelector';
import showResumeViewHistoryErrorFetchingNotification from 'src/pages/ResumeView/components/View/ResumeSidebar/ViewHistory/Notifications';
import ResumeBlock from 'src/pages/ResumeView/employerRedesign/components/ResumeBlock';
import ResumeBullet from 'src/pages/ResumeView/employerRedesign/components/ResumeBullet';

const TrlKeys = {
    title: 'resume.employer.viewhistory.views',
};

interface ResumeViewHistoryProps {
    inSidebar?: boolean;
}

const ResumeViewHistory: TranslatedComponent<ResumeViewHistoryProps> = ({ trls, inSidebar = false }) => {
    const { addNotification } = useNotification();
    const resumeViewHistory = useSelector((state) => state.resumeViewHistory);
    const shouldBeRendered = useSelector((state) => state.printVersion.history !== false);

    useEffect(() => {
        if (resumeViewHistory.showResumeFallbackWarning) {
            addNotification(showResumeViewHistoryErrorFetchingNotification);
        }
    }, [addNotification, resumeViewHistory]);

    if (!resumeViewHistory.resumeView?.length || !shouldBeRendered) {
        return null;
    }

    return (
        <ResumeBlock isTitleInside={inSidebar} title={trls[TrlKeys.title]}>
            <VSpacingContainer default={24}>
                {resumeViewHistory.resumeView
                    .sort((a, b) => b.lastViewDate['@timestamp'] - a.lastViewDate['@timestamp'])
                    .map((item, index) => (
                        <VSpacingContainer default={4} key={index}>
                            <Text typography="label-3-regular">{item.fullName}</Text>
                            <Text typography="label-3-regular" style="secondary">
                                {formatDate(item.lastViewDate.$, 'dd.MM.yy')}
                                <ResumeBullet paddings={false} />
                                {formatDate(item.lastViewDate.$, 'HH:mm')}
                            </Text>
                        </VSpacingContainer>
                    ))}
            </VSpacingContainer>
        </ResumeBlock>
    );
};

export default translation(ResumeViewHistory);
