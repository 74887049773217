import { FC, PropsWithChildren, useCallback, useLayoutEffect, useMemo, useRef } from 'react';

import { Avatar, Cell, Link, Text, useResize, VSpacing } from '@hh.ru/magritte-ui';
import { SPALink } from '@hh.ru/redux-spa-middleware';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import AsyncHighlighter from 'src/components/Markup/AsyncHighlighter';
import translation from 'src/components/translation';
import { useCdnImageHost } from 'src/hooks/useCdnImageHost';
import { useSelector } from 'src/hooks/useSelector';
import { EmployerState } from 'src/models/employerStatus';
import { CompanyLogoTypes, Experience } from 'src/models/resume/resumeCommon.types';
import ResumeBullet from 'src/pages/ResumeView/employerRedesign/components/ResumeBullet';
import ResumeExperienceInterval from 'src/pages/ResumeView/employerRedesign/components/ResumeExperience/ResumeExperienceInterval';
import { EXPERIENCE } from 'src/utils/constants/resumeHiddenFields';

import styles from './styles.less';

const TrlKeys = {
    isHidden: 'resume.hiddenFields.experience',
};

type ResumeExperienceInfoProps = Pick<
    Experience,
    | 'employerId'
    | 'companyName'
    | 'companyState'
    | 'companyAreaId'
    | 'companyAreaTitle'
    | 'companyUrl'
    | 'prettyUrl'
    | 'interval'
    | 'startDate'
    | 'endDate'
    | 'companyLogos'
>;

interface WrapperProps {
    url: string | false;
    logoSrc?: string | false;
    companyName: string;
}

const AVATAR_SIZE = 48;
const CELL_PADDING = 12;

const Wrapper: FC<PropsWithChildren<WrapperProps>> = ({ url, logoSrc, companyName, children }) => {
    const fullLogoSrc = useCdnImageHost(logoSrc || '');
    const avatarRef = useRef<HTMLDivElement>(null);
    const wrapperRef = useRef<HTMLDivElement>(null);

    const resize = useCallback(() => {
        if (avatarRef.current && wrapperRef.current) {
            const { x } = avatarRef.current.getBoundingClientRect();
            if (x < 0) {
                wrapperRef.current.classList.remove(styles.wrapper);
            }
            if (x >= 0 && x - (AVATAR_SIZE + CELL_PADDING) >= 0) {
                wrapperRef.current.classList.add(styles.wrapper);
            }
        }
    }, []);

    useLayoutEffect(() => {
        resize();
    }, [resize]);

    useResize(resize);

    const logo = logoSrc ? (
        <Avatar
            ref={avatarRef}
            mode="image"
            fallbackMode="placeholder"
            placeholder="city"
            size={AVATAR_SIZE}
            shapeCircle
            image={fullLogoSrc}
            aria-label={companyName}
        />
    ) : (
        <Avatar
            ref={avatarRef}
            mode="placeholder"
            placeholder="city"
            size={AVATAR_SIZE}
            shapeCircle
            aria-label={companyName}
        />
    );

    const content = url ? (
        <Cell Element={SPALink} to={url} align="top" left={logo}>
            {children}
        </Cell>
    ) : (
        <Cell align="top" left={logo}>
            {children}
        </Cell>
    );

    return (
        <div ref={wrapperRef} className={styles.wrapper}>
            {content}
        </div>
    );
};

const ResumeExperienceInfo: TranslatedComponent<ResumeExperienceInfoProps> = ({
    employerId,
    companyName,
    companyState,
    companyAreaId,
    companyAreaTitle,
    companyUrl,
    prettyUrl,
    interval,
    startDate,
    endDate,
    companyLogos,
    trls,
}) => {
    const isHidden = useSelector((state) => state.resume?.hiddenFields.includes(EXPERIENCE));
    const isApproved = companyState === EmployerState.Approved;

    const companyLogoSrc = useMemo(() => {
        return (
            !isHidden &&
            companyLogos?.logo &&
            companyLogos.logo.length > 0 &&
            companyLogos.logo.find((logo) => logo.type === CompanyLogoTypes.Medium)?.url
        );
    }, [companyLogos?.logo, isHidden]);

    return (
        <Wrapper
            url={!isHidden && isApproved && `/employer/${employerId}`}
            logoSrc={companyLogoSrc}
            companyName={companyName}
        >
            <Text typography="subtitle-1-semibold">
                {isHidden ? trls[TrlKeys.isHidden] : <AsyncHighlighter>{companyName}</AsyncHighlighter>}
            </Text>
            {!isHidden && (companyAreaId || companyUrl) && (
                <>
                    <VSpacing default={4} />
                    <Text typography="label-3-regular">
                        {companyAreaId && companyAreaTitle}
                        {companyUrl && (
                            <>
                                {companyAreaId && <ResumeBullet typography="label-3-regular" />}
                                <Link
                                    inline
                                    style="neutral"
                                    typography="label-3-regular"
                                    href={companyUrl}
                                    target="_blank"
                                    rel="noreferrer noopener"
                                >
                                    <span className={styles.link}>{prettyUrl}</span>
                                </Link>
                            </>
                        )}
                    </Text>
                </>
            )}
            <VSpacing default={8} />
            <ResumeExperienceInterval startDate={startDate} endDate={endDate} interval={interval} />
        </Wrapper>
    );
};

export default translation(ResumeExperienceInfo);
