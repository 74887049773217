import { Banner, Text, Title } from '@hh.ru/magritte-ui';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';
import { formatToReactComponent } from 'bloko/common/trl';

import translation from 'src/components/translation';
import { useSelector } from 'src/hooks/useSelector';
import { HiddenFields } from 'src/models/resume/resumeCommon.types';

const TrlKeys = {
    title: 'resume.anonymous.warning.pre',
    description: 'resume.anonymous.warning.variants',
    and: 'abstract.and',
    [HiddenFields.NamesAndPhoto]: 'resume.anonymous.warning.field.names_and_photo',
    [HiddenFields.Phones]: 'resume.anonymous.warning.field.phones',
    [HiddenFields.Email]: 'resume.anonymous.warning.field.email',
    [HiddenFields.Experience]: 'resume.anonymous.warning.field.experience',
    [HiddenFields.OtherContacts]: 'resume.anonymous.warning.field.other_contacts',
};

const ResumeHasHiddenFieldsWarning: TranslatedComponent = ({ trls }) => {
    const resumeContactsOpening = useSelector((state) => state.resume?.resumeContactsOpening);
    const hiddenFields = useSelector((state) => state.resume?.hiddenFields);

    if (!resumeContactsOpening || !hiddenFields || hiddenFields.length === 0) {
        return null;
    }

    const lastFieldIndex = hiddenFields.length - 1;

    return (
        <Banner
            data-qa="info-block-substrate-warning"
            stretched
            style="accent-secondary"
            showClose={false}
            title={
                <Title
                    size="small"
                    Element="h5"
                    data-qa="anonymous-resume-warning-text"
                    data-hidden-fields={hiddenFields.join(', ')}
                >
                    {formatToReactComponent(trls[TrlKeys.title], {
                        '{0}': hiddenFields
                            .map((field, index) => {
                                const fieldName = trls[TrlKeys[field]];

                                if (index === 0) {
                                    return fieldName;
                                }

                                const delimiter = index === lastFieldIndex ? ` ${trls[TrlKeys.and]}` : ',';
                                return `${delimiter} ${fieldName}`;
                            })
                            .join(''),
                    })}
                </Title>
            }
            content={<Text typography="paragraph-2-regular">{trls[TrlKeys.description]}</Text>}
        />
    );
};

export default translation(ResumeHasHiddenFieldsWarning);
