import { useDispatch, useSelector } from 'react-redux';

import { translation } from '@hh.ru/front-static-app';

import { selectResumeEditorOpenedKeySkills } from 'src/models/applicant/resume/blocks/keySkills/editor/selector';
import { useResumeEditorField } from 'src/models/applicant/resume/editor/form/change';
import { useTranslatedErrorMap } from 'src/models/applicant/resume/editor/form/errors';
import { submitResumeEditorData } from 'src/models/applicant/resume/editor/store/actions/submitEditor';
import { closeResumeEditorModal } from 'src/models/applicant/resume/editor/store/actions/toggleModal';
import { selectResumeHash } from 'src/models/applicant/resume/selectors';
import EditorModal from 'src/pages/ResumeView/redesign/common/EditorModal';

import KeySkillsSuggest from 'src/pages/ResumeView/redesign/blocks/skills/KeySkillsEditor/KeySkillsSuggest';

const TrlKeys = {
    title: 'resume.editor.key.skills.title',
    commonErrors: {
        UNKNOWN: 'resume.editor.field.error.unknown',
        REQUIRED: 'resume.editor.field.error.required',
        SIZE_LESS_THAN_MINIMUM: 'resume.editor.field.error.required',
        LENGTH_LESS_THAN_MAXIMUM: 'resume.editor.field.error.required',
        SIZE_GREATER_THAN_MAXIMUM: {
            one: 'resume.editor.field.error.skills.size-greater.one',
            some: 'resume.editor.field.error.skills.size-greater.some',
            many: 'resume.editor.field.error.skills.size-greater.many',
        },
        LENGTH_GREATER_THAN_MAXIMUM: {
            one: 'resume.editor.field.error.max-length.one',
            some: 'resume.editor.field.error.max-length.some',
            many: 'resume.editor.field.error.max-length.many',
        },
    },
};

const ResumeKeySkillsEditor = translation(({ trls }) => {
    const dispatch = useDispatch();

    const visible = useSelector(selectResumeEditorOpenedKeySkills);
    const resumeHash = useSelector(selectResumeHash);

    const errorsMap = useTranslatedErrorMap(TrlKeys.commonErrors, trls);

    const { value, onChange, invalid, errorMessage } = useResumeEditorField('keySkills', errorsMap);

    const handleSave = () => dispatch(submitResumeEditorData());
    const handleClose = () => dispatch(closeResumeEditorModal());

    const handleChanged = (value: string[]) => onChange(value);

    return (
        <EditorModal title={trls[TrlKeys.title]} visible={visible} onSave={handleSave} onClose={handleClose}>
            <KeySkillsSuggest
                skills={value}
                invalid={invalid}
                onChange={handleChanged}
                resumeHash={resumeHash}
                errorMessage={errorMessage}
            />
        </EditorModal>
    );
});

export default ResumeKeySkillsEditor;
