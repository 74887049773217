import { translation } from '@hh.ru/front-static-app';
import { Input } from '@hh.ru/magritte-ui';

import { useResumeEditorListField } from 'src/models/applicant/resume/editor/form/change';
import { ExperienceFieldProps } from 'src/pages/ResumeView/redesign/blocks/experience/Editor/types';

const TrlKeys = {
    label: 'resume.editor.experience.companyUrl.label',
};

const CompanyUrlField = translation<ExperienceFieldProps>(({ trls, errorsMap, fieldName, index, disabled }) => {
    const { value, invalid, errorMessage, onChange } = useResumeEditorListField(
        fieldName,
        index,
        'companyUrl',
        errorsMap
    );

    return (
        <Input
            size="medium"
            value={value || ''}
            invalid={invalid}
            onChange={onChange}
            errorMessage={errorMessage}
            placeholder={trls[TrlKeys.label]}
            disabled={disabled}
        />
    );
});

export default CompanyUrlField;
