import { useEffect } from 'react';
import { useDispatch } from 'react-redux';

import { useSelector } from '@hh.ru/front-static-app';

import {
    sendResumeEditCancelButtonClickAnalytics,
    sendResumeEditCancelElementShown,
} from 'src/models/applicant/resume/analytics/actions';
import { submitResumeEditorData } from 'src/models/applicant/resume/editor/store/actions/submitEditor';
import { closeResumeEditor } from 'src/models/applicant/resume/editor/store/actions/toggleModal';
import { selectResumeEditorChangedConfirm } from 'src/models/applicant/resume/editor/store/confirm/selectors';
import { hideResumeEditorConfirm } from 'src/models/applicant/resume/editor/store/slice';

import ChangedAlert from 'src/pages/ResumeView/redesign/common/Confirm/ChangedAlert';

const EditorCloseConfirm = () => {
    const dispatch = useDispatch();
    const visible = useSelector(selectResumeEditorChangedConfirm);

    const handleCancel = () => {
        dispatch(sendResumeEditCancelButtonClickAnalytics());
        dispatch(hideResumeEditorConfirm());
        dispatch(closeResumeEditor());
    };

    const handleApprove = () => {
        dispatch(hideResumeEditorConfirm());
        dispatch(submitResumeEditorData({ fromAlert: true }));
    };

    useEffect(() => {
        if (visible) {
            dispatch(sendResumeEditCancelElementShown());
        }
    }, [visible, dispatch]);

    const handleClose = () => dispatch(hideResumeEditorConfirm());

    return <ChangedAlert visible={visible} onClose={handleClose} onApprove={handleApprove} onCancel={handleCancel} />;
};

export default EditorCloseConfirm;
