import { translation } from '@hh.ru/front-static-app';
import { Input, Placeholder, Suggest } from '@hh.ru/magritte-ui';

import { PositionDTO, usePositionDataProvider } from 'src/models/applicant/resume/api/position';
import { useResumeEditorField } from 'src/models/applicant/resume/editor/form/change';
import { ErrorsMap } from 'src/models/applicant/resume/editor/form/errors';
import EditorField from 'src/pages/ResumeView/redesign/common/Form/EditorField';

const TITLE_FIELD_NAME = 'title';
const PROFESSIONAL_ROLE_FIELD_NAME = 'professionalRole';

const TrlKeys = {
    label: 'resume.editor.postion.title.label',
    errorTitle: 'resume.editor.suggest.errors.placeholder',
    resetButton: 'resume.editor.suggest.errors.button',
    errorDescription: 'resume.editor.suggest.errors.description',
};

const diffCountErrorKeys = ['LENGTH_GREATER_THAN_MAXIMUM'];

const TitleField = translation<{ commonErrors: ErrorsMap }>(({ trls, commonErrors }) => {
    const { value, onChange, invalid, errorMessage } = useResumeEditorField(
        TITLE_FIELD_NAME,
        commonErrors,
        diffCountErrorKeys
    );
    const dataProvider = usePositionDataProvider();
    const professionalRole = useResumeEditorField(PROFESSIONAL_ROLE_FIELD_NAME);

    const handleChanged = (value: string, isValueFromList: boolean, data?: PositionDTO) => {
        onChange(value);
        if (isValueFromList && data?.professionalRoles) {
            professionalRole.onChange(data.professionalRoles);
        }
    };

    return (
        <EditorField htmlFor="title" label={trls[TrlKeys.label]}>
            <Suggest
                onChange={handleChanged}
                dataProvider={dataProvider}
                navigationBarProps={{
                    title: trls[TrlKeys.label],
                }}
                input={{
                    component: Input,
                    props: {
                        value,
                        size: 'medium',
                        invalid,
                        clearable: true,
                        errorMessage,
                    },
                }}
                trls={{
                    resetButton: trls[TrlKeys.resetButton],
                }}
                errorPlaceholder={
                    <Placeholder title={trls[TrlKeys.errorTitle]} description={trls[TrlKeys.errorDescription]} />
                }
            />
        </EditorField>
    );
});

export default TitleField;
