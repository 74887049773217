import { memo } from 'react';

import { Link, Text, VSpacingContainer } from '@hh.ru/magritte-ui';
import { DotFilledSize16, PhoneOutlinedSize16 } from '@hh.ru/magritte-ui/icon';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import DenseText from 'src/components/DenseText';
import { CELL_PHONE_TYPE, getPreferredPhoneType } from 'src/components/ResumeContacts/preferredContacts';
import translation from 'src/components/translation';
import { useSelector } from 'src/hooks/useSelector';
import { PhoneType } from 'src/models/applicant/resume/blocks/contacts/types';
import { HiddenFields } from 'src/models/resume/resumeCommon.types';

import PhoneTypeAndPreference from 'src/pages/ResumeView/employerRedesign/components/ResumeContacts/PhoneTypeAndPreference';
import PhoneVerified from 'src/pages/ResumeView/employerRedesign/components/ResumeContacts/PhoneVerified';

import styles from './resumeContactPhones.less';

const TrlKeys = {
    isHidden: 'resume.hiddenFields.phones',
};

const ResumeContactsPhones: TranslatedComponent = ({ trls }) => {
    const isHidden = useSelector(({ resume }) => resume?.hiddenFields?.includes(HiddenFields.Phones));
    const phones = useSelector(({ resume }) => resume?.phone.value) || [];
    const preferredType = useSelector(({ resume }) => getPreferredPhoneType(resume?.preferredContact?.value || ''));

    if (isHidden) {
        return (
            <Text style="secondary" typography="label-3-regular">
                {trls[TrlKeys.isHidden]}
            </Text>
        );
    }

    return (
        <VSpacingContainer default={4}>
            {phones.map((phone, index, src) => {
                const isPreferred = !!preferredType && phone.type === preferredType;
                const isFirstOfType = isPreferred && src.findIndex((el) => el.type === phone.type) === index;
                const isPhoneVerified = phone.type === CELL_PHONE_TYPE && phone.verified;
                return (
                    <div key={phone.type} data-qa="resume-serp_resume-item-content">
                        <div className={styles.phoneContainer}>
                            {isPhoneVerified ? (
                                <PhoneVerified />
                            ) : (
                                <PhoneOutlinedSize16 padding={4} initialColor="secondary" />
                            )}
                            <Link
                                typography="label-3-regular"
                                style="neutral"
                                href={`tel:+${phone.raw}`}
                                inline
                                data-qa={isPreferred ? 'resume-contact-preferred' : undefined}
                            >
                                <DenseText typography="label-3-regular">{phone.formatted}</DenseText>
                            </Link>
                            <PhoneTypeAndPreference
                                isPreferred={isPreferred && isFirstOfType}
                                type={phone.type as PhoneType}
                                isShort
                            />
                            {phone.comment && (
                                <>
                                    <DotFilledSize16 initialColor="tertiary" />
                                    <Text Element="span" style="secondary" typography="label-3-regular">
                                        {phone.comment}
                                    </Text>
                                </>
                            )}
                        </div>
                    </div>
                );
            })}
        </VSpacingContainer>
    );
};

export default translation(memo(ResumeContactsPhones));
