import { ComponentType } from 'react';

import { useSelector } from '@hh.ru/front-static-app';
import { Tag } from '@hh.ru/magritte-ui';

import { selectResumeCreds } from 'src/models/applicant/resume/blocks/creds/selectors';
import { isQuestionRendered } from 'src/models/applicant/resume/blocks/creds/utils';

const ANSWERS_PATTERN = '{answers}';

interface Props {
    questionId: string;
    answers: string[];
}

const QuestionAnswer: ComponentType<Props> = ({ questionId, answers }) => {
    const creds = useSelector(selectResumeCreds);

    if (!creds || !isQuestionRendered(answers, creds)) {
        return null;
    }

    const renderAnswers = () =>
        answers
            .filter((answerId) => !creds.answers[answerId]?.skipAtResult)
            .map((answerId) => creds.answers[answerId]?.positiveTitle)
            .join(', ');

    const viewTitle = creds.questions[questionId]?.viewTitle || ANSWERS_PATTERN;

    return <Tag>{viewTitle.replace(ANSWERS_PATTERN, renderAnswers())}</Tag>;
};

export default QuestionAnswer;
