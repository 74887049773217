import { useEffect, useMemo, useRef, useState } from 'react';

import { format, translation } from '@hh.ru/front-static-app';
import {
    Modal,
    Action,
    Button,
    ActionBar,
    BottomSheet,
    ListControls,
    TreeSelector,
    NavigationBar,
    useBreakpoint,
    TreeCollection,
    BottomSheetFooter,
} from '@hh.ru/magritte-ui';
import { ChevronLeftOutlinedSize24, CrossOutlinedSize24, PlusOutlinedSize24 } from '@hh.ru/magritte-ui/icon';

import { RoleCatalogDTO } from 'src/models/applicant/resume/api/dto';
import { ProfessionalRoleTreeAdditional } from 'src/models/professionalRoleTree';

const TrlKeys = {
    hint: 'resume.editor.postion.professionalRole.model.selected.hint',
    save: 'resume.editor.postion.professionalRole.model.btn.save',
    label: 'resume.editor.postion.professionalRole.addButton.label',
    title: 'resume.editor.postion.professionalRole.model.title',
    reset: 'resume.editor.postion.professionalRole.model.btn.cancel',
};

interface Props {
    value: RoleCatalogDTO[];
    onChange: (selectedIds: string[]) => void;
    collection: TreeCollection<ProfessionalRoleTreeAdditional>;
    maxSelected?: number;
}

const RoleTreeSelector = translation<Props>(({ trls, value, collection, onChange, maxSelected = 5 }) => {
    const { isMobile } = useBreakpoint();
    const controlsRef = useRef<ListControls>(null);

    const currentIds = useMemo(() => {
        return value?.map(({ id }) => id) || [];
    }, [value]);
    const disabled = (currentIds.length || 0) >= maxSelected;

    const [visible, setVisible] = useState<boolean>(false);
    const [selected, setSelected] = useState<string[]>(currentIds);

    useEffect(() => {
        setSelected(currentIds);
    }, [currentIds]);

    const description = useMemo(() => {
        if (!maxSelected) {
            return undefined;
        }

        return format(trls[TrlKeys.hint], {
            '{0}': selected.length,
            '{1}': maxSelected,
        });
    }, [selected, maxSelected, trls]);

    const handleSave = () => {
        onChange(selected);
        setVisible(false);
    };
    const handleReset = () => {
        onChange(currentIds);
    };
    const handleClose = () => {
        onChange(currentIds);
        setVisible(false);
    };
    const handleChange = (allSelected: string[]) => {
        setSelected(allSelected);
    };
    const handleOpenSelector = () => {
        setVisible(true);
    };
    const handleBack = () => {
        controlsRef.current?.back();
    };

    return (
        <>
            <Button
                mode="secondary"
                size="medium"
                icon={<PlusOutlinedSize24 />}
                style="accent"
                onClick={handleOpenSelector}
                disabled={disabled}
                stretched={isMobile}
            >
                {trls[TrlKeys.label]}
            </Button>

            <TreeSelector
                leavesOnly
                collapseToParentId
                ref={controlsRef}
                value={selected}
                onChange={handleChange}
                collection={collection}
                maxSelected={maxSelected}
                getSelectAllParentTrl={() => undefined}
            >
                {({ renderTreeSelector, renderInput }) => {
                    return isMobile ? (
                        <BottomSheet
                            visible={visible}
                            height="full-screen"
                            header={
                                <NavigationBar
                                    title={trls[TrlKeys.title]}
                                    subtitle={description}
                                    options={renderInput()}
                                    right={<Action icon={CrossOutlinedSize24} onClick={handleClose} />}
                                    left={<Action icon={ChevronLeftOutlinedSize24} onClick={handleBack} />}
                                />
                            }
                            footer={
                                <BottomSheetFooter>
                                    <Button mode="secondary" style="accent" stretched onClick={handleReset}>
                                        {trls[TrlKeys.reset]}
                                    </Button>
                                    <Button mode="primary" style="accent" stretched onClick={handleSave}>
                                        {trls[TrlKeys.save]}
                                    </Button>
                                </BottomSheetFooter>
                            }
                            onClose={handleClose}
                        >
                            {renderTreeSelector()}
                        </BottomSheet>
                    ) : (
                        <Modal
                            title={trls[TrlKeys.title]}
                            visible={visible}
                            options={renderInput()}
                            onClose={handleClose}
                            titleSize="large"
                            titleDescription={description}
                            titleDescriptionStyle="secondary"
                            actions={
                                <Action
                                    style="neutral"
                                    mode="secondary"
                                    icon={CrossOutlinedSize24}
                                    onClick={handleClose}
                                />
                            }
                            footer={
                                <ActionBar
                                    primaryActions={
                                        <Button mode="primary" style="accent" onClick={handleSave}>
                                            {trls[TrlKeys.save]}
                                        </Button>
                                    }
                                    secondaryActions={
                                        <Button mode="secondary" style="accent" onClick={handleReset}>
                                            {trls[TrlKeys.reset]}
                                        </Button>
                                    }
                                />
                            }
                        >
                            {renderTreeSelector()}
                        </Modal>
                    );
                }}
            </TreeSelector>
        </>
    );
});

export default RoleTreeSelector;
