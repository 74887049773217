import { ComponentType, PropsWithChildren } from 'react';

import { translation } from '@hh.ru/front-static-app';
import { Card, VSpacingContainer, Cell, VSpacing, useBreakpoint, Link } from '@hh.ru/magritte-ui';
import { PlusOutlinedSize16 } from '@hh.ru/magritte-ui/icon';

import AddButton from 'src/pages/ResumeView/redesign/common/AddButton';
import BlockLabel from 'src/pages/ResumeView/redesign/common/BlockLabel';
import EditButton from 'src/pages/ResumeView/redesign/common/EditButton';
import EditIcon from 'src/pages/ResumeView/redesign/common/EditIcon';

import styles from './styles.less';

type Props = {
    label: string;
    onEdit: VoidFunction;
    mode?: 'add' | 'edit';
} & PropsWithChildren;

const TrlKeys = {
    buttonTextEdit: 'resume.editor.button.text',
    buttonTextAdd: 'resume.editor.button.text.add',
};

const ResumeCard: ComponentType<Props> = translation(({ trls, children, label, onEdit, mode }) => {
    const { isMobile } = useBreakpoint();

    return (
        <VSpacingContainer default={12}>
            <div className={styles.blockTitle}>
                <BlockLabel>{label}</BlockLabel>
                {mode === 'add' && !isMobile && (
                    <Link onClick={onEdit} iconLeft={<PlusOutlinedSize16 />}>
                        {trls[TrlKeys.buttonTextAdd]}
                    </Link>
                )}
            </div>

            <Card stretched borderWidth="default" borderRadius={24} padding={24}>
                {isMobile ? (
                    children
                ) : (
                    <Cell right={mode === 'edit' ? <EditIcon onClick={onEdit} /> : undefined}>{children}</Cell>
                )}
                {isMobile && <VSpacing default={24} />}
                {mode === 'edit' && <EditButton onClick={onEdit}>{trls[TrlKeys.buttonTextEdit]}</EditButton>}
                {mode === 'add' && <AddButton onClick={onEdit}>{trls[TrlKeys.buttonTextAdd]}</AddButton>}
            </Card>
        </VSpacingContainer>
    );
});

export default ResumeCard;
