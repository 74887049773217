import { ButtonTitle } from '@hh.ru/analytics-js-events/build/xhh/applicant/resume/resume_completion_button_click';
import { ButtonTitle as KeySkillType } from '@hh.ru/analytics-js-events/build/xhh/applicant/resume_key_skill/resume_key_skill_button_click';
import { ElementSpyInstance } from '@hh.ru/browser-api-utils';

import { EditorFormType } from 'src/models/applicant/resume/editor/types';

export const INIT_VALUE = {
    // Показ карточки с навыками в резюме
    resumeKeySkillElementShown: (_element: HTMLElement) => ({}) as ElementSpyInstance,
    // Показ блока "Поднимите резюме в поиске"
    resumeRenewalElementShown: (_element: HTMLElement, _params: { isActive: boolean }) => ({}) as ElementSpyInstance,
    // Показ блока с саджестами "Завершённость резюме" на странице резюме
    resumeCompletenessElementShown: (_element: HTMLElement, _params: { fields: string[] }) =>
        ({}) as ElementSpyInstance,

    // Клик на любой пункт блока с саджестами "Завершённость резюме" на странице резюме
    resumeCompletenessTagClick: (_fieldName: string) => {},
    // Нажатие на "Карандаш" для редактирования любой части резюме или на "Добавить ..." (фото и т.п.)
    resumeEditButtonClick: (_type: EditorFormType) => {},
    // Клик на любую из кнопок "Показать ещё"/"Посмотреть всё" на странице резюме для расхлопа конкретного блока в составе резюме (на моб. вебе)
    resumeVewAllButtonClick: (_label: string) => {},
    // Клик на "Добавить навыки" и "Указать уровень" на карточке с навыками в резюме
    resumeKeySkillButtonClick: (_type: KeySkillType) => {},
    // Клик на "Добавить образование" в блоке "Образование"
    resumeEducationAddButtonClick: () => {},
    // Клик на "Добавить опыт работы" в блоке "Опыт работы"
    resumeWorkExperienceAddButtonClick: () => {},
    // Распечатать резюме
    resumePrintClick: () => {},
    // Удалить
    resumeDeleteClick: () => {},
    // Поднять в поиске (ТОЛЬКО если кнопка активна)
    resumeUpdateClick: () => {},
    // Три точки
    resumeSettingsClick: () => {},
    // Изменить видимость
    changeVisibilityClick: () => {},
    // N подходящих вакансий
    vacancySearchListClick: () => {},
    // Перевести резюме на английский
    resumeTranslateEnglishClick: () => {},
};

// Соответствие названия полей в резюме и то что ждет аналитика
export const BUTTON_TITLE_BY_FIELD_NAME: Partial<Record<string, ButtonTitle>> = {
    // Обо мне
    skills: 'about_me',
    // Повышение квалификации и курсы
    additionalEducation: 'additional_education',
    // Контакты
    phone: 'contacts',
    // Образование (основное образование - высшее и т.д.)
    educationLevel: 'education',
    primaryEducation: 'education',
    // Экзамены и тесты
    attestationEducation: 'exams_tests',
    // Опыт работы
    experience: 'experience',
    // Навыки
    keySkills: 'key_skills',
    // Фото
    photo: 'photo',
    // Желаемая зарплата
    salary: 'salary',
    // желаемая должность, специализация
    specialization: 'specialization',
    // Портфолио
    portfolio: 'portfolio',
    // Рекомендации
    recommendation: 'recommendations',
    // Эл. сертификаты
    certificates: 'certificates',
    // личный сайт
    personalSite: 'website',
};
