import { FC } from 'react';

import { VSpacingContainer } from '@hh.ru/magritte-ui';

import ResumeButtons from 'src/pages/ResumeView/employerRedesign/components/ResumeButtons';
import ResumeCommentsWrapper from 'src/pages/ResumeView/employerRedesign/components/ResumeCommentsWrapper';
import ResumeHistory from 'src/pages/ResumeView/employerRedesign/components/ResumeHistory';
import ResumeViewHistory from 'src/pages/ResumeView/employerRedesign/components/ResumeViewHistory';
import VacancyTests from 'src/pages/ResumeView/employerRedesign/components/VacancyTests';

const ResumeSidebar: FC = () => {
    return (
        <VSpacingContainer default={24}>
            <ResumeButtons />
            <ResumeCommentsWrapper inSidebar />
            <VacancyTests inSidebar />
            <ResumeHistory inSidebar />
            <ResumeViewHistory inSidebar />
        </VSpacingContainer>
    );
};

export default ResumeSidebar;
