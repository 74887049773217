import { useRef, useState, useEffect, useLayoutEffect, ReactNode, useCallback, useMemo, RefObject } from 'react';
import classnames from 'classnames';

import { Button as MagritteButton, useBreakpoint, ButtonProps, DropProps, ButtonStyle } from '@hh.ru/magritte-ui';
import {
    EllipsisHorizontalOutlinedSize16,
    EllipsisHorizontalOutlinedSize24,
    EllipsisVerticalOutlinedSize16,
} from '@hh.ru/magritte-ui/icon';
import Button from 'bloko/blocks/button';
import BlokoMenu, { MenuLayer, MenuPlacement } from 'bloko/blocks/drop/Menu';
import VSpacingContainer from 'bloko/blocks/vSpacing/VSpacingContainer';
import useBlokoBreakpoint, { Breakpoint } from 'bloko/common/hooks/useBreakpoint';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import BlokoIconReplaceContainer from 'src/components/BlokoIconReplaceContainer';
import translation from 'src/components/translation';
import { useShownRawAnalyticsWithInnerButtons } from 'src/hooks/useMyVacanciesAnalytics';
import { useSelector } from 'src/hooks/useSelector';
import { useToggleState } from 'src/hooks/useToggleState';

import Menu, { MenuType } from 'src/components/AdaptiveButtons/Menu';

import styles from './styles.less';

interface AdaptiveButtonsProps {
    size?: ButtonProps['size'];
    buttons: ReactNode[];
    menuItems: ({ onClose }: { onClose: () => void }) => ReactNode[];
    menuType?: MenuType;
    menuPlacement?: DropProps['placement'];
    menuWithMinimalPaddings?: boolean;
    menuButtonStyle?: ButtonStyle;
    withEmployerVacanciesAnalytics?: boolean;
    containerDropHostEnabled?: boolean;
    redesign?: boolean;
    customContainerRef?: RefObject<HTMLElement>;
}

const TrlKeys = {
    showMore: 'adaptiveButtons.showMore',
};

const AdaptiveButtons: TranslatedComponent<AdaptiveButtonsProps> = ({
    trls,
    buttons,
    size,
    menuType,
    menuItems,
    menuPlacement = 'bottom-left',
    menuWithMinimalPaddings,
    menuButtonStyle = 'neutral',
    withEmployerVacanciesAnalytics = false,
    containerDropHostEnabled = false,
    redesign = false,
    customContainerRef = null,
}) => {
    const isHiringManagerExperiment = useSelector((state) => state.isHiringManagerExperiment);
    const isGtXS = useBlokoBreakpoint() !== Breakpoint.XS;
    const { isMobile } = useBreakpoint();
    const [isCalculationInProgress, setIsCalculationInProgress] = useState(true);

    const buttonsFiltered = useMemo(() => buttons.filter(Boolean), [buttons]);

    const containerRef = useRef<HTMLDivElement>(null);
    const buttonsRef = useRef<HTMLSpanElement[]>([]);
    const menuButtonRef = useRef<HTMLSpanElement>(null);
    const menuButtonsRef = useRef<HTMLDivElement>(null);
    const actionListActivatorRef = useRef<HTMLButtonElement>(null);

    const [containerWidth, setContainerWidth] = useState(0);
    const [buttonsWidths, setButtonsWidths] = useState<number[]>([]);
    const [buttonsShown, setButtonsShown] = useState(0);
    const [menuIsOpen, toggleMenuIsOpen, setMenuIsOpen] = useToggleState(false);
    const closeDrop = useCallback(() => setMenuIsOpen(false), [setMenuIsOpen]);

    const shownButtonsRawAnalytics = useShownRawAnalyticsWithInnerButtons(customContainerRef || containerRef);
    const shownMenuRawAnalytics = useShownRawAnalyticsWithInnerButtons(menuButtonsRef);
    const menuActivatorRef = useRef(null);

    useEffect(() => {
        if (withEmployerVacanciesAnalytics) {
            shownButtonsRawAnalytics('employer_vacancies_vacancy_button_bar');
        }
    }, [shownButtonsRawAnalytics, withEmployerVacanciesAnalytics]);

    const startCalculationForWindowResize = useCallback(() => {
        setIsCalculationInProgress(true);
    }, []);

    useLayoutEffect(() => {
        window.addEventListener('resize', startCalculationForWindowResize);
        setIsCalculationInProgress(true);
        return () => window.removeEventListener('resize', startCalculationForWindowResize);
    }, [startCalculationForWindowResize]);

    useLayoutEffect(() => {
        setIsCalculationInProgress(true);
    }, [buttonsFiltered]);

    useLayoutEffect(() => {
        if (isCalculationInProgress) {
            setContainerWidth((customContainerRef || containerRef).current?.getBoundingClientRect().width || 0);
            setButtonsWidths(buttonsRef.current?.map((element) => element.getBoundingClientRect().width) || 0);
        }
    }, [customContainerRef, isCalculationInProgress]);

    useLayoutEffect(() => {
        const menuButtonLeftOffset = 4;
        const menuButtonWidth = (menuButtonRef.current?.getBoundingClientRect().width || 0) + menuButtonLeftOffset;

        let sumWidth = 0;
        let newButtonsShowed = 0;

        for (const width of buttonsWidths) {
            const requiredWidthWithOneMoreButton = sumWidth + width + menuButtonWidth;

            if (requiredWidthWithOneMoreButton > containerWidth) {
                break;
            }
            if (width) {
                newButtonsShowed += 1;
                sumWidth += width;
            }
        }
        setButtonsShown(newButtonsShowed);
        setIsCalculationInProgress(false);
    }, [buttonsWidths, containerWidth]);

    const toggleMenu = () => {
        if (withEmployerVacanciesAnalytics) {
            shownMenuRawAnalytics('employer_vacancies_vacancy_button_bar_menu');
        }
        toggleMenuIsOpen();
    };

    const dropProps: DropProps = {
        ...(containerDropHostEnabled && { host: containerRef }),
        activatorRef: actionListActivatorRef,
        placement: menuPlacement,
        role: 'status',
    };

    const menuElements = menuItems({ onClose: closeDrop }).filter(Boolean).slice(buttonsShown);
    const oldMenu =
        isMobile && isHiringManagerExperiment ? (
            <>
                <Button
                    onClick={toggleMenu}
                    icon={
                        <BlokoIconReplaceContainer>
                            <EllipsisVerticalOutlinedSize16 />
                        </BlokoIconReplaceContainer>
                    }
                    data-qa="adaptive-buttons-menu-button"
                />
                <Menu
                    type={menuType}
                    visible={menuIsOpen}
                    onClose={closeDrop}
                    minimalPaddings={menuWithMinimalPaddings}
                    {...dropProps}
                >
                    <VSpacingContainer base={2}>{menuElements.map((element) => element)}</VSpacingContainer>
                </Menu>
            </>
        ) : (
            <BlokoMenu
                host={containerDropHostEnabled && isGtXS ? containerRef.current : undefined}
                show={menuIsOpen}
                layer={MenuLayer.Overlay}
                onClose={closeDrop}
                placement={MenuPlacement.BottomEnd}
                render={() => <div ref={menuButtonsRef}>{menuElements}</div>}
                activatorRef={menuActivatorRef}
            >
                <Button
                    onClick={toggleMenu}
                    icon={
                        <BlokoIconReplaceContainer>
                            <EllipsisVerticalOutlinedSize16 />
                        </BlokoIconReplaceContainer>
                    }
                    data-qa="adaptive-buttons-menu-button"
                    innerRef={menuActivatorRef}
                />
            </BlokoMenu>
        );

    return (
        <div ref={containerRef} className={styles.adaptiveButtonsContainer}>
            {isCalculationInProgress && (
                <div className={styles.adaptiveButtonsWidthComputer}>
                    {buttonsFiltered.map((button, index) => (
                        <span key={index} ref={(ref) => ref && (buttonsRef.current[index] = ref)}>
                            {button}
                        </span>
                    ))}
                </div>
            )}
            {buttonsFiltered.slice(0, buttonsShown).map((button, index) => (
                <span key={index}>{button}</span>
            ))}
            <span className={styles.adaptiveButtonsSpacer} />
            <span
                ref={menuButtonRef}
                className={classnames({
                    [styles.isHidden]: isCalculationInProgress || buttonsShown === buttonsFiltered.length,
                })}
            >
                {redesign ? (
                    <>
                        <MagritteButton
                            ref={actionListActivatorRef}
                            data-qa="adaptive-buttons-menu-button"
                            mode="secondary"
                            size={size}
                            style={menuButtonStyle}
                            icon={
                                size === 'small' ? (
                                    <EllipsisHorizontalOutlinedSize16 initialColor="primary" />
                                ) : (
                                    <EllipsisHorizontalOutlinedSize24 initialColor="primary" />
                                )
                            }
                            onClick={toggleMenu}
                            aria-label={trls[TrlKeys.showMore]}
                            hideLabel
                        >
                            {trls[TrlKeys.showMore]}
                        </MagritteButton>
                        <Menu
                            type={menuType}
                            visible={menuIsOpen}
                            onClose={closeDrop}
                            minimalPaddings={menuWithMinimalPaddings}
                            {...dropProps}
                        >
                            <VSpacingContainer base={2}>{menuElements.map((element) => element)}</VSpacingContainer>
                        </Menu>
                    </>
                ) : (
                    oldMenu
                )}
            </span>
        </div>
    );
};

export default translation(AdaptiveButtons);
