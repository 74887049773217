import { useCallback, useEffect } from 'react';
import { useDispatch } from 'react-redux';

import { translation, useSelector } from '@hh.ru/front-static-app';
import { Cell, CellText, useBreakpoint, Text } from '@hh.ru/magritte-ui';

import ContainerForMicroFrontend from 'src/components/CodeInjector/ContainerForMicroFrontend';
import { updateQuestionToAnswerMap } from 'src/models/applicant/resume/blocks/creds/actions';
import { selectResumeCreds } from 'src/models/applicant/resume/blocks/creds/selectors';
import { Creds } from 'src/models/applicant/resume/blocks/creds/types';
import { isAnyQuestionRendered } from 'src/models/applicant/resume/blocks/creds/utils';
import ResumeCard from 'src/pages/ResumeView/redesign/common/Card';

import QuestionAnswer from 'src/pages/ResumeView/redesign/blocks/creds/Card/QuestionAnswer';

import styles from './styles.less';

const CREDS_UPDATE = 'rpf-creds-update';
const EDIT_OPEN = 'rpf-creds-edit-open';

declare global {
    interface WindowEventMap {
        [CREDS_UPDATE]: CustomEvent;
    }
}

const TrlKeys = {
    title: 'rb.title.creds',
    noCredsTitle: 'rb.creds.empty.title',
    noCredsSubtitle: 'rb.creds.empty.text',
};

const ApplicantResumeCreds = translation(({ trls }) => {
    const { isMobile } = useBreakpoint();
    const dispatch = useDispatch();
    const creds = useSelector(selectResumeCreds);

    const handleEdit = () => {
        window.dispatchEvent(new CustomEvent(EDIT_OPEN));
    };

    const handleUpdateQuestionToAnswerMap = useCallback(
        (questionToAnswerMap: Record<string, string[]>) => {
            dispatch(updateQuestionToAnswerMap(questionToAnswerMap));
        },
        [dispatch]
    );

    useEffect(() => {
        const handleUpdateCreds = (event: CustomEvent<{ creds: Creds }>) => {
            event.detail.creds && handleUpdateQuestionToAnswerMap(event.detail.creds.questionToAnswerMap);
        };

        window.addEventListener(CREDS_UPDATE, handleUpdateCreds);

        return () => window.removeEventListener(CREDS_UPDATE, handleUpdateCreds);
    }, [handleUpdateQuestionToAnswerMap]);

    if (!creds) {
        return null;
    }

    const anyQuestionRendered = isAnyQuestionRendered(creds);

    return (
        <>
            <ResumeCard label={trls[TrlKeys.title]} onEdit={handleEdit} mode={anyQuestionRendered ? 'edit' : 'add'}>
                <div className={styles.answers}>
                    {anyQuestionRendered ? (
                        Object.entries(creds.questionToAnswerMap).map(([questionId, answers]) => (
                            <QuestionAnswer key={questionId} questionId={questionId} answers={answers} />
                        ))
                    ) : (
                        <>
                            {isMobile && (
                                <div className={styles.empty}>
                                    <Text typography="subtitle-1-semibold">{trls[TrlKeys.noCredsTitle]}</Text>
                                    <Text typography="paragraph-3-regular" style="secondary">
                                        {trls[TrlKeys.noCredsSubtitle]}
                                    </Text>
                                </div>
                            )}
                            {!isMobile && (
                                <Cell>
                                    <CellText type="title">{trls[TrlKeys.noCredsTitle]}</CellText>
                                    <CellText type="subtitle">{trls[TrlKeys.noCredsSubtitle]}</CellText>
                                </Cell>
                            )}
                        </>
                    )}
                </div>
            </ResumeCard>
            <ContainerForMicroFrontend place={'credsEditor'} />
        </>
    );
});

export default ApplicantResumeCreds;
