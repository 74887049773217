import { useRef } from 'react';
import { connect } from 'react-redux';

import resumePrintButtonClick from '@hh.ru/analytics-js-events/build/xhh/employer/resume/resume_print_button_click';
import { TooltipHover, Button } from '@hh.ru/magritte-ui';
import { PrinterOutlinedSize16 } from '@hh.ru/magritte-ui/icon';
import BlokoButton from 'bloko/blocks/button';
import HoverTip, { TipLayer } from 'bloko/blocks/drop/Tip/HoverTip';
import urlParser from 'bloko/common/urlParser';

import BlokoIconReplaceContainer from 'src/components/BlokoIconReplaceContainer';
import loadContacts from 'src/components/ResumeView/LoadContacts';
import translation from 'src/components/translation';
import { useIsClient } from 'src/hooks/useIsClient';

const ButtonPrint = ({ trls, validate, resumeId, location, isMagritte = false }) => {
    const isClient = useIsClient();
    const activatorRef = useRef(null);

    let url = { href: '' };
    url = urlParser(location.pathname + location.search);
    url.params.print = [true];

    const onClick = (event) => {
        resumePrintButtonClick({ vacancyId: location.query.vacancyId, resumeId });
        validate(event, 'Print')
            .then(() => window.open(url.href, '_blank', 'noopener'))
            .catch(console.error);
    };

    if (isMagritte) {
        return (
            <>
                <Button
                    size="small"
                    mode="secondary"
                    icon={<PrinterOutlinedSize16 />}
                    onClick={onClick}
                    aria-label="resume-print"
                    data-qa="resume-print-view"
                    ref={activatorRef}
                >
                    {null}
                </Button>
                <TooltipHover activatorRef={activatorRef} placement="top-center">
                    {trls[ButtonPrint.trls.print]}
                </TooltipHover>
            </>
        );
    }

    return (
        <HoverTip
            host={isClient ? document.body : null}
            layer={TipLayer.Overlay}
            render={() => trls[ButtonPrint.trls.print]}
            activatorRef={activatorRef}
        >
            <BlokoButton
                icon={
                    <BlokoIconReplaceContainer>
                        <PrinterOutlinedSize16 initialColor="secondary" />
                    </BlokoIconReplaceContainer>
                }
                Element={'button'}
                onClick={onClick}
                data-qa="resume-print-view"
                aria-label={trls[ButtonPrint.trls.print]}
                innerRef={activatorRef}
            />
        </HoverTip>
    );
};

ButtonPrint.trls = {
    print: 'topbuttons.resume.print',
};

export default connect((state) => ({ location: state.router.location, resumeId: state.resume?.id }))(
    loadContacts(translation(ButtonPrint))
);
