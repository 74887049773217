import { ComponentType, useMemo } from 'react';

import Analytics from '@hh.ru/analytics-js';
import { useSelector } from '@hh.ru/front-static-app';
import { Link, Tag, Text, VSpacingContainer } from '@hh.ru/magritte-ui';
import {
    LinkedinSolidSize16,
    FacebookSolidSize16,
    SkypeSolidSize16,
    FlSolidSize16,
    LivejournalSolidSize16,
    MediumSolidSize16,
} from '@hh.ru/magritte-ui/service';

import translation from 'src/components/translation';
import {
    getConvertedPersonalSites,
    getSeparatedSocialAndPersonalSites,
} from 'src/models/applicant/resume/blocks/contacts/lib';
import { selectResumePersonalSite } from 'src/models/applicant/resume/blocks/contacts/selectors';

import styles from './styles.less';

const sendAnalyticsOnSocialLinkClick = () => {
    Analytics.sendEvent('employer', 'resume-with-social-link-click');
};

const TrlKeys = {
    personalSites: 'applicant.resume.contacts.field.personalSites',
};

const SOCIAL_SITE_ICON = {
    linkedin: LinkedinSolidSize16,
    facebook: FacebookSolidSize16,
    skype: SkypeSolidSize16,
    freelance: FlSolidSize16,
    livejournal: LivejournalSolidSize16,

    moi_krug: MediumSolidSize16,
} as const;

const ContactsPersonalSite: ComponentType = translation(({ trls }) => {
    const personalSites = useSelector(selectResumePersonalSite);

    const convertedSites = useMemo(() => {
        return getConvertedPersonalSites(personalSites);
    }, [personalSites]);

    const [personalSite, socialSites] = useMemo(() => {
        return getSeparatedSocialAndPersonalSites(convertedSites);
    }, [convertedSites]);

    if (socialSites.length === 0 && !personalSite) {
        return null;
    }

    return (
        <VSpacingContainer default={12}>
            <Text style="secondary" typography="label-3-regular">
                {trls[TrlKeys.personalSites]}
            </Text>
            <div className={styles.list}>
                {personalSite && (
                    <Link
                        key={personalSite.type}
                        href={personalSite.url}
                        target="_blank"
                        rel="noopener noreferrer"
                        onClick={sendAnalyticsOnSocialLinkClick}
                        data-qa={`resume-personalsite-${personalSite.type}`}
                    >
                        <div className={styles.link}>
                            <Tag style="neutral">{personalSite.text}</Tag>
                        </div>
                    </Link>
                )}
                {socialSites.map((site) => (
                    <div key={`${site.text}-${site.type}`}>
                        {site.url ? (
                            <Link
                                href={site.url}
                                target="_blank"
                                rel="noopener noreferrer"
                                onClick={sendAnalyticsOnSocialLinkClick}
                                data-qa={`resume-personalsite-${site.type}`}
                            >
                                <div className={styles.link}>
                                    <Tag icon={SOCIAL_SITE_ICON[site.type]} style="neutral">
                                        {site.text}
                                    </Tag>
                                </div>
                            </Link>
                        ) : (
                            <div
                                className={styles.link}
                                onClick={sendAnalyticsOnSocialLinkClick}
                                data-qa={`resume-personalsite-${site.type}`}
                            >
                                <Tag icon={SOCIAL_SITE_ICON[site.type]} style="neutral">
                                    {site.text}
                                </Tag>
                            </div>
                        )}
                    </div>
                ))}
            </div>
        </VSpacingContainer>
    );
});

export default ContactsPersonalSite;
