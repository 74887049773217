import { FC } from 'react';

import { HSpacing, Text } from '@hh.ru/magritte-ui';
import type { TextProps } from '@hh.ru/magritte-ui-typography/types';

import { DOT } from 'src/utils/constants/symbols';

interface ResumeBulletProps {
    paddings?: boolean;
    leftSpace?: boolean;
}

const ResumeBullet: FC<ResumeBulletProps & Pick<TextProps, 'typography' | 'style'>> = ({
    paddings = true,
    leftSpace = true,
    style = 'tertiary',
    typography,
}) => {
    return (
        <Text Element="span" typography={typography} style={style}>
            {paddings && <HSpacing default={2} />}
            {`${leftSpace ? ' ' : ''}${DOT} `}
            {paddings && <HSpacing default={2} />}
        </Text>
    );
};

export default ResumeBullet;
