import { useMemo } from 'react';

import {
    combineProviders,
    createDataProvider,
    decorateWithResultProducer,
    StaticDataFetcherItem,
    DataSectionOfType,
    DataSection,
    createStaticDataProvider,
} from '@hh.ru/magritte-ui';

import { PopularAreaDTO } from 'src/models/applicant/resume/api/dto';

import { getAreaLeafs } from 'src/models/applicant/resume/api/area/fetcher';

export const buildSearchDataProvider = (siteLanguage: string) =>
    createDataProvider({
        fetcher: decorateWithResultProducer(
            (query: string) => getAreaLeafs(query, siteLanguage),
            (items) => {
                if (!items) {
                    return [];
                }

                const sections: DataSection<StaticDataFetcherItem>[] = [];
                const section: DataSectionOfType<'cells', StaticDataFetcherItem> = { type: 'cells', items: [] };

                items.forEach((item) => {
                    const id = String(item.id);
                    section.items.push({ value: id, data: { text: item.text, value: id } });
                });

                sections.push(section);

                return sections;
            }
        ),
    });

const buildStaticDataProvider = (delimeterTitle: string, popularAreas: PopularAreaDTO[]) => {
    const items = popularAreas.map((area) => ({ value: String(area.id), text: area.name }));
    return createStaticDataProvider(items, delimeterTitle);
};

export const useAreaDataProvider = (siteLanguage: string, delimeterTitle: string, popularAreas: PopularAreaDTO[]) => {
    return useMemo(() => {
        const providers = [buildSearchDataProvider(siteLanguage)];
        if (popularAreas.length) {
            providers.push(buildStaticDataProvider(delimeterTitle, popularAreas));
        }
        return combineProviders(providers);
    }, [siteLanguage, delimeterTitle, popularAreas]);
};
