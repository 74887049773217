import { ComponentProps } from 'react';

import Column from 'bloko/blocks/column';

import { selectorMColumnSize } from 'src/components/HiringManager/utils/selectorMColumnSize';
import { useSelector } from 'src/hooks/useSelector';

type MSizes = ComponentProps<typeof Column>['m'];

const useGetMColumnSize = (): { mMain: MSizes; mSidebar: MSizes } => {
    return useSelector(selectorMColumnSize);
};

export { useGetMColumnSize };
