import { Fragment, useMemo } from 'react';

import { DotFilledSize16 } from '@hh.ru/magritte-ui/icon';

import ResumePhoneDeepLinks from 'src/components/ResumePhoneDeepLinks';
import { useSelector, useSelectorNonNullable } from 'src/hooks/useSelector';
import { Phone } from 'src/models/resumeCard';
import { OTHER_CONTACTS } from 'src/utils/constants/resumeHiddenFields';

import Site from 'src/pages/ResumeView/employerRedesign/components/ResumeContacts/PersonalSite';
import SocialSite from 'src/pages/ResumeView/employerRedesign/components/ResumeContacts/SocialSite';

import styles from './styles.less';

const PERSONAL_TYPE = 'personal';

const ResumeContactsPersonalSites = () => {
    const personalSites = useSelector((state) => state.resume?.personalSite?.value || []);
    const personalSite = personalSites?.find(({ type, url }) => type === PERSONAL_TYPE && !!url);

    const socialSites = useMemo(() => {
        const sites = personalSites.filter(({ type }) => type !== PERSONAL_TYPE);
        const withUrl = sites.filter(({ url }) => !!url);
        const withoutUrl = sites.filter(({ url }) => !url);

        return [...withUrl, ...withoutUrl];
    }, [personalSites]);

    const {
        phone,
        id: resumeId,
        simHash,
        created,
        hash: resumeHash,
        hiddenFields,
    } = useSelectorNonNullable(({ resume }) => resume);
    const shouldHideSites = hiddenFields.includes(OTHER_CONTACTS);
    const shouldShowDeepLinks = !!phone.value?.[0]?.raw;

    if (shouldHideSites) {
        return null;
    }

    const items = [
        personalSite && <Site {...personalSite} />,
        socialSites?.length && socialSites.map((site) => <SocialSite key={site.type} {...site} />),
        shouldShowDeepLinks && phone.value && (
            <ResumePhoneDeepLinks
                isMagritte
                phone={phone.value[0] as Phone}
                resumeId={resumeId}
                simHash={simHash}
                created={created}
                resumeHash={resumeHash}
                fromResumeView={true}
            />
        ),
    ]
        .filter(Boolean)
        .flat();

    if (!items.length && !(shouldShowDeepLinks && phone.value)) {
        return null;
    }

    return (
        <div className={styles.oneRow}>
            {!!items.length && (
                <div className={styles.wrapper}>
                    {items.map((item, index) => (
                        <Fragment key={index}>
                            {item}
                            {index !== items.length - 1 && <DotFilledSize16 initialColor="tertiary" />}
                        </Fragment>
                    ))}
                </div>
            )}
        </div>
    );
};

export default ResumeContactsPersonalSites;
