import { translation } from '@hh.ru/front-static-app';
import { Card, Text, VSpacing, Link } from '@hh.ru/magritte-ui';
import { SPALink } from '@hh.ru/redux-spa-middleware';

const TrlKeys = {
    title: 'rsvUserCompetencies.applicant.employeesCannotSeeResults.title',
    text: 'rsvUserCompetencies.applicant.employeesCannotSeeResults.text',
    toSettings: 'rsvUserCompetencies.applicant.employeesCannotSeeResults.goToSettings',
};

const RsvVerificationVisibilityBanner = translation(({ trls }) => {
    return (
        <Card style="secondary" stretched borderRadius={16} padding={12}>
            <Text typography="custom-2-medium">{trls[TrlKeys.title]}</Text>
            <VSpacing default={6} />
            <Text typography="label-2-regular" style="secondary">
                {trls[TrlKeys.text]}
            </Text>
            <VSpacing default={12} />
            <Link Element={SPALink} to="/applicant/settings">
                {trls[TrlKeys.toSettings]}
            </Link>
        </Card>
    );
});

export default RsvVerificationVisibilityBanner;
