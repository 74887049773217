import { useMemo } from 'react';

import { Text, VSpacingContainer } from '@hh.ru/magritte-ui';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import DenseText from 'src/components/DenseText';
import FormatMoney from 'src/components/FormatMoney';
import translation from 'src/components/translation';
import { useSelector } from 'src/hooks/useSelector';
import { capitalized } from 'src/models/applicant/resume/lib/common/string';
import { EmploymentType, WorkSchedule } from 'src/models/resume/resumeCommon.types';
import ResumeBlock from 'src/pages/ResumeView/employerRedesign/components/ResumeBlock';
import { NON_BREAKING_SPACE } from 'src/utils/constants/symbols';

import styles from './styles.less';

const TrlKeys = {
    inHand: 'resume.salary.net',
    professionalRoleTitle: 'resume.professionalRoles.specializations',
    employmentTitle: 'resume.additionalInfo.employment.title',
    employment: {
        [EmploymentType.Probation]: 'vacancy.employment.probation',
        [EmploymentType.Full]: 'vacancy.employment.full',
        [EmploymentType.Part]: 'vacancy.employment.part',
        [EmploymentType.Volunteer]: 'vacancy.employment.volunteer',
        [EmploymentType.Project]: 'vacancy.employment.project',
    },
    workScheduleTitle: 'resume.additionalInfo.workSchedule.title',
    workSchedule: {
        [WorkSchedule.FullDay]: 'schedule.lowercase.full_day',
        [WorkSchedule.Shift]: 'schedule.lowercase.shift',
        [WorkSchedule.Flexible]: 'schedule.lowercase.flexible',
        [WorkSchedule.Remote]: 'schedule.lowercase.remote',
        [WorkSchedule.FlyInFlyOut]: 'schedule.lowercase.fly_in_fly_out',
    },
};

const ResumeSpecializations: TranslatedComponent = ({ trls }) => {
    const position = useSelector((state) => state.resume?.title.value);
    const salary = useSelector((state) => state.resume?.salary.value);
    const professionalRole = useSelector((state) => state.resume?.professionalRole.value);
    const employment = useSelector((state) => state.resume?.employment.value);
    const workSchedule = useSelector((state) => state.resume?.workSchedule.value);

    const items = useMemo(
        () =>
            [
                {
                    title: trls[TrlKeys.professionalRoleTitle],
                    value: professionalRole?.length && professionalRole.map(({ trl }) => trl).join(', '),
                },
                {
                    title: trls[TrlKeys.employmentTitle],
                    value:
                        employment?.length &&
                        employment.map(({ string }) => trls[TrlKeys.employment[string]]).join(', '),
                },
                {
                    title: trls[TrlKeys.workScheduleTitle],
                    value:
                        workSchedule?.length &&
                        workSchedule.map(({ string }) => capitalized(trls[TrlKeys.workSchedule[string]])).join(', '),
                },
            ].filter(({ value }) => !!value),
        [employment, professionalRole, trls, workSchedule]
    );

    return (
        <ResumeBlock border={false} innerSpacing={16}>
            <div className={styles.header}>
                <Text typography="title-5-semibold">
                    <div className={styles.title}>{position}</div>
                </Text>
                {salary && (
                    <div className={styles.salary}>
                        <Text typography="title-5-semibold">
                            <FormatMoney currency={salary.currency}>{salary.amount}</FormatMoney>
                        </Text>
                        {NON_BREAKING_SPACE}
                        <Text Element="span" typography="label-2-regular">
                            {trls[TrlKeys.inHand]}
                        </Text>
                    </div>
                )}
            </div>
            {!!items.length && (
                <VSpacingContainer default={8}>
                    {items.map(({ title, value }) => (
                        <div className={styles.cell} key={title}>
                            <Text typography="label-3-regular" style="secondary">
                                {title}
                            </Text>
                            <DenseText typography="label-3-regular">{value}</DenseText>
                        </div>
                    ))}
                </VSpacingContainer>
            )}
        </ResumeBlock>
    );
};

export default translation(ResumeSpecializations);
