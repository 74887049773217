import { useMemo } from 'react';
import { useDispatch } from 'react-redux';

import { translation, useExperiment, useSelector } from '@hh.ru/front-static-app';
import { Input, VSpacingContainer } from '@hh.ru/magritte-ui';

import {
    selectResumeEditorOpenedRecomendation,
    selectResumeEditorRecommendationIndex,
} from 'src/models/applicant/resume/blocks/recommendation/editor/selectors';
import { useResumeEditorListField } from 'src/models/applicant/resume/editor/form/change';
import { useTranslatedErrorMap } from 'src/models/applicant/resume/editor/form/errors';
import { isNewArrayField } from 'src/models/applicant/resume/editor/lib';
import { deleteFieldResumeEditor } from 'src/models/applicant/resume/editor/store/actions/delete';
import { submitResumeEditorData } from 'src/models/applicant/resume/editor/store/actions/submitEditor';
import { closeResumeEditorModal } from 'src/models/applicant/resume/editor/store/actions/toggleModal';
import EditorModal from 'src/pages/ResumeView/redesign/common/EditorModal';

import CompanyField from 'src/pages/ResumeView/redesign/blocks/recommendation/Editor/Company';
import PositionField from 'src/pages/ResumeView/redesign/blocks/recommendation/Editor/Position';

const TrlKeys = {
    title: 'resume.editor.recommendation.title',
    delete: 'resume.editor.recommendation.delete.title',
    labels: {
        name: 'resume.editor.recommendation.labels.name',
        organization: 'resume.editor.recommendation.labels.organization',
        position: 'resume.editor.recommendation.labels.position',
    },
    description: 'resume.editor.recommendation.description',
    commonErrors: {
        UNKNOWN: 'resume.editor.field.error.unknown',
        REQUIRED: 'resume.editor.field.error.required',
        NOT_MATCH_REGEXP: 'resume.editor.field.error.regexp',
        LENGTH_GREATER_THAN_MAXIMUM: {
            one: 'resume.editor.field.error.max-length.one',
            some: 'resume.editor.field.error.max-length.some',
            many: 'resume.editor.field.error.max-length.many',
        },
    },
};
const FIELD_NAME = 'recommendation';

const ResumeRecommendationEditor = translation(({ trls }) => {
    const isNewSuggest = useExperiment('web_new_positions_suggestions');
    const dispatch = useDispatch();

    const visible = useSelector(selectResumeEditorOpenedRecomendation);

    const index = useSelector(selectResumeEditorRecommendationIndex);
    const errorsMap = useTranslatedErrorMap(TrlKeys.commonErrors, trls);

    const id = useResumeEditorListField<number>(FIELD_NAME, index, 'id');
    const name = useResumeEditorListField(FIELD_NAME, index, 'name', errorsMap);
    const position = useResumeEditorListField(FIELD_NAME, index, 'position', errorsMap);
    const organization = useResumeEditorListField(FIELD_NAME, index, 'organization', errorsMap);

    const handleSave = () => dispatch(submitResumeEditorData());
    const handleClose = () => dispatch(closeResumeEditorModal());
    const handleDelete = () => dispatch(deleteFieldResumeEditor(trls[TrlKeys.delete]));

    const isNew = useMemo(() => isNewArrayField({ id: id.value }), [id.value]);

    return (
        <EditorModal
            title={trls[TrlKeys.title]}
            visible={visible}
            onSave={handleSave}
            onClose={handleClose}
            onDelete={!isNew ? handleDelete : undefined}
            description={trls[TrlKeys.description]}
        >
            <VSpacingContainer default={12}>
                <Input
                    size="medium"
                    value={name.value}
                    invalid={name.invalid}
                    onChange={name.onChange}
                    errorMessage={name.errorMessage}
                    placeholder={trls[TrlKeys.labels.name]}
                />

                {isNewSuggest ? (
                    <>
                        <PositionField
                            value={position.value}
                            invalid={position.invalid}
                            onChange={position.onChange}
                            errorMessage={position.errorMessage}
                            placeholder={trls[TrlKeys.labels.position]}
                        />
                        <CompanyField
                            value={organization.value}
                            invalid={organization.invalid}
                            onChange={organization.onChange}
                            errorMessage={organization.errorMessage}
                            placeholder={trls[TrlKeys.labels.organization]}
                        />
                    </>
                ) : (
                    <>
                        <Input
                            size="medium"
                            value={position.value}
                            invalid={position.invalid}
                            onChange={position.onChange}
                            errorMessage={position.errorMessage}
                            placeholder={trls[TrlKeys.labels.position]}
                        />
                        <Input
                            size="medium"
                            value={organization.value}
                            invalid={organization.invalid}
                            onChange={organization.onChange}
                            errorMessage={organization.errorMessage}
                            placeholder={trls[TrlKeys.labels.organization]}
                        />
                    </>
                )}
            </VSpacingContainer>
        </EditorModal>
    );
});

export default ResumeRecommendationEditor;
