import { VSpacing, Modal, useBreakpoint, BottomSheet, NavigationBar, Action, Text, Loader } from '@hh.ru/magritte-ui';
import { CrossOutlinedSize24 } from '@hh.ru/magritte-ui/icon';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import CdnImg from 'src/components/CdnImg';
import translation from 'src/components/translation';

import styles from './styles.less';

const TrlKeys = {
    error: 'resume.block.portfolio.load.error',
    withoutName: 'resume.block.portfolio.item.withoutName',
};

interface ResumePortfolioModalProps {
    visible: boolean;
    onClose: () => void;
    title?: string | null;
    image: string;
    loaded: boolean;
    error: boolean;
}

const ResumePortfolioModal: TranslatedComponent<ResumePortfolioModalProps> = ({
    trls,
    visible,
    onClose,
    title,
    image,
    loaded,
    error,
}) => {
    const { isMobile } = useBreakpoint();
    const closeAction = <Action mode="secondary" onClick={onClose} icon={CrossOutlinedSize24} />;

    const renderContent = () => {
        if (error) {
            return (
                <div className={styles.wrapper}>
                    <Text style="negative">{trls[TrlKeys.error]}</Text>;
                </div>
            );
        }

        if (loaded) {
            return (
                <div className={styles.wrapper}>
                    <CdnImg className={styles.image} path={image} alt="" />
                </div>
            );
        }

        return (
            <div className={styles.wrapper}>
                <Loader size={24} initial="accent-secondary" />
            </div>
        );
    };

    return isMobile ? (
        <BottomSheet
            visible={visible}
            onClose={onClose}
            header={<NavigationBar size="large" right={closeAction} title={title || trls[TrlKeys.withoutName]} />}
        >
            {renderContent()}
            <VSpacing default={8} />
        </BottomSheet>
    ) : (
        <Modal
            visible={visible}
            onClose={onClose}
            size="large"
            titleSize="large"
            title={title || trls[TrlKeys.withoutName]}
            actions={closeAction}
        >
            {renderContent()}
        </Modal>
    );
};

export default translation(ResumePortfolioModal);
