import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import TagWithContrastText from 'src/components/TagWithContrastText';
import translation from 'src/components/translation';
import { useSelectorNonNullable } from 'src/hooks/useSelector';
import { JobSearchStatus } from 'src/models/applicantUserStatuses';

const DISPLAYED_STATUSES = [
    JobSearchStatus.ActiveSearch,
    JobSearchStatus.LookingForOffers,
    JobSearchStatus.HasJobOffer,
    JobSearchStatus.AcceptedJobOffer,
    JobSearchStatus.NotLookingForJob,
] as const;

const IN_SEARCH_STATUSES = [JobSearchStatus.ActiveSearch, JobSearchStatus.LookingForOffers] as const;

const TrlKeys = {
    [JobSearchStatus.ActiveSearch]: 'jobSearchStatus.employer.version2.active_search',
    [JobSearchStatus.LookingForOffers]: 'jobSearchStatus.employer.version2.looking_for_offers',
    [JobSearchStatus.HasJobOffer]: 'jobSearchStatus.employer.version2.has_job_offer',
    [JobSearchStatus.AcceptedJobOffer]: 'jobSearchStatus.employer.version2.accepted_job_offer',
    [JobSearchStatus.NotLookingForJob]: 'jobSearchStatus.employer.version2.not_looking_for_job',
};

const ResumeJobSearchStatus: TranslatedComponent = ({ trls }) => {
    const userId = useSelectorNonNullable((state) => state.resume.user);
    const applicantUserStatus = useSelectorNonNullable((state) => state.resume.applicantUserStatuses[userId]);
    const currentStatus = applicantUserStatus?.jobSearchStatus?.name;

    if (!currentStatus || !DISPLAYED_STATUSES.includes(currentStatus)) {
        return null;
    }

    const isInSearch = IN_SEARCH_STATUSES.includes(currentStatus);
    const tagStyle = isInSearch ? 'positive' : 'warning';

    return (
        <TagWithContrastText style={tagStyle} data-qa="job-search-status">
            {trls[TrlKeys[currentStatus as keyof typeof TrlKeys]]}
        </TagWithContrastText>
    );
};

export default translation(ResumeJobSearchStatus);
