import { useEffect, useRef, useState } from 'react';
import classnames from 'classnames';

import { VSpacing, Modal, useBreakpoint, BottomSheet, NavigationBar, Action, Loader } from '@hh.ru/magritte-ui';
import { CrossOutlinedSize24 } from '@hh.ru/magritte-ui/icon';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import translation from 'src/components/translation';
import { useSelector } from 'src/hooks/useSelector';
import { useResumeMapSearchPoints } from 'src/pages/ResumeView/employerRedesign/utils';

import styles from './styles.less';

const TrlKeys = {
    title: 'resume.map.modal.title',
    subtitle: 'resume.map.modal.subtitle',
};

interface ResumeMapModalProps {
    visible: boolean;
    onClose: () => void;
}

const ResumeMapModal: TranslatedComponent<ResumeMapModalProps> = ({ trls, visible, onClose }) => {
    const staticHost = useSelector((state) => state.config.staticHost);
    const mapSearchPoints = useResumeMapSearchPoints();

    const { isMobile } = useBreakpoint();
    const [isLoading, setLoading] = useState(true);
    const mapContainer = useRef<HTMLDivElement>(null);

    useEffect(() => {
        const container = mapContainer.current;

        if (!visible || !container || !mapSearchPoints) {
            return undefined;
        }

        const abortController = new AbortController();
        setLoading(true);

        import(/* webpackMode: "eager" */ 'Modules/Maps/showMap').then(({ default: showMap, defaults }) => {
            showMap(
                container,
                {
                    address: {
                        mapData: {
                            points: {
                                center: { ...mapSearchPoints, zoom: 4 },
                                marker: mapSearchPoints,
                            },
                        },
                    },
                    disableScrollZoom: true,
                    placemarkZIndex: defaults.placemarkZIndex + 1,
                    placemarkIcon: `${staticHost}/images/Components/VacancyMap/resume-placemark.svg`,
                    placemarkIconSize: [39, 39],
                    placemarkOffset: [-10, 12],
                    zoomControlPosition: {
                        left: 10,
                        top: 30,
                    },
                },
                abortController.signal,
                'resume-modal-map'
            )
                .then(() => {
                    if (abortController.signal.aborted) {
                        return;
                    }
                    setLoading(false);
                })
                .catch(console.error);
        }, console.error);
        return () => {
            abortController?.abort();
        };
    }, [visible, mapSearchPoints, staticHost, isMobile]);

    if (!mapSearchPoints) {
        return null;
    }

    const closeAction = <Action mode="secondary" onClick={onClose} icon={CrossOutlinedSize24} />;
    const content = (
        <>
            {isLoading && (
                <div className={styles.loader}>
                    <Loader size={24} />
                </div>
            )}
            <div
                className={classnames(styles.map, {
                    [styles.mapHidden]: isLoading,
                })}
                ref={mapContainer}
            />
        </>
    );

    return isMobile ? (
        <BottomSheet
            visible={visible}
            onClose={onClose}
            header={
                <NavigationBar
                    size="large"
                    right={closeAction}
                    title={trls[TrlKeys.title]}
                    subtitle={trls[TrlKeys.subtitle]}
                />
            }
        >
            {content}
            <VSpacing default={8} />
        </BottomSheet>
    ) : (
        <Modal
            visible={visible}
            onClose={onClose}
            titleSize="medium"
            title={trls[TrlKeys.title]}
            titleDescription={trls[TrlKeys.subtitle]}
            titleDescriptionStyle="secondary"
            actions={closeAction}
        >
            {content}
        </Modal>
    );
};

export default translation(ResumeMapModal);
