import { useMemo } from 'react';

import { useSelector } from '@hh.ru/front-static-app';
import { DataProvider, DataProviderResult } from '@hh.ru/magritte-ui';

import { updateUrl } from 'Modules/url';
import { buildDataProvider, getAutosuggestItems } from 'src/utils/suggest/buildDataProvider';

export interface CompanyDTO {
    id: number;
    url: string;
    area: string;
    text: string;
    area_id: string;
    logo_url?: string;
    industry: string;
    industries?: Array<{ id: number; parentId: number; name: string }>;
    industry_id: number;
}

const REMOTE = '/autosuggest/multiprefix/v2';

export function createSearchFormatter<T extends CompanyDTO>() {
    return function (items: T[] | null): DataProviderResult<T> {
        if (!items) {
            return [];
        }

        return items.map((item) => ({
            type: 'cells',
            items: [
                {
                    data: item,
                    value: item.text,
                },
            ],
        }));
    };
}

export const useCompanyDataProvider = (): DataProvider<CompanyDTO> => {
    const lang = useSelector((state) => state.langs[0]);
    const dynamicUrlPart = `companies_${lang}`;
    const remoteUrl = updateUrl(REMOTE, { f: 'HEADHUNTER' });

    const searchProvider = useMemo(
        () =>
            buildDataProvider(remoteUrl, dynamicUrlPart, createSearchFormatter(), getAutosuggestItems, {
                minCharsCount: 2,
            }),
        [dynamicUrlPart, remoteUrl]
    );

    return searchProvider;
};
