import { ElementShownAnchor } from '@hh.ru/analytics-js';
import { Cell, Text, VSpacingContainer } from '@hh.ru/magritte-ui';
import { ChevronRightOutlinedSize24 } from '@hh.ru/magritte-ui/icon';
import { SPALink } from '@hh.ru/redux-spa-middleware';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import FormatMoney from 'src/components/FormatMoney';
import translation from 'src/components/translation';
import { useFeatureEnabled } from 'src/hooks/useFeatureEnabled';
import { useSearchResultShown } from 'src/hooks/useSearchResultShown';
import { ResumeCardData } from 'src/models/resumeCard';
import ResumeBullet from 'src/pages/ResumeView/employerRedesign/components/ResumeBullet';
import { SearchType } from 'src/types/search/common';
import { getFullName } from 'src/utils/resume/getFullName';

const Features = {
    collectResumeViewDuration: 'collect_resume_view_duration',
};

const TrlKeys = {
    emptyTitle: 'resume.title.empty',
    compensationNotSet: 'vacancySearchResults.compensationNotSet',
};

const getSalary = (resume: ResumeCardData) => {
    const salary = resume.salary?.[0]?.amount;
    const currency = resume.salary?.[0]?.currency;

    if (!salary || !currency) {
        return null;
    }

    return <FormatMoney currency={currency}>{salary}</FormatMoney>;
};

const getExperience = (resume: ResumeCardData) => {
    const companyName = resume.shortExperience?.[0]?.companyName;
    const position = resume.shortExperience?.[0]?.position;

    if (!position) {
        return null;
    }

    return [companyName, position].filter(Boolean).join(', ');
};

interface ResumeRelatedItemProps {
    resume: ResumeCardData;
}

const ResumeRelatedItem: TranslatedComponent<ResumeRelatedItemProps> = ({ resume, trls }) => {
    const collectResumeViewDurationFeature = useFeatureEnabled(Features.collectResumeViewDuration);

    const title = resume.title?.[0]?.string;
    const name = getFullName(resume);
    const salary = getSalary(resume);
    const experience = getExperience(resume);

    const queryParams: Record<string, string> = {};
    if (collectResumeViewDurationFeature && resume.searchRid) {
        queryParams.searchRid = resume.searchRid;
    }

    const searchResultShown = useSearchResultShown({
        searchType: SearchType.Resume,
        itemId: resume._attributes.hash,
        searchRid: resume.searchRid,
    });

    return (
        <div data-qa="similar-resumes-block-item">
            <ElementShownAnchor fn={searchResultShown}>
                <Cell
                    Element={SPALink}
                    to={`/resume/${resume._attributes.hash}?hhtmFromLabel=related_resumes`}
                    additionalQueryParams={queryParams}
                    align="top"
                    right={<ChevronRightOutlinedSize24 initialColor="secondary" />}
                    data-qa="similar-resumes-block-item-link"
                >
                    <VSpacingContainer default={4}>
                        <Text typography="label-3-regular" data-qa="similar-resumes-block-item-title">
                            {name && (
                                <>
                                    <span data-qa="similar-resumes-block-item-name">{name}</span>
                                    <ResumeBullet />
                                </>
                            )}
                            {title || trls[TrlKeys.emptyTitle]}
                        </Text>
                        <Text
                            typography="label-3-regular"
                            style="secondary"
                            data-qa="similar-resumes-block-item-salary"
                        >
                            {salary || trls[TrlKeys.compensationNotSet]}
                        </Text>
                        <Text
                            typography="label-3-regular"
                            style="secondary"
                            data-qa="similar-resumes-block-item-experience"
                        >
                            {experience}
                        </Text>
                    </VSpacingContainer>
                </Cell>
            </ElementShownAnchor>
        </div>
    );
};

export default translation(ResumeRelatedItem);
