import { useMemo } from 'react';
import { useDispatch } from 'react-redux';
import isTomorrow from 'date-fns/isTomorrow';

import { ElementShownAnchor } from '@hh.ru/analytics-js';
import { translation, useSelector } from '@hh.ru/front-static-app';
import { Button, Card, Cell, CellText, VSpacing } from '@hh.ru/magritte-ui';
import { ArrowUpOutlinedSize24 } from '@hh.ru/magritte-ui/icon';
import { SPALink } from '@hh.ru/redux-spa-middleware';
import { format } from 'bloko/common/trl';

import { sendAnalyticsFindJobFaster } from 'Modules/ApplicantAnalytics';
import formatDate from 'Modules/formatDate';
import { useActionLinkAnalytics } from 'src/models/applicant/resume/analytics/provider';
import { selectResumeEditorRenewalSaving } from 'src/models/applicant/resume/editor/store/loading/selectors';
import { touchResume } from 'src/models/applicant/resume/renewal/actions';
import { useShowUpdateBlock } from 'src/models/applicant/resume/renewal/hooks';
import {
    selectIsRenewal,
    selectUpdatedTimeMs,
    selectUpdatedTimeoutMs,
    selectHasRenewalService,
} from 'src/models/applicant/resume/renewal/selectors';
import { selectResumeHash } from 'src/models/applicant/resume/selectors';

const TrlKeys = {
    title: 'applicant.resume.update.title',
    updateBtn: 'applicant.resume.update.button.text',
    lastUpdate: 'applicant.resume.update.last',
    renewalLink: 'applicant.resume.update.renewal.link',
    renewalToday: 'applicant.resume.update.manual.time.today',
    renewalTomorrow: 'applicant.resume.update.manual.time.tomorrow',
    renewalAutomatic: 'applicant.resume.update.renewal.automatic',
    renewalAutomaticEnabled: 'applicant.resume.update.renewal.automatic.enabled',
};

const RenewalResume = translation(({ trls }) => {
    const dispatch = useDispatch();

    const analytics = useActionLinkAnalytics();
    const visibility = useShowUpdateBlock();

    const hash = useSelector(selectResumeHash);
    const renewal = useSelector(selectIsRenewal);
    const loading = useSelector(selectResumeEditorRenewalSaving);
    const updatedTimeMs = useSelector(selectUpdatedTimeMs);
    const updateTimeoutMs = useSelector(selectUpdatedTimeoutMs);
    const hasRenewalService = useSelector(selectHasRenewalService);

    const isManualUpdateable = useMemo(() => {
        const msToManualUpdate = new Date(updatedTimeMs + updateTimeoutMs).getTime() - new Date().getTime();

        return msToManualUpdate <= 0;
    }, [updateTimeoutMs, updatedTimeMs]);

    const manualUpdateDateMs = useMemo(
        () => new Date(updatedTimeMs + updateTimeoutMs).getTime(),
        [updatedTimeMs, updateTimeoutMs]
    );

    const handleUpdate = () => {
        analytics.resumeUpdateClick();
        void dispatch(touchResume());
    };

    if (!visibility) {
        return null;
    }

    if (renewal) {
        return (
            <ElementShownAnchor fn={analytics.resumeRenewalElementShown} isActive={false}>
                <Card padding={12} borderRadius={24} borderWidth="default" stretched>
                    <Card padding={12}>
                        <Cell left={<ArrowUpOutlinedSize24 />}>
                            <CellText type="description">{trls[TrlKeys.title]}</CellText>
                            <CellText style="positive">{trls[TrlKeys.renewalAutomaticEnabled]}</CellText>
                        </Cell>
                    </Card>
                </Card>
            </ElementShownAnchor>
        );
    }

    if (isManualUpdateable) {
        return (
            <ElementShownAnchor fn={analytics.resumeRenewalElementShown} isActive>
                <Card padding={12} borderRadius={24} borderWidth="default" stretched>
                    <Card padding={12}>
                        <Cell left={<ArrowUpOutlinedSize24 />}>
                            <CellText type="description">{trls[TrlKeys.title]}</CellText>
                            <CellText>
                                {format(trls[TrlKeys.lastUpdate], {
                                    '{0}': formatDate(updatedTimeMs, 'dd MMMM'),
                                    '{1}': formatDate(updatedTimeMs, 'HH:mm'),
                                })}
                            </CellText>
                        </Cell>
                    </Card>
                    <VSpacing default={12} />
                    <Button
                        size="small"
                        mode="primary"
                        style="accent"
                        loading={loading}
                        disabled={loading}
                        onClick={handleUpdate}
                        data-qa="resume-update-button"
                        stretched
                    >
                        {trls[TrlKeys.updateBtn]}
                    </Button>
                </Card>
            </ElementShownAnchor>
        );
    }
    return (
        <ElementShownAnchor fn={analytics.resumeRenewalElementShown} isActive={false}>
            <Card padding={12} borderRadius={24} borderWidth="default" stretched>
                <Card padding={12}>
                    <Cell left={<ArrowUpOutlinedSize24 />}>
                        <CellText type="description">{trls[TrlKeys.title]}</CellText>
                        <CellText>
                            {format(
                                isTomorrow(manualUpdateDateMs)
                                    ? trls[TrlKeys.renewalTomorrow]
                                    : trls[TrlKeys.renewalToday],
                                {
                                    '{0}': formatDate(manualUpdateDateMs, 'HH:mm'),
                                }
                            )}
                        </CellText>
                    </Cell>
                </Card>
                {hasRenewalService && (
                    <>
                        <VSpacing default={12} />
                        <Button
                            Element={SPALink}
                            data-qa="resumeservice-button__renewresume"
                            stretched
                            to={`/applicant/services/payment?from=myresume&package=basic&resumeHash=${hash}`}
                            size="small"
                            mode="secondary"
                            style="accent"
                            onMouseUp={sendAnalyticsFindJobFaster}
                        >
                            {trls[TrlKeys.renewalLink]}
                        </Button>
                    </>
                )}
            </Card>
        </ElementShownAnchor>
    );
});

export default RenewalResume;
