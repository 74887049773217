import { DefaultRootState } from 'react-redux';
import { Dispatch } from 'redux';

import { SiteLanguages } from '@hh.ru/front-static-app/lib/types';

import { ResumeApi } from 'src/models/applicant/resume/api';
import { isSuccess } from 'src/models/applicant/resume/api/lib';
import { showNotification } from 'src/models/applicant/resume/editor/store/slice';
import { Thunk } from 'src/models/applicant/resume/editor/types/actions';
import { selectHhtmSource, selectResumeHash } from 'src/models/applicant/resume/selectors';
import { setApplicantResumeFields } from 'src/models/applicant/resume/slice';

export const submitResumeLanguage =
    (value: SiteLanguages): Thunk =>
    async (dispatch: Dispatch, getState: () => DefaultRootState): Promise<void> => {
        try {
            const resumeHash = selectResumeHash(getState());
            const hhtmSource = selectHhtmSource(getState());

            const response = await ResumeApi.update(resumeHash, hhtmSource, { lang: [{ string: value }] });

            if (isSuccess(response)) {
                dispatch(setApplicantResumeFields(response.resume));
                window.location.reload();
                return;
            }

            dispatch(showNotification('error-api'));
            // mb sentry log
        } catch (_) {
            dispatch(showNotification('error-api'));
            // mb sentry log
        }
    };
