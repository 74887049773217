import { ButtonTitle } from '@hh.ru/analytics-js-events/build/xhh/applicant/resume/resume_completion_button_click';

import { BUTTON_TITLE_BY_FIELD_NAME } from 'src/models/applicant/resume/analytics/constants';

export const convertFieldNameToAnalyticsButtonTitle = (fieldName: string): ButtonTitle => {
    const buttonTitle = BUTTON_TITLE_BY_FIELD_NAME[fieldName];
    if (buttonTitle) {
        return buttonTitle;
    }
    return fieldName as ButtonTitle;
};
