import { FC } from 'react';

import { useSelector } from '@hh.ru/front-static-app';

import { selectApplicantResumeGender } from 'src/models/applicant/resume/blocks/position/selectors';
import { Gender } from 'src/models/applicant/resume/blocks/position/types';

import { Female } from 'src/pages/ResumeView/redesign/blocks/position/Editor/fields/ArtifactPlaceholder/Female';
import { Male } from 'src/pages/ResumeView/redesign/blocks/position/Editor/fields/ArtifactPlaceholder/Male';
import { Undefined } from 'src/pages/ResumeView/redesign/blocks/position/Editor/fields/ArtifactPlaceholder/Undefined';

const ArtifactPlaceholder: FC<{ size?: number | string }> = ({ size }) => {
    const gender = useSelector(selectApplicantResumeGender);

    if (gender === Gender.Female) {
        return <Female role="img" width={size} height={size} />;
    }

    if (gender === Gender.Male) {
        return <Male role="img" width={size} height={size} />;
    }

    return <Undefined role="img" width={size} height={size} />;
};

export default ArtifactPlaceholder;
