import { useDispatch, useSelector } from 'react-redux';

import { translation } from '@hh.ru/front-static-app';
import { Card, Cell, useBreakpoint, Text, VSpacing } from '@hh.ru/magritte-ui';
import { ChevronRightOutlinedSize24, QuestionCircleOutlinedSize24 } from '@hh.ru/magritte-ui/icon';

import {
    updateKeySkillsLevels,
    closeSkillsLevelsEditor,
} from 'src/models/applicant/resume/blocks/keySkills/skillsLevels/actions';
import {
    selectSkillLevelList,
    selectLevelsBySkillId,
    selectSkillsLevelsEditorOpened,
} from 'src/models/applicant/resume/blocks/keySkills/skillsLevels/selector';
import {
    setSkillLevels,
    toggleSkillsLevelsInfoModal,
} from 'src/models/applicant/resume/blocks/keySkills/skillsLevels/slice';
import { LevelBySkillId } from 'src/models/applicant/resume/blocks/keySkills/skillsLevels/types';
import EditorModal from 'src/pages/ResumeView/redesign/common/EditorModal';

import { SkillsLevelsList } from 'src/pages/ResumeView/redesign/blocks/skills/KeySkillsLevelEditor/LevelsList';
import { SkillLevelModal } from 'src/pages/ResumeView/redesign/blocks/skills/KeySkillsLevelEditor/ModalInfo';
import SkillChangedAlert from 'src/pages/ResumeView/redesign/blocks/skills/KeySkillsLevelEditor/SkillChangedAlert';

const TrlKeys = {
    info: 'resume.editor.skills.levels.info',
    title: 'resume.editor.skills.levels.title',
};

const KeySkillsLevelEditor = translation(({ trls }) => {
    const dispatch = useDispatch();
    const { isMobile } = useBreakpoint();

    const skills = useSelector(selectSkillLevelList);
    const levels = useSelector(selectLevelsBySkillId);
    const visible = useSelector(selectSkillsLevelsEditorOpened);

    const handleLevelsChaned = (levels: LevelBySkillId) => dispatch(setSkillLevels(levels));

    const handleClose = () => dispatch(closeSkillsLevelsEditor());

    const handleSave = () => {
        void dispatch(updateKeySkillsLevels());
    };

    const handleOpenInfoModal = () => {
        dispatch(toggleSkillsLevelsInfoModal(true));
    };

    return (
        <>
            <EditorModal title={trls[TrlKeys.title]} visible={visible} onSave={handleSave} onClose={handleClose}>
                <Card padding={12} borderRadius={16} stretched borderWidth="default" onClick={handleOpenInfoModal}>
                    <Cell
                        left={<QuestionCircleOutlinedSize24 initialColor="secondary" />}
                        right={!isMobile ? <ChevronRightOutlinedSize24 initialColor="secondary" /> : undefined}
                    >
                        <Text typography="label-2-regular">{trls[TrlKeys.info]}</Text>
                    </Cell>
                </Card>
                <VSpacing default={8} />
                <SkillsLevelsList skills={skills} levels={levels} onChange={handleLevelsChaned} />
            </EditorModal>
            <SkillLevelModal />
            <SkillChangedAlert />
        </>
    );
});

export default KeySkillsLevelEditor;
