import { Fragment } from 'react';

import Text, { TextImportance } from 'bloko/blocks/text';

import formatDate from 'Modules/formatDate';

function History({ historyList, visible = true }) {
    if (!visible) {
        return null;
    }

    return (
        <Fragment>
            {historyList
                .sort((a, b) => b.lastViewDate['@timestamp'] - a.lastViewDate['@timestamp'])
                .map((item, index) => (
                    <div className="resume-sidebar-item" key={index}>
                        <div className="resume-sidebar-item__text-wrapper">{item.fullName}</div>
                        <div className="resume-sidebar-item__info">
                            <Text importance={TextImportance.Tertiary}>
                                <span suppressHydrationWarning>
                                    {formatDate(item.lastViewDate.$, 'dd.MM.yy HH:mm')}
                                </span>
                            </Text>
                        </div>
                    </div>
                ))}
        </Fragment>
    );
}

export default History;
