import { Fragment } from 'react';
import { useSelector } from 'react-redux';

import BlokoTranslateGuard from 'bloko/blocks/translateGuard';

import translation from 'src/components/translation';
import { useProfile } from 'src/hooks/useProfile';
import { Platform } from 'src/models/locale';
import ResumeEditLink from 'src/pages/ResumeView/components/View/ResumeEditLink';
import ResumeEmptyBlock from 'src/pages/ResumeView/components/View/ResumeEmptyBlock';
import ResumeJobArea from 'src/pages/ResumeView/components/View/ResumeJobArea';
import { MALE } from 'src/utils/constants/resumeGenders';
import { NON_BREAKING_SPACE } from 'src/utils/constants/symbols';

const TrlKeys = {
    metro: 'rb.edit.metro.m',
    district: 'rb.edit.district.m',
    'relocation.no_relocation.male': 'rb.no_relocation.male',
    'relocation.relocation_desirable.male': 'rb.relocation_desirable.male',
    'relocation.relocation_possible.male': 'rb.relocation_possible.male',
    'relocation.no_relocation.female': 'rb.no_relocation.female',
    'relocation.relocation_desirable.female': 'rb.relocation_desirable.female',
    'relocation.relocation_possible.female': 'rb.relocation_possible.female',
    'relocation.no_relocation.unknown': 'rb.no_relocation.unknown',
    'relocation.relocation_desirable.unknown': 'rb.relocation_desirable.unknown',
    'relocation.relocation_possible.unknown': 'rb.relocation_possible.unknown',

    'businessTrip.never.male': 'rb.businessTripReadiness.never.male',
    'businessTrip.ready.male': 'rb.businessTripReadiness.ready.male',
    'businessTrip.sometimes.male': 'rb.businessTripReadiness.sometimes.male',
    'businessTrip.never.female': 'rb.businessTripReadiness.never.female',
    'businessTrip.ready.female': 'rb.businessTripReadiness.ready.female',
    'businessTrip.sometimes.female': 'rb.businessTripReadiness.sometimes.female',
    'businessTrip.never.unknown': 'rb.businessTripReadiness.never.unknown',
    'businessTrip.ready.unknown': 'rb.businessTripReadiness.ready.unknown',
    'businessTrip.sometimes.unknown': 'rb.businessTripReadiness.sometimes.unknown',
};

const ResumePersonalLocation = translation(({ trls }) => {
    const gender = useSelector((state) => state.resume?.gender.value || MALE);
    const area = useSelector((state) => state.resume?.area.value);
    const metro = useSelector((state) => state.resume?.metro.value);
    const relocation = useSelector((state) => state.resume?.relocation.value);
    const relocationArea = useSelector((state) => state.resume?.relocationArea.value);
    const residenceDistrict = useSelector((state) => state.resume?.residenceDistrict.value);
    const businessTripReadiness = useSelector((state) => state.resume?.businessTripReadiness.value);
    const isEmpty = useSelector((state) => state.resume?.emptyBlocks.includes('personal'));
    const isZp = useSelector((state) => state.locale.platform === Platform.Zarplata);
    if (useProfile()) {
        return null;
    }

    const renderArea = () => {
        if (!area || !area.id) {
            return null;
        }
        return (
            <Fragment>
                <span data-qa="resume-personal-address">{area.title}</span>
                {', '}
            </Fragment>
        );
    };

    const renderDistrict = () => {
        if (!residenceDistrict) {
            return null;
        }
        return (
            <Fragment>
                <span data-qa="resume-personal-district">
                    {`${trls[TrlKeys.district]}${NON_BREAKING_SPACE}${residenceDistrict.title}, `}
                </span>
            </Fragment>
        );
    };

    const renderMetro = () => {
        if (!metro) {
            return null;
        }
        return (
            <Fragment>
                <span data-qa="resume-personal-metro" style={{ color: metro.color }}>
                    {trls[TrlKeys.metro]}
                    &nbsp;
                    {metro.title}
                </span>
                {', '}
            </Fragment>
        );
    };

    const renderRelocation = () => {
        return (
            <Fragment>
                {trls[TrlKeys[`relocation.${relocation}.${gender}`]]}
                {relocationArea.length > 0 && !isZp && ` (${relocationArea.map((area) => area.title).join(', ')})`}
            </Fragment>
        );
    };

    const renderBusinessTrip = () => {
        return (
            <Fragment>
                {', '}
                {trls[TrlKeys[`businessTrip.${businessTripReadiness}.${gender}`]]}
            </Fragment>
        );
    };

    if (isEmpty) {
        return <ResumeEmptyBlock block="personal" />;
    }

    return (
        <BlokoTranslateGuard>
            <p>
                {renderArea()}
                {renderDistrict()}
                {renderMetro()}
                {renderRelocation()}
                {renderBusinessTrip()}
            </p>
            {isZp && <ResumeJobArea />}
            <p>
                <ResumeEditLink block="personal" data-qa="resume-block-personal-edit" />
            </p>
        </BlokoTranslateGuard>
    );
});

export default ResumePersonalLocation;
