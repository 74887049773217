import { useMemo } from 'react';
import { useDispatch } from 'react-redux';

import { translation, useSelector } from '@hh.ru/front-static-app';
import {
    Cell,
    Card,
    Radio,
    Action,
    Button,
    CellText,
    BottomSheet,
    NavigationBar,
    BottomSheetFooter,
    VSpacingContainer,
} from '@hh.ru/magritte-ui';
import { CrossOutlinedSize24 } from '@hh.ru/magritte-ui/icon';

import {
    selectResumeLanguages,
    selectResumeEditorOpenedLang,
} from 'src/models/applicant/resume/blocks/languages/selectors';
import { useResumeEditorField } from 'src/models/applicant/resume/editor/form/change';
import { submitResumeEditorData } from 'src/models/applicant/resume/editor/store/actions/submitEditor';
import { closeResumeEditorModal } from 'src/models/applicant/resume/editor/store/actions/toggleModal';

const TrlKeys = {
    RU: 'topbuttons.resume.lang.RU',
    EN: 'topbuttons.resume.lang.EN',
    UA: 'topbuttons.resume.lang.UA',
    AZ: 'topbuttons.resume.lang.AZ',
    KZ: 'topbuttons.resume.lang.KZ',
    UZ: 'topbuttons.resume.lang.UZ',
    KG: 'topbuttons.resume.lang.KG',
    label: 'applicant.resume.lang.label',
    translate: 'applicant.resume.lang.translate',
};

const FIELD_NAME = 'lang';

const LanguageBottonSheet = translation(({ trls }) => {
    const dispatch = useDispatch();

    const visible = useSelector(selectResumeEditorOpenedLang);
    const languages = useSelector(selectResumeLanguages);

    const { value: currentLanguage, onChange } = useResumeEditorField(FIELD_NAME);

    const options = useMemo(
        () =>
            languages.map((language) => ({
                value: language,
                label: trls[TrlKeys[language]],
            })),
        [languages, trls]
    );

    const handleClose = () => dispatch(closeResumeEditorModal());
    const handleSave = () => dispatch(submitResumeEditorData());

    return (
        <BottomSheet
            header={
                <NavigationBar
                    size="standard"
                    title={trls[TrlKeys.label]}
                    right={<Action icon={CrossOutlinedSize24} onClick={handleClose} />}
                />
            }
            footer={
                <BottomSheetFooter>
                    <Button mode="primary" style="accent" onClick={handleSave} stretched>
                        {trls[TrlKeys.translate]}
                    </Button>
                </BottomSheetFooter>
            }
            height="content"
            visible={visible}
            onClose={handleClose}
        >
            <VSpacingContainer default={8}>
                {options.map(({ value, label }) => (
                    <Card key={value} borderRadius={16} borderWidth="default" hoverEnabled>
                        <Cell
                            horPadding
                            vertPadding
                            roundedBorder
                            right={<Radio checked={value === currentLanguage} onChange={() => onChange(value)} />}
                        >
                            <CellText>{label}</CellText>
                        </Cell>
                    </Card>
                ))}
            </VSpacingContainer>
        </BottomSheet>
    );
});

export default LanguageBottonSheet;
