import { useState } from 'react';
import { useDispatch } from 'react-redux';

import { translation, useSelector } from '@hh.ru/front-static-app';
import { Action, BottomSheet, Button, NavigationBar, VSpacing, VSpacingContainer } from '@hh.ru/magritte-ui';
import {
    PrinterOutlinedSize24,
    HieroglyphLetterOutlinedSize24,
    ChevronLeftOutlinedSize24,
    ArrowDownLineOutlinedSize24,
    EllipsisHorizontalOutlinedSize24,
    TrashOutlinedSize24,
} from '@hh.ru/magritte-ui/icon';
import { usePush } from '@hh.ru/redux-spa-middleware';

import { useActionLinkAnalytics } from 'src/models/applicant/resume/analytics/provider';
import { useResumeDeleteModalContext } from 'src/models/applicant/resume/delete/resumeDeleteModal';
import { openResumeEditorModal } from 'src/models/applicant/resume/editor/store/actions/toggleModal';
import { printResume } from 'src/models/applicant/resume/print';
import { selectApplicantResume } from 'src/models/applicant/resume/selectors';

import LanguageBottonSheet from 'src/pages/ResumeView/redesign/blocks/languages/LanguageBottomSheet';
import DownloadBottomSheet from 'src/pages/ResumeView/redesign/download/DownloadBottomSheet';

const RESUMES_PAGE_URL = '/applicant/resumes';

const TrlKeys = {
    print: 'applicant.resume.print',
    delete: 'applicant.resume.delete',
    language: 'applicant.resume.language',
    download: 'topbuttons.resume.download',
};

const MobileNavigationBar = translation(({ trls }) => {
    const push = usePush();
    const dispatch = useDispatch();
    const analytics = useActionLinkAnalytics();

    const resume = useSelector(selectApplicantResume);
    const deleteModalContext = useResumeDeleteModalContext();

    const [visibleMoreBS, toggleMoreBS] = useState(false);
    const [visibleDownloadBS, toggleDownloadBS] = useState(false);

    const goToResumesPage = () => push(RESUMES_PAGE_URL);

    const openMoreBottomSheet = () => {
        analytics.resumeSettingsClick();
        toggleMoreBS(true);
    };
    const closeMoreBottomSheet = () => toggleMoreBS(false);
    const openDownloadBottomSheet = () => toggleDownloadBS(true);
    const closeDownloadBottomSheet = () => toggleDownloadBS(false);

    const handleOnDelete = () => {
        analytics.resumeDeleteClick();
        deleteModalContext.openModal({ resume, from: 'resume-view' });
    };
    const openLanguageBottonSheet = () => dispatch(openResumeEditorModal('lang'));

    const handlePrint = () => {
        analytics.resumePrintClick();
        printResume();
    };

    return (
        <>
            <NavigationBar
                left={<Action mode="secondary" onClick={goToResumesPage} icon={ChevronLeftOutlinedSize24} />}
                right={[
                    <Action
                        key="download"
                        mode="secondary"
                        onClick={openDownloadBottomSheet}
                        icon={ArrowDownLineOutlinedSize24}
                        data-qa="resume-download-button"
                    />,
                    <Action
                        key="more"
                        mode="secondary"
                        icon={EllipsisHorizontalOutlinedSize24}
                        onClick={openMoreBottomSheet}
                    />,
                ]}
                showDivider="with-scroll"
            />
            <BottomSheet height="content" visible={visibleMoreBS} onClose={closeMoreBottomSheet}>
                <VSpacingContainer default={8}>
                    <Button
                        icon={<HieroglyphLetterOutlinedSize24 />}
                        size="medium"
                        style="neutral"
                        mode="tertiary"
                        onClick={openLanguageBottonSheet}
                        stretched
                    >
                        {trls[TrlKeys.language]}
                    </Button>
                    <Button
                        icon={<PrinterOutlinedSize24 />}
                        size="medium"
                        style="neutral"
                        mode="tertiary"
                        onClick={handlePrint}
                        data-qa="resume-print-view"
                        stretched
                    >
                        {trls[TrlKeys.print]}
                    </Button>
                    <Button
                        icon={<TrashOutlinedSize24 />}
                        size="medium"
                        style="negative"
                        mode="tertiary"
                        onClick={handleOnDelete}
                        stretched
                    >
                        {trls[TrlKeys.delete]}
                    </Button>
                </VSpacingContainer>
                <VSpacing default={16} />
            </BottomSheet>

            <LanguageBottonSheet />
            <DownloadBottomSheet visible={visibleDownloadBS} onClose={closeDownloadBottomSheet} />
        </>
    );
});

export default MobileNavigationBar;
