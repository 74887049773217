import { ReactNode, useState } from 'react';

import { Action, Button, Card, Cell, CellText, Link, VSpacing } from '@hh.ru/magritte-ui';
import { PenOutlinedSize24, PlusOutlinedSize16 } from '@hh.ru/magritte-ui/icon';

import translation from 'src/components/translation';
import { useActionLinkAnalytics } from 'src/models/applicant/resume/analytics/provider';
import { isEmpty } from 'src/models/applicant/resume/lib/common/empty';
import BlockLabel from 'src/pages/ResumeView/redesign/common/BlockLabel';

import { ListCardItems } from 'src/pages/ResumeView/redesign/common/ListCard/types';

import styles from './styles.less';

const TrlKeys = {
    add: 'applicant.resume.block.add',
    hide: 'applicant.resume.block.btn.hide',
    expanded: 'applicant.resume.block.btn.expanded',
};

const MAX_ITEMS = 2;

interface Props {
    label: ReactNode;
    items: ListCardItems;
    onAdd: () => void;
    onEdit: (index: number) => void;
    banner?: ReactNode;
    cardAlign?: 'center' | 'top';
    editable?: boolean;
    customHeader?: ReactNode;
    blockName?: string;
}

const DesktopCard = translation<Props>(
    ({ trls, onAdd, onEdit, items, label, banner, cardAlign = 'top', blockName, customHeader, editable = true }) => {
        const [expanded, setExpanded] = useState(false);
        const analytics = useActionLinkAnalytics();

        const handleExpanded = () => {
            if (blockName) {
                analytics.resumeVewAllButtonClick(blockName);
            }
            setExpanded(!expanded);
        };

        const showBtn = items.length > MAX_ITEMS;
        const itemToShow = expanded ? items.length : MAX_ITEMS;

        return (
            <div>
                <div className={styles.header}>
                    {customHeader || <BlockLabel>{label}</BlockLabel>}

                    {editable && (
                        <Link onClick={onAdd} iconLeft={<PlusOutlinedSize16 />}>
                            {trls[TrlKeys.add]}
                        </Link>
                    )}
                </div>
                {banner && (
                    <>
                        {banner}
                        <VSpacing default={12} />
                    </>
                )}
                {!isEmpty(items) && (
                    <Card stretched padding={12} borderRadius={24} borderWidth="default">
                        <div className={styles.card}>
                            {items.slice(0, itemToShow).map((item, index) => (
                                <Cell
                                    key={item.id}
                                    align={cardAlign}
                                    right={
                                        editable ? (
                                            <Action
                                                style="neutral"
                                                icon={PenOutlinedSize24}
                                                mode="secondary"
                                                disablePadding
                                                onClick={() => onEdit(index)}
                                                showBackground={false}
                                            />
                                        ) : undefined
                                    }
                                    avatar={item.avatar}
                                >
                                    {'cellContent' in item ? (
                                        item.cellContent
                                    ) : (
                                        <>
                                            <CellText>{item.title}</CellText>
                                            <CellText type="subtitle">{item.subtitle}</CellText>
                                            <CellText type="description">{item.description}</CellText>
                                        </>
                                    )}
                                </Cell>
                            ))}
                        </div>
                        {showBtn && (
                            <>
                                <VSpacing default={12} />
                                <Button
                                    stretched
                                    size="medium"
                                    style="neutral"
                                    mode="secondary"
                                    onClick={handleExpanded}
                                >
                                    {expanded ? trls[TrlKeys.hide] : trls[TrlKeys.expanded]}
                                </Button>
                            </>
                        )}
                    </Card>
                )}
            </div>
        );
    }
);

export default DesktopCard;
