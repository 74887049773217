import { Button, Card, Text } from '@hh.ru/magritte-ui';
import BlokoButton from 'bloko/blocks/button';
import MenuItem from 'bloko/blocks/drop/Menu/Item';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import translation from 'src/components/translation';
import { useMagritte } from 'src/hooks/useMagritte';

const TrlKeys = {
    complaint: 'employer.vacancyResponses.responseComplaint',
};

const ComplaintButton: TranslatedComponent<{ type: 'button' | 'menuItem'; onOpen: () => void }> = ({
    trls,
    type = 'button',
    onOpen,
}) => {
    const text = trls[TrlKeys.complaint];
    const isMagritte = useMagritte();
    if (type === 'menuItem') {
        return isMagritte ? (
            <Card onClick={onOpen} borderRadius={16} padding={12} paddingLeft={16} hoverStyle="secondary" stretched>
                <Text style="primary" typography="label-2-regular">
                    {text}
                </Text>
            </Card>
        ) : (
            <MenuItem onClick={onOpen}>{text}</MenuItem>
        );
    }

    if (!isMagritte) {
        return <BlokoButton onClick={onOpen}>{text}</BlokoButton>;
    }

    return (
        <Button mode="secondary" style="accent" size="small" onClick={onOpen}>
            {text}
        </Button>
    );
};

export default translation(ComplaintButton);
