import Gap from 'bloko/blocks/gap';
import { H4 } from 'bloko/blocks/header';
import BlokoLink from 'bloko/blocks/link';
import Text, { TextImportance, TextSize } from 'bloko/blocks/text';
import VSpacing from 'bloko/blocks/vSpacing';

import translation from 'src/components/translation';

const ResumeCertificates = ({ certificates, removeCertificate, title, trls }) => {
    return (
        <div className="resume-certificates">
            {title && <div className="resume-certificates__title">{trls[ResumeCertificates.trls.title]}</div>}
            <Gap bottom>
                {certificates.grouped.map((groupByType) => (
                    <div key={groupByType.type} className="h-resume-certificates-view">
                        <div className="resume-certificates-view">
                            {groupByType.type !== 'custom' && (
                                <>
                                    <H4>{trls[ResumeCertificates.trls[groupByType.type]]}</H4>
                                    <VSpacing base={4} />
                                </>
                            )}
                            {groupByType.years.map((groupByYear) => (
                                <div key={groupByYear.year} className="resume-certificates-view__year-group">
                                    <div className="resume-certificates-view__year-group-title">
                                        <Text size={TextSize.Small}>{groupByYear.year}</Text>
                                    </div>
                                    <ul className="resume-certificates-view__items">
                                        {groupByYear.certificateIds.map((id) => (
                                            <li key={id} className="resume-certificates-view__item">
                                                <Text size={TextSize.Small}>
                                                    {certificates.data[id].url ? (
                                                        <BlokoLink
                                                            href={certificates.data[id].url}
                                                            target="_blank"
                                                            rel="noopener noreferrer"
                                                        >
                                                            {certificates.data[id].title}
                                                        </BlokoLink>
                                                    ) : (
                                                        certificates.data[id].title
                                                    )}
                                                </Text>
                                                {removeCertificate && (
                                                    <span
                                                        className="resume-certificates-view__item-remove"
                                                        onClick={() =>
                                                            removeCertificate(id, certificates.data[id].title)
                                                        }
                                                        data-qa={`${certificates.data[id].title}-remove`}
                                                    >
                                                        ×
                                                    </span>
                                                )}
                                            </li>
                                        ))}
                                    </ul>
                                </div>
                            ))}
                            {groupByType.ownerName && (
                                <div className="resume-certificates-view__owner">
                                    <Text size={TextSize.Small} importance={TextImportance.Tertiary}>
                                        {groupByType.ownerName}
                                    </Text>
                                </div>
                            )}
                        </div>
                    </div>
                ))}
                <div className="g-clear" />
            </Gap>
        </div>
    );
};

ResumeCertificates.trls = {
    custom: 'rb.view.certificates.custom',
    microsoft: 'rb.view.certificates.microsoft',
    title: 'rb.view.certificates.title',
};

export default translation(ResumeCertificates);
