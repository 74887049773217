import { SVGProps, FC } from 'react';

const Undefined: FC<SVGProps<SVGSVGElement>> = (props) => (
    <svg data-qa="svg-undefined" viewBox="0 0 208 208" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
        <g clipPath="url(#clip0_40934_55262)">
            <rect width="208" height="208" fill="#EEF1F7" />
            <circle cx="104" cy="225.333" r="95.3333" fill="#ffffff" />
            <mask
                id="mask0_40934_55262"
                style={{ maskType: 'alpha' }}
                maskUnits="userSpaceOnUse"
                x="8"
                y="130"
                width="192"
                height="191"
            >
                <circle cx="103.999" cy="225.333" r="95.3333" fill="#DCE3EB" />
            </mask>
            <g mask="url(#mask0_40934_55262)">
                <circle cx="121.333" cy="121.333" r="52" fill="#DCE3EB" />
            </g>
            <circle cx="104" cy="86.667" r="52" fill="#ffffff" />
        </g>
        <defs>
            <clipPath id="clip0_40934_55262">
                <rect width="208" height="208" fill="#EEF1F7" />
            </clipPath>
        </defs>
    </svg>
);

export { Undefined };
