import { ChangeEvent, useRef, useState } from 'react';

import { translation } from '@hh.ru/front-static-app';
import { TextArea, useBreakpoint } from '@hh.ru/magritte-ui';

import { useResumeEditorListField } from 'src/models/applicant/resume/editor/form/change';
import { ExperienceFieldProps } from 'src/pages/ResumeView/redesign/blocks/experience/Editor/types';
import EditorField from 'src/pages/ResumeView/redesign/common/Form/EditorField';

import DescriptionMobile from 'src/pages/ResumeView/redesign/blocks/experience/Editor/fields/Description/DescriptionMobile';

const TrlKeys = {
    titlePlaceholder: 'resume.editor.experience.description.title.placeholder',
    description: 'resume.editor.experience.description.description',
};

const DescriptionField = translation<ExperienceFieldProps>(({ trls, index, errorsMap, fieldName }) => {
    const [mobileVisible, setMobileVisible] = useState(false);
    const { isMobile } = useBreakpoint();

    const { value, onChange, invalid, errorMessage } = useResumeEditorListField(
        fieldName,
        index,
        'description',
        errorsMap
    );

    const showVisibleRef = useRef(!value);

    const handleChange = (event: ChangeEvent<HTMLTextAreaElement>) => {
        onChange(event.target.value);
    };

    const handleFocus = () => {
        if (isMobile) {
            setMobileVisible(true);
        }
    };

    const formatDescriptionValue = () => {
        onChange(value.trim());
    };

    const handleCloseMobileDescription = () => {
        formatDescriptionValue();
        setMobileVisible(false);
    };

    const textArea = isMobile ? (
        <TextArea
            id={'description'}
            value={value}
            placeholder={trls[TrlKeys.titlePlaceholder]}
            onChange={handleChange}
            onFocus={handleFocus}
            invalid={invalid}
            layout={'fill-both'}
            errorMessage={errorMessage}
            onBlur={() => formatDescriptionValue()}
            description={showVisibleRef.current ? trls[TrlKeys.description] : ''}
        />
    ) : (
        <TextArea
            id={'description'}
            value={value}
            placeholder={''}
            onChange={handleChange}
            onFocus={handleFocus}
            invalid={invalid}
            layout={'hug'}
            errorMessage={errorMessage}
            onBlur={() => formatDescriptionValue()}
            description={showVisibleRef.current ? trls[TrlKeys.description] : ''}
        />
    );

    if (isMobile) {
        return (
            <>
                {textArea}
                <DescriptionMobile
                    visible={mobileVisible}
                    value={value}
                    placeholder={trls[TrlKeys.titlePlaceholder]}
                    invalid={invalid}
                    onChange={handleChange}
                    onClose={handleCloseMobileDescription}
                />
            </>
        );
    }

    return (
        <EditorField htmlFor="description" label={trls[TrlKeys.titlePlaceholder]}>
            {textArea}
        </EditorField>
    );
});

export default DescriptionField;
