import { FC, PropsWithChildren } from 'react';

import { Text, TextProps } from '@hh.ru/magritte-ui';

import styles from './styles.less';

// Кастом, портфель на выпиливание – https://jira.hh.ru/browse/PORTFOLIO-38390

const DenseText: FC<PropsWithChildren<Omit<TextProps, 'style'>>> = (props) => (
    <span className={styles.text}>
        <Text {...props} />
    </span>
);

export default DenseText;
