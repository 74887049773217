import { connect } from 'react-redux';

import ConversionNumber from 'bloko/blocks/conversion';
import Link from 'bloko/blocks/link';
import Text, { TextImportance } from 'bloko/blocks/text';

import translation from 'src/components/translation';

function VacancyTest({ trls, topicList, visible = true }) {
    if (!visible) {
        return null;
    }

    const current = topicList && topicList.find((topic) => topic.currentVacancy);
    if (!current || !current.testSolution) {
        return null;
    }

    return (
        <div className="resume-sidebar-item__info">
            <Text importance={TextImportance.Tertiary}>
                <Link href={`/employer/vacancy_response/test?topicId=${current.id}`}>
                    {trls[VacancyTest.trls.testSolution]}
                    &nbsp;
                    {
                        <ConversionNumber
                            value={parseInt(current.testSolution.score, 10)}
                            one={trls[VacancyTest.trls.one]}
                            some={trls[VacancyTest.trls.some]}
                            many={trls[VacancyTest.trls.many]}
                            zero={trls[VacancyTest.trls.zero]}
                        />
                    }
                </Link>
            </Text>
        </div>
    );
}

VacancyTest.trls = {
    testSolution: 'employer.resume.testSolution.result',

    one: 'score.1',
    some: 'score.2',
    many: 'score.5',
    zero: 'score.5',
};

export default connect((state) => ({
    topicList: state.employerNegotiations.topicList,
}))(translation(VacancyTest));
