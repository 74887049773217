import { useRef, useState } from 'react';

import { useSelector } from '@hh.ru/front-static-app';
import { ActionList, Button, TooltipHover } from '@hh.ru/magritte-ui';
import { ArrowDownLineOutlinedSize16, DocumentOutlinedSize24 } from '@hh.ru/magritte-ui/icon';

import translation from 'src/components/translation';
import { selectResumeExportTypes } from 'src/models/applicant/resume/download/selectors';

const TrlKeys = {
    download: 'topbuttons.resume.download',
    doc: 'applicant.resume.converter.doc',
    rtf: 'applicant.resume.converter.rtf',
    pdf: 'applicant.resume.converter.pdf',
    txt: 'applicant.resume.converter.txt',
};

const ButtonDownloadDrop = translation(({ trls }) => {
    const activatorRef = useRef<HTMLButtonElement>(null);
    const resumeExportTypes = useSelector(selectResumeExportTypes);

    const [visible, toggleVisibility] = useState(false);
    const handleDownload = () => {
        toggleVisibility((v) => !v);
    };

    return (
        <>
            <Button
                ref={activatorRef}
                icon={<ArrowDownLineOutlinedSize16 />}
                mode="secondary"
                size="small"
                style="neutral"
                data-qa="resume-download-button"
                onClick={handleDownload}
            />
            <ActionList
                visible={visible}
                onClose={handleDownload}
                dropProps={{
                    width: 280,
                    placement: 'bottom-left',
                    activatorRef,
                }}
            >
                {resumeExportTypes.map((exportType) => {
                    const { link, name, postprocessing } = exportType;
                    return (
                        <Button
                            key={name}
                            icon={<DocumentOutlinedSize24 />}
                            href={link}
                            size="medium"
                            style="neutral"
                            mode="tertiary"
                            target={postprocessing ? undefined : '_blank'}
                            Element="a"
                            stretched
                        >
                            {trls[TrlKeys[name]]}
                        </Button>
                    );
                })}
            </ActionList>
            <TooltipHover placement="top-center" activatorRef={activatorRef}>
                {trls[TrlKeys.download]}
            </TooltipHover>
        </>
    );
});

export default ButtonDownloadDrop;
