import { useSelector } from 'src/hooks/useSelector';
import { UserType } from 'src/models/userType';

import EmployerResumeView from 'src/pages/ResumeView/employerRedesign/ResumeView';
import ResumeViewOld from 'src/pages/ResumeView/old/ResumeView';
import ResumeViewNew from 'src/pages/ResumeView/redesign/ResumeView';
import { useMagrittePageEnabled } from 'src/pages/ResumeView/redesign/lib';

const ResumeView = (props) => {
    const userType = useSelector(({ userType }) => userType);
    const isEmployer = UserType.Employer === userType;
    const isPrintVersion = useSelector(({ printVersion }) => printVersion.isPrintVersion);
    const isEmployerMagritteExp = useSelector((state) => state.isMagritteEmployerResumeView);

    const isApplicantMagritteExp = useMagrittePageEnabled();

    if (isEmployer && isEmployerMagritteExp && !isPrintVersion) {
        return <EmployerResumeView />;
    }
    if (isApplicantMagritteExp) {
        return <ResumeViewNew />;
    }
    return <ResumeViewOld {...props} />;
};

export default ResumeView;
