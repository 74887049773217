import { translation, useSelector } from '@hh.ru/front-static-app';
import { Card, Cell, CellText } from '@hh.ru/magritte-ui';
import { BriefcaseOutlinedSize24, ChevronRightOutlinedSize24 } from '@hh.ru/magritte-ui/icon';
import { SPALink } from '@hh.ru/redux-spa-middleware';
import Conversion from 'bloko/blocks/conversion';
import { format } from 'bloko/common/trl';

import paths from 'src/app/routePaths';
import { useActionLinkAnalytics } from 'src/models/applicant/resume/analytics/provider';
import { selectResumeHash } from 'src/models/applicant/resume/selectors';
import { selectResumeSuitableVacancy } from 'src/models/applicant/resume/suitableVacancy/selector';

const TrlKeys = {
    one: 'applicant.resume.suitable.vacancy.one',
    some: 'applicant.resume.suitable.vacancy.some',
    many: 'applicant.resume.suitable.vacancy.many',
};

const SuitableVacancy = translation(({ trls }) => {
    const analytics = useActionLinkAnalytics();

    const hash = useSelector(selectResumeHash);
    const vacancyCount = useSelector(selectResumeSuitableVacancy);

    if (vacancyCount === 0) {
        return null;
    }

    const invitationTrl = (trl: string) =>
        format(trl, {
            '{0}': vacancyCount || '',
        });

    const handleClick = () => analytics.vacancySearchListClick();

    return (
        <Card
            Element={SPALink}
            to={`${paths.vacancySearch}?resume=${hash}`}
            onClick={handleClick}
            padding={24}
            borderWidth="default"
            borderRadius={24}
        >
            <Cell left={<BriefcaseOutlinedSize24 />} right={<ChevronRightOutlinedSize24 />}>
                <CellText>
                    <Conversion
                        value={vacancyCount}
                        one={trls[TrlKeys.one]}
                        some={trls[TrlKeys.some]}
                        many={trls[TrlKeys.many]}
                        hasValue={false}
                        format={invitationTrl}
                    />
                </CellText>
            </Cell>
        </Card>
    );
});

export default SuitableVacancy;
