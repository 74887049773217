import { useRef } from 'react';

import { useSelector } from '@hh.ru/front-static-app';
import { Button, TooltipHover } from '@hh.ru/magritte-ui';
import { TrashOutlinedSize16 } from '@hh.ru/magritte-ui/icon';

import translation from 'src/components/translation';
import { useActionLinkAnalytics } from 'src/models/applicant/resume/analytics/provider';
import { useResumeDeleteModalContext } from 'src/models/applicant/resume/delete/resumeDeleteModal';
import { selectApplicantResume } from 'src/models/applicant/resume/selectors';

const TrlKeys = {
    tooltip: 'applicant.resume.delete.tooltip',
};

const ResumeButtonDelete = translation(({ trls }) => {
    const analytics = useActionLinkAnalytics();

    const resume = useSelector(selectApplicantResume);

    const activatorRef = useRef<HTMLButtonElement>(null);

    const deleteModalContext = useResumeDeleteModalContext();
    const handleDelete = () => {
        analytics.resumeDeleteClick();
        deleteModalContext.openModal({ resume, from: 'resume-view' });
    };

    return (
        <>
            <Button
                ref={activatorRef}
                icon={<TrashOutlinedSize16 />}
                mode="secondary"
                size="small"
                style="negative"
                onClick={handleDelete}
            />
            <TooltipHover placement="top-center" activatorRef={activatorRef}>
                {trls[TrlKeys.tooltip]}
            </TooltipHover>
        </>
    );
});

export default ResumeButtonDelete;
