import { FC, PropsWithChildren } from 'react';
import classnames from 'classnames';

import { Text } from '@hh.ru/magritte-ui';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import AsyncHighlighter from 'src/components/Markup/AsyncHighlighter';
import translation from 'src/components/translation';
import { useSelector } from 'src/hooks/useSelector';
import { useResumeApplicantFullName } from 'src/pages/ResumeView/employerRedesign/utils';
import { NAMES_AND_PHOTO } from 'src/utils/constants/resumeHiddenFields';
import { VIEW_WITHOUT_CONTACTS } from 'src/utils/constants/resumePermission';

import styles from './index.less';

const TrlKeys = {
    isHidden: 'resume.hiddenFields.name',
    candidate: 'resume.stub.candidate',
    pfpCandidate: 'resume.stub.candidatePfp',
    pfpVacancyHint: 'resume.stub.pfpVacancyHint',
};

const TitleWrapper: FC<PropsWithChildren<{ isMobile?: boolean }>> = ({ children, isMobile, ...rest }) => (
    <div className={classnames({ [styles.center]: isMobile })}>
        <Text typography="title-5-semibold" {...rest}>
            {children}
        </Text>
    </div>
);

const ResumeName: TranslatedComponent<{ isMobile?: boolean }> = ({ isMobile, trls }) => {
    const isHidden = useSelector((state) => state.resume?.hiddenFields.includes(NAMES_AND_PHOTO));
    const isAllowed = useSelector((state) => state.resume?.permission !== VIEW_WITHOUT_CONTACTS);
    const isEmpty = useSelector((state) => state.resume?.emptyBlocks.includes('personal'));
    const name = useResumeApplicantFullName();
    const hasPfpTopics = useSelector((state) => state.resume?.hasPfpTopics);

    if (isEmpty || !isAllowed) {
        return (
            <TitleWrapper isMobile={isMobile}>
                {trls[TrlKeys[hasPfpTopics ? 'pfpCandidate' : 'candidate']]}
            </TitleWrapper>
        );
    }

    if (isHidden) {
        return <TitleWrapper isMobile={isMobile}>{trls[TrlKeys.isHidden]}</TitleWrapper>;
    }

    return (
        <TitleWrapper isMobile={isMobile} data-qa="resume-personal-name">
            <AsyncHighlighter>{name}</AsyncHighlighter>
        </TitleWrapper>
    );
};

export default translation(ResumeName);
