import { ComponentType } from 'react';

import { Action, Avatar, Card, Cell, CellText, Link } from '@hh.ru/magritte-ui';
import { CrossOutlinedSize24 } from '@hh.ru/magritte-ui/icon';

export const ListItem: ComponentType<{
    logo?: string;
    name: string;
    id: number;
    onDelete: VoidFunction;
}> = ({ name, logo, onDelete, id }) => {
    const link = `/employer/${id}`;
    return (
        <Card borderRadius={12} padding={12} borderWidth="default">
            <Cell
                left={
                    <Avatar
                        mode="image"
                        image={logo}
                        placeholder="city"
                        fallbackMode="placeholder"
                        size={40}
                        shapeCircle
                        aria-label={name}
                    />
                }
                right={<Action mode="secondary" style="neutral" onClick={onDelete} icon={CrossOutlinedSize24} />}
            >
                <CellText type="title">
                    <Link
                        style="neutral"
                        href={link}
                        onClick={(e) => {
                            e.preventDefault();
                            window.open(link, '_blank');
                        }}
                    >
                        {name}
                    </Link>
                </CellText>
            </Cell>
        </Card>
    );
};
