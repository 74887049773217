export enum ApplicantSkillsVerificationMethodKeys {
    AvailableAt = 'available_at',
    BrandingSettings = 'branding_settings',
    AgeLabel = 'age_label',
    CtaButtonEnabled = 'cta_button_enabled',
    KakDelaQuiz = 'kak_dela_quiz',
    EstimatedTime = 'estimated_time',
    QuizId = 'quiz_id',
    UrlTemplate = 'url_template',
    PromotionSettings = 'promotion_settings',
    BannerEnabled = 'banner_enabled',
    VerificationObjects = 'verification_objects',
    InternalId = 'internal_id',
    ValidUntil = 'valid_until',
}

export enum ValidityStatus {
    NONE = 'NONE',
    EXPIRED = 'EXPIRED',
    GRACE = 'GRACE',
    EFFECTIVE = 'EFFECTIVE',
}

interface Original {
    original: string;
}

interface Icon {
    dark: Original;
    defaults: Original;
    light: Original;
}

export interface ApplicantSkillsVerificationMethod {
    availability: {
        [ApplicantSkillsVerificationMethodKeys.AvailableAt]: string | null;
        status: string;
    };
    [ApplicantSkillsVerificationMethodKeys.BrandingSettings]: {
        [ApplicantSkillsVerificationMethodKeys.AgeLabel]: string;
        [ApplicantSkillsVerificationMethodKeys.CtaButtonEnabled]: boolean;
        enabled: boolean;
    };
    description: string;
    headline: null;
    icon: Icon;
    id: number;
    [ApplicantSkillsVerificationMethodKeys.KakDelaQuiz]: {
        content: string;
        [ApplicantSkillsVerificationMethodKeys.EstimatedTime]: number;
        [ApplicantSkillsVerificationMethodKeys.QuizId]: string;
        [ApplicantSkillsVerificationMethodKeys.UrlTemplate]: string;
    };
    name: string;
    platform: string;
    [ApplicantSkillsVerificationMethodKeys.PromotionSettings]: {
        [ApplicantSkillsVerificationMethodKeys.BannerEnabled]: boolean;
    };
    source?: {
        description: null;
        headline: Icon;
        href: string;
        icon: Icon;
        id: number;
        name: string;
    };
    [ApplicantSkillsVerificationMethodKeys.VerificationObjects]: [
        {
            category: string;
            id: number;
            level: {
                id: number;
                [ApplicantSkillsVerificationMethodKeys.InternalId]: string;
                name: string;
                rank: number;
            };
            name: string;
        },
    ];
    validity: {
        state: ValidityStatus;
        [ApplicantSkillsVerificationMethodKeys.ValidUntil]: string | null;
    };
}

export type ApplicantSkillsVerificationMethods = Array<ApplicantSkillsVerificationMethod>;
