import { RpfEvent, XhhEvent } from 'src/models/applicant/resume/blocks/contacts/editor/proxy/types';

export const PhoneProxyEventBus = {
    sendEvent(event: XhhEvent): void {
        window.dispatchEvent(new CustomEvent('xhh-phone-verification-events', { detail: event }));
    },

    subscribe(cb: (event: RpfEvent) => void): EventListener {
        const listener = ({ detail }: CustomEvent<RpfEvent>) => {
            cb(detail);
        };
        window.addEventListener('rpf-phone-verification-events', listener as EventListener);
        return listener as EventListener;
    },

    unsubscribe(listener: EventListener): void {
        window.removeEventListener('rpf-phone-verification-events', listener);
    },
};
