import { ComponentType } from 'react';

import { Input } from '@hh.ru/magritte-ui';

import ContainerForMicroFrontend from 'src/components/CodeInjector/ContainerForMicroFrontend';
import { usePhoneProxyEventBus } from 'src/models/applicant/resume/blocks/contacts/editor/proxy/hooks';
import { Phone } from 'src/models/applicant/resume/blocks/contacts/types';

import styles from './styles.less';

interface Props {
    phone?: Phone;
    onChange: (phone: Phone) => void;
    placeholder?: string;
}

const PhoneProxy: ComponentType<Props> = ({ phone, onChange, placeholder }) => {
    const [status] = usePhoneProxyEventBus(onChange, phone);

    return (
        <div className={styles.root}>
            {status === 'close' && (
                <Input
                    size="medium"
                    value={phone?.formatted ?? ''}
                    onChange={() => undefined}
                    disabled
                    inputMode="tel"
                    placeholder={placeholder}
                />
            )}
            <ContainerForMicroFrontend preloadContent={null} place="phoneVerificationInput" />
        </div>
    );
};

export default PhoneProxy;
