import { useMemo } from 'react';
import { useDispatch } from 'react-redux';

import { translation, useSelector } from '@hh.ru/front-static-app';
import { Avatar, useBreakpoint } from '@hh.ru/magritte-ui';
import { SPALink } from '@hh.ru/redux-spa-middleware';

import ConversionDateInterval from 'src/components/Conversion/DateInterval';
import { useActionLinkAnalytics } from 'src/models/applicant/resume/analytics/provider';
import { convertTotalExperience } from 'src/models/applicant/resume/blocks/experience/lib';
import {
    selectResumeExperience,
    selectResumeTotalExperience,
} from 'src/models/applicant/resume/blocks/experience/selectors';
import { openResumeEditorModal } from 'src/models/applicant/resume/editor/store/actions/toggleModal';
import { isEmpty } from 'src/models/applicant/resume/lib/common/empty';
import { CompanyLogo } from 'src/models/resume/resumeCommon.types';
import ResumeExperienceEditor from 'src/pages/ResumeView/redesign/blocks/experience/Editor';
import DesktopCard from 'src/pages/ResumeView/redesign/common/ListCard/DesktopCard';
import MobileCard from 'src/pages/ResumeView/redesign/common/ListCard/MobileCard';

import ResumeExperienceInterval from 'src/pages/ResumeView/redesign/blocks/experience/Card/ResumeExperienceInterval';

const TrlKeys = {
    label: 'applicant.resume.experience.label',
};
const BLOCK_NAME = 'experience';

const ApplicantResumeExperience = translation(({ trls }) => {
    const { isMobile } = useBreakpoint();
    const dispatch = useDispatch();
    const analytics = useActionLinkAnalytics();

    const resumeExperience = useSelector(selectResumeExperience);
    const resumeTotalExperience = useSelector(selectResumeTotalExperience);

    const handleAdd = () => {
        if (isMobile) {
            analytics.resumeWorkExperienceAddButtonClick();
        } else {
            analytics.resumeEditButtonClick(BLOCK_NAME);
        }
        void dispatch(openResumeEditorModal(BLOCK_NAME));
    };
    const handleEdit = (index: number) => {
        analytics.resumeEditButtonClick(BLOCK_NAME);
        void dispatch(openResumeEditorModal(BLOCK_NAME, { index }));
    };

    const totalExperience = useMemo(() => convertTotalExperience(resumeTotalExperience), [resumeTotalExperience]);

    const { years, months } = totalExperience;

    const experience = useMemo(
        () =>
            resumeExperience.map((experienceItem) => ({
                id: experienceItem.id,
                title: experienceItem.position ?? null,
                subtitle: experienceItem.companyName ?? null,
                description: (
                    <ResumeExperienceInterval
                        startDate={experienceItem.startDate}
                        endDate={experienceItem.endDate}
                        interval={experienceItem.interval}
                    />
                ),
                avatar: (
                    <Avatar
                        Element={experienceItem.companyState === 'APPROVED' ? SPALink : 'div'}
                        to={`/employer/${experienceItem.employerId}`}
                        mode="image"
                        image={
                            experienceItem.companyLogos ? getCompanyLogo(experienceItem.companyLogos.logo) : undefined
                        }
                        size={40}
                        fallbackMode="placeholder"
                        placeholder="city"
                        shapeCircle
                        aria-label={experienceItem.position}
                    />
                ),
            })),
        [resumeExperience]
    );

    return (
        <>
            {!isEmpty(experience) && (
                <>
                    {isMobile ? (
                        <MobileCard
                            onAdd={handleAdd}
                            onEdit={handleEdit}
                            items={experience}
                            label={
                                <span>
                                    {`${trls[TrlKeys.label]}: `}
                                    <ConversionDateInterval years={years} months={months} />
                                </span>
                            }
                            listTitle={trls[TrlKeys.label]}
                            blockName={BLOCK_NAME}
                            listDescription={<ConversionDateInterval years={years} months={months} />}
                        />
                    ) : (
                        <DesktopCard
                            onAdd={handleAdd}
                            onEdit={handleEdit}
                            items={experience}
                            blockName={BLOCK_NAME}
                            label={
                                <span>
                                    {`${trls[TrlKeys.label]}: `}
                                    <ConversionDateInterval years={years} months={months} />
                                </span>
                            }
                        />
                    )}
                </>
            )}
            <ResumeExperienceEditor />
        </>
    );
});

function getCompanyLogo(logo: CompanyLogo[]): string | undefined {
    return logo.find(({ type }) => type === 'medium')?.url;
}

export default ApplicantResumeExperience;
