import { useMemo } from 'react';
import { useDispatch } from 'react-redux';

import { translation, useSelector } from '@hh.ru/front-static-app';
import { CellText, useBreakpoint } from '@hh.ru/magritte-ui';

import { useActionLinkAnalytics } from 'src/models/applicant/resume/analytics/provider';
import { selectApplicantResumeEducationLevel } from 'src/models/applicant/resume/blocks/educationLevel/selectors';
import { selectApplicantResumeElementaryEducation } from 'src/models/applicant/resume/blocks/elementaryEducation/selectors';
import { selectApplicantResumePrimaryEducation } from 'src/models/applicant/resume/blocks/primaryEducation/selectors';
import { openResumeEditorModal } from 'src/models/applicant/resume/editor/store/actions/toggleModal';
import { isEmpty } from 'src/models/applicant/resume/lib/common/empty';
import { EducationLevelEditor } from 'src/pages/ResumeView/redesign/blocks/education/Editor/EducationLevel';
import { ResumeElementaryEducationEditor } from 'src/pages/ResumeView/redesign/blocks/education/Editor/ElementaryEducation';
import { ResumePrimaryEducationEditor } from 'src/pages/ResumeView/redesign/blocks/education/Editor/PrimaryEducation';
import DesktopCard from 'src/pages/ResumeView/redesign/common/ListCard/DesktopCard';
import MobileCard from 'src/pages/ResumeView/redesign/common/ListCard/MobileCard';
import { ListCardItems } from 'src/pages/ResumeView/redesign/common/ListCard/types';

const TrlKeys = {
    label: 'applicant.resume.education.label',
    levelLabel: 'applicant.resume.education.level-label',
    listTitle: 'applicant.resume.education.mobile.list.title',
};
const BLOCK_NAME = 'educationLevel';

export const ApplicantResumeEducation = translation(({ trls }) => {
    const dispatch = useDispatch();
    const { isMobile } = useBreakpoint();
    const analytics = useActionLinkAnalytics();

    const educationLevel = useSelector(selectApplicantResumeEducationLevel);
    const educationLevelText = useSelector(
        (state) => state.educationLevels.find((item) => item.value === educationLevel)?.text
    );

    const elementaryEducation = useSelector(selectApplicantResumeElementaryEducation);
    const primaryEducation = useSelector(selectApplicantResumePrimaryEducation);

    const handleAdd = () => {
        const blockName = educationLevel === 'secondary' ? 'elementaryEducation' : 'primaryEducation';

        if (isMobile) {
            analytics.resumeEducationAddButtonClick();
        } else {
            analytics.resumeEditButtonClick(blockName);
        }
        void dispatch(openResumeEditorModal(blockName));
    };

    const handleEdit = (index: number) => {
        if (index === 0) {
            analytics.resumeEditButtonClick('educationLevel');
            void dispatch(openResumeEditorModal('educationLevel'));
        } else if (educationLevel === 'secondary') {
            analytics.resumeEditButtonClick('elementaryEducation');
            void dispatch(openResumeEditorModal('elementaryEducation', { index: index - 1 }));
        } else {
            analytics.resumeEditButtonClick('primaryEducation');
            void dispatch(openResumeEditorModal('primaryEducation', { index: index - 1 }));
        }
    };
    const items = useMemo(() => {
        const _items: ListCardItems = [
            {
                id: 'educationLevel',
                cellContent: (
                    <>
                        <CellText type="subtitle">{trls[TrlKeys.levelLabel]}</CellText>
                        <CellText type="title">{educationLevelText}</CellText>
                    </>
                ),
            },
        ];
        if (educationLevel === 'secondary') {
            elementaryEducation.forEach((item) => {
                _items.push({
                    id: item.id,
                    title: item.name,
                    subtitle: item.year,
                    description: '',
                });
            });
        }
        if (educationLevel !== 'secondary') {
            primaryEducation.forEach((item) => {
                _items.push({
                    id: item.id,
                    title: item.name,
                    subtitle: item.year,
                    description: '',
                });
            });
        }
        return _items;
    }, [educationLevel, educationLevelText, elementaryEducation, primaryEducation, trls]);
    return (
        <>
            {!isEmpty(educationLevel) && (
                <>
                    {isMobile ? (
                        <MobileCard
                            onAdd={handleAdd}
                            onEdit={handleEdit}
                            items={items}
                            label={trls[TrlKeys.label]}
                            listTitle={trls[TrlKeys.listTitle]}
                            cardAlign="center"
                            blockName={BLOCK_NAME}
                        />
                    ) : (
                        <DesktopCard
                            onAdd={handleAdd}
                            onEdit={handleEdit}
                            items={items}
                            label={trls[TrlKeys.label]}
                            cardAlign="center"
                            blockName={BLOCK_NAME}
                        />
                    )}
                </>
            )}
            <EducationLevelEditor />
            <ResumeElementaryEducationEditor />
            <ResumePrimaryEducationEditor />
        </>
    );
});
