import { translation } from '@hh.ru/front-static-app';
import { Alert, Button } from '@hh.ru/magritte-ui';
import { InfoCircleOutlinedSize24 } from '@hh.ru/magritte-ui/icon';

const TrlKeys = {
    question: 'resume.editor.question',
    cancel: 'resume.editor.question.cancel',
    approve: 'resume.editor.question.approve',
};

interface Props {
    visible: boolean;
    onClose: () => void;
    onCancel: () => void;
    onApprove: () => void;
}

const ChangedAlert = translation<Props>(({ trls, visible, onClose, onApprove, onCancel }) => {
    return (
        <Alert
            title={trls[TrlKeys.question]}
            visible={visible}
            onClose={onClose}
            layout="vertical"
            iconStyle="neutral"
            icon={<InfoCircleOutlinedSize24 />}
            buttons={
                <>
                    <Button stretched mode="primary" style="accent" onClick={onApprove}>
                        {trls[TrlKeys.approve]}
                    </Button>
                    <Button stretched mode="secondary" style="accent" onClick={onCancel}>
                        {trls[TrlKeys.cancel]}
                    </Button>
                </>
            }
        />
    );
});

export default ChangedAlert;
