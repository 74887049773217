import { Fragment } from 'react';

import Button, { ButtonKind } from 'bloko/blocks/button';
import Gap from 'bloko/blocks/gap';

import translation from 'src/components/translation';

const ResumeUpdateView = ({ disabled = false, doUpdate = () => {}, error = false, message = '', trls }) => {
    return (
        <Fragment>
            <Gap top>
                {error && (
                    <div className="error-text" data-qa="resume-update-error">
                        {trls[ResumeUpdateView.trls.error]}
                    </div>
                )}
                <div className="resume-sidebar-link">
                    <Button
                        kind={ButtonKind.Primary}
                        stretched
                        disabled={disabled}
                        onClick={doUpdate}
                        data-qa="resume-update-button"
                    >
                        {trls[ResumeUpdateView.trls.touch]}
                    </Button>
                </div>
            </Gap>
            {disabled && message && (
                <div className="resume-sidebar-text" suppressHydrationWarning data-qa="resume-update-message">
                    {message}
                </div>
            )}
        </Fragment>
    );
};

ResumeUpdateView.trls = {
    error: 'rb.touch.error',
    touch: 'rb.touch',
};

export default translation(ResumeUpdateView);
