import { ComponentPropsWithoutRef, FC, ReactNode } from 'react';
import classnames from 'classnames';

import { Card, Text, VSpacingContainer } from '@hh.ru/magritte-ui';
import { TokenBaseStaticSpace } from '@hh.ru/magritte-ui/types';

import styles from './styles.less';

interface ResumeBlockProps {
    title?: ReactNode;
    isTitleInside?: boolean;
    border?: boolean;
    actions?: ReactNode;
    right?: ReactNode;
    buttons?: ReactNode;
    spacing?: TokenBaseStaticSpace;
    innerSpacing?: TokenBaseStaticSpace;
}

const ResumeBlock: FC<Omit<ComponentPropsWithoutRef<'div'>, 'title'> & ResumeBlockProps> = ({
    title,
    isTitleInside = false,
    border = true,
    actions,
    right,
    buttons,
    children,
    spacing = 16,
    innerSpacing = 24,
    ...otherProps
}) => (
    <div {...otherProps}>
        <VSpacingContainer default={spacing}>
            {!isTitleInside && title && (
                <div className={styles.header}>
                    <div className={styles.title}>
                        <Text typography="title-5-semibold">{title}</Text>
                        {actions && <div className={styles.actions}>{actions}</div>}
                    </div>
                    {right && <div className={styles.right}>{right}</div>}
                </div>
            )}
            <Card
                stretched
                borderWidth={border ? 'default' : 'none'}
                borderRadius={border ? 24 : 0}
                padding={border ? 12 : 0}
            >
                <VSpacingContainer default={border ? 12 : 24}>
                    {(children || (isTitleInside && title)) && (
                        <div className={classnames({ [styles.wrapper]: border })}>
                            <VSpacingContainer default={innerSpacing}>
                                {isTitleInside && title && (
                                    <div className={styles.header}>
                                        <Text typography="subtitle-1-semibold">{title}</Text>
                                        {right && <span className={styles.right}>{right}</span>}
                                    </div>
                                )}
                                <>{children}</>
                            </VSpacingContainer>
                        </div>
                    )}
                    {buttons && <div className={styles.buttons}>{buttons}</div>}
                </VSpacingContainer>
            </Card>
        </VSpacingContainer>
    </div>
);

export default ResumeBlock;
