import { useState } from 'react';
import { useDispatch } from 'react-redux';

import { translation, useSelector } from '@hh.ru/front-static-app';
import {
    Alert,
    Button,
    Cell,
    CellText,
    CheckableCard,
    Radio,
    useBreakpoint,
    VSpacingContainer,
} from '@hh.ru/magritte-ui';
import { ExclamationTriangleFilledSize24, InfoCircleFilledSize24 } from '@hh.ru/magritte-ui/icon';

import { selectResumeEditorOpenedEducationLevel } from 'src/models/applicant/resume/blocks/educationLevel/editor/selectors';
import {
    selectApplicantResumeChangeToHigherEducationLevel,
    selectApplicantResumeChangeToLowerEducationLevel,
    selectEducationLevels,
} from 'src/models/applicant/resume/blocks/educationLevel/selectors';
import { useResumeEditorField } from 'src/models/applicant/resume/editor/form/change';
import { useTranslatedErrorMap } from 'src/models/applicant/resume/editor/form/errors';
import { submitResumeEditorData } from 'src/models/applicant/resume/editor/store/actions/submitEditor';
import { closeResumeEditorModal } from 'src/models/applicant/resume/editor/store/actions/toggleModal';
import EditorModal from 'src/pages/ResumeView/redesign/common/EditorModal';

const TrlKeys = {
    title: 'resume.editor.educationLevel.title',
    description: 'resume.editor.educationLevel.description',
    saveText: 'resume.editor.educationLevel.saveText',
    lowerAlert: {
        title: 'resume.editor.educationLevel.lower-alert.title',
        description: 'resume.editor.educationLevel.lower-alert.description',
        confirm: 'resume.editor.educationLevel.lower-alert.confirm',
        cancel: 'resume.editor.educationLevel.lower-alert.cancel',
    },
    higherAlert: {
        title: 'resume.editor.educationLevel.higher-alert.title',
        description: 'resume.editor.educationLevel.higher-alert.description',
        ok: 'resume.editor.educationLevel.higher-alert.ok',
    },
    commonErrors: {
        UNKNOWN: 'resume.editor.field.error.unknown',
        REQUIRED: 'resume.editor.field.error.required',
        DUPLICATE: 'resume.editor.field.error.title.not-in',
        NOT_MATCH_REGEXP: 'resume.editor.field.error.regexp',
        SIZE_LESS_THAN_MINIMUM: 'resume.editor.field.error.required',
        LENGTH_GREATER_THAN_MAXIMUM: {
            one: 'resume.editor.field.error.max-length.one',
            some: 'resume.editor.field.error.max-length.some',
            many: 'resume.editor.field.error.max-length.many',
        },
    },
};

export const EducationLevelEditor = translation(({ trls }) => {
    const { isMobile } = useBreakpoint();
    const dispatch = useDispatch();
    const [openLowerAlert, setOpenLowerAlert] = useState<boolean>(false);
    const [openHigherAlert, setOpenHigherAlert] = useState<boolean>(false);
    const [closeAfterLowerAlert, setCloseAfterLowerAlert] = useState<boolean>(false);

    const visible = useSelector(selectResumeEditorOpenedEducationLevel);

    const isChangeToLower = useSelector(selectApplicantResumeChangeToLowerEducationLevel);
    const isChangeToHigher = useSelector(selectApplicantResumeChangeToHigherEducationLevel);

    const closeEditor = (skipCheck = false) => dispatch(closeResumeEditorModal(skipCheck));

    const handleClose = () => {
        if (isChangeToLower) {
            setOpenLowerAlert(true);
            setCloseAfterLowerAlert(true);
        } else {
            closeEditor();
        }
    };

    const saveEditor = () => dispatch(submitResumeEditorData());

    const handleSave = () => {
        if (isChangeToLower) {
            setOpenLowerAlert(true);
        } else if (isChangeToHigher) {
            setOpenHigherAlert(true);
            saveEditor();
        } else {
            saveEditor();
        }
    };

    const educationLevels = useSelector(selectEducationLevels);

    const errorsMap = useTranslatedErrorMap(TrlKeys.commonErrors, trls);

    const { value, onChange } = useResumeEditorField('educationLevel', errorsMap);

    return (
        <>
            <EditorModal
                title={trls[TrlKeys.title]}
                visible={visible}
                onSave={handleSave}
                onClose={handleClose}
                description={trls[TrlKeys.description]}
                saveText={trls[TrlKeys.saveText]}
                bottomSheetProps={{ size: 'standard' }}
            >
                <VSpacingContainer default={12}>
                    {educationLevels.map((item) => {
                        const radio = <Radio name="radio" readOnly checked={value === item.value} />;
                        return (
                            <CheckableCard
                                flexible
                                borderRadius={16}
                                padding={16}
                                type="radio"
                                key={item.value}
                                checked={value === item.value}
                                value={item.value}
                                onChange={(e) => onChange(e.target.value)}
                            >
                                <Cell left={!isMobile ? radio : undefined} right={isMobile ? radio : undefined}>
                                    <CellText>{item.text}</CellText>
                                </Cell>
                            </CheckableCard>
                        );
                    })}
                </VSpacingContainer>
            </EditorModal>
            <Alert
                title={trls[TrlKeys.lowerAlert.title]}
                description={trls[TrlKeys.lowerAlert.description]}
                visible={openLowerAlert}
                onClose={() => setOpenLowerAlert(false)}
                layout="vertical"
                iconStyle="warning"
                icon={<ExclamationTriangleFilledSize24 />}
                buttons={
                    <>
                        <Button
                            stretched
                            mode="primary"
                            style="negative"
                            onClick={() => {
                                setOpenLowerAlert(false);
                                saveEditor();
                            }}
                        >
                            {trls[TrlKeys.lowerAlert.confirm]}
                        </Button>
                        <Button
                            stretched
                            mode="secondary"
                            style="neutral"
                            onClick={() => {
                                if (closeAfterLowerAlert) {
                                    closeEditor(true);
                                }
                                setOpenLowerAlert(false);
                            }}
                        >
                            {trls[TrlKeys.lowerAlert.cancel]}
                        </Button>
                    </>
                }
            />
            <Alert
                title={trls[TrlKeys.higherAlert.title]}
                description={trls[TrlKeys.higherAlert.description]}
                visible={openHigherAlert}
                onClose={() => setOpenHigherAlert(false)}
                layout="vertical"
                iconStyle="neutral"
                icon={<InfoCircleFilledSize24 />}
                buttons={
                    <>
                        <Button
                            stretched
                            mode="primary"
                            style="accent"
                            onClick={() => {
                                setOpenHigherAlert(false);
                            }}
                        >
                            {trls[TrlKeys.higherAlert.ok]}
                        </Button>
                    </>
                }
            />
        </>
    );
});
