import { FC } from 'react';

import { HSpacingContainer, VSpacing } from '@hh.ru/magritte-ui';
import useBreakpoint from '@hh.ru/magritte-ui-breakpoint/useBreakpoint';

import ResumeButtonPrint from 'src/components/ResumeActions/ButtonPrint';
import { useIsHhru } from 'src/hooks/useSites';
import ResumeButtonDownload from 'src/pages/ResumeView/components/ResumeHeader/ResumeButtonDownload';
import ResumeTalantixButton from 'src/pages/ResumeView/components/ResumeHeader/ResumeTalantixButton';

import ResumeAddToFavorite from 'src/pages/ResumeView/employerRedesign/components/ResumeButtons/ResumeAddToFavorite';
import ResumeSendMessage from 'src/pages/ResumeView/employerRedesign/components/ResumeButtons/ResumeSendMessage';

import styles from './index.less';

const ResumeButtons: FC = () => {
    const isHhru = useIsHhru();
    const { isMobile } = useBreakpoint();

    const buttons = (
        <>
            <ResumeAddToFavorite />
            <ResumeButtonDownload isMagritte />
            <ResumeButtonPrint isMagritte />
            <ResumeSendMessage />
            <>{isHhru && <ResumeTalantixButton isMagritte />}</>
        </>
    );

    return isMobile ? (
        <>
            <div className={styles.mobileWrapper}>{buttons}</div>
            <VSpacing default={24} />
        </>
    ) : (
        <HSpacingContainer default={12}>{buttons}</HSpacingContainer>
    );
};

export default ResumeButtons;
