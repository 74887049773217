import { ComponentType, useCallback, useMemo } from 'react';

import { translation } from '@hh.ru/front-static-app';
import { Card, Cell, CheckableCard, Text, VSpacing } from '@hh.ru/magritte-ui';

import { LevelBySkillId } from 'src/models/applicant/resume/blocks/keySkills/skillsLevels/types';
import { VerifiedSkill } from 'src/models/resume/resumeVerifiedSkills.types';

import styles from './styles.less';

const TrlKeys = {
    level: {
        advanced: 'profile.skillLevels.level.advanced.magritte',
        medium: 'profile.skillLevels.level.medium.magritte',
        basic: 'profile.skillLevels.level.basic.magritte',
    },
    info: 'profile.skillLevels.info.magritte',
};

interface SkillLevelsProps {
    levels: LevelBySkillId;
    skills: VerifiedSkill[];
    onChange: (levels: LevelBySkillId) => void;
}

export const SkillsLevelsList: ComponentType<SkillLevelsProps> = translation(({ trls, skills, levels, onChange }) => {
    const handleChange = useCallback(
        (skillId: number, skillRank: number) => {
            onChange({ ...levels, [skillId]: skillRank });
        },
        [levels, onChange]
    );

    const SKILL_LEVELS = useMemo(
        () => [
            {
                name: trls[TrlKeys.level.basic],
                rank: 1,
            },
            {
                name: trls[TrlKeys.level.medium],
                rank: 2,
            },
            {
                name: trls[TrlKeys.level.advanced],
                rank: 3,
            },
        ],
        [trls]
    );

    return (
        <>
            {skills.map((skill) => (
                <Card stretched key={skill.name} data-qa="skill">
                    <VSpacing default={24} />
                    <Text typography="label-2-regular" style="secondary" data-qa="skillName">
                        {skill.name}
                    </Text>
                    <VSpacing default={12} />
                    <div className={styles.levelsCardsRow}>
                        {SKILL_LEVELS.map((level) => {
                            const rank = levels[skill.id];

                            return (
                                <CheckableCard
                                    checked={level.rank === rank}
                                    key={skill.name + level.name}
                                    padding={12}
                                    borderRadius={16}
                                    flexible
                                    type={'radio'}
                                    name={skill.name + level.name}
                                    onChange={() => handleChange(skill.id, level.rank)}
                                >
                                    <Cell align="center">
                                        <Text typography="label-4-regular" style="primary">
                                            <div
                                                className={styles.levelsCardTextContainer}
                                                data-qa={`skill-level-${level.rank ?? ''}`}
                                            >
                                                {level.name}
                                            </div>
                                        </Text>
                                    </Cell>
                                </CheckableCard>
                            );
                        })}
                    </div>
                </Card>
            ))}
        </>
    );
});
