import { StringFieldType } from 'src/models/applicant/resume/types/common';

export const splitDate = (
    date: string | null
): {
    year: number;
    month: number;
} | null => {
    if (!date) {
        return null;
    }
    const splittedDate = date.split('-');
    return {
        year: Number(splittedDate[0]),
        month: Number(splittedDate[1]),
    };
};

export const convertTotalExperience = (totalExperience: StringFieldType<number>[]) => {
    if (!totalExperience) {
        return {};
    }
    const months = totalExperience[0].string;

    return { years: Math.floor(months / 12), months: months % 12 };
};
