import { useCallback } from 'react';
import { connect } from 'react-redux';

import { SPALink } from '@hh.ru/redux-spa-middleware';
import Column from 'bloko/blocks/column';
import Gap from 'bloko/blocks/gap';
import BlokoLink from 'bloko/blocks/link';

import translation from 'src/components/translation';
import { useGetMColumnSize } from 'src/pages/ResumeView/hooks/useGetMColumnSize';
import { EDIT } from 'src/utils/constants/resumePermission';

const ResumeModerationLink = ({ link, children, ...otherProps }) => {
    if (!link) {
        return null;
    }
    return (
        <span className="resume-moderation-link">
            <BlokoLink disableVisited Element={SPALink} {...otherProps} to={link}>
                {children}
            </BlokoLink>
        </span>
    );
};

const ResumeModerationControls = ({ hash, permission, applicantInfo, resumeViewBackoffice, trls }) => {
    const { mMain } = useGetMColumnSize();
    const { moderationLink, loginAsUserLink, userServicesLink } = resumeViewBackoffice;

    const renderResumeListLink = useCallback(() => {
        if (applicantInfo.total > 0) {
            const hasFinishedResume = applicantInfo.finished > 0;
            return (
                <BlokoLink
                    Element={SPALink}
                    to={hasFinishedResume ? `/applicant/resumes` : `/applicant/resumes/short?resume=${hash}`}
                >
                    {trls[ResumeModerationControls.trls.backlink]}
                </BlokoLink>
            );
        }
        return null;
    }, [applicantInfo, hash, trls]);

    if (permission !== EDIT) {
        return null;
    }
    return (
        <Column xs="4" s="8" m={mMain} l="12">
            <div className="resume-moderation-controls">
                <Gap bottom>
                    <ResumeModerationLink link={moderationLink}>
                        {trls[ResumeModerationControls.trls.moderation]}
                    </ResumeModerationLink>
                    <ResumeModerationLink link={loginAsUserLink} target="_blank">
                        {trls[ResumeModerationControls.trls.loginAsUser]}
                    </ResumeModerationLink>
                    <ResumeModerationLink link={userServicesLink} target="_blank">
                        {trls[ResumeModerationControls.trls.services]}
                    </ResumeModerationLink>
                    {renderResumeListLink()}
                </Gap>
            </div>
        </Column>
    );
};

ResumeModerationControls.trls = {
    backlink: 'rb.view.backlink',
    moderation: 'admin.rb.tomoderation',
    loginAsUser: 'moderatorResume.bottom.user',
    services: 'moderatorResume.bottom.services',
};

export default connect((state) => ({
    hash: state.resume?.hash,
    permission: state.resume?.permission,
    applicantInfo: state.applicantInfo,
    resumeViewBackoffice: state.resumeViewBackoffice || {},
}))(translation(ResumeModerationControls));
