import { FC, PropsWithChildren } from 'react';

import { Title } from '@hh.ru/magritte-ui';

const BlockLabel: FC<PropsWithChildren> = ({ children }) => {
    return (
        <Title Element="h4" size="small">
            {children}
        </Title>
    );
};

export default BlockLabel;
