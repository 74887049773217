import { translation } from '@hh.ru/front-static-app';
import { Input, Placeholder, Suggest, Cell, CellText, Avatar } from '@hh.ru/magritte-ui';

import { CompanyDTO, useCompanyDataProvider } from 'src/models/applicant/resume/api/company';
import { useEditorExperienceChanged } from 'src/models/applicant/resume/blocks/experience/editor/hooks';
import { useResumeEditorListField } from 'src/models/applicant/resume/editor/form/change';
import { ExperienceFieldProps } from 'src/pages/ResumeView/redesign/blocks/experience/Editor/types';

const TrlKeys = {
    titlePlaceholder: 'resume.editor.experience.company.title.placeholder',
    errorTitle: 'resume.editor.suggest.errors.placeholder',
    resetButton: 'resume.editor.suggest.errors.button',
    errorDescription: 'resume.editor.suggest.errors.description',
};

interface Props extends ExperienceFieldProps {
    changeDisabled: (changedFromSuggest: boolean) => void;
}

const CompanyField = translation<Props>(({ trls, errorsMap, index, fieldName, changeDisabled }) => {
    const { value, invalid, errorMessage } = useResumeEditorListField(fieldName, index, 'companyName', errorsMap);

    const onChange = useEditorExperienceChanged();

    const handleChange = (value: string, isValueFromList: boolean) => {
        if (!isValueFromList) {
            onChange({
                companyId: null,
                companyName: value,
            });
            changeDisabled(false);
        }
    };

    const handleSelect = (item?: CompanyDTO) => {
        if (item) {
            onChange({
                companyId: item.id,
                companyUrl: item.url,
                companyName: item.text,
                companyAreaId: Number(item.area_id),
                companyIndustries: item.industries?.map((ind) => ind.id) || [],
                companyIndustryId: item.industry_id,
            });

            changeDisabled(true);
        }
    };

    const dataProvider = useCompanyDataProvider();

    return (
        <Suggest
            onChange={handleChange}
            onSelectValidator={(_value, data) => {
                if (!data) {
                    return true;
                }

                handleSelect(data);

                return true;
            }}
            dataProvider={dataProvider}
            navigationBarProps={{
                title: trls[TrlKeys.titlePlaceholder],
            }}
            input={{
                component: Input,
                props: {
                    name: 'company',
                    size: 'medium',
                    clearable: true,
                    placeholder: trls[TrlKeys.titlePlaceholder],
                    value,
                    invalid,
                    errorMessage,
                },
            }}
            trls={{
                resetButton: trls[TrlKeys.resetButton],
            }}
            errorPlaceholder={
                <Placeholder title={trls[TrlKeys.errorTitle]} description={trls[TrlKeys.errorDescription]} />
            }
            renderItem={({ data: item }) => {
                if (!item) {
                    return null;
                }
                return (
                    <Cell
                        avatar={
                            <Avatar
                                image={item.logo_url}
                                mode="image"
                                size={40}
                                aria-label="company-logo"
                                shapeCircle
                                placeholder="city"
                                fallbackMode="placeholder"
                            />
                        }
                    >
                        <CellText>{item.text}</CellText>
                        <CellText type="subtitle">{item.area}</CellText>
                    </Cell>
                );
            }}
        />
    );
});

export default CompanyField;
