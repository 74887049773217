import { useMemo } from 'react';

import { useSelector } from '@hh.ru/front-static-app';

import {
    selectResumeStatus,
    selectResumeHasErrors,
    selectResumeStringField,
} from 'src/models/applicant/resume/selectors';
import { UserType } from 'src/models/userType';

const VALID_STATUSES = ['approved', 'auto_approved', 'modified'];

export const useShowUpdateBlock = () => {
    const status = useSelector(selectResumeStatus);
    const userType = useSelector(({ userType }) => userType);
    const hasErrors = useSelector(selectResumeHasErrors);
    const accessType = useSelector(selectResumeStringField('accessType', 'no_one'));
    const isBackoffice = userType === UserType.BackOffice;

    return useMemo(() => {
        return !hasErrors && !isBackoffice && accessType !== 'no_one' && VALID_STATUSES.includes(status);
    }, [status, hasErrors, accessType, isBackoffice]);
};
