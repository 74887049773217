import { memo } from 'react';

import { Link, Text } from '@hh.ru/magritte-ui';
import { DotFilledSize16, EnvelopeOutlinedSize16 } from '@hh.ru/magritte-ui/icon';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import DenseText from 'src/components/DenseText';
import AsyncHighlighter from 'src/components/Markup/AsyncHighlighter';
import { EMAIL as PREFERRED_CONTACT_EMAIL } from 'src/components/ResumeContacts/preferredContacts';
import translation from 'src/components/translation';
import { useSelectorNonNullable } from 'src/hooks/useSelector';
import { EMAIL } from 'src/utils/constants/resumeHiddenFields';

import styles from './styles.less';

const TrlKeys = {
    isHidden: 'resume.hiddenFields.email',
    isPreferred: 'rb.view.preferredcontact',
};

const ResumeContactsEmail: TranslatedComponent = ({ trls }) => {
    const email = useSelectorNonNullable(({ resume }) => resume.email.value);
    const isHidden = useSelectorNonNullable(({ resume }) => resume.hiddenFields.includes(EMAIL));
    const isPreferred = useSelectorNonNullable(
        ({ resume }) => resume.preferredContact.value === PREFERRED_CONTACT_EMAIL
    );

    if (isHidden) {
        return (
            <Text style="secondary" typography="label-3-regular">
                {trls[TrlKeys.isHidden]}
            </Text>
        );
    }

    return (
        <div className={styles.wrapper} data-qa="resume-contact-email">
            <EnvelopeOutlinedSize16 padding={4} initialColor="secondary" />
            <Link
                typography="label-3-regular"
                style="neutral"
                href={`mailto:${email}`}
                data-qa={isPreferred ? 'resume-contact-preferred' : undefined}
            >
                <AsyncHighlighter>
                    <DenseText typography="label-3-regular">{email}</DenseText>
                </AsyncHighlighter>
            </Link>
            {isPreferred && (
                <>
                    <DotFilledSize16 initialColor="tertiary" />
                    <Text typography="label-3-regular" style="secondary">
                        {trls[TrlKeys.isPreferred]}
                    </Text>
                </>
            )}
        </div>
    );
};

export default translation(memo(ResumeContactsEmail));
