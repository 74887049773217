import { Action, BottomSheet, Modal, NavigationBar, useBreakpoint } from '@hh.ru/magritte-ui';
import { CrossOutlinedSize24 } from '@hh.ru/magritte-ui/icon';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import CdnImg from 'src/components/CdnImg';
import translation from 'src/components/translation';

import styles from './photoModal.less';

interface Props {
    isVisible: boolean;
    close: () => void;
    imgUrl: string;
    title: string;
}

const PhotoModal: TranslatedComponent<Props> = ({ isVisible, close, imgUrl, title }) => {
    const { isGtS } = useBreakpoint();

    const content = (
        <div className={styles.content}>
            <CdnImg loading="lazy" path={imgUrl} alt="resume photo" className={styles.image} />
        </div>
    );

    if (isGtS) {
        return (
            <Modal
                visible={isVisible}
                title={title}
                actions={<Action mode="secondary" onClick={close} icon={CrossOutlinedSize24} />}
                onClose={close}
            >
                {content}
            </Modal>
        );
    }

    return (
        <BottomSheet
            height="content"
            visible={isVisible}
            header={<NavigationBar title={title} right={<CrossOutlinedSize24 onClick={close} />} />}
            onClose={close}
        >
            {content}
        </BottomSheet>
    );
};

export default translation(PhotoModal);
