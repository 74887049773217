import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import Label, { LabelColor, LabelType } from 'src/components/Label';
import translation from 'src/components/translation';
import { JobSearchStatus, JobSearchStatusSetted } from 'src/models/applicantUserStatuses';

const DISPLAYED_STATUSES = [
    JobSearchStatus.ActiveSearch,
    JobSearchStatus.LookingForOffers,
    JobSearchStatus.HasJobOffer,
    JobSearchStatus.AcceptedJobOffer,
    JobSearchStatus.NotLookingForJob,
] as const;

const IN_SEARCH_STATUSES = [JobSearchStatus.ActiveSearch, JobSearchStatus.LookingForOffers] as const;

const TrlKeys = {
    status: {
        [JobSearchStatus.ActiveSearch]: 'jobSearchStatus.employer.version2.active_search',
        [JobSearchStatus.LookingForOffers]: 'jobSearchStatus.employer.version2.looking_for_offers',
        [JobSearchStatus.HasJobOffer]: 'jobSearchStatus.employer.version2.has_job_offer',
        [JobSearchStatus.AcceptedJobOffer]: 'jobSearchStatus.employer.version2.accepted_job_offer',
        [JobSearchStatus.NotLookingForJob]: 'jobSearchStatus.employer.version2.not_looking_for_job',
    },
    ownStatus: {
        [JobSearchStatus.ActiveSearch]: 'jobSearchStatus.current.version2.active_search',
        [JobSearchStatus.LookingForOffers]: 'jobSearchStatus.current.version2.looking_for_offers',
        [JobSearchStatus.HasJobOffer]: 'jobSearchStatus.current.version2.has_job_offer',
        [JobSearchStatus.AcceptedJobOffer]: 'jobSearchStatus.current.version2.accepted_job_offer',
        [JobSearchStatus.NotLookingForJob]: 'jobSearchStatus.current.version2.not_looking_for_job',
    },
};

interface Props {
    currentStatus: JobSearchStatusSetted;
    isOwnResume: boolean;
}

const ResumeJobSearchStatusName: TranslatedComponent<Props> = ({ trls, currentStatus, isOwnResume }) => {
    if (!DISPLAYED_STATUSES.includes(currentStatus)) {
        return null;
    }

    const isInSearch = IN_SEARCH_STATUSES.includes(currentStatus);
    const labelColor = isInSearch ? LabelColor.LightGreen : LabelColor.LightYellow;

    return (
        <Label
            type={LabelType.CustomContent}
            color={labelColor}
            Content={trls[TrlKeys[isOwnResume ? 'ownStatus' : 'status'][currentStatus]]}
            data-qa="job-search-status"
        />
    );
};

export default translation(ResumeJobSearchStatusName);
