import { Breadcrumbs, BreadcrumbsItem } from '@hh.ru/magritte-ui';
import { SPALink } from '@hh.ru/redux-spa-middleware';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import translation from 'src/components/translation';
import { useSelector } from 'src/hooks/useSelector';

const TrlKeys = {
    vacancies: 'employer.resumeView.breadcrumbs.myVacancies',
    responses: 'employer.resumeView.breadcrumbs.responses',
    resume: 'employer.resumeView.breadcrumbs.resume',
};

const EmployerResumeViewBreadcrumbs: TranslatedComponent = ({ trls }) => {
    const isHiringManager = useSelector((state) => state.isHiringManager);
    const vacancyId = useSelector((state) => state.router.location.query.vacancyId);
    const topicList = useSelector((state) => state.employerNegotiations.topicList);
    const topic = topicList.find((topic) => topic.vacancyId === Number(vacancyId));

    if (!topic) {
        return null;
    }

    const { vacancyName } = topic;

    return (
        <Breadcrumbs>
            <BreadcrumbsItem
                Element={SPALink}
                to={isHiringManager ? '/employer/hiring_manager/vacancies' : '/employer/vacancies'}
            >
                {trls[TrlKeys.vacancies]}
            </BreadcrumbsItem>
            <BreadcrumbsItem Element={SPALink} to={`/vacancy/${vacancyId}`}>
                {vacancyName}
            </BreadcrumbsItem>
            <BreadcrumbsItem Element={SPALink} to={`/employer/vacancyresponses?vacancyId=${vacancyId}`}>
                {trls[TrlKeys.responses]}
            </BreadcrumbsItem>
            <BreadcrumbsItem>{trls[TrlKeys.resume]}</BreadcrumbsItem>
        </Breadcrumbs>
    );
};

export default translation(EmployerResumeViewBreadcrumbs);
