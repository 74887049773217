import { Ref, useCallback, useMemo, useRef, useState } from 'react';
import classnames from 'classnames';

import {
    createStaticDataProvider,
    findStaticDataFetcherItemByValue,
    StaticDataFetcherItem,
} from '@hh.ru/magritte-common-data-provider';
import { Cell, CellText, Select, RenderOptionType } from '@hh.ru/magritte-ui';
import ClickMenu, { MenuItem, MenuPlacement } from 'bloko/blocks/drop/Menu/Click';
import Link, { LinkAppearance } from 'bloko/blocks/link';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import { MarkAsViewedEvent } from 'Modules/Notices';
import Infotip, { InfoPlacement } from 'src/components/Infotip';
import MagritteInfotip from 'src/components/Infotip/MagritteInfotip';
import translation from 'src/components/translation';
import { useSelector } from 'src/hooks/useSelector';
import { NON_BREAKING_SPACE } from 'src/utils/constants/symbols';

import styles from './divisionSelector.less';

const TrlKeys = {
    division: 'employer.resume.showContacts.division',
    infotip: 'employer.resume.showContacts.division.description',
    civ: 'employer.resume.showContacts.division.civ',
};

const INFO_TIP_NAME = 'resumeDivisionCiv';

type DivisionItem = StaticDataFetcherItem & {
    availableCiv: number;
};

interface DivisionSelectorProps {
    currentDivisionId: number;
    setDivisionId: (divisionId: number) => void;
    isMagritte?: boolean;
    isInMenu?: boolean;
}

const DivisionSelector: TranslatedComponent<DivisionSelectorProps> = ({
    currentDivisionId,
    setDivisionId,
    isMagritte,
    isInMenu,
    trls,
}) => {
    const employerDivisions = useSelector((state) => state.employerDivisions);
    const activatorRef = useRef(null);

    const divisionsList: DivisionItem[] = useMemo(
        () =>
            Object.values(employerDivisions).map(({ divisionId, divisionName, servicesInfo }) => {
                return { value: String(divisionId), text: divisionName, availableCiv: servicesInfo.availableCiv };
            }),
        [employerDivisions]
    );
    const staticProvider = createStaticDataProvider(divisionsList);
    const [value, setValue] = useState(findStaticDataFetcherItemByValue(String(currentDivisionId), divisionsList));

    const renderItem: RenderOptionType<DivisionItem> = useCallback(
        ({ input, data }) => {
            return (
                <Cell Element="div" align="top" left={input}>
                    <CellText maxLines={3}>{data.text}</CellText>
                    <CellText type="subtitle">
                        {data.availableCiv}
                        {NON_BREAKING_SPACE}
                        {trls[TrlKeys.civ]}
                    </CellText>
                </Cell>
            );
        },
        [trls]
    );

    if (isMagritte) {
        return (
            <MagritteInfotip
                name={INFO_TIP_NAME}
                placement="bottom-center"
                visible
                showClose
                closeByClickOutside={false}
                showBannerOnMobile={false}
                markAsViewedEvent={MarkAsViewedEvent.OnHide}
                render={({ activatorRef }) => (
                    <div
                        className={classnames({ [styles.selectWrapper]: !isInMenu })}
                        ref={activatorRef as Ref<HTMLDivElement>}
                    >
                        <Select
                            type="radio"
                            name="division"
                            dataProvider={staticProvider}
                            value={value}
                            onChange={(newValue) => {
                                setValue(newValue);
                                setDivisionId(Number(newValue.value));
                            }}
                            triggerProps={{
                                size: 'small',
                                stretched: true,
                            }}
                            renderDesktopItem={renderItem}
                            renderMobileItem={renderItem}
                        />
                    </div>
                )}
            >
                <div className={styles.tooltipText}>{trls[TrlKeys.infotip]}</div>
            </MagritteInfotip>
        );
    }

    return (
        <Infotip
            show
            name={INFO_TIP_NAME}
            render={() => trls[TrlKeys.infotip]}
            placement={InfoPlacement.TopStart}
            markAsViewedEvent={MarkAsViewedEvent.OnHide}
            activatorRef={activatorRef}
        >
            <ClickMenu
                flexible
                placement={MenuPlacement.BottomStart}
                render={(handleClose) =>
                    Object.values(employerDivisions).map(({ divisionId, divisionName, servicesInfo }) => (
                        <MenuItem
                            key={divisionId}
                            selectable
                            selected={divisionId === currentDivisionId}
                            onClick={() => {
                                setDivisionId(divisionId);
                                handleClose?.();
                            }}
                        >
                            <div className="resume-contacts-division-name">{divisionName}</div>
                            <div className="resume-contacts-division-civ">
                                {servicesInfo.availableCiv}
                                {NON_BREAKING_SPACE}
                                {trls[TrlKeys.civ]}
                            </div>
                        </MenuItem>
                    ))
                }
                activatorRef={activatorRef}
            >
                <div className={styles.selectBlokoWrapper}>
                    {trls[TrlKeys.division]}
                    {NON_BREAKING_SPACE}
                    <Link appearance={LinkAppearance.Pseudo} ref={activatorRef}>
                        <span className={styles.selectorLink}>{employerDivisions[currentDivisionId].divisionName}</span>
                    </Link>
                </div>
            </ClickMenu>
        </Infotip>
    );
};

export default translation(DivisionSelector);
