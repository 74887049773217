import { useEffect, useRef } from 'react';

import resumeRsvCompetenceButtonClick from '@hh.ru/analytics-js-events/build/xhh/employer/experiments/PORTFOLIO-25156/resume_rsv_competence_button_click';
import resumeRsvCompetenceElementShown from '@hh.ru/analytics-js-events/build/xhh/employer/experiments/PORTFOLIO-25156/resume_rsv_competence_element_shown';
import { useSelector } from '@hh.ru/front-static-app';
import { Card, Text, Tag, VSpacing, Button } from '@hh.ru/magritte-ui';
import { SPALink } from '@hh.ru/redux-spa-middleware';
import urlParser from 'bloko/common/urlParser';

import scrollToElement from 'Utils/ScrollToElement';
import ElementShownAnchor from 'src/components/ElementShownAnchor';
import HumanDate from 'src/components/HumanDate';
import translation from 'src/components/translation';
import { selectRsvCompetencyList } from 'src/models/applicant/resume/rsv/selectors';
import { selectResumeHash, selectResumeId } from 'src/models/applicant/resume/selectors';

import RsvVerificationVisibilityBanner from 'src/pages/ResumeView/redesign/blocks/applicantRsvVerification/RsvVerificationVisibilityBanner';

import styles from './styles.less';

const TrlKeys = {
    title: 'rsvUserCompetencies.applicant.applicantRsvVerificationCard.title',
    show: 'rsvUserCompetencies.applicant.applicantRsvVerificationCard.show',
};

interface QueryParams {
    vacancyId: string | null;
}

const ApplicantRsvVerification = translation(({ trls }) => {
    const resumeHash = useSelector(selectResumeHash);
    const resumeId = useSelector(selectResumeId);

    const rsvCompetencyList = useSelector(selectRsvCompetencyList);
    const { vacancyId = null } = useSelector((state) => state.router.location.query) as unknown as QueryParams;
    const verificationCardRef = useRef<HTMLDivElement>(null);

    const handleShowClick = () => {
        resumeRsvCompetenceButtonClick({ type: 'RESUME', resumeId });
    };

    useEffect(() => {
        if (verificationCardRef.current !== null) {
            if (typeof window === 'undefined') {
                return;
            }
            const urlParams = new URLSearchParams(window?.location.search);
            const scrollTo = urlParams.get('scrollTo');
            if (scrollTo === 'rsv') {
                scrollToElement(verificationCardRef.current, { centered: false, topOffset: 0 });
            }
        }
    }, []);

    if (!rsvCompetencyList) {
        return null;
    }

    return (
        <ElementShownAnchor fn={resumeRsvCompetenceElementShown} type={'RESUME'} resumeId={resumeId}>
            <Card ref={verificationCardRef} stretched borderRadius={24} padding={12} borderWidth="default">
                <div className={styles.wrapper}>
                    <div className={styles.text}>
                        <Text typography="label-3-regular" style="secondary">
                            {trls[TrlKeys.title]}
                        </Text>
                    </div>
                    <Tag>
                        <HumanDate date={rsvCompetencyList.updatedAt} />
                    </Tag>
                </div>
                <VSpacing default={12} gteM={24} />
                {!rsvCompetencyList.visible && (
                    <>
                        <RsvVerificationVisibilityBanner />
                        <VSpacing default={24} />
                    </>
                )}
                <Button
                    stretched
                    size="small"
                    mode="secondary"
                    Element={SPALink}
                    to={`/applicant/rsv_verifications/${resumeHash}?${urlParser.stringify({
                        vacancyId,
                    })}`}
                    onClick={handleShowClick}
                >
                    {trls[TrlKeys.show]}
                </Button>
            </Card>
        </ElementShownAnchor>
    );
});

export default ApplicantRsvVerification;
