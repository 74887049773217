import { useCallback } from 'react';

import { Button, Text, VSpacing } from '@hh.ru/magritte-ui';
import { usePush } from '@hh.ru/redux-spa-middleware';
import BlokoButton, { ButtonKind } from 'bloko/blocks/button';
import { H3 } from 'bloko/blocks/header';
import VSpacingBloko from 'bloko/blocks/vSpacing';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import { updateUrl } from 'Modules/url';
import paths from 'src/app/routePaths';
import translation from 'src/components/translation';
import { useMagritte } from 'src/hooks/useMagritte';
import { TariffProductType } from 'src/models/price/product.types';
import { isResumeAccessProduct } from 'src/utils/price/products';

const TrlKeys = {
    titleSnippetZp: 'employer.zp.snippet.title',
    buyAccessResumeZp: 'employer.zp.buy.contacts',
};

interface BuyContactsZpProps {
    currentProduct: TariffProductType;
}

const BuyContactsZp: TranslatedComponent<BuyContactsZpProps> = ({ trls, currentProduct }) => {
    const push = usePush();
    const isMagritte = useMagritte();

    const handleClick = useCallback(() => {
        const urlRedirect = isResumeAccessProduct(currentProduct) ? paths.priceZpResumeAccess : paths.priceZpBundles;

        push(updateUrl(urlRedirect, { priceRegionId: currentProduct?.region }));
    }, [currentProduct, push]);

    return isMagritte ? (
        <>
            <Text typography="title-5-semibold">{trls[TrlKeys.titleSnippetZp]}</Text>
            <VSpacing default={16} />
            <Button size="small" style="accent" mode="primary" data-qa="buy-contacts-zp" onClick={handleClick}>
                {trls[TrlKeys.buyAccessResumeZp]}
            </Button>
        </>
    ) : (
        <>
            <H3>{trls[TrlKeys.titleSnippetZp]}</H3>
            <VSpacingBloko base={4} />
            <BlokoButton kind={ButtonKind.Inversed} data-qa="buy-contacts-zp" onClick={handleClick}>
                {trls[TrlKeys.buyAccessResumeZp]}
            </BlokoButton>
        </>
    );
};

export default translation(BuyContactsZp);
