import { useEffect } from 'react';
import { connect } from 'react-redux';

import Column from 'bloko/blocks/column';
import { H3 } from 'bloko/blocks/header';
import VSpacing from 'bloko/blocks/vSpacing';

import { useNotification } from 'src/components/Notifications/Provider';
import translation from 'src/components/translation';
import { useGetMColumnSize } from 'src/pages/ResumeView/hooks/useGetMColumnSize';

import ViewHistory from 'src/pages/ResumeView/components/View/ResumeSidebar/ViewHistory';
import showResumeViewHistoryErrorFetchingNotification from 'src/pages/ResumeView/components/View/ResumeSidebar/ViewHistory/Notifications';

function SidebarHistory({ trls, resumeViewHistory, shouldBeRendered }) {
    const { addNotification } = useNotification();
    const { mSidebar } = useGetMColumnSize();

    useEffect(() => {
        if (resumeViewHistory.showResumeFallbackWarning) {
            addNotification(showResumeViewHistoryErrorFetchingNotification);
        }
    }, [addNotification, resumeViewHistory]);

    if (!resumeViewHistory.resumeView || !resumeViewHistory.resumeView.length || !shouldBeRendered) {
        return null;
    }

    return (
        <Column xs="0" s="4" m={mSidebar} l="4">
            <div className="resume-sidebar-section resume-sidebar-section_noprint">
                <H3>{trls[SidebarHistory.trls.title]}</H3>
                <VSpacing base={4} />
                <ViewHistory historyList={resumeViewHistory.resumeView} />
            </div>
        </Column>
    );
}

SidebarHistory.trls = {
    title: 'resume.employer.viewhistory.views',
};

export default connect((state) => ({
    resumeViewHistory: state.resumeViewHistory,
    shouldBeRendered: state.printVersion.history !== false,
}))(translation(SidebarHistory));
