import { connect } from 'react-redux';

import translation from 'src/components/translation';

import ResumeBlock from 'src/pages/ResumeView/components/View/ResumeBlock';
import ResumeEditLink from 'src/pages/ResumeView/components/View/ResumeEditLink';
import ResumeEducationBody from 'src/pages/ResumeView/components/View/ResumeEducationBody';

const ResumeEducationAdditional = ({ education, trls }) => {
    if (!education.length) {
        return null;
    }
    return (
        <ResumeBlock
            title={trls[ResumeEducationAdditional.trls.title]}
            data-qa="resume-block-additional-education"
            EditLink={
                <ResumeEditLink
                    block="education"
                    field="additionalEducation"
                    data-qa="resume-block-additional-education-edit"
                />
            }
        >
            {education.map((education) => {
                return <ResumeEducationBody key={education.id} {...education} />;
            })}
        </ResumeBlock>
    );
};

ResumeEducationAdditional.trls = {
    title: 'rb.title.additionalEducation',
};

export default connect((state) => ({
    education: state.resume?.additionalEducation.value,
}))(translation(ResumeEducationAdditional));
