import { useDispatch } from 'react-redux';

import { translation, useSelector } from '@hh.ru/front-static-app';

import { useActionLinkAnalytics } from 'src/models/applicant/resume/analytics/provider';
import { getStringListTrl } from 'src/models/applicant/resume/blocks/additional/lib';
import { selectResumeWorkSchedule } from 'src/models/applicant/resume/blocks/additional/selectors';
import { openResumeEditorModal } from 'src/models/applicant/resume/editor/store/actions/toggleModal';
import Cell from 'src/pages/ResumeView/redesign/blocks/additional/Card/Cell';

const TrlKeys = {
    title: 'resume.editor.workSchedule.title',
    more: 'resume.editor.additional.more',
    type: {
        full_day: 'resume.editor.workSchedule.full_day',
        fly_in_fly_out: 'resume.editor.workSchedule.fly_in_fly_out',
        flexible: 'resume.editor.workSchedule.flexible',
        shift: 'resume.editor.workSchedule.shift',
        remote: 'resume.editor.workSchedule.remote',
    },
};
const BLOCK_NAME = 'workSchedule';

const WorkSchedule = translation(({ trls }) => {
    const dispatch = useDispatch();
    const analytics = useActionLinkAnalytics();

    const workSchedule = useSelector(selectResumeWorkSchedule);

    const handleEdit = () => {
        analytics.resumeEditButtonClick(BLOCK_NAME);
        void dispatch(openResumeEditorModal(BLOCK_NAME));
    };

    return (
        <Cell
            title={trls[TrlKeys.title]}
            text={getStringListTrl(workSchedule, trls, TrlKeys.type, trls[TrlKeys.more])}
            onClick={handleEdit}
        />
    );
});

export default WorkSchedule;
