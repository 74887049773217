import { translation, useSelector } from '@hh.ru/front-static-app';
import { Action, Button, BottomSheet, NavigationBar, VSpacingContainer, VSpacing } from '@hh.ru/magritte-ui';
import { CrossOutlinedSize24, DocumentOutlinedSize24 } from '@hh.ru/magritte-ui/icon';

import { selectResumeExportTypes } from 'src/models/applicant/resume/download/selectors';

const TrlKeys = {
    download: 'topbuttons.resume.download',
    description: 'applicant.resume.converter.description',
    doc: 'applicant.resume.converter.doc',
    rtf: 'applicant.resume.converter.rtf',
    pdf: 'applicant.resume.converter.pdf',
    txt: 'applicant.resume.converter.txt',
};
interface Props {
    visible: boolean;
    onClose: () => void;
}

const DownloadBottomSheet = translation<Props>(({ trls, visible, onClose }) => {
    const resumeExportTypes = useSelector(selectResumeExportTypes);

    return (
        <BottomSheet
            header={
                <NavigationBar
                    size="standard"
                    title={trls[TrlKeys.download]}
                    subtitle={trls[TrlKeys.description]}
                    right={<Action icon={CrossOutlinedSize24} onClick={onClose} />}
                    showDivider="always"
                />
            }
            height="content"
            visible={visible}
            onClose={onClose}
        >
            <VSpacingContainer default={8}>
                {resumeExportTypes.map((exportType) => {
                    const { link, name, postprocessing } = exportType;
                    return (
                        <Button
                            key={name}
                            icon={<DocumentOutlinedSize24 />}
                            href={link}
                            size="medium"
                            style="neutral"
                            mode="tertiary"
                            target={postprocessing ? undefined : '_blank'}
                            Element="a"
                            stretched
                        >
                            {trls[TrlKeys[name]]}
                        </Button>
                    );
                })}
            </VSpacingContainer>
            <VSpacing default={16} />
        </BottomSheet>
    );
});

export default DownloadBottomSheet;
