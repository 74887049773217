import { Creds } from 'src/models/applicant/resume/blocks/creds/types';

export const isQuestionRendered = (answers: string[], creds: Creds): boolean =>
    answers.filter((answerId) => creds.answers[answerId] && !creds.answers[answerId]?.skipAtResult).length > 0;

export const isAnyQuestionRendered = (creds: Creds): boolean => {
    return (
        Object.values(creds.questionToAnswerMap)
            .map((answers) => isQuestionRendered(answers, creds))
            .filter(Boolean).length > 0
    );
};
