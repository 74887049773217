import { ComponentType, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { translation } from '@hh.ru/front-static-app';
import { Card, Cell, CellText, Loader } from '@hh.ru/magritte-ui';
import { ChevronRightOutlinedSize24 } from '@hh.ru/magritte-ui/icon';

import { ResumeEmployersListType } from 'src/models/applicant/resume/api';
import { selectResumeEditorOpenedVisibility } from 'src/models/applicant/resume/blocks/visibility/editor/selectors';
import { fetchEmployersListForResume } from 'src/models/applicant/resume/employersLists/actions';
import {
    getSelectResumeEmployersListSelector,
    selectResumeEmployerListFetching,
} from 'src/models/applicant/resume/employersLists/selectors';

import { collapseListTitle } from 'src/pages/ResumeView/redesign/visibility/Editor/collapseListTitle';

const TrlKeys = {
    emptyList: 'applicant.resume.visibility.editor.employerList.empty',
    postfix: 'applicant.resume.visibility.editor.employerList.postfix',
    whiteListActivatorSubtitle: 'applicant.resume.visibility.editor.employerList.whitelist.subtitle',
    blackListActivatorSubtitle: 'applicant.resume.visibility.editor.employerList.blacklist.subtitle',
};

interface Props {
    type: ResumeEmployersListType;
    onClick: () => void;
}

export const EmployersListActivator: ComponentType<Props> = translation(({ type, trls, onClick }) => {
    const dispatch = useDispatch();
    const visible = useSelector(selectResumeEditorOpenedVisibility);
    const list = useSelector(getSelectResumeEmployersListSelector(type));

    const fetching = useSelector(selectResumeEmployerListFetching);
    useEffect(() => {
        if (visible && list?.page !== 0 && !fetching) {
            void dispatch(fetchEmployersListForResume(type, 0));
        }
    }, [type, dispatch, visible, fetching, list?.page]);

    const getCardText = () => {
        if (list?.items?.length) {
            return collapseListTitle(
                list.items.map((item) => item.name),
                3,
                list.total,
                trls[TrlKeys.postfix]
            );
        }

        return trls[TrlKeys.emptyList];
    };

    return (
        <Card padding={16} borderRadius={12} style="secondary" onClick={onClick}>
            <Cell disabled={fetching} align="center" right={<ChevronRightOutlinedSize24 />}>
                {(!!list?.items?.length || fetching) && (
                    <CellText type="subtitle">
                        {type === 'whitelist'
                            ? trls[TrlKeys.whiteListActivatorSubtitle]
                            : trls[TrlKeys.blackListActivatorSubtitle]}
                    </CellText>
                )}

                {fetching ? <Loader /> : <CellText type="title">{getCardText()}</CellText>}
            </Cell>
        </Card>
    );
});
