import { connect } from 'react-redux';

import { useBreakpoint } from '@hh.ru/magritte-ui';
import Column from 'bloko/blocks/column';
import Gap from 'bloko/blocks/gap';
import Text, { TextImportance, TextSize } from 'bloko/blocks/text';

import { CONTENT_ID } from 'src/components/A11y/A11yConstants';
import ContactFeedbackBanner from 'src/components/EmployerSearch/ContactFeedbackBanner';
import { useBannerProps } from 'src/components/EmployerSearch/ContactFeedbackBanner/hooks/useBannerProps';
import { usePrint } from 'src/hooks/usePrint';
import { useProfile } from 'src/hooks/useProfile';
import { useSelector } from 'src/hooks/useSelector';
import { UserType } from 'src/models/userType';
import ResumeAnonymousWarning from 'src/pages/ResumeView/components/ResumeAnonymous/Warning';
import ResumeEmployerTransitions from 'src/pages/ResumeView/components/ResumeTransitions';
import ResumePosition from 'src/pages/ResumeView/components/View/ResumePosition';
import { useGetMColumnSize } from 'src/pages/ResumeView/hooks/useGetMColumnSize';
import { EDIT, VIEW } from 'src/utils/constants/resumePermission';

import EmployerBlackList from 'src/pages/ResumeView/components/ResumeHeader/BlackList';
import CandidateForVacancy from 'src/pages/ResumeView/components/ResumeHeader/CandidateForVacancy';
import ResumeContacts from 'src/pages/ResumeView/components/ResumeHeader/Contacts/ResumeContacts';
import FindJobHhPro from 'src/pages/ResumeView/components/ResumeHeader/FindJobHhPro';
import Map from 'src/pages/ResumeView/components/ResumeHeader/Map';
import ResumeButtons from 'src/pages/ResumeView/components/ResumeHeader/ResumeButtons';
import ResumeCrosspostedBadge from 'src/pages/ResumeView/components/ResumeHeader/ResumeCrosspostedBadge';
import ResumeJobSearchStatus from 'src/pages/ResumeView/components/ResumeHeader/ResumeJobSearchStatus';
import ResumeLastActivityTime from 'src/pages/ResumeView/components/ResumeHeader/ResumeLastActivityTime';
import ResumeMedia from 'src/pages/ResumeView/components/ResumeHeader/ResumeMedia';
import ResumeName from 'src/pages/ResumeView/components/ResumeHeader/ResumeName';
import ResumePersonalGender from 'src/pages/ResumeView/components/ResumeHeader/ResumePersonalGender';
import ResumePersonalLocation from 'src/pages/ResumeView/components/ResumeHeader/ResumePersonalLocation';
import ResumeUpdateDate from 'src/pages/ResumeView/components/ResumeHeader/ResumeUpdateDate';
import WantWork from 'src/pages/ResumeView/components/ResumeHeader/WantWork';

const ResumeHeader = ({ isEmptyResume, permission, userType }) => {
    const canEdit = permission === EDIT;
    const isEmployer = userType === UserType.Employer;
    const isAnonymous = userType === UserType.Anonymous;
    const isProfile = useProfile();
    const isPrint = usePrint();

    const { isMobile } = useBreakpoint();
    const { mMain, mSidebar } = useGetMColumnSize();
    const isAvailableHhPro = useSelector(({ isAvailableHhPro }) => isAvailableHhPro);
    const resume = useSelector(({ resume }) => resume);

    const [isBannerVisible, handleContactClick] = useBannerProps({
        resumeHash: resume.hash,
        shouldShowBanner: resume.showContactSuccessBanner,
        isAllowedPage: true,
    });

    const renderAdditional = () => {
        let content;
        if (!isEmptyResume && (canEdit || isEmployer)) {
            content = (
                <>
                    <div className="resume-header-separator" />
                    <ResumeButtons />
                    <div className="resume-header-additional__update-date">
                        <Text size={TextSize.Small} importance={TextImportance.Tertiary}>
                            <ResumeUpdateDate />
                        </Text>
                    </div>
                    {isAvailableHhPro && <FindJobHhPro />}
                </>
            );
        } else if (permission === VIEW && !isAnonymous) {
            content = (
                <>
                    <div className="resume-header-separator" />
                    <ResumeButtons />
                </>
            );
        }
        return (
            <div className="resume-header-additional">
                <Column xs="4" s="8" m={mSidebar} l="4">
                    {content}
                </Column>
                <CandidateForVacancy />
            </div>
        );
    };

    return (
        <Column xs="4" s="8" m="12" l="16" container>
            <div className="resume-header-wrapper">
                <div className="resume-header">
                    <Column xs="4" s="8" m={mMain} l="12">
                        <Gap bottom={!isProfile || isMobile}>
                            <ResumeEmployerTransitions />
                            <div className="resume-header-main" id={CONTENT_ID}>
                                <div className="resume-header-title">
                                    <div className="resume-header-photo-mobile" data-qa="resume-photo-mobile">
                                        <ResumeMedia />
                                        <ResumeCrosspostedBadge />
                                    </div>
                                    {isProfile && <ResumePosition />}
                                    {!isProfile && !isPrint && <ResumeLastActivityTime />}

                                    {!isProfile && <ResumeName />}

                                    <ResumeAnonymousWarning />
                                    {isEmployer && <EmployerBlackList />}
                                    <ResumePersonalGender />
                                    <ResumeJobSearchStatus />
                                    <ResumePersonalLocation />
                                    {isBannerVisible && (
                                        <ContactFeedbackBanner
                                            paddingSize="large"
                                            resumeHash={resume.hash}
                                            limitedWidth
                                        />
                                    )}
                                    <ResumeContacts onContactsClick={handleContactClick} />
                                    {isEmployer && <WantWork />}
                                    {(canEdit || isEmployer) && (
                                        <div className="resume-header-print-update-date">
                                            <ResumeUpdateDate />
                                        </div>
                                    )}
                                </div>
                                <div className="resume-header-photo-desktop" data-qa="resume-photo-desktop">
                                    <ResumeMedia />
                                    <ResumeCrosspostedBadge />
                                </div>
                            </div>
                            <Map />
                        </Gap>
                    </Column>
                    {renderAdditional()}
                </div>
            </div>
        </Column>
    );
};

export default connect((state) => ({
    permission: state.resume?.permission,
    isEmptyResume: state.resume?.percent === 0,
    userType: state.userType,
}))(ResumeHeader);
