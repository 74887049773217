import { useSelector } from '@hh.ru/front-static-app';
import { VSpacingContainer, CellRightLabel } from '@hh.ru/magritte-ui';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import translation from 'src/components/translation';
import ResumeBlock from 'src/pages/ResumeView/employerRedesign/components/ResumeBlock';
import ResumeEducationItem from 'src/pages/ResumeView/employerRedesign/components/ResumeEducation/ResumeEducationItem';

const TrlKeys = {
    title: 'resume.block.education.tests.title',
    proftestTitle: 'resume.block.education.tests.proftest.title',
    proftestHeadHunter: 'resume.block.education.tests.proftest.headhunter',
    proftestResult: 'resume.block.education.tests.proftest.result',
};

const ResumeEducationTests: TranslatedComponent = ({ trls }) => {
    const tests = useSelector((state) => state.resume?.attestationEducation.value);
    const proftest = useSelector((state) => state.resume?.proftest.value);
    const proftestAvailable = proftest?.visible && proftest?.attached;

    if (!(tests?.length || proftestAvailable)) {
        return null;
    }

    return (
        <ResumeBlock title={trls[TrlKeys.title]}>
            <VSpacingContainer default={12}>
                {tests?.map(({ id, name, year, organization, result }) => (
                    <ResumeEducationItem key={id} name={name} year={year} organization={organization} result={result} />
                ))}
                {proftestAvailable && (
                    <ResumeEducationItem
                        name={trls[TrlKeys.proftestTitle]}
                        organization={trls[TrlKeys.proftestHeadHunter]}
                        link={proftest.link}
                        right={<CellRightLabel hideIcon={false}>{trls[TrlKeys.proftestResult]}</CellRightLabel>}
                    />
                )}
            </VSpacingContainer>
        </ResumeBlock>
    );
};

export default translation(ResumeEducationTests);
