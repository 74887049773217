import { ComponentType } from 'react';

import { useBreakpoint, Button, Action } from '@hh.ru/magritte-ui';
import { PenOutlinedSize24 } from '@hh.ru/magritte-ui/icon';

import translation from 'src/components/translation';

import styles from './styles.less';

interface Props {
    onClick: () => void;
}

const TrlKeys = {
    buttonText: 'applciant.resume.contacts.button.text.mobile',
};

const EditorButton: ComponentType<Props> = translation(({ trls, onClick }) => {
    const { isMobile } = useBreakpoint();

    if (isMobile) {
        return (
            <Button mode="secondary" style="neutral" onClick={onClick}>
                {trls[TrlKeys.buttonText]}
            </Button>
        );
    }

    return (
        <div className={styles.pin}>
            <Action
                mode="secondary"
                style="neutral"
                disablePadding
                showBackground={false}
                onClick={onClick}
                icon={PenOutlinedSize24}
            />
        </div>
    );
});

export default EditorButton;
