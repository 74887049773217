import { useMemo } from 'react';

import { VSpacingContainer, Text, Action } from '@hh.ru/magritte-ui';
import { QuestionCircleOutlinedSize16 } from '@hh.ru/magritte-ui/icon';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import translation from 'src/components/translation';
import { useOnOffState } from 'src/hooks/useOnOffState';
import { useSelector } from 'src/hooks/useSelector';
import type { KeySkill } from 'src/models/resume/resumeCommon.types';
import { VerifiedSkillCategory } from 'src/models/resume/resumeVerifiedSkills.types';
import { SkillsLevelsKeys } from 'src/models/skills';
import ResumeBlock from 'src/pages/ResumeView/employerRedesign/components/ResumeBlock';
import ResumeTagsContainer from 'src/pages/ResumeView/employerRedesign/components/ResumeTagsContainer';
import { SKILLS_LEVELS_BY_RANK, SKILLS_LEVELS_ORDER } from 'src/utils/constants/skills';

import ResumeSkillsItem from 'src/pages/ResumeView/employerRedesign/components/ResumeSkills/ResumeSkillsItem';
import ResumeSkillsModal from 'src/pages/ResumeView/employerRedesign/components/ResumeSkills/ResumeSkillsModal';

const TrlKeys = {
    title: 'rb.title.keySkills',
    levels: {
        [SkillsLevelsKeys.NONE]: 'skillLevels.level.none',
        [SkillsLevelsKeys.BASIC]: 'skillLevels.level.basic',
        [SkillsLevelsKeys.MEDIUM]: 'skillLevels.level.medium',
        [SkillsLevelsKeys.ADVANCED]: 'skillLevels.level.advanced',
    },
};

const ResumeSkills: TranslatedComponent = ({ trls }) => {
    const [infoModalVisibility, showInfoModal, hideInfoModal] = useOnOffState(false);

    const skills = useSelector((state) => state.resume?.advancedKeySkills.value);
    const resumeApplicantSkills = useSelector((state) => state.resume?.resumeApplicantSkills);

    const skillsByLevel = useMemo(() => {
        return skills?.reduce<Partial<Record<SkillsLevelsKeys, KeySkill[]>>>((skillsByLevel, skill) => {
            const currentSkill = resumeApplicantSkills?.find(
                (vSkill) => vSkill.id === skill.id && vSkill.category === VerifiedSkillCategory.Skill
            );
            const skillLevel = SKILLS_LEVELS_BY_RANK[currentSkill?.level?.rank || 0];
            (skillsByLevel[skillLevel] ??= []).push(skill);

            return skillsByLevel;
        }, {});
    }, [resumeApplicantSkills, skills]);
    const levels = useMemo(
        () =>
            Object.keys(skillsByLevel).sort((a, b) => SKILLS_LEVELS_ORDER.indexOf(a) - SKILLS_LEVELS_ORDER.indexOf(b)),
        [skillsByLevel]
    );

    if (!skills?.length || !skillsByLevel) {
        return null;
    }

    const hideLevelTitle = levels.length === 1 && levels[0] === SkillsLevelsKeys.NONE;
    const showInfoAction = levels.length > 1 || (levels.length === 1 && levels[0] !== SkillsLevelsKeys.NONE);

    return (
        <>
            {infoModalVisibility && <ResumeSkillsModal visible={infoModalVisibility} onClose={hideInfoModal} />}
            <ResumeBlock
                title={trls[TrlKeys.title]}
                actions={
                    showInfoAction && (
                        <Action
                            mode="secondary"
                            icon={QuestionCircleOutlinedSize16}
                            disablePadding
                            onClick={() => showInfoModal()}
                        />
                    )
                }
                data-qa="skills-table"
            >
                <VSpacingContainer default={24}>
                    {levels.map((level) => {
                        return (
                            <VSpacingContainer key={level} default={8}>
                                {!hideLevelTitle && (
                                    <Text
                                        style="secondary"
                                        typography="label-3-regular"
                                        data-qa={`skill-level-title-${level}`}
                                    >
                                        {trls[TrlKeys.levels[level]]}
                                    </Text>
                                )}
                                <ResumeTagsContainer>
                                    {skillsByLevel[level]!.map((skill) => {
                                        return <ResumeSkillsItem key={skill.id} skill={skill} />;
                                    })}
                                </ResumeTagsContainer>
                            </VSpacingContainer>
                        );
                    })}
                </VSpacingContainer>
            </ResumeBlock>
        </>
    );
};

export default translation(ResumeSkills);
